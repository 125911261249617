import { IconEnum } from "@incident-ui";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { QuoteContentBox } from "./QuoteContextBox";
import { TimelineItemRenderProps } from "./TimelineItem";
import { NewValue, PreviousValue } from "./TimelineItemCustomFieldValueUpdate";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemIncidentTimestampSet = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_timestamp_set) {
    throw new Error(
      "malformed timeline item: incident_timestamp_set was missing incident_timestamp_set field",
    );
  }

  const hasPrevious =
    !!item.incident_timestamp_set.incident_timestamp_old_value?.value;
  const hasNext =
    !!item.incident_timestamp_set.incident_timestamp_new_value?.value;
  let verb: string;
  if (hasPrevious && hasNext) {
    verb = "updated";
  } else if (hasPrevious && !hasNext) {
    verb = "unset";
  } else {
    verb = "set";
  }

  return {
    icon: IconEnum.Clock,
    description: (
      <>
        <TimelineItemSource actor={item.incident_timestamp_set.creator} />{" "}
        {verb} the{" "}
        <span className="font-semibold">
          {item.incident_timestamp_set.incident_timestamp.name}
        </span>{" "}
        timestamp
      </>
    ),
    avatarUrl: item.incident_timestamp_set.creator.user?.avatar_url,
    children: (
      <QuoteContentBox>
        {hasPrevious && (
          <PreviousValue>
            {item.incident_timestamp_set.incident_timestamp_old_value.value?.toLocaleString()}
          </PreviousValue>
        )}
        {hasNext && (
          <NewValue>
            {item.incident_timestamp_set.incident_timestamp_new_value.value?.toLocaleString()}
          </NewValue>
        )}
      </QuoteContentBox>
    ),
  };
};
