import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemReport = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.report) {
    throw new Error("malformed timeline item: report was missing report field");
  }

  return {
    description: (
      <>
        Incident reported by <TimelineItemSource actor={item.report.actor} />
      </>
    ),
    avatarUrl: item.report.actor?.user?.avatar_url,
    icon: IconEnum.Exclamation,
  };
};
