import { TemplatedTextDisplayStyle } from "@incident-shared/forms/v1/TemplatedText";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { Button, ButtonSize, ButtonTheme } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  TimelineItemObject,
  TimelineItemsCreateRequestBodyItemTypeEnum,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./TimelineItems.module.scss";

export type NoteFormType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  text: any;
};

export const CreateEditTimelineNoteSection = ({
  incident,
  occurredAt,
  onClose,
  mode,
  initialData,
}: {
  incident: Incident;
  occurredAt: Date;
  onClose: () => void;
} & CreateEditFormProps<TimelineItemObject>): React.ReactElement => {
  const formMethods = useForm<NoteFormType>({
    defaultValues: { text: initialData?.timeline_note?.text },
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "timelineItemsList",
    { incidentId: incident.id },
    async (apiClient, data: NoteFormType) => {
      if (mode === Mode.Edit) {
        await apiClient.timelineItemsUpdate({
          id: initialData.id,
          updateRequestBody: {
            occured_at: initialData.occured_at,
            timeline_note: {
              text: data.text,
            },
          },
        });
      } else {
        await apiClient.timelineItemsCreate({
          createRequestBody: {
            item_type: TimelineItemsCreateRequestBodyItemTypeEnum.TimelineNote,
            incident_id: incident.id,
            occured_at: occurredAt,
            timeline_note: {
              text: data.text,
            },
          },
        });
      }
    },
    {
      onSuccess: () => {
        onClose();
      },
    },
  );

  return (
    <li className={tcx(styles.timelineNote)}>
      <Form.Root
        formMethods={formMethods}
        outerClassName={styles.noteBox}
        onSubmit={onSubmit}
        genericError={genericError}
        saving={saving}
      >
        <div className={tcx(styles.itemDescription, "!items-end")}>
          <TemplatedTextInputV2
            formMethods={formMethods}
            name="text"
            format="basic"
            placeholder="Add a note..."
            required
            className="mb-3"
            style={TemplatedTextDisplayStyle.Naked}
            autoFocus={true}
            includeVariables={false}
            includeExpressions={false}
          />
          <div className="flex gap-2">
            <Button
              title="Send"
              analyticsTrackingId="timeline-item-comment-save"
              type="submit"
              theme={ButtonTheme.Primary}
              size={ButtonSize.Small}
              loading={saving}
              disabled={saving}
            >
              Submit
            </Button>
            <Button
              title="Send"
              analyticsTrackingId="timeline-item-comment-save"
              type="reset"
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Small}
              disabled={saving}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form.Root>
    </li>
  );
};
