import { Incident } from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";

import { ActivityLogEntry } from "../../../contexts/ClientContext";
import { getProviderNameAndLogo } from "../../incident-calls/helpers";
import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemIncidentCallStarted = (
  inc: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_call_started) {
    throw new Error(
      "malformed timeline item: missing incident_call_started field",
    );
  }

  const { name } = getProviderNameAndLogo(
    item.content.incident_call_started.incident_call.external_provider,
  );

  return {
    title: name + " call started",
    icon: IconEnum.Call,
    colour: ColorPaletteEnum.Purple,
  };
};
