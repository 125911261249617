import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

export const ActivityItemIncidentRename = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_rename) {
    throw new Error(
      "malformed timeline item: incident_rename was missing incident_rename field",
    );
  }

  return {
    title: "Incident renamed",
    icon: IconEnum.TextItalic,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_rename.actor,
    quotedContent: (
      <div className="break-words">{item.content.incident_rename.to}</div>
    ),
  };
};
