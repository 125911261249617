import { ExpressionElseBranchInput } from "@incident-shared/engine/expressions/ExpressionElseBranchInput";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ButtonTheme,
  Callout,
  CalloutTheme,
  LoadingModal,
  ModalFooter,
  StackedList,
} from "@incident-ui";
import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import React, { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { EngineScope, Resource } from "src/contexts/ClientContext";
import { filterScope, mergeScopes } from "src/utils/scope";
import { v4 as uuidV4 } from "uuid";

import {
  EngineRefIsSelectable,
  isEmptyBinding,
  isExpression,
  MaybeRef,
} from "../..";
import {
  CreateEditExpressionFormData,
  ExpressionFormProps,
  ExpressionModalLabelOverrides,
} from "../AddEditExpressionModal";
import {
  ExpressionDeletionUsages,
  expressionIsInUse,
  getUsagesNode,
} from "../ExpressionsEditor";
import { useExpressionsMethods } from "../ExpressionsMethodsProvider";
import { ExpressionFormData } from "../expressionToPayload";
import { ExpressionFixedResultType } from "../ifelse/createDefaultExpressionFormValues";
import {
  QueryOperationsEditor,
  refMatchesFixedResult,
  validateQueryOperationFilterFields,
} from "./QueryOperationsEditor";
import { useCatalogScope } from "./useCatalogScope";
import { useGetPreviousReference } from "./useGetPreviousReference";
import { ViewQueryExpression } from "./ViewQueryExpression";

export const QueryExpressionEditModal = ({
  initialExpression,
  defaultRootReference,
  onAddExpression,
  onEditExpression,
  onClose,
  analyticsTrackingContext,
  scope: fullScope,
  resources,
  existingExpressions,
  fixedResult,
  currentlyInUse,
  onDeleteExpression,
  labelOverrides,
  validateReturnType,
  elseBranchRequired,
}: ExpressionFormProps): React.ReactElement => {
  const { showExpressionNames, allowAllOfACatalogType } =
    useExpressionsMethods();

  const isEditingExistingExpression = !!initialExpression;
  const formMethods = useForm<CreateEditExpressionFormData>({
    defaultValues: initialExpression
      ? initialExpression
      : {
          reference: uuidV4().split("-")[0],
          label: fixedResult ? fixedResult.label : "",
          root_reference: defaultRootReference,
        },
    shouldUnregister: false,
  });

  const { setError, clearErrors, reset } = formMethods;

  const { catalogScope, catalogScopeLoading, catalogScopeError } =
    useCatalogScope({
      allowAllOfACatalogType,
    });

  // Exclude other expression results, as we don't know what order they'll be
  // evaluated.
  let scope = filterScope(fullScope, (ref) => {
    return !isExpression(ref.key);
  });

  if (allowAllOfACatalogType) {
    scope = mergeScopes(scope, catalogScope);
  }

  const getPreviousRef = useGetPreviousReference({
    scope,
    resources,
    formMethods,
  });

  const onOk = (expression: ExpressionFormData) => {
    clearErrors();
    if (!expression.root_reference) {
      setError("root_reference", {
        type: "required",
        message: "You must choose a starting point",
      });
      return;
    }

    if (
      expression.else_branch &&
      isEmptyBinding(expression.else_branch.result)
    ) {
      if (elseBranchRequired) {
        if (expression.returns.array) {
          setError("else_branch.result.array_value", {
            type: "required",
            message: "This field is required",
          });
        } else {
          setError("else_branch.result.value", {
            type: "required",
            message: "This field is required",
          });
        }
        return;
      }
    }

    if (!expression.operations || expression.operations.length === 0) {
      setError("operations", {
        type: "required",
        message:
          "Please use the 'Then...' option to add an operation to your query",
      });
      return;
    }

    if (!validateQueryOperationFilterFields(expression.operations)) {
      setError("operations", {
        type: "required",
        message: "Filter operations must include at least one filter",
      });
      return;
    }

    if (!showExpressionNames) {
      expression.label =
        fixedResult?.label ?? "new-derived-custom-field-expression";
    }

    // If the user can customise the label, then validate it's unique.
    // If not, it's an automatically set label, so we don't need to validate it.
    if (!fixedResult?.label) {
      if (
        !isEditingExistingExpression &&
        existingExpressions.some((e) => e.label === expression.label)
      ) {
        setError("label", {
          type: "manual",
          message: "An expression with this name already exists",
        });
        return;
      }

      if (!expression.label || expression.label?.trim().length === 0) {
        setError("label", {
          type: "required",
          message: "Please provide an expression name",
        });
        return;
      }
    }

    const finalRef = getPreviousRef();
    if (!finalRef) {
      throw new Error(
        `Couldn't get previous ref - expected to get at least one ref`,
      );
    }

    expression.returns = {
      array: finalRef.array,
      type: finalRef.type,
    };

    if (fixedResult) {
      const fixedResultTypeMatches = refMatchesFixedResult(
        finalRef,
        fixedResult,
      );
      if (!fixedResultTypeMatches) {
        setError("operations", {
          type: "manual",
          // This is fully explained in an error callout, so this is just a backup incase
          // someone tries to add one anyway.
          message: `This query does not return the correct type`,
        });
        return;
      }
    }

    // TODO replace with validateReturnType
    if (validateReturnType) {
      const validateResult = validateReturnType(finalRef);
      const isValid = validateResult === undefined || validateResult === true;
      if (!isValid) {
        setError("operations", {
          type: "manual",
          // This is fully explained in an error callout, so this is just a backup incase
          // someone tries to add one anyway.
          message:
            validateResult || `This query does not return the correct type`,
        });
        return;
      }
    }

    if (isEditingExistingExpression) {
      onEditExpression(expression);
      reset();
    } else {
      onAddExpression(expression);
      reset();
    }
  };

  const modalTitle =
    labelOverrides?.modalTitle ??
    `${initialExpression ? `Edit expression` : `Add an expression`}${
      fixedResult?.label && showExpressionNames
        ? ` (${fixedResult?.label})`
        : ""
    }`;

  if (catalogScopeError) {
    return (
      <ErrorModal
        error={catalogScopeError}
        title={modalTitle}
        onClose={onClose}
      />
    );
  }

  if (catalogScopeLoading) {
    return <LoadingModal title={modalTitle} onClose={onClose} />;
  }

  let currentlyInUseTooltip: React.ReactNode | undefined;
  if (currentlyInUse) {
    currentlyInUseTooltip = (
      <>
        This expression is currently in use by {getUsagesNode(currentlyInUse)}{" "}
        and cannot be deleted.
      </>
    );
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      analyticsTrackingId={`${analyticsTrackingContext}-add-expression`}
      title={modalTitle}
      disableQuickClose
      onClose={onClose}
      onSubmit={onOk}
      isExtraLarge
      footer={
        <ModalFooter
          gadget={
            initialExpression && onDeleteExpression ? (
              <GatedButton
                analyticsTrackingId={null}
                theme={ButtonTheme.Destroy}
                onClick={() => {
                  onDeleteExpression(initialExpression);
                  reset();
                }}
                disabledTooltipContent={currentlyInUseTooltip}
                disabled={expressionIsInUse(currentlyInUse)}
              >
                Delete
              </GatedButton>
            ) : undefined
          }
          confirmButtonType="submit"
          onClose={onClose}
          confirmButtonText={initialExpression ? "Continue" : "Add"}
          disabled={expressionIsInUse(currentlyInUse)}
        />
      }
    >
      <QueryExpressionEditForm
        fixedResult={fixedResult}
        resources={resources}
        initialExpression={initialExpression}
        scope={scope}
        currentlyInUse={currentlyInUse}
        labelOverrides={labelOverrides}
        elseBranchRequired={elseBranchRequired}
      />
    </Form.Modal>
  );
};

type QueryExpressionEditFormProps = {
  initialExpression?: ExpressionFormData;
  scope: EngineScope;
  resources: Resource[];
  fixedRootReference?: string;
  fixedResult?: ExpressionFixedResultType;
  currentlyInUse?: ExpressionDeletionUsages;
  validateReturnType?: EngineRefIsSelectable;
  elseBranchRequired?: boolean;
  labelOverrides?: ExpressionModalLabelOverrides;
};

export const UNKNOWN_TYPE = "UNKNOWN_TYPE";

export const QueryExpressionEditForm = ({
  fixedRootReference,
  fixedResult,
  resources,
  scope,
  currentlyInUse,
  labelOverrides,
  validateReturnType,
  elseBranchRequired,
}: QueryExpressionEditFormProps): React.ReactElement | null => {
  const formMethods = useFormContext<CreateEditExpressionFormData>();
  const isCurrentlyInUse = expressionIsInUse(currentlyInUse);
  const { showExpressionNames } = useExpressionsMethods();

  const getPreviousRef = useGetPreviousReference({
    scope,
    resources,
    formMethods,
  });

  // Register ourselves as the controller of the `operations` form key, so
  // that `FormErrors` doesn't think that errors on that key are not being
  // rendered elsewhere and double-show them.
  const { register, unregister } = formMethods;
  useEffect(() => {
    register("operations");

    return () => unregister("operations");
  }, [register, unregister]);

  const previousRef = getPreviousRef();

  const isValidType = (ref: MaybeRef): boolean => {
    if (!validateReturnType) return true;
    const res = validateReturnType(ref);
    if (res === undefined) {
      return true;
    }
    if (typeof res === "string") {
      // This means it's an error message: so not valid
      return false;
    }

    return res;
  };

  // Apply the fixed root reference if it's provided.
  const rootReference = formMethods.watch("root_reference");
  if (fixedRootReference && !rootReference) {
    formMethods.setValue<"root_reference">(
      "root_reference",
      fixedRootReference,
    );
  }

  return (
    <div className="space-y-4">
      {/* Label */}
      {showExpressionNames && (
        <InputV2
          formMethods={formMethods}
          name="label"
          placeholder={"e.g. Team Slack Channel"}
          label={
            labelOverrides?.nameLabel ??
            "What should this expression be called?"
          }
          helptext={
            labelOverrides?.helpText ??
            "This is how you'll refer to your expression later on in the workflow."
          }
          autoComplete="none"
          disabled={isCurrentlyInUse}
        />
      )}
      {isCurrentlyInUse ? (
        <div className="space-y-4">
          <Callout theme={CalloutTheme.Plain}>
            This expression is currently in use by{" "}
            {getUsagesNode(currentlyInUse)} and cannot be edited.
          </Callout>
          <StackedList>
            <ViewQueryExpression
              expression={formMethods.getValues()}
              scope={scope}
              resources={resources}
            />
          </StackedList>
        </div>
      ) : (
        <QueryOperationsEditor
          fixedRootReference={fixedRootReference}
          scope={scope}
          resources={resources}
          fixedResult={fixedResult}
        />
      )}
      <ExpressionElseBranchInput
        disabled={isCurrentlyInUse}
        label={"What should be used if the query returns nothing?"}
        scope={scope}
        resourceType={previousRef?.type}
        isArray={previousRef?.array ?? false}
        availableReturnTypeResources={resources.filter((resource) =>
          isValidType({ resource, array: previousRef?.array ?? false }),
        )}
        required={elseBranchRequired}
      />
    </div>
  );
};
