import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import {
  ActivityLogEntry,
  EnrichedMicrosoftTeamsMessage,
  Incident,
} from "src/contexts/ClientContext";

export const ActivityItemMicrosoftTeamsMessagePinned = (
  incident: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  const pin = item.content.microsoft_teams_pin;

  if (!pin) {
    throw new Error("malformed timeline item");
  }

  return {
    title: "Message pinned",
    icon: IconEnum.User,
    colour: ColorPaletteEnum.Purple,
    unquotedContent: pin.message && (
      <ActivityItemMicrosoftTeamsMessagePinnedContent message={pin.message} />
    ),
  };
};

const ActivityItemMicrosoftTeamsMessagePinnedContent = ({
  message,
}: {
  message: EnrichedMicrosoftTeamsMessage;
}) => {
  return <>{message.subject}</>;
};
