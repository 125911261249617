import { InsightsDateRange, Panel } from "@incident-io/api";
import {
  DateRangePickerMode,
  DateRangePickerState,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";

import { InsightsDateRangeState, PanelFormData } from "./useInsightsContext";

export const panelToFormData = (panel: Panel): PanelFormData => {
  const variables = {};

  if (panel.variables) {
    panel.variables.forEach((variable) => {
      variables[variable.form_field.key] = variable.value;
    });
  }

  return {
    ...panel,
    key: panel.key || panel.name,
    variables_data: variables,
  };
};

export const dateRangeToFormState = (
  dateRange: InsightsDateRange,
): InsightsDateRangeState => {
  let mode = dateRange.mode as unknown as DateRangePickerMode;
  let quickSelect = QuickSelectInterval.Last4Weeks;

  // Is it a quick select range?
  if (dateRange.relative) {
    const data = dateRange.relative;
    Object.entries(QUICK_SELECT_INTERVAL_CONFIG).forEach(
      ([interval, { state }]) => {
        const numMatch = state.numberOfIntervals === data.number_of_intervals;
        const intervalMatch = state.interval === data.interval;
        const includeMatch =
          state.includeThisInterval === data.include_this_interval;

        if (numMatch && intervalMatch && includeMatch) {
          quickSelect = interval as QuickSelectInterval;
          mode = DateRangePickerMode.QuickSelect;
        }
      },
    );
  }

  return {
    is_comparison: dateRange.compare_previous_period,
    aggregation: dateRange.aggregation,
    range: {
      mode,
      quick_select: quickSelect,
      relative: dateRange.relative
        ? {
            interval: dateRange.relative.interval,
            numberOfIntervals: dateRange.relative.number_of_intervals,
            includeThisInterval: dateRange.relative.include_this_interval,
          }
        : undefined,
      absolute: dateRange.absolute,
    } as DateRangePickerState,
  };
};
