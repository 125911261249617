import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

export const ActivityItemIncidentTypeChanged = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_type_changed) {
    throw new Error(
      "malformed timeline item: incident_type_changed was missing incident_type_changed field",
    );
  }

  let title =
    "Incident type changed to " + item.content.incident_type_changed?.to.name;
  if (item.content.incident_type_changed?.from.name) {
    title =
      "Incident type changed from " +
      item.content.incident_type_changed?.from.name +
      " to " +
      item.content.incident_type_changed?.to.name;
  }

  return {
    title: title,
    icon: IconEnum.IncidentType,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_type_changed?.actor,
  };
};
