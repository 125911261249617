import { DevTool } from "@hookform/devtools";
import { Modal, ModalContent } from "@incident-ui";
import { ModalProps } from "@incident-ui/Modal/Modal";
import { TabModal, TabModalProps } from "@incident-ui/TabModal/TabModal";
import React, { useMemo } from "react";
import { FieldValues, FormProvider } from "react-hook-form";
import { Environment, getEnvironment } from "src/utils/environment";
import { tcx } from "src/utils/tailwind-classes";

import { FormErrors } from "../ErrorMessage";
import { FormRootProps } from "./Root";

type FormModalProps<FormData extends FieldValues> = FormRootProps<FormData> &
  Omit<ModalProps, "as" | "onSubmit" | "isOpen"> & {
    footer?: React.ReactNode;
    contentClassName?: string;
    onClose: () => void;
    bgGrey?: boolean;
  };

export const FormModal = <FormData extends FieldValues>({
  genericError,
  formMethods,
  onSubmit,
  children,
  footer,
  contentClassName,
  warnWhenDirty,
  overrideIsDirty,
  bgGrey,
  ...modalProps
}: FormModalProps<FormData>): React.ReactElement => {
  const { handleSubmit, formState, control } = formMethods;
  // if disableQuickClose is not defined, check warnWhenDirty
  // if that is not defined, default to true
  const disableQuickClose =
    modalProps.disableQuickClose ?? warnWhenDirty ?? true;
  const isDirty =
    overrideIsDirty ?? (formState.isDirty && !formState.isSubmitSuccessful);

  const environment = useMemo(() => getEnvironment(), []);

  return (
    <Modal
      isOpen
      {...modalProps}
      as="form"
      onSubmit={(e) => {
        // Prevent this portal'd modal from submitting the parent one
        // https://github.com/facebook/react/issues/19637#issuecomment-811096805
        e.stopPropagation();

        // We're surprised React's handleSubmit doesn't already do this for us, but we need to stop
        // people from double submitting a form by hitting enter twice.
        if (formState.isSubmitting) {
          return;
        }
        handleSubmit(onSubmit)(e);
      }}
      {...(disableQuickClose && {
        disableQuickClose: isDirty,
      })}
    >
      <FormProvider {...formMethods}>
        {environment === Environment.Development && (
          <DevTool placement={"top-right"} control={formMethods.control} />
        )}
        {/* We do *not* apply styles.form here: we want helptext to fill the whole modal */}
        <ModalContent
          className={tcx("space-y-4", contentClassName)}
          backgroundColor={bgGrey ? "grey" : undefined}
        >
          <FormErrors
            errors={formState.errors}
            fields={control._fields}
            genericError={genericError}
          />
          {children}
        </ModalContent>
        {footer}
      </FormProvider>
    </Modal>
  );
};

export const FormTabModal = <FormData extends FieldValues>({
  genericError,
  formMethods,
  onSubmit,
  children,
  footer,
  contentClassName,
  warnWhenDirty,
  overrideIsDirty,
  ...modalProps
}: FormRootProps<FormData> &
  Omit<TabModalProps, "as" | "onSubmit" | "isOpen"> & {
    footer?: React.ReactNode;
    contentClassName?: string;
    onClose: () => void;
  }): React.ReactElement => {
  const { handleSubmit, formState, control } = formMethods;
  // if disableQuickClose is not defined, check warnWhenDirty
  // if that is not defined, default to true
  const disableQuickClose =
    modalProps.disableQuickClose ?? warnWhenDirty ?? true;
  const isDirty =
    overrideIsDirty ?? (formState.isDirty && !formState.isSubmitSuccessful);

  return (
    <FormProvider {...formMethods}>
      <TabModal
        isOpen
        footer={footer}
        {...modalProps}
        as="form"
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
          // Prevent this portal'd modal from submitting the parent one
          // https://github.com/facebook/react/issues/19637#issuecomment-811096805
          e.stopPropagation();
        }}
        {...(disableQuickClose && {
          disableQuickClose: isDirty,
        })}
      >
        <div className={tcx("space-y-4", contentClassName)}>
          <FormErrors
            errors={formState.errors}
            fields={control._fields}
            genericError={genericError}
          />
          {children}
        </div>
      </TabModal>
    </FormProvider>
  );
};
