import {
  GroupedIncidentTimelineElement,
  IncidentTimelineElementTypeEnum,
  IncidentTimelineItem,
} from "@incident-io/api";
import { Button, ButtonSize, EmptyState, IconEnum, Loader } from "@incident-ui";
import React, { useState } from "react";
import { useIncident } from "src/components/legacy/incident/hooks";
import { getShortTimeZone } from "src/utils/datetime";
import { useNavigateToModal } from "src/utils/query-params";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineDateGroup } from "./TimelineDateGroup";
import { TimelineGap } from "./TimelineGap";
import { TimelineItem } from "./TimelineItem";
import { TimelineSpacer } from "./TimelineSpacer";

type TimelineProps = {
  incidentId: string | null;
  editing?: boolean;
  expanded: string[];
  commentBoxOpen: string[];
  setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
  setCommentBoxOpen: React.Dispatch<React.SetStateAction<string[]>>;
  setEditing?: React.Dispatch<
    React.SetStateAction<IncidentTimelineItem | undefined>
  >;
};

export const Timeline = ({
  incidentId,
  editing,
  expanded,
  setExpanded,
  commentBoxOpen,
  setCommentBoxOpen,
  setEditing,
}: TimelineProps) => {
  const { incident } = useIncident(incidentId);
  const { data: groupedTimelineItems, isLoading } = useAPI(
    incidentId ? "incidentTimelineListTimelineItems" : null,
    {
      incidentId: incidentId ?? "",
    },
    { fallbackData: { timeline_items: [] } },
  );
  const { trigger: deleteTimelineItem } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incidentId ?? "",
    },
    async (client, data: { incidentId: string; timelineItemId: string }) => {
      return await client.incidentTimelineDestroyTimelineItem({
        incidentId: data.incidentId,
        timelineItemId: data.timelineItemId,
      });
    },
  );

  // When a user clicks on an image in the activity log, we'll pop it open in a modal.
  const [zoomImageSource, setZoomImageSource] = useState<string | undefined>();

  const timelineItems = groupedTimelineItems?.timeline_items ?? [];

  const setItemExpanded = (itemId: string, expanded: boolean) => {
    setExpanded((prevExpanded) =>
      expanded
        ? [...prevExpanded, itemId]
        : prevExpanded.filter((id) => id !== itemId),
    );
  };

  const setSingleCommentBoxOpen = (itemId: string, open: boolean) => {
    setCommentBoxOpen((prevOpen) =>
      open ? [...prevOpen, itemId] : prevOpen.filter((id) => id !== itemId),
    );
  };

  const onDelete = ({
    incidentId,
    itemId,
  }: {
    incidentId: string;
    itemId: string;
  }) => {
    deleteTimelineItem({ incidentId, timelineItemId: itemId });
  };

  if (!incident || isLoading) {
    return <Loader />;
  }

  if (timelineItems.length === 0) {
    return (
      <div className="flex flex-col gap-2">
        {!editing && (
          <TimelineHeader
            expanded={expanded}
            setExpanded={setExpanded}
            timelineItems={timelineItems}
          />
        )}
        <EmptyState
          content="You have removed all of your items from the timeline."
          icon={IconEnum.Activity}
        />
      </div>
    );
  }

  return (
    <>
      {timelineItems.map((groupedTimelineItem, groupIndex) => (
        <TimelineDateGroup
          key={groupedTimelineItem.date.toString()}
          date={groupedTimelineItem.date}
          className={tcx(groupIndex === 0 && "mb-4")}
          actions={
            !editing ? (
              <TimelineHeader
                expanded={expanded}
                setExpanded={setExpanded}
                timelineItems={timelineItems}
              />
            ) : undefined
          }
        >
          {groupIndex > 0 && <TimelineSpacer />}
          {groupedTimelineItem.items.map((element, elementIndex) => {
            const isLast =
              elementIndex === groupedTimelineItem.items.length - 1 &&
              groupIndex === timelineItems.length - 1;

            // Refactored conditional rendering using switch
            switch (element.type) {
              case IncidentTimelineElementTypeEnum.TimelineItem: {
                const timelineItem = element.timeline_item;
                if (timelineItem === undefined) {
                  return null;
                }
                return (
                  <TimelineItem
                    key={timelineItem.id}
                    id={timelineItem.id}
                    description={timelineItem.description}
                    incident={incident}
                    title={timelineItem.title}
                    timestamp={timelineItem.timestamp}
                    evidence={timelineItem.evidence}
                    comments={timelineItem.comments}
                    hideSpacer={isLast}
                    allowCommenting={!editing}
                    expanded={expanded.includes(timelineItem.id)}
                    commentBoxOpen={commentBoxOpen.includes(timelineItem.id)}
                    setExpanded={(expanded) =>
                      setItemExpanded(timelineItem.id, expanded)
                    }
                    setCommentBoxOpen={(open) =>
                      setSingleCommentBoxOpen(timelineItem.id, open)
                    }
                    onEdit={
                      setEditing ? () => setEditing(timelineItem) : undefined
                    }
                    onDelete={
                      editing
                        ? () =>
                            onDelete({
                              incidentId: timelineItem.incident_id,
                              itemId: timelineItem.id,
                            })
                        : undefined
                    }
                    zoomImageSource={zoomImageSource}
                    setZoomImageSource={setZoomImageSource}
                  />
                );
              }
              case IncidentTimelineElementTypeEnum.TimeGap: {
                const gapItem = element.timeline_gap;
                if (gapItem === undefined) {
                  return null;
                }
                return (
                  <TimelineGap
                    key={gapItem.duration}
                    duration={gapItem.duration}
                  />
                );
              }
              default:
                return null;
            }
          })}
        </TimelineDateGroup>
      ))}
    </>
  );
};

type TimelineHeaderProps = {
  expanded: string[];
  setExpanded: React.Dispatch<React.SetStateAction<string[]>>;
  timelineItems: GroupedIncidentTimelineElement[];
};

const TimelineHeader = ({
  expanded,
  setExpanded,
  timelineItems,
}: TimelineHeaderProps) => {
  const navigateToModal = useNavigateToModal();

  return (
    <div className="flex-center-y justify-end gap-2">
      <div className="text-xs-med text-content-tertiary pr-1">
        Times shown in {getShortTimeZone(new Date())}
      </div>
      <ExpandTimelineItemsButton
        expanded={expanded}
        setExpanded={setExpanded}
        timelineItems={timelineItems}
      />
      <Button
        icon={IconEnum.Activity}
        size={ButtonSize.Small}
        analyticsTrackingId={"view-read-only-activity-log"}
        onClick={() => navigateToModal("activity-log")}
      >
        View all activity
      </Button>
      <Button
        icon={IconEnum.Edit}
        size={ButtonSize.Small}
        analyticsTrackingId={"edit-timeline"}
        onClick={() => navigateToModal("edit-timeline")}
      >
        Edit
      </Button>
    </div>
  );
};

export const ExpandTimelineItemsButton = ({
  expanded,
  setExpanded,
  timelineItems,
}: {
  expanded: string[];
  setExpanded: (expanded: string[]) => void;
  timelineItems: GroupedIncidentTimelineElement[];
}) => {
  const hasExpandedItems = expanded.length > 0;

  const openAll = () => {
    setExpanded(
      timelineItems
        .flatMap((i) => i.items)
        .filter((i) => i.timeline_item)
        .map((item) => item.timeline_item?.id ?? ""),
    );
  };

  const closeAll = () => {
    setExpanded([]);
  };

  return (
    <Button
      analyticsTrackingId={"timeline-expand-all"}
      onClick={hasExpandedItems ? closeAll : openAll}
      size={ButtonSize.Small}
      icon={hasExpandedItems ? IconEnum.Collapse : IconEnum.Expand}
    >
      {hasExpandedItems ? "Collapse all" : "Expand all"}
    </Button>
  );
};
