import {
  Incident,
  IncidentTimelineItem,
  TextDocumentPayload,
  TimelineItemComment,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  ContentBox,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  LocalDateTime,
} from "@incident-ui";
import { useMemo } from "react";
import { tcx } from "src/utils/tailwind-classes";

import { ActivityItemTypeToComponentMap } from "../activity-items/ActivityItem";
import {
  ActivityLogItemMode,
  ActivityLogItemUIWrapperV2,
} from "../ActivityLogItemUIV2";
import { TimelineItemCommentBox } from "./TimelineItemComments";
import { TimelineSpacer } from "./TimelineSpacer";

type TimelineItemProps = {
  id: string;
  incident: Incident;
  title: string;
  timestamp: Date;
  description?: TextDocumentPayload;
  evidence?: IncidentTimelineItem["evidence"];
  hideSpacer?: boolean;
  comments?: TimelineItemComment[];
  expanded: boolean;
  commentBoxOpen: boolean;
  allowCommenting?: boolean;
  setExpanded: (expanded: boolean) => void;
  setCommentBoxOpen: (open: boolean) => void;
  onEdit?: () => void;
  onDelete?: () => void;
  zoomImageSource: string | undefined;
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void;
};

type IconDetails = {
  icon: IconEnum;
  color: ColorPaletteEnum;
};

export const TimelineItem = ({
  id,
  title,
  description,
  timestamp,
  incident,
  allowCommenting = false,
  evidence = [],
  comments = [],
  hideSpacer = false,
  expanded,
  setExpanded,
  commentBoxOpen,
  setCommentBoxOpen,
  onEdit,
  onDelete,
  zoomImageSource,
  setZoomImageSource,
}: TimelineItemProps) => {
  const rollupIcon = useMemo<IconDetails>(() => {
    const activityLogEvidence = evidence.find((e) =>
      Boolean(e.incident_activity_log),
    );

    if (activityLogEvidence) {
      const activityLog = activityLogEvidence.incident_activity_log;

      if (activityLog) {
        const ActivityLogItemComponent =
          ActivityItemTypeToComponentMap[activityLog.type];
        if (ActivityLogItemComponent) {
          const activityProps = ActivityLogItemComponent(
            incident,
            activityLog,
            zoomImageSource,
            setZoomImageSource,
          );

          if (activityProps) {
            return {
              icon: activityProps.icon,
              color: activityProps.colour,
            };
          }
        }
      }
    }

    // If there's no component, it'll be a custom event.
    return {
      icon: IconEnum.Bookmark,
      color: ColorPaletteEnum.Pink,
    };
  }, [evidence, incident, zoomImageSource, setZoomImageSource]);

  const expandable = evidence.length > 0 || description || allowCommenting;

  return (
    <div>
      <div className="h-7 flex items-center space-x-5 group relative">
        <div className="w-9 text-content-tertiary">
          <LocalDateTime
            // LocalDateTime internally sets text-sm
            // so we haev to !important this bad boy
            className="!text-xs-med"
            timestamp={timestamp}
            format="HH:mm"
          />
        </div>
        <IconBadge
          icon={rollupIcon.icon}
          iconProps={{ size: IconSize.Small }}
          size={IconSize.Small}
          color={rollupIcon.color}
        />
        <div
          className={tcx(
            "flex items-center space-x-1 flex-grow",
            expandable && "cursor-pointer",
          )}
          onClick={() => (expandable ? setExpanded(!expanded) : undefined)}
        >
          <div className="text-sm-bold text-content-primary">{title}</div>
          <>
            <IconicNumberDisplay
              count={comments.length}
              icon={IconEnum.Message}
            />
          </>
          {expandable && (
            <Icon
              id={expanded ? IconEnum.ChevronUp : IconEnum.ChevronDown}
              size={IconSize.Small}
              className="text-content-secondary"
            />
          )}
        </div>
        <div className="ml-auto flex items-center space-x-2">
          {!onEdit && (
            <Button
              theme={ButtonTheme.Naked}
              size={ButtonSize.Small}
              iconPosition="left"
              icon={IconEnum.Message}
              analyticsTrackingId={null}
              title="Leave a comment"
              className="invisible group-hover:visible transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
              onClick={() => {
                setExpanded(true);
                setCommentBoxOpen(true);
                // Focus the comment box after a short delay to ensure it's rendered
                setTimeout(() => {
                  const commentBox = document.querySelector(
                    `[data-timeline-item-id="${id}"]`,
                  );
                  if (commentBox instanceof HTMLTextAreaElement) {
                    commentBox.focus();
                  }
                }, 100);
              }}
            >
              Leave comment
            </Button>
          )}
          {onEdit && (
            <Button
              size={ButtonSize.Small}
              icon={IconEnum.Edit}
              onClick={onEdit}
              analyticsTrackingId={null}
              title="Edit"
            />
          )}
          {onDelete && (
            <Button
              size={ButtonSize.Small}
              icon={IconEnum.Delete}
              onClick={onDelete}
              analyticsTrackingId={null}
              title="Delete"
            />
          )}
        </div>
      </div>
      <TimelineSpacer expanded={expanded} hideLine={hideSpacer}>
        {description && (
          <TemplatedTextDisplay
            className="grow text-content-secondary"
            value={description.text_node ?? ""}
            style={TemplatedTextDisplayStyle.Compact}
          />
        )}
        {evidence.map((evidenceItem) => {
          const activityLog = evidenceItem.incident_activity_log;
          if (activityLog) {
            const ActivityLogItemComponent =
              ActivityItemTypeToComponentMap[activityLog.type];
            if (ActivityLogItemComponent) {
              const activityProps = ActivityLogItemComponent(
                incident,
                activityLog,
                zoomImageSource,
                setZoomImageSource,
              );

              if (activityProps) {
                return (
                  <ContentBox key={evidenceItem.id} className="p-1">
                    <ActivityLogItemUIWrapperV2
                      incident={incident}
                      mode={ActivityLogItemMode.Timeline}
                      item={activityLog}
                      zoomImageSource={zoomImageSource}
                      setZoomImageSource={setZoomImageSource}
                    />
                  </ContentBox>
                );
              }
            }
          }

          return null;
        })}
        {allowCommenting && (comments.length > 0 || commentBoxOpen) && (
          <TimelineItemCommentBox
            incidentId={incident.id}
            timelineItemId={id}
            comments={comments}
          />
        )}
      </TimelineSpacer>
    </div>
  );
};

type IconicNumberDisplayProps = {
  count: number;
  icon: IconEnum;
};

const IconicNumberDisplay = ({ count, icon }: IconicNumberDisplayProps) => {
  if (count === 0) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Icon
        id={icon}
        size={IconSize.Small}
        className="text-content-secondary"
      />
      <span className="text-xs text-content-secondary">{count}</span>
    </div>
  );
};
