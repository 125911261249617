import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { Modal } from "@incident-ui/Modal/Modal";
import { ModalContent } from "@incident-ui/Modal/ModalContent";
import { ModalFooter } from "@incident-ui/Modal/ModalFooter";
import React from "react";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import styles from "src/components/timeline/TimelineItems.module.scss";
import { ActivityLogEntry, Actor, Incident } from "src/contexts/ClientContext";

export const ActivityItemSlackImage = (
  _: Incident,
  item: ActivityLogEntry,
  zoomImageSource: string | undefined,
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void,
): ActivityItemRenderProps | null => {
  const slackImage = item.content.slack_image;
  if (!slackImage) {
    throw new Error(
      "malformed timeline item: slack_image was missing slack_image field",
    );
  }

  // Don't try to return a timeline item if the image has been deleted.
  if (slackImage.slack_message?.deleted) {
    return null;
  }

  let title = "An image was posted";
  if (slackImage.images.length > 1) {
    title = "Some images were posted";
  }

  const imageZoomModal = (
    <Modal
      title="No title shown"
      isOpen={!!zoomImageSource}
      analyticsTrackingId={null}
      onClose={() => setZoomImageSource(undefined)}
      hideHeader
      maximised
    >
      <ModalContent>
        <img src={zoomImageSource} alt="" />
      </ModalContent>
      <ModalFooter
        hideConfirmButton={true}
        confirmButtonType="button"
        onConfirm={() => setZoomImageSource(undefined)}
        onClose={() => setZoomImageSource(undefined)}
        cancelButtonText={"Close"}
      />
    </Modal>
  );

  const userActor: Actor = {
    user: slackImage.message_user,
  };

  return {
    icon: IconEnum.Files,
    colour: ColorPaletteEnum.Pink,
    // An actor should only be shown if there is a user associated with the message.
    // This could happen if the message was posted by a bot!
    actor: slackImage.message_user && userActor,
    title: title,
    unquotedContent: (
      <>
        {imageZoomModal}
        <div className="space-y-3">
          <Markdown className={styles.contentBox}>
            {slackImage.slack_message?.text}
          </Markdown>
          {slackImage.images &&
            slackImage.images.map((im) => (
              <img
                key={im.id}
                alt=""
                src={im.url}
                className={"rounded-2 border border-stroke shadow-sm"}
                onClick={() => setZoomImageSource(im.url)}
              />
            ))}
        </div>
      </>
    ),
  };
};
