import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Drawer, DrawerBody, DrawerTitle } from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useLocation } from "react-router";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useQueryParams } from "src/utils/query-params";
import { useEventListener } from "use-hooks";

import {
  PageWidth,
  PageWrapper,
} from "../../@shared/layout/PageWrapper/PageWrapper";
import { IncidentBody } from "./body/IncidentBody";
import { IncidentDetailsBanner, IncidentHeader } from "./header/IncidentHeader";
import { useIncident } from "./hooks";
import { IncidentSidebar } from "./sidebar/IncidentSidebar";

export function OnCallOnlyIncident({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement {
  const [detailSidebarOpen, setDetailSidebarOpen] = useState(false);
  const { incident } = useIncident(incidentId);
  const navigate = useOrgAwareNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { pathname } = location;
  const modalInPath = Object.values(IncidentHeaderModal).find((m) =>
    pathname.includes(m),
  );

  const setModalOpen = (modal: IncidentHeaderModal | null) => {
    if (modal) {
      const newPath =
        `${pathname + (pathname.endsWith("/") ? "" : "/")}${modal}?` +
        queryParams.toString();
      navigate(newPath, { replace: true });
    } else {
      navigate(pathname.replace(modalInPath ?? "", ""), { replace: true });
    }
  };

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Esc" || (e.key === "Escape" && detailSidebarOpen)) {
      setDetailSidebarOpen(false);
    }
  });

  return (
    <PageWrapper
      width={PageWidth.Medium}
      title={incident ? `INC-${incident.external_id}: ${incident.name}` : ""}
      headerNode={
        <IncidentHeader
          incident={incident}
          detailSidebarOpen={detailSidebarOpen}
          setDetailSidebarOpen={setDetailSidebarOpen}
          setModalOpen={setModalOpen}
        />
      }
      banner={
        <IncidentDetailsBanner
          incidentId={incidentId}
          setModalOpen={setModalOpen}
          anySidebarOpen={detailSidebarOpen}
        />
      }
    >
      <div className={"flex items-stretch gap-8"}>
        <IncidentBody incidentId={incidentId} />
        {/* The sidebar shown next to the incident body */}
        <IncidentSidebar incidentId={incidentId} className="hidden xl:block" />
      </div>
      {/* Same sidebar, but in a drawer that can be explicitly opened for small screens */}
      <AnimatePresence>
        {detailSidebarOpen && (
          <Drawer
            // If the stream drawer is open, the detail sidebar should not be open
            onClose={() => setDetailSidebarOpen(false)}
            width="extra-small"
          >
            <DrawerTitle
              title="Details"
              onClose={() => setDetailSidebarOpen(false)}
              compact
            />
            <DrawerBody>
              <IncidentSidebar incidentId={incidentId} />
            </DrawerBody>
          </Drawer>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
}
