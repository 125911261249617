import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

export const ActivityItemFollowUpCreated = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.follow_up_created) {
    throw new Error(
      "malformed timeline item: follow_up_created was missing follow_up_created field",
    );
  }

  return {
    title: "Follow-up created",
    icon: IconEnum.FollowUps,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.follow_up_created.creator,
    quotedContent: (
      <Markdown>{item.content.follow_up_created.follow_up.title}</Markdown>
    ),
  };
};
