import { Callout, CalloutTheme, ModalFooter } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { TabModalPane } from "@incident-ui/TabModal/TabModal";
import React, { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { GithLabRepoEditorV2 } from "src/components/@shared/forms/v2/editors/GitLabRepoEditorV2";
import { GithLabUserEditorV2 } from "src/components/@shared/forms/v2/editors/GitLabUserEditorV2";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticMultiSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  FollowUp,
  FollowUpsConnectExternalIssueRequestBodyProviderEnum,
  IssueTrackersGitLabCreateIssueRequestBody,
  IssueTrackersGitLabTypeaheadOptionsFieldEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

export type GitlabFormData = IssueTrackersGitLabCreateIssueRequestBody & {
  url: string;
  repo: {
    label: string;
    value: string;
  };
  assignee_username: {
    label: string;
    value: string;
  };
};

export const ExportToGitLabModal = ({
  followUp,
  isPrivateIncident,
  onClose,
  updateCallback,
}: {
  followUp: FollowUp;
  isPrivateIncident: boolean;
  onClose: () => void;
  updateCallback: (newFollowUp: FollowUp) => Promise<void>;
}): React.ReactElement => {
  const [connectToExistingTicket, setConnectToExistingTicket] =
    useState<boolean>(false);

  const formMethods = useForm<GitlabFormData>({
    defaultValues: {
      title: followUp.title,
      description: followUp.description,
    },
  });
  const { setError, watch } = formMethods;

  const userSelectedRepo = watch("repo");

  const {
    data: { typeahead_options: labels },
    isLoading: labelsLoading,
  } = useAPI(
    userSelectedRepo ? "issueTrackersGitLabTypeaheadOptions" : null,
    {
      field: IssueTrackersGitLabTypeaheadOptionsFieldEnum.Label,
      repo: userSelectedRepo?.value,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, body: GitlabFormData) => {
      const { url, ...createData } = body;

      const { follow_up: newFollowUp } = connectToExistingTicket
        ? await apiClient.followUpsConnectExternalIssue({
            id: followUp.id,
            connectExternalIssueRequestBody: {
              url,
              provider:
                FollowUpsConnectExternalIssueRequestBodyProviderEnum.Gitlab,
            },
          })
        : await apiClient.issueTrackersGitLabCreateIssue({
            gitLabCreateIssueRequestBody: {
              ...createData,
              follow_up_id: followUp.id,
              repo: extractRepoName(body.repo?.value),
              assignee_username: body.assignee_username?.value,
            },
          });

      await updateCallback(newFollowUp);
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  return (
    <Form.TabModal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Export to GitLab"
      analyticsTrackingId="add-to-gitlab"
      onClose={onClose}
      hideHeader
      tabs={[
        {
          id: "add",
          label: "Create new",
        },
        {
          id: "connect",
          label: "Connect to existing",
        },
      ]}
      onTabChange={(tab: string) =>
        setConnectToExistingTicket(tab === "connect")
      }
      footer={
        <ModalFooter
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText={connectToExistingTicket ? "Connect" : "Create"}
          saving={saving}
        />
      }
    >
      <>
        {isPrivateIncident && (
          <Callout className="m-2" theme={CalloutTheme.Warning}>
            This is a private incident. This issue will be visible to anyone
            with access to your GitLab repository.
          </Callout>
        )}

        <ExportActionPane labels={labels || []} labelsLoading={labelsLoading} />
        <TabModalPane tabId="connect" className="space-y-4 ">
          <p className="text-sm">
            Connect this follow-up to an existing GitLab issue by pasting in the
            issue reference.
          </p>
          <Callout theme={CalloutTheme.Info}>
            If you link this to an issue tracker, we will use that as the source
            of truth, and the follow-up will no longer be editable in
            incident.io.
          </Callout>

          <InputV2
            formMethods={formMethods}
            label="Existing issue reference"
            name="url"
            required={
              connectToExistingTicket
                ? "Please enter a issue reference"
                : undefined
            }
            placeholder="https://gitlab.com/my-organisation/my-project/-/issues/1"
          />
        </TabModalPane>
      </>
    </Form.TabModal>
  );
};

// input should be in the format 'owner/repo', however the function will return the full label if
// the input is not in that format.
function extractRepoName(input: string): string {
  if (!input) {
    return input;
  }

  const parts = input.split("/");
  return parts.length > 1 ? parts[1] : input;
}

function ExportActionPane({
  labels,
  labelsLoading,
}: {
  labelsLoading: boolean;
  labels: SelectOption[];
}): React.ReactElement {
  const formMethods = useFormContext<GitlabFormData>();
  return (
    <TabModalPane tabId="add" className="space-y-4">
      <p className="text-sm text-content-primary">
        We&apos;ll automatically sync any changes in GitLab.
      </p>
      <InputV2
        formMethods={formMethods}
        label="Title"
        name="title"
        required="Please enter a title"
      />
      <TextareaV2
        formMethods={formMethods}
        label="Description"
        required={false}
        placeholder="Any more details you want to add while you're here?"
        rows={4}
        name="description"
      />
      <GitLabBulkExportableFields
        labels={labels}
        labelsLoading={labelsLoading}
      />
    </TabModalPane>
  );
}

// GitLabBulkExportableFields holds the fields that are present on both the singular export and the bulk
// export modals. If you're adding a new field, please consider whether it should be added here (ie.
// a user might want to set it for multiple actions at once), or above (ie. a user will only want to
// set it for a particular followUp).
export const GitLabBulkExportableFields = ({
  labels,
  labelsLoading,
}: {
  labels: SelectOption[];
  labelsLoading: boolean;
}): React.ReactElement => {
  const formMethods = useFormContext<GitlabFormData>();
  return (
    <>
      <GithLabRepoEditorV2
        formMethods={formMethods}
        name="repo"
        label="Project"
      />

      <GithLabUserEditorV2
        formMethods={formMethods}
        name="assignee_username"
        label="Assignee"
        required={false}
      />

      <StaticMultiSelectV2
        formMethods={formMethods}
        label="Labels"
        required={false}
        name={"labels"}
        options={labels || []}
        placeholder="Select labels"
        isLoading={labelsLoading}
      />
    </>
  );
};
