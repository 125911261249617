import { PanelVariable } from "@incident-io/api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Heading,
  IconEnum,
  Markdown,
} from "@incident-ui";
import { format, parseISO } from "date-fns";
import React from "react";

import { calculatePreviousPeriod } from "./calculatePreviousPeriod";
import { PanelVariableInputs } from "./PanelVariables";

export const PanelWrapper = ({
  title,
  description,
  variables,
  panelIdx,
  editable,
  trends,
  children,
}: {
  title: string;
  description: string;
  variables: PanelVariable[];
  panelIdx: number;
  editable: boolean;
  trends: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-5">
      {/* Header section */}
      <div className="flex items-start gap-4">
        <div className="w-1/2">
          <div className="flex flex-col gap-2">
            <Heading level={2} size="small">
              {title}
            </Heading>
            <p className="mb-4 text-slate-700 text-sm max-w-96">
              <Markdown>{description}</Markdown>
            </p>
            <PanelVariableInputs
              variables={variables}
              panelIdx={panelIdx}
              editable={editable}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="grid grid-cols-3 gap-4" dir="rtl">
            {trends}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export const ComparisonModeHeading = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  return (
    <div className="flex flex-row gap-4 pt-4 w-full sticky top-0 bg-gradient-to-b from-surface-primary via-surface-primary via-75% to-90%">
      <div className="w-1/2">
        <Badge
          theme={BadgeTheme.Tertiary}
          className="w-full mb-4 justify-center"
          label={`${parseDateRange(prevStartDate, prevEndDate)}`}
          size={BadgeSize.Medium}
          icon={IconEnum.Compare}
        />
      </div>
      <div className="w-1/2">
        <Badge
          theme={BadgeTheme.Info}
          className="w-full mb-4 justify-center"
          label={`${parseDateRange(startDate, endDate)}`}
          size={BadgeSize.Medium}
          icon={IconEnum.Calendar}
        />
      </div>
    </div>
  );
};

// This parser expects from and to to be in ISO format with a timezone
export const parseDateRange = (from: string, to: string) => {
  // Parse the dates using parseISO
  const fromDate = parseISO(from);
  const toDate = parseISO(to);

  // Format the dates using format
  const dateFmtOptions = "d MMM yy";

  const fromDateString = format(fromDate, dateFmtOptions);
  const toDateString = format(toDate, dateFmtOptions);

  return `${fromDateString} - ${toDateString}`;
};
