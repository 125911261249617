import {
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Heading,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { kebabCase } from "lodash";
import React from "react";
import { isDevelopment } from "src/utils/environment";
import { tcx } from "src/utils/tailwind-classes";

import { BreadcrumbLink, Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";

export type HeaderBarProps = {
  className?: string;
  crumbs?: BreadcrumbLink[];
  title: string;
  titleNode?: React.ReactNode;
  titleAccessory?: React.ReactNode;
  subtitle?: React.ReactNode;
  accessory?: React.ReactNode;

  // Control the icon container
  icon: IconEnum;
  iconSize?: IconSize;
  color?: ColorPaletteEnum;
  backHref?: string;
  backOnClick?: () => void;

  // Set your own flex-basis so we wrap sooner as the page gets smaller
  leftSideClassName?: string;

  onEditTitle?: () => void;
};

export const HeaderBar = React.forwardRef<HTMLDivElement, HeaderBarProps>(
  ({
    onEditTitle,
    title,
    titleNode,
    subtitle,
    crumbs,
    accessory,
    backHref,
    backOnClick,
    titleAccessory,
    icon,
    iconSize,
    color,
    leftSideClassName,
    className,
    // className,
  }): React.ReactElement => {
    // NOTE: The z-30 here is important for when we have popovers and the like.
    // Altering this value may cause conflicts with other z-indexes e.g.
    // Drawers.
    return (
      <div
        className={tcx(
          "z-30 w-full",
          "px-4 md:px-8 py-6 border-b border-stroke",
          "flex flex-wrap items-center justify-between gap-4",
          "bg-white text-content-primary",
          className,
        )}
      >
        {/* Stop the icon and header from becoming separated */}
        {/* Use a small flex-basis so we don't force a wrap where we don't need one */}
        <div
          className={tcx(
            "flex items-center gap-4 basis-40 grow truncate",
            leftSideClassName,
          )}
        >
          <HeaderBarIcon
            icon={icon as IconEnum}
            size={iconSize}
            color={color}
            backHref={backHref}
            backOnClick={backOnClick}
          />
          {titleNode ? (
            titleNode
          ) : (
            <HeaderBarTitle
              title={title}
              titleNode={title}
              titleAccessory={titleAccessory}
              crumbs={crumbs}
              subtitle={subtitle}
              onEditTitle={onEditTitle}
            />
          )}
        </div>
        <div className="flex items-center gap-2">{accessory}</div>
      </div>
    );
  },
);

HeaderBar.displayName = "HeaderBar";

export const HeaderBarTitle = ({
  title,
  titleNode,
  titleAccessory,
  crumbs,
  subtitle,
  onEditTitle,
  isEditable,
}: {
  title: string;
  titleNode: React.ReactNode;
  titleAccessory?: React.ReactNode;
  crumbs?: BreadcrumbLink[];
  subtitle?: React.ReactNode;
  onEditTitle?: () => void;
  isEditable?: boolean;
}) => {
  if (!!crumbs && !!subtitle && isDevelopment()) {
    return (
      <div className="text-xl text-red-500">
        {`You've provided crumbs AND a subtitle, which isn't allowed. We'll just
        render the crumbs in production`}
      </div>
    );
  }

  const hasCrumbsOrSubtitle = !!crumbs || !!subtitle;

  return (
    <div className={"flex flex-col grow truncate justify-center"}>
      {crumbs && <Breadcrumbs links={crumbs} />}
      <div className="flex items-center gap-2">
        <Heading
          level={1}
          data-testid={kebabCase(title)}
          size={hasCrumbsOrSubtitle ? "medium" : "2xl"}
          onClick={onEditTitle}
          className={tcx("group truncate font-semibold", {
            "hover:cursor-pointer flex items-center gap-1": !!onEditTitle,
            grow: isEditable,
          })}
        >
          {titleNode}
          {onEditTitle && (
            <Icon
              id={IconEnum.Edit}
              className="text-content-tertiary invisible group-hover:visible"
            />
          )}
        </Heading>
        {titleAccessory}
      </div>
      {subtitle && (
        <div className="text-content-tertiary text-xs font-normal">
          {subtitle}
        </div>
      )}
    </div>
  );
};

export const HeaderBarIcon = ({
  icon,
  color = ColorPaletteEnum.Red,
  backHref,
  backOnClick,
  size = IconSize.Medium,
}: {
  icon: IconEnum;
  color?: ColorPaletteEnum;
  backHref?: string;
  backOnClick?: () => void;
  size?: IconSize;
}) => {
  const iconNode = (
    <IconBadge
      icon={icon as IconEnum}
      color={color}
      size={size}
      className={tcx(HoverBackgroundLookup[color], "transition-transform")}
      iconProps={{
        className: "group-hover:-translate-x-10 transition-transform",
      }}
    />
  );

  const palette = getColorPalette(color);
  if (!backHref && !backOnClick) {
    return <>{iconNode}</>;
  }

  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      href={backHref}
      onClick={backOnClick}
      className="block relative group overflow-hidden shrink-0"
    >
      {iconNode}
      <Icon
        id={IconEnum.ArrowLeft}
        size={IconSize.Medium}
        className={tcx(
          palette.icon,
          "absolute top-2.5 translate-x-10 group-hover:translate-x-2.5 transition-transform",
        )}
      />
    </Button>
  );
};

const HoverBackgroundLookup: {
  [key in ColorPaletteEnum]: string;
} = {
  [ColorPaletteEnum.SlateOnWhite]: "group-hover:bg-slate-50",
  [ColorPaletteEnum.Slate]: "group-hover:bg-slate-100",
  [ColorPaletteEnum.Slate200]: "group-hover:bg-slate-300",
  [ColorPaletteEnum.Blue]: "group-hover:bg-blue-200",
  [ColorPaletteEnum.Cyan]: "group-hover:bg-cyan-100",
  [ColorPaletteEnum.Red]: "group-hover:bg-red-surface",
  [ColorPaletteEnum.Violet]: "group-hover:bg-violet-100",
  [ColorPaletteEnum.Pink]: "group-hover:bg-pink-surface",
  [ColorPaletteEnum.Yellow]: "group-hover:bg-yellow-100",
  [ColorPaletteEnum.Orange]: "group-hover:bg-orange-100",
  [ColorPaletteEnum.Green]: "group-hover:bg-green-surface",
  [ColorPaletteEnum.Lime]: "group-hover:bg-lime-100",
  [ColorPaletteEnum.Teal]: "group-hover:bg-teal-100",
  [ColorPaletteEnum.Sky]: "group-hover:bg-sky-100",
  [ColorPaletteEnum.Indigo]: "group-hover:bg-indigo-100",
  [ColorPaletteEnum.Rose]: "group-hover:bg-rose-100",
  [ColorPaletteEnum.Fuchsia]: "group-hover:bg-fuchsia-100",
  [ColorPaletteEnum.Purple]: "group-hover:bg-purple-surface",
  [ColorPaletteEnum.Emerald]: "group-hover:bg-emerald-100",
};
