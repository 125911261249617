import { IncidentsShowResponseBody } from "@incident-io/api";
import { LoadingModal, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentRole,
  IncidentRoleRoleTypeEnum,
  IncidentsUpdateRoleAssignmentsRequestBody,
  IncidentsUpdateRoleAssignmentsResponseBody,
  Stream,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { useRevalidate } from "../../../../utils/use-revalidate";
import { useIncident } from "../hooks";
import { IncidentRoleFormElement } from "../IncidentRoleFormElement";

type FormIncidentRoleAssignments = {
  [key: string]: { assignee_id?: string };
};

type EditRolesFormData = {
  incident_role_assignments: FormIncidentRoleAssignments;
};

const convertIncidentResponseToFormData = (
  incident: Incident | Stream,
  ourIncidentRoles: IncidentRole[],
): EditRolesFormData => {
  const incidentRoleAssignments: FormIncidentRoleAssignments = {};
  ourIncidentRoles.forEach((role) => {
    const existingAssignment = incident.incident_role_assignments.find(
      (x) => x.role.id === role.id,
    );

    incidentRoleAssignments[role.id] = {
      assignee_id: existingAssignment?.assignee?.id,
    };
  });

  return {
    incident_role_assignments: incidentRoleAssignments,
  };
};

const editRolesFormDataToRequestBody = (
  formData: EditRolesFormData,
): IncidentsUpdateRoleAssignmentsRequestBody => {
  return {
    role_assignments: Object.entries(formData.incident_role_assignments).map(
      ([roleId, assignee]) => {
        return {
          incident_role_id: roleId,
          assignee: assignee.assignee_id
            ? { id: assignee.assignee_id }
            : undefined,
        };
      },
    ),
  };
};

export const EditStreamRoleAssignmentsModal = ({
  streamId,
  onClose,
}: {
  streamId?: string;
  onClose: () => void;
}): React.ReactElement => {
  const {
    data: { incident_roles: incidentRoles },
    isLoading: incidentRolesLoading,
    error: incidentRolesError,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const { streamId: paramsStreamId } = useParams() as {
    streamId: string;
  };

  if (!streamId) {
    streamId = paramsStreamId;
  }

  const {
    data: { stream, applicable_fields },
    isLoading,
    error,
  } = useAPI(
    streamId == null ? null : "streamsShow",
    {
      id: streamId ?? "",
    },
    { fallbackData: { stream: undefined } },
  );

  if (incidentRolesError || error) {
    throw incidentRolesError;
  }

  if (incidentRolesLoading || isLoading || stream == null) {
    return <LoadingModal title="Edit Role Assignments" onClose={onClose} />;
  }

  const isApplicableToCurrentIncident = (r: IncidentRole) =>
    applicable_fields?.roles.includes(r.id);

  const applicableIncidentRoles = incidentRoles.filter((role) => {
    const isPreviouslyAssignedRole = stream.incident_role_assignments.some(
      (x) => x.assignee && x.role.id === role.id,
    );
    return (
      role.role_type !== IncidentRoleRoleTypeEnum.Reporter &&
      (isApplicableToCurrentIncident(role) || isPreviouslyAssignedRole)
    );
  });

  const applicableIncidentRolesWithStreamNames = applicableIncidentRoles.map(
    (role) => {
      return { ...role, name: role.stream_name || role.name };
    },
  );

  return (
    <EditRoleAssignmentsForm
      onClose={onClose}
      incident={stream}
      incidentRoles={applicableIncidentRolesWithStreamNames}
      isStream
    />
  );
};

export const EditRoleAssignmentsModal = ({
  incident,
  onClose,
  shownRoleIds,
}: {
  incident: Incident | Stream;
  // shownRoleIds is a list of incident roles to show in the modal, by default all applicable roles are shown
  shownRoleIds?: string[];
  onClose: () => void;
}): React.ReactElement => {
  const { applicableFields } = useIncident(incident.id);

  const {
    data: { incident_roles: incidentRoles },
    isLoading: incidentRolesLoading,
    error: incidentRolesError,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  if (incidentRolesError) {
    throw incidentRolesError;
  }

  if (incidentRolesLoading || !applicableFields) {
    return <LoadingModal title="Edit Role Assignments" onClose={onClose} />;
  }

  const isApplicableToCurrentIncident = (r: IncidentRole) =>
    applicableFields.roles.includes(r.id);

  const applicableIncidentRoles = incidentRoles.filter((role) => {
    if (shownRoleIds) {
      return shownRoleIds.includes(role.id);
    }

    const isPreviouslyAssignedRole = incident.incident_role_assignments.some(
      (x) => x.assignee && x.role.id === role.id,
    );
    return (
      role.role_type !== IncidentRoleRoleTypeEnum.Reporter &&
      (isApplicableToCurrentIncident(role) || isPreviouslyAssignedRole)
    );
  });

  return (
    <EditRoleAssignmentsForm
      onClose={onClose}
      incident={incident}
      incidentRoles={applicableIncidentRoles}
    />
  );
};

const EditRoleAssignmentsForm = ({
  onClose,
  incident,
  incidentRoles,
  isStream = false,
}: {
  onClose: (data?: IncidentsUpdateRoleAssignmentsResponseBody) => void;
  incident: Incident | Stream;
  incidentRoles: IncidentRole[];
  isStream?: boolean;
}): React.ReactElement => {
  const cacheToRefetch = isStream ? "streamsShow" : "incidentsShow";
  const formMethods = useForm<EditRolesFormData>({
    defaultValues: convertIncidentResponseToFormData(incident, incidentRoles),
  });
  const { setError } = formMethods;

  const refetchTimeline = useAPIRefetch("timelineItemsList", {
    incidentId: incident.id,
  });
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const refreshStreamsList = useRevalidate(["streamsList"]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    cacheToRefetch,
    { id: incident.id },
    async (apiClient, formData: EditRolesFormData) => {
      const editRolesRequestBody = editRolesFormDataToRequestBody(formData);

      await apiClient.incidentsUpdateRoleAssignments({
        id: incident.id,
        updateRoleAssignmentsRequestBody: editRolesRequestBody,
      });

      await refetchPostIncidentTasks();
    },
    {
      onSuccess: (response) => {
        refetchTimeline();
        refreshIncidentList();
        refreshStreamsList();
        const { incident } = response as IncidentsShowResponseBody;
        const stream = response as Stream;
        if (incident) {
          onClose({
            role_assignments: incident.incident_role_assignments,
          });
        } else if (stream) {
          onClose({
            role_assignments: stream?.incident_role_assignments ?? [],
          });
        } else {
          onClose({
            role_assignments: [],
          });
        }
      },
      setError,
    },
  );

  const incidentLeadFirst = (a: IncidentRole, b: IncidentRole) => {
    if (a.role_type === IncidentRoleRoleTypeEnum.Lead) {
      return -1;
    }
    if (b.role_type === IncidentRoleRoleTypeEnum.Lead) {
      return 1;
    }
    return 0;
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Edit Role Assignments"
      analyticsTrackingId="edit-role-assignments"
      disableQuickClose
      onClose={() => onClose()}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={() => onClose()}
          confirmButtonType="submit"
          confirmButtonText="Save"
          analyticsTrackingId="edit-role-assignments-submit"
          saving={saving}
        />
      }
    >
      {incidentRoles.sort(incidentLeadFirst).map((role, i) => {
        return (
          <div key={role.id} className="mb-4">
            <IncidentRoleFormElement
              formMethods={formMethods}
              key={role.id}
              formKey={`incident_role_assignments.${role.id}.assignee_id`}
              incidentId={incident.id}
              role={role}
              autoFocus={i === 0} // autofocus the first item to make it feel nice and fast to edit
              required={
                isStream && role.role_type === IncidentRoleRoleTypeEnum.Lead
              }
            />
          </div>
        );
      })}
    </Form.Modal>
  );
};
