/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationWebhooksGitHubRequestBody,
    IntegrationWebhooksGitHubRequestBodyFromJSON,
    IntegrationWebhooksGitHubRequestBodyToJSON,
    IntegrationWebhooksLinearRequestBody,
    IntegrationWebhooksLinearRequestBodyFromJSON,
    IntegrationWebhooksLinearRequestBodyToJSON,
    IntegrationWebhooksPagerDutyRequestBody,
    IntegrationWebhooksPagerDutyRequestBodyFromJSON,
    IntegrationWebhooksPagerDutyRequestBodyToJSON,
    IntegrationWebhooksSvixRequestBody,
    IntegrationWebhooksSvixRequestBodyFromJSON,
    IntegrationWebhooksSvixRequestBodyToJSON,
    IntegrationWebhooksZoomRequestBody,
    IntegrationWebhooksZoomRequestBodyFromJSON,
    IntegrationWebhooksZoomRequestBodyToJSON,
    IntegrationWebhooksZoomResponseBody,
    IntegrationWebhooksZoomResponseBodyFromJSON,
    IntegrationWebhooksZoomResponseBodyToJSON,
} from '../models';

export interface IntegrationWebhooksAsanaRequest {
    organisationId?: string;
    projectId?: string;
    xHookSecret?: string;
    xHookSignature?: string;
}

export interface IntegrationWebhooksGitHubRequest {
    xGitHubEvent: string;
    gitHubRequestBody: IntegrationWebhooksGitHubRequestBody;
}

export interface IntegrationWebhooksGoogleCalendarRequest {
    xGoogChannelID: string;
    xGoogMessageNumber: number;
    xGoogResourceID: string;
    xGoogResourceState: IntegrationWebhooksGoogleCalendarXGoogResourceStateEnum;
    xGoogResourceURI: string;
    xGoogChannelExpiration?: string;
    xGoogChannelToken?: string;
}

export interface IntegrationWebhooksGoogleDriveRequest {
    xGoogChannelID?: string;
    xGoogMessageNumber?: number;
    xGoogResourceID?: string;
    xGoogResourceState?: string;
    xGoogChanged?: string;
    xGoogResourceURI?: string;
}

export interface IntegrationWebhooksLinearRequest {
    linearRequestBody: IntegrationWebhooksLinearRequestBody;
    linearDelivery?: string;
    linearEvent?: string;
}

export interface IntegrationWebhooksOpsgenieRequest {
    organisationId: string;
}

export interface IntegrationWebhooksPagerDutyRequest {
    organisationId: string;
    pagerDutyRequestBody: IntegrationWebhooksPagerDutyRequestBody;
}

export interface IntegrationWebhooksSentryRequest {
    sentryHookResource: string;
    sentryHookTimestamp: string;
    sentryHookSignature: string;
}

export interface IntegrationWebhooksStripeRequest {
    any: object;
}

export interface IntegrationWebhooksSvixRequest {
    svixRequestBody: IntegrationWebhooksSvixRequestBody;
}

export interface IntegrationWebhooksWorkOSRequest {
    workosSignature?: string;
}

export interface IntegrationWebhooksZendeskRequest {
    organisationId: string;
    xZendeskAccountId: string;
    xZendeskWebhookId: string;
    xZendeskWebhookInvocationId: string;
    xZendeskWebhookSignature: string;
    xZendeskWebhookSignatureTimestamp: string;
}

export interface IntegrationWebhooksZoomRequest {
    zoomRequestBody: IntegrationWebhooksZoomRequestBody;
}

/**
 * 
 */
export class IntegrationWebhooksApi extends runtime.BaseAPI {

    /**
     * Webhooks from Asana
     * Asana IntegrationWebhooks
     */
    async integrationWebhooksAsanaRaw(requestParameters: IntegrationWebhooksAsanaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['project_id'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xHookSecret !== undefined && requestParameters.xHookSecret !== null) {
            headerParameters['X-Hook-Secret'] = String(requestParameters.xHookSecret);
        }

        if (requestParameters.xHookSignature !== undefined && requestParameters.xHookSignature !== null) {
            headerParameters['X-Hook-Signature'] = String(requestParameters.xHookSignature);
        }

        const response = await this.request({
            path: `/webhooks/asana`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from Asana
     * Asana IntegrationWebhooks
     */
    async integrationWebhooksAsana(requestParameters: IntegrationWebhooksAsanaRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksAsanaRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from GitHub
     * GitHub IntegrationWebhooks
     */
    async integrationWebhooksGitHubRaw(requestParameters: IntegrationWebhooksGitHubRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xGitHubEvent === null || requestParameters.xGitHubEvent === undefined) {
            throw new runtime.RequiredError('xGitHubEvent','Required parameter requestParameters.xGitHubEvent was null or undefined when calling integrationWebhooksGitHub.');
        }

        if (requestParameters.gitHubRequestBody === null || requestParameters.gitHubRequestBody === undefined) {
            throw new runtime.RequiredError('gitHubRequestBody','Required parameter requestParameters.gitHubRequestBody was null or undefined when calling integrationWebhooksGitHub.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xGitHubEvent !== undefined && requestParameters.xGitHubEvent !== null) {
            headerParameters['X-GitHub-Event'] = String(requestParameters.xGitHubEvent);
        }

        const response = await this.request({
            path: `/webhooks/github`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationWebhooksGitHubRequestBodyToJSON(requestParameters.gitHubRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from GitHub
     * GitHub IntegrationWebhooks
     */
    async integrationWebhooksGitHub(requestParameters: IntegrationWebhooksGitHubRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksGitHubRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook notification from Google Calendar about calendar events.
     * GoogleCalendar IntegrationWebhooks
     */
    async integrationWebhooksGoogleCalendarRaw(requestParameters: IntegrationWebhooksGoogleCalendarRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xGoogChannelID === null || requestParameters.xGoogChannelID === undefined) {
            throw new runtime.RequiredError('xGoogChannelID','Required parameter requestParameters.xGoogChannelID was null or undefined when calling integrationWebhooksGoogleCalendar.');
        }

        if (requestParameters.xGoogMessageNumber === null || requestParameters.xGoogMessageNumber === undefined) {
            throw new runtime.RequiredError('xGoogMessageNumber','Required parameter requestParameters.xGoogMessageNumber was null or undefined when calling integrationWebhooksGoogleCalendar.');
        }

        if (requestParameters.xGoogResourceID === null || requestParameters.xGoogResourceID === undefined) {
            throw new runtime.RequiredError('xGoogResourceID','Required parameter requestParameters.xGoogResourceID was null or undefined when calling integrationWebhooksGoogleCalendar.');
        }

        if (requestParameters.xGoogResourceState === null || requestParameters.xGoogResourceState === undefined) {
            throw new runtime.RequiredError('xGoogResourceState','Required parameter requestParameters.xGoogResourceState was null or undefined when calling integrationWebhooksGoogleCalendar.');
        }

        if (requestParameters.xGoogResourceURI === null || requestParameters.xGoogResourceURI === undefined) {
            throw new runtime.RequiredError('xGoogResourceURI','Required parameter requestParameters.xGoogResourceURI was null or undefined when calling integrationWebhooksGoogleCalendar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xGoogChannelID !== undefined && requestParameters.xGoogChannelID !== null) {
            headerParameters['X-Goog-Channel-ID'] = String(requestParameters.xGoogChannelID);
        }

        if (requestParameters.xGoogMessageNumber !== undefined && requestParameters.xGoogMessageNumber !== null) {
            headerParameters['X-Goog-Message-Number'] = String(requestParameters.xGoogMessageNumber);
        }

        if (requestParameters.xGoogResourceID !== undefined && requestParameters.xGoogResourceID !== null) {
            headerParameters['X-Goog-Resource-ID'] = String(requestParameters.xGoogResourceID);
        }

        if (requestParameters.xGoogResourceState !== undefined && requestParameters.xGoogResourceState !== null) {
            headerParameters['X-Goog-Resource-State'] = String(requestParameters.xGoogResourceState);
        }

        if (requestParameters.xGoogResourceURI !== undefined && requestParameters.xGoogResourceURI !== null) {
            headerParameters['X-Goog-Resource-URI'] = String(requestParameters.xGoogResourceURI);
        }

        if (requestParameters.xGoogChannelExpiration !== undefined && requestParameters.xGoogChannelExpiration !== null) {
            headerParameters['X-Goog-Channel-Expiration'] = String(requestParameters.xGoogChannelExpiration);
        }

        if (requestParameters.xGoogChannelToken !== undefined && requestParameters.xGoogChannelToken !== null) {
            headerParameters['X-Goog-Channel-Token'] = String(requestParameters.xGoogChannelToken);
        }

        const response = await this.request({
            path: `/webhooks/googlecalendar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook notification from Google Calendar about calendar events.
     * GoogleCalendar IntegrationWebhooks
     */
    async integrationWebhooksGoogleCalendar(requestParameters: IntegrationWebhooksGoogleCalendarRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksGoogleCalendarRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook notifications from Google Drive about postmortem file changes.
     * GoogleDrive IntegrationWebhooks
     */
    async integrationWebhooksGoogleDriveRaw(requestParameters: IntegrationWebhooksGoogleDriveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xGoogChannelID !== undefined && requestParameters.xGoogChannelID !== null) {
            headerParameters['X-Goog-Channel-ID'] = String(requestParameters.xGoogChannelID);
        }

        if (requestParameters.xGoogMessageNumber !== undefined && requestParameters.xGoogMessageNumber !== null) {
            headerParameters['X-Goog-Message-Number'] = String(requestParameters.xGoogMessageNumber);
        }

        if (requestParameters.xGoogResourceID !== undefined && requestParameters.xGoogResourceID !== null) {
            headerParameters['X-Goog-Resource-ID'] = String(requestParameters.xGoogResourceID);
        }

        if (requestParameters.xGoogResourceState !== undefined && requestParameters.xGoogResourceState !== null) {
            headerParameters['X-Goog-Resource-State'] = String(requestParameters.xGoogResourceState);
        }

        if (requestParameters.xGoogChanged !== undefined && requestParameters.xGoogChanged !== null) {
            headerParameters['X-Goog-Changed'] = String(requestParameters.xGoogChanged);
        }

        if (requestParameters.xGoogResourceURI !== undefined && requestParameters.xGoogResourceURI !== null) {
            headerParameters['X-Goog-Resource-URI'] = String(requestParameters.xGoogResourceURI);
        }

        const response = await this.request({
            path: `/webhooks/googledrive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook notifications from Google Drive about postmortem file changes.
     * GoogleDrive IntegrationWebhooks
     */
    async integrationWebhooksGoogleDrive(requestParameters: IntegrationWebhooksGoogleDriveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksGoogleDriveRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from Linear
     * Linear IntegrationWebhooks
     */
    async integrationWebhooksLinearRaw(requestParameters: IntegrationWebhooksLinearRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.linearRequestBody === null || requestParameters.linearRequestBody === undefined) {
            throw new runtime.RequiredError('linearRequestBody','Required parameter requestParameters.linearRequestBody was null or undefined when calling integrationWebhooksLinear.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.linearDelivery !== undefined && requestParameters.linearDelivery !== null) {
            headerParameters['Linear-Delivery'] = String(requestParameters.linearDelivery);
        }

        if (requestParameters.linearEvent !== undefined && requestParameters.linearEvent !== null) {
            headerParameters['Linear-Event'] = String(requestParameters.linearEvent);
        }

        const response = await this.request({
            path: `/webhooks/linear`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationWebhooksLinearRequestBodyToJSON(requestParameters.linearRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from Linear
     * Linear IntegrationWebhooks
     */
    async integrationWebhooksLinear(requestParameters: IntegrationWebhooksLinearRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksLinearRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from Opsgenie
     * Opsgenie IntegrationWebhooks
     */
    async integrationWebhooksOpsgenieRaw(requestParameters: IntegrationWebhooksOpsgenieRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationWebhooksOpsgenie.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/webhooks/opsgenie/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Webhooks from Opsgenie
     * Opsgenie IntegrationWebhooks
     */
    async integrationWebhooksOpsgenie(requestParameters: IntegrationWebhooksOpsgenieRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.integrationWebhooksOpsgenieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Webhooks from PagerDuty
     * PagerDuty IntegrationWebhooks
     */
    async integrationWebhooksPagerDutyRaw(requestParameters: IntegrationWebhooksPagerDutyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationWebhooksPagerDuty.');
        }

        if (requestParameters.pagerDutyRequestBody === null || requestParameters.pagerDutyRequestBody === undefined) {
            throw new runtime.RequiredError('pagerDutyRequestBody','Required parameter requestParameters.pagerDutyRequestBody was null or undefined when calling integrationWebhooksPagerDuty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/webhooks/pagerduty/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationWebhooksPagerDutyRequestBodyToJSON(requestParameters.pagerDutyRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Webhooks from PagerDuty
     * PagerDuty IntegrationWebhooks
     */
    async integrationWebhooksPagerDuty(requestParameters: IntegrationWebhooksPagerDutyRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.integrationWebhooksPagerDutyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Webhooks from Sentry
     * Sentry IntegrationWebhooks
     */
    async integrationWebhooksSentryRaw(requestParameters: IntegrationWebhooksSentryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sentryHookResource === null || requestParameters.sentryHookResource === undefined) {
            throw new runtime.RequiredError('sentryHookResource','Required parameter requestParameters.sentryHookResource was null or undefined when calling integrationWebhooksSentry.');
        }

        if (requestParameters.sentryHookTimestamp === null || requestParameters.sentryHookTimestamp === undefined) {
            throw new runtime.RequiredError('sentryHookTimestamp','Required parameter requestParameters.sentryHookTimestamp was null or undefined when calling integrationWebhooksSentry.');
        }

        if (requestParameters.sentryHookSignature === null || requestParameters.sentryHookSignature === undefined) {
            throw new runtime.RequiredError('sentryHookSignature','Required parameter requestParameters.sentryHookSignature was null or undefined when calling integrationWebhooksSentry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.sentryHookResource !== undefined && requestParameters.sentryHookResource !== null) {
            headerParameters['Sentry-Hook-Resource'] = String(requestParameters.sentryHookResource);
        }

        if (requestParameters.sentryHookTimestamp !== undefined && requestParameters.sentryHookTimestamp !== null) {
            headerParameters['Sentry-Hook-Timestamp'] = String(requestParameters.sentryHookTimestamp);
        }

        if (requestParameters.sentryHookSignature !== undefined && requestParameters.sentryHookSignature !== null) {
            headerParameters['Sentry-Hook-Signature'] = String(requestParameters.sentryHookSignature);
        }

        const response = await this.request({
            path: `/webhooks/sentry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from Sentry
     * Sentry IntegrationWebhooks
     */
    async integrationWebhooksSentry(requestParameters: IntegrationWebhooksSentryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksSentryRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from stripe
     * Stripe IntegrationWebhooks
     */
    async integrationWebhooksStripeRaw(requestParameters: IntegrationWebhooksStripeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.any === null || requestParameters.any === undefined) {
            throw new runtime.RequiredError('any','Required parameter requestParameters.any was null or undefined when calling integrationWebhooksStripe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/webhooks/stripe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.any as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Webhooks from stripe
     * Stripe IntegrationWebhooks
     */
    async integrationWebhooksStripe(requestParameters: IntegrationWebhooksStripeRequest, initOverrides?: RequestInit): Promise<object> {
        const response = await this.integrationWebhooksStripeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Webhooks from Svix (our webhooks provider, meta eh?)
     * Svix IntegrationWebhooks
     */
    async integrationWebhooksSvixRaw(requestParameters: IntegrationWebhooksSvixRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.svixRequestBody === null || requestParameters.svixRequestBody === undefined) {
            throw new runtime.RequiredError('svixRequestBody','Required parameter requestParameters.svixRequestBody was null or undefined when calling integrationWebhooksSvix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/webhooks/svix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationWebhooksSvixRequestBodyToJSON(requestParameters.svixRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from Svix (our webhooks provider, meta eh?)
     * Svix IntegrationWebhooks
     */
    async integrationWebhooksSvix(requestParameters: IntegrationWebhooksSvixRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksSvixRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from WorkOS
     * WorkOS IntegrationWebhooks
     */
    async integrationWebhooksWorkOSRaw(requestParameters: IntegrationWebhooksWorkOSRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.workosSignature !== undefined && requestParameters.workosSignature !== null) {
            headerParameters['Workos-Signature'] = String(requestParameters.workosSignature);
        }

        const response = await this.request({
            path: `/webhooks/workos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from WorkOS
     * WorkOS IntegrationWebhooks
     */
    async integrationWebhooksWorkOS(requestParameters: IntegrationWebhooksWorkOSRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksWorkOSRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from Zendesk
     * Zendesk IntegrationWebhooks
     */
    async integrationWebhooksZendeskRaw(requestParameters: IntegrationWebhooksZendeskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationWebhooksZendesk.');
        }

        if (requestParameters.xZendeskAccountId === null || requestParameters.xZendeskAccountId === undefined) {
            throw new runtime.RequiredError('xZendeskAccountId','Required parameter requestParameters.xZendeskAccountId was null or undefined when calling integrationWebhooksZendesk.');
        }

        if (requestParameters.xZendeskWebhookId === null || requestParameters.xZendeskWebhookId === undefined) {
            throw new runtime.RequiredError('xZendeskWebhookId','Required parameter requestParameters.xZendeskWebhookId was null or undefined when calling integrationWebhooksZendesk.');
        }

        if (requestParameters.xZendeskWebhookInvocationId === null || requestParameters.xZendeskWebhookInvocationId === undefined) {
            throw new runtime.RequiredError('xZendeskWebhookInvocationId','Required parameter requestParameters.xZendeskWebhookInvocationId was null or undefined when calling integrationWebhooksZendesk.');
        }

        if (requestParameters.xZendeskWebhookSignature === null || requestParameters.xZendeskWebhookSignature === undefined) {
            throw new runtime.RequiredError('xZendeskWebhookSignature','Required parameter requestParameters.xZendeskWebhookSignature was null or undefined when calling integrationWebhooksZendesk.');
        }

        if (requestParameters.xZendeskWebhookSignatureTimestamp === null || requestParameters.xZendeskWebhookSignatureTimestamp === undefined) {
            throw new runtime.RequiredError('xZendeskWebhookSignatureTimestamp','Required parameter requestParameters.xZendeskWebhookSignatureTimestamp was null or undefined when calling integrationWebhooksZendesk.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xZendeskAccountId !== undefined && requestParameters.xZendeskAccountId !== null) {
            headerParameters['x-zendesk-account-id'] = String(requestParameters.xZendeskAccountId);
        }

        if (requestParameters.xZendeskWebhookId !== undefined && requestParameters.xZendeskWebhookId !== null) {
            headerParameters['x-zendesk-webhook-id'] = String(requestParameters.xZendeskWebhookId);
        }

        if (requestParameters.xZendeskWebhookInvocationId !== undefined && requestParameters.xZendeskWebhookInvocationId !== null) {
            headerParameters['x-zendesk-webhook-invocation-id'] = String(requestParameters.xZendeskWebhookInvocationId);
        }

        if (requestParameters.xZendeskWebhookSignature !== undefined && requestParameters.xZendeskWebhookSignature !== null) {
            headerParameters['x-zendesk-webhook-signature'] = String(requestParameters.xZendeskWebhookSignature);
        }

        if (requestParameters.xZendeskWebhookSignatureTimestamp !== undefined && requestParameters.xZendeskWebhookSignatureTimestamp !== null) {
            headerParameters['x-zendesk-webhook-signature-timestamp'] = String(requestParameters.xZendeskWebhookSignatureTimestamp);
        }

        const response = await this.request({
            path: `/webhooks/zendesk/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhooks from Zendesk
     * Zendesk IntegrationWebhooks
     */
    async integrationWebhooksZendesk(requestParameters: IntegrationWebhooksZendeskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationWebhooksZendeskRaw(requestParameters, initOverrides);
    }

    /**
     * Webhooks from Zoom
     * Zoom IntegrationWebhooks
     */
    async integrationWebhooksZoomRaw(requestParameters: IntegrationWebhooksZoomRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationWebhooksZoomResponseBody>> {
        if (requestParameters.zoomRequestBody === null || requestParameters.zoomRequestBody === undefined) {
            throw new runtime.RequiredError('zoomRequestBody','Required parameter requestParameters.zoomRequestBody was null or undefined when calling integrationWebhooksZoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/webhooks/zoom`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegrationWebhooksZoomRequestBodyToJSON(requestParameters.zoomRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationWebhooksZoomResponseBodyFromJSON(jsonValue));
    }

    /**
     * Webhooks from Zoom
     * Zoom IntegrationWebhooks
     */
    async integrationWebhooksZoom(requestParameters: IntegrationWebhooksZoomRequest, initOverrides?: RequestInit): Promise<IntegrationWebhooksZoomResponseBody> {
        const response = await this.integrationWebhooksZoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IntegrationWebhooksGoogleCalendarXGoogResourceStateEnum {
    Sync = 'sync',
    Exists = 'exists',
    NotExists = 'not_exists'
}
