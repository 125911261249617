import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { Route, Routes } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";

import { InsightsCustomDashboardEditRoute } from "./dashboards/create-edit/InsightsCustomDashboardEditRoute";
import { InsightsCoreDashboardRoute } from "./dashboards/show/InsightsCoreDashboardRoute";
import { InsightsCustomDashboardRoute } from "./dashboards/show/InsightsCustomDashboardRoute";
import { InsightsV3ListPage } from "./list/InsightsV3ListPage";

export const InsightsV3Route = (): React.ReactElement => {
  const { insightsV3 } = useFlags();
  if (!insightsV3) {
    return <NotFoundPage />;
  }

  return (
    <Routes>
      <Route path="/" element={<InsightsV3ListPage />} />
      <Route
        path="dashboards/core/:id"
        element={<InsightsCoreDashboardRoute />}
      />
      <Route
        path="dashboards/custom/:id"
        element={<InsightsCustomDashboardRoute />}
      />
      <Route
        path="dashboards/custom/:id/edit"
        element={<InsightsCustomDashboardEditRoute />}
      />
    </Routes>
  );
};
