import {
  AvailableIncidentFormEscalationElementElementTypeEnum,
  CatalogEntry,
  EscalationProviderEnum,
  IncidentFormEscalationElement,
  IncidentFormsPreviewEscalateFormResponseBody,
  IncidentFormsSubmitEscalateFormRequestBody,
} from "@incident-io/api";
import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { CheckboxRowV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Callout, CalloutTheme, Spinner } from "@incident-ui";
import _ from "lodash";
import { ReactElement } from "react";
import { useFormContext, useFormState } from "react-hook-form";

import { useClient } from "../../../contexts/ClientContext";
import { useAPI } from "../../../utils/swr";
import { tcx } from "../../../utils/tailwind-classes";
import { joinSpansWithCommasAndConnectorWord } from "../../../utils/utils";
import { asArray, ElementBindings } from "../element-bindings";

export type NativeFormData = Omit<
  IncidentFormsSubmitEscalateFormRequestBody,
  "element_bindings"
> & {
  element_bindings: ElementBindings;
} & {
  declare_incident: boolean;
  provider: EscalationProviderEnum.Native;
};

export const NativeEscalateForm = ({
  elements,
}: {
  elements: IncidentFormEscalationElement[];
}): ReactElement => {
  const formMethods = useFormContext<NativeFormData>();
  const elementsWithoutPriority = elements.filter(
    (element) =>
      element.available_element.element_type !==
      AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
  );

  const priorityElement = elements.find(
    (element) =>
      element.available_element.element_type ===
      AvailableIncidentFormEscalationElementElementTypeEnum.Priority,
  );

  return (
    <div>
      <div className="text-base-bold">Who do you need?</div>
      <div className="text-xs-med text-content-primary pt-[2px] pb-1">
        Pick from any of the options below and we&apos;ll find the right people
        to page
      </div>
      <div className="h-3" />
      <div className="bg-surface-secondary p-4 rounded-3">
        {elementsWithoutPriority.map((element, index) => {
          return (
            <div
              key={element.id}
              className={tcx({
                "pb-6": index !== elementsWithoutPriority.length - 1,
              })}
            >
              <EscalateFormElement
                element={element}
                priorityElement={priorityElement}
              />
            </div>
          );
        })}
      </div>
      <div className="text-sm-bold pt-10 pb-1">Notification message</div>
      <InputV2
        name="title"
        className="pb-3"
        formMethods={formMethods}
        placeholder="Something's wrong with the database"
      />
      {priorityElement ? (
        <div key={priorityElement.id} className="py-3">
          <EscalateFormElement
            element={priorityElement}
            priorityElement={priorityElement}
          />
        </div>
      ) : null}
      <div className="flex-grow"></div>
      <NoValidSelectionsCallout elements={elements} />
      <div className="h-4" />
      <CheckboxRowV2
        name="declare_incident"
        description={undefined}
        className="w-full"
        formMethods={formMethods}
        label="Also declare an incident"
      />
      <div className="h-4" />
    </div>
  );
};

const EscalateFormElement = ({
  element,
  priorityElement,
}: {
  element: IncidentFormEscalationElement;
  priorityElement: IncidentFormEscalationElement | undefined;
}) => {
  switch (element.available_element.element_type) {
    case AvailableIncidentFormEscalationElementElementTypeEnum.CatalogType:
      return (
        <CatalogTypeFormElement
          element={element}
          priorityElementId={priorityElement?.id}
          showPreview={true}
          catalogTypeId={element.available_element.catalog_type?.id || ""}
          label={element.available_element.catalog_type?.name || ""}
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.EscalationPolicy:
      return (
        <CatalogTypeFormElement
          element={element}
          priorityElementId={priorityElement?.id}
          showPreview={true}
          catalogTypeId="EscalationPath"
          label="Escalation path"
        />
      );
    case AvailableIncidentFormEscalationElementElementTypeEnum.User:
      return <UserEscalationFormElement element={element} />;
    case AvailableIncidentFormEscalationElementElementTypeEnum.Priority:
      return (
        <CatalogTypeFormElement
          element={element}
          priorityElementId={priorityElement?.id}
          showPreview={false}
          catalogTypeId="AlertPriority"
          label="Priority"
          isMulti={false}
        />
      );
    default:
      throw new Error(
        `Unrecognised element type: ${element.available_element.element_type}`,
      );
  }
};

const CatalogTypeFormElement = ({
  element,
  priorityElementId,
  catalogTypeId,
  showPreview,
  label,
  isMulti = true,
}: {
  element: IncidentFormEscalationElement;
  priorityElementId?: string;
  catalogTypeId: string;
  showPreview: boolean;
  label: string;
  isMulti?: boolean;
}) => {
  const apiClient = useClient();

  const formMethods = useFormContext<NativeFormData>();

  const elementValueOrScalar = formMethods.watch(
    `element_bindings.${element.id}`,
  );
  const elementValue = asArray(elementValueOrScalar);
  const priorityElementValue = formMethods.watch(
    `element_bindings.${priorityElementId}`,
  );

  const bindings = {
    [element.id]: elementValue,
  };

  if (priorityElementId && priorityElementValue) {
    bindings[priorityElementId] = asArray(priorityElementValue);
  }

  const { data: result, isLoading: loading } = useAPI(
    priorityElementId !== element.id
      ? "incidentFormsPreviewEscalateForm"
      : null,
    {
      id: element.incident_form_id,
      previewEscalateFormRequestBody: {
        element_bindings: bindings,
      },
    },
  );

  const pathsPreview = Object.values(result?.for_paths || {});
  const usersPreview = Object.values(result?.for_users || {});
  const preview = pathsPreview.concat(usersPreview);

  const targetsToBePaged = preview
    .map((val) => {
      return val.map((target) => {
        return target.name;
      });
    })
    .flat();
  const uniqueTargetsToBePaged = _.uniq(targetsToBePaged);

  const loadDefaultValueOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: catalogTypeId,
  });

  const hydrateDefaultValueOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: catalogTypeId,
  });
  return (
    <>
      <div className="text-sm-bold">
        {label}{" "}
        <span className="text-content-tertiary text-sm-normal">(optional)</span>
      </div>

      <div className="pb-1"></div>
      <div
        className={tcx({
          "bg-slate-50 rounded-t-lg":
            showPreview && uniqueTargetsToBePaged.length > 0,
          "bg-red-surface rounded-t-lg":
            uniqueTargetsToBePaged.length === 0 &&
            elementValue &&
            elementValue.length > 0 &&
            showPreview,
        })}
      >
        {isMulti ? (
          <DynamicMultiSelectV2
            name={`element_bindings.${element.id}.array_value`}
            loadOptions={loadDefaultValueOptions}
            hydrateOptions={hydrateDefaultValueOptions}
            formMethods={formMethods}
            closeMenuOnSelect={true}
          />
        ) : (
          <DynamicSingleSelectV2
            name={`element_bindings.${element.id}.scalar_value`}
            loadOptions={loadDefaultValueOptions}
            hydrateOptions={hydrateDefaultValueOptions}
            formMethods={formMethods}
          />
        )}
      </div>
      {showPreview ? (
        <div className="">
          <ElementPreview element={element} result={result} loading={loading} />
        </div>
      ) : null}
      {element.description && (
        <div className="text-xs-med text-content-secondary pt-1">
          {element.description}
        </div>
      )}
    </>
  );
};

const UserEscalationFormElement = ({
  element,
}: {
  element: IncidentFormEscalationElement;
}) => {
  const apiClient = useClient();
  const formMethods = useFormContext<NativeFormData>();

  return (
    <>
      <div className="text-sm-bold">
        User{" "}
        <span className="text-content-tertiary text-sm-normal">(optional)</span>
      </div>
      {element.description && (
        <div className="text-sm-normal pt-[2px]">{element.description}</div>
      )}
      <div className="pb-1"></div>
      <DynamicMultiSelectV2
        name={`element_bindings.${element.id}.array_value`}
        loadOptions={getTypeaheadOptions(
          apiClient,
          TypeaheadTypeEnum.OnCallUser,
          {
            sortKey: "name",
          },
        )}
        hydrateOptions={hydrateInitialSelectOptions(
          apiClient,
          TypeaheadTypeEnum.OnCallUser,
        )}
        formMethods={formMethods}
      />
    </>
  );
};

const ElementPreview = ({
  element,
  result,
  loading,
}: {
  element: IncidentFormEscalationElement;
  result: IncidentFormsPreviewEscalateFormResponseBody | undefined;
  loading: boolean;
}) => {
  const formMethods = useFormContext<NativeFormData>();

  const bindingsOrScalar = formMethods.watch(`element_bindings.${element.id}`);
  const bindings = asArray(bindingsOrScalar);

  if (bindings.length === 0) {
    return <></>;
  }

  const pathsPreview = Object.values(result?.for_paths || {});
  const usersPreview = Object.values(result?.for_users || {});
  const preview = pathsPreview.concat(usersPreview);

  const targetsToBePaged = preview
    .map((val) => {
      return val.map((target) => {
        return target.name;
      });
    })
    .flat();
  const uniqueTargetsToBePaged = _.uniq(targetsToBePaged);

  const messages: React.ReactElement[] = [];

  if (uniqueTargetsToBePaged.length > 0) {
    messages.push(
      <>
        <span className="text-sm-bold">
          {joinSpansWithCommasAndConnectorWord(uniqueTargetsToBePaged)}
        </span>
        <span className="text-sm-normal"> will be notified.</span>
      </>,
    );
  }

  const addServiceMessages = (
    serviceData,
    catalogEntries: CatalogEntry[] | undefined,
    provider: string,
  ) => {
    Object.keys(serviceData).forEach((key) => {
      const catalogEntry = (catalogEntries || []).find(
        (entry) => entry.id === key,
      );
      if (catalogEntry) {
        const serviceName = serviceData[key][0]?.name;
        if (serviceName) {
          if (messages.length > 0) {
            messages.push(<span> </span>);
          }

          messages.push(
            <div className="inline">
              We will escalate to
              <span className="text-sm-bold inline">
                {" "}
                {serviceName}
              </span> in {provider}.{" "}
            </div>,
          );
        }
      }
    });
  };

  addServiceMessages(
    result?.for_pagerduty_service || {},
    result?.catalog_entries,
    "PagerDuty",
  );
  addServiceMessages(
    result?.for_opsgenie_team || {},
    result?.catalog_entries,
    "Opsgenie",
  );

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {uniqueTargetsToBePaged.length > 0 ? (
            <div className="pt-2">{messages}</div>
          ) : (
            <div className="pt-2 text-red-500">
              You haven&apos;t set an escalation path for{" "}
              {bindings.length === 1 ? "this type" : "these types"}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const NoValidSelectionsCallout = ({
  elements,
}: {
  elements: IncidentFormEscalationElement[];
}) => {
  const formMethods = useFormContext<NativeFormData>();

  const formState = useFormState(formMethods);

  const bindings = formMethods.watch(`element_bindings`);

  const nonPriorityBindings = Object.fromEntries(
    Object.entries(bindings).filter(([element_id, _]) => {
      return (
        elements.find((element) => element.id === element_id)?.available_element
          .element_type !==
        AvailableIncidentFormEscalationElementElementTypeEnum.Priority
      );
    }),
  );

  if (
    (formState.dirtyFields.title || formState.dirtyFields.element_bindings) &&
    Object.values(nonPriorityBindings).every((val) => {
      if (!val) {
        return true;
      }

      return asArray(val).length === 0;
    })
  ) {
    return (
      <Callout theme={CalloutTheme.Danger}>
        You need to select at least one option for who you need
      </Callout>
    );
  }

  return <></>;
};
