import { IconEnum, Markdown } from "@incident-ui";
import { Modal } from "@incident-ui/Modal/Modal";
import { ModalContent } from "@incident-ui/Modal/ModalContent";
import { ModalFooter } from "@incident-ui/Modal/ModalFooter";
import React from "react";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import styles from "src/components/timeline/TimelineItems.module.scss";
import {
  Incident,
  SlackImage,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { SlackMessageLink } from "./TimelineItemSlackPin";

export const TimelineItemSlackImage = (
  _: Incident,
  item: TimelineItemObject,
  zoomImageSource: string | undefined,
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void,
): TimelineItemRenderProps | null => {
  const slackImage = item.slack_image;
  if (!slackImage) {
    throw new Error(
      "malformed timeline item: slack_image was missing slack_image field",
    );
  }

  // Don't try to return a timeline item if the image has been deleted.
  if (slackImage.slack_message?.deleted) {
    return null;
  }

  const imageText = imageMessageText(slackImage);

  const imageZoomModal = (
    <Modal
      title="No title shown"
      isOpen={!!zoomImageSource}
      analyticsTrackingId={null}
      onClose={() => setZoomImageSource(undefined)}
      hideHeader
      maximised
    >
      <ModalContent>
        <img src={zoomImageSource} alt="" />
      </ModalContent>
      <ModalFooter
        hideConfirmButton={true}
        confirmButtonType="button"
        onConfirm={() => setZoomImageSource(undefined)}
        onClose={() => setZoomImageSource(undefined)}
        cancelButtonText={"Close"}
      />
    </Modal>
  );

  return {
    icon: IconEnum.Bookmark,
    avatarUrl: slackImage.message_user?.avatar_url,
    description: imageText,
    children: (
      <>
        {imageZoomModal}
        <TimelineItemContentBox>
          <Markdown className={styles.contentBox}>
            {slackImage.slack_message?.text}
          </Markdown>
          {slackImage.images &&
            slackImage.images.map((im) => (
              <img
                key={im.id}
                alt=""
                src={im.url}
                className={styles.slackPinImage}
                onClick={() => setZoomImageSource(im.url)}
              />
            ))}
        </TimelineItemContentBox>
      </>
    ),
  };
};

export const imageMessageText = (
  slack_image: SlackImage,
): React.ReactElement => {
  const countText = slack_image.images.length > 1 ? "some images" : "an image";
  return (
    <>
      <strong>{slack_image.message_user?.name ?? "Someone"}</strong> posted{" "}
      {countText} in{" "}
      <SlackMessageLink
        permalink={slack_image.slack_message?.permalink}
        text="a Slack message"
      />
    </>
  );
};
