import { TrendSentimentEnum } from "@incident-io/api";
import {
  calculateDateRangePickerDates,
  DateRangePickerState,
  dateRangePickerStateToTimestamps,
} from "@incident-shared/forms/v1/DateRangePicker";
import { Badge, BadgeSize, BadgeTheme, IconEnum, Tooltip } from "@incident-ui";
import { format, formatDistance, parseISO } from "date-fns";
import { upperCase } from "lodash";
import { tcx } from "src/utils/tailwind-classes";

import { calculatePreviousPeriod } from "./InsightsDashboardSection";

export type TrendTileProps = {
  title: string;
  subtitle?: string;
  sentiment: TrendSentimentEnum;
  dateRange: DateRangePickerState;
  percentageChange: number;
  previousValue: number;
  currentValue: number;
  unit: string;
  onClick?: () => void;
  smaller?: boolean; // For a smaller tile
};

export const TrendTile = ({
  title,
  subtitle,
  sentiment,
  dateRange,
  percentageChange,
  previousValue,
  currentValue,
  unit,
  onClick,
  smaller,
}: TrendTileProps) => {
  const styling = sentimentStyling[sentiment];

  let displayPercentageChange = `${percentageChange.toFixed(0)}%`;
  if (percentageChange > 0) {
    displayPercentageChange = `+${displayPercentageChange}`;
  } else if (percentageChange === 0) {
    displayPercentageChange = "No change";
  }

  return (
    <div dir="ltr">
      <Tooltip
        align="start"
        content={
          <ToolTipContent
            dateRange={dateRange}
            previousValue={previousValue}
            currentValue={currentValue}
            unit={unit}
            colour={styling.tooltipBar}
          />
        }
      >
        <div
          className={tcx(
            "flex flex-col h-full gap-2 rounded-lg p-4 border box-border border-transparent",
            "hover:bg-gradient-to-r",
            styling.hover,
            styling.background,
            onClick ? "cursor-pointer" : "",
          )}
          onClick={onClick}
        >
          <div>
            <span
              className={
                smaller
                  ? "text-xs-med text-content-primary"
                  : "text-sm-med text-content-primary"
              }
            >
              {title}
            </span>

            {subtitle && (
              <div
                className={tcx(
                  "text-xs-med flex flex-wrap items-center",
                  styling.variables,
                )}
              >
                {subtitle}
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <Badge
              icon={
                sentiment === TrendSentimentEnum.Neutral
                  ? IconEnum.TrendNeutral
                  : percentageChange > 0
                  ? IconEnum.TrendUp
                  : IconEnum.TrendDown
              }
              theme={styling.theme}
              size={BadgeSize.ExtraSmall}
            />
            <span className={smaller ? "text-base-bold" : "text-2xl-bold"}>
              {displayPercentageChange}
            </span>
          </div>
          {!smaller && (
            <span className="text-xs-med text-content-secondary">
              vs previous {dateRangePickerStateToHumanString(dateRange)}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

const ToolTipContent = ({
  dateRange,
  previousValue,
  currentValue,
  unit,
  colour,
}: {
  dateRange: DateRangePickerState;
  previousValue: number;
  currentValue: number;
  unit: string;
  colour: string;
}) => {
  const { from: startDate, to: endDate } =
    dateRangePickerStateToTimestamps(dateRange);
  const { prevEndDate, prevStartDate } = calculatePreviousPeriod(
    startDate,
    endDate,
  );

  return (
    <div className="flex flex-col gap-3 rounded-lg">
      <ToolTipContentRow
        startDate={prevStartDate}
        endDate={prevEndDate}
        value={previousValue}
        unit={unit}
        colour={colour}
      />
      <ToolTipContentRow
        startDate={startDate}
        endDate={endDate}
        value={currentValue}
        unit={unit}
        colour="bg-slate-600"
      />
    </div>
  );
};

const ToolTipContentRow = ({
  startDate,
  endDate,
  value,
  unit,
  colour,
}: {
  startDate: string;
  endDate: string;
  value: number;
  unit: string;
  colour: string;
}) => {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  const dateFmtOptions = "MMM dd";
  const startDateString = format(start, dateFmtOptions);
  const endDateString = format(end, dateFmtOptions);

  let roundedValue = value.toFixed(0);
  if (!Number.isInteger(value)) {
    roundedValue = value.toFixed(2);
  }

  return (
    <div className="flex flex-row gap-2">
      <div className={tcx("w-[4px] h-9 rounded", colour)}></div>
      <div className={"flex flex-col"}>
        <span className="text-content-tertiary text-xs-med">
          {upperCase(startDateString)} - {upperCase(endDateString)}
        </span>
        <span className="text-white text-sm-bold">
          {roundedValue} {unit}
        </span>
      </div>
    </div>
  );
};

const sentimentStyling: {
  [key in TrendSentimentEnum]: {
    background: string;
    tooltipBar: string;
    hover: string;
    variables: string;
    theme: BadgeTheme;
  };
} = {
  [TrendSentimentEnum.Good]: {
    background: "bg-green-50",
    tooltipBar: "bg-green-400",
    hover: "hover:border-green-200 hover:from-[#EEFCF880] hover:to-[#A9EFDD]",
    variables: "text-green-content",
    theme: BadgeTheme.Success,
  },
  [TrendSentimentEnum.Neutral]: {
    background: "bg-blue-50",
    tooltipBar: "bg-blue-400",
    hover: "hover:border-blue-200 hover:from-[#EFF6FF80] hover:to-[#BFDBFE]",
    variables: "text-blue-content",
    theme: BadgeTheme.Info,
  },
  [TrendSentimentEnum.Bad]: {
    background: "bg-red-50",
    tooltipBar: "bg-red-400",
    hover: "hover:border-red-200 hover:from-[#FEF2F280] hover:to-[#FECACA]",
    variables: "text-red-content",
    theme: BadgeTheme.Error,
  },
};

const dateRangePickerStateToHumanString = (
  state: DateRangePickerState,
): string => {
  const { from, to } = calculateDateRangePickerDates(state);
  const dist = formatDistance(from, to);
  if (dist.startsWith("about ")) {
    return `~${dist.slice(6)}`;
  }
  if (dist === "28 days") {
    return "4 weeks"; // Sorry not sorry
  }
  return dist;
};
