import { IconEnum } from "@incident-ui";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemRenderProps } from "./TimelineItem";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemStatusPageIncidentLinked = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  const link = item.status_page_incident_linked;
  if (!link) {
    throw new Error(
      "malformed timeline item: status_page_incident_linked was missing status_page_incident_linked field",
    );
  }

  return {
    icon: IconEnum.StatusPageHifi,
    avatarUrl: link.linker.user?.avatar_url,
    timestamp: item.occured_at,
    description: (
      <>
        <TimelineItemSource actor={link.linker} /> linked a status page incident
      </>
    ),
  };
};
