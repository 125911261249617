import {
  getCatalogTypeaheadOptions,
  hydrateInitialCatalogOptions,
} from "@incident-shared/catalog";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { Loader } from "@incident-ui";
import { useFormContext } from "react-hook-form";

import {
  EscalationProviderEnum,
  useClient,
} from "../../../contexts/ClientContext";
import { useAPI } from "../../../utils/swr";

export type SplunkFormData = {
  title: string;
  provider: EscalationProviderEnum.SplunkOnCall;
  user_targets: string[];
  escalation_policy_targets: string[];
};

export const SplunkEscalateForm = () => {
  const formMethods = useFormContext<SplunkFormData>();

  const { data: catalogData, isLoading: loadingCatalogTypes } = useAPI(
    "catalogListTypes",
    {},
  );

  const splunkUserCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "SplunkOnCallUser",
  );
  const splunkEPCatalogType = catalogData?.catalog_types.find(
    (type) => type.type_name === "SplunkOnCallEscalationPolicy",
  );

  const apiClient = useClient();

  const loadUserOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: splunkUserCatalogType?.id ?? "",
  });
  const hydrateUserOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: splunkUserCatalogType?.id ?? "",
  });

  const loadEPOptions = getCatalogTypeaheadOptions({
    apiClient,
    catalogTypeID: splunkEPCatalogType?.id ?? "",
  });
  const hydrateEPOptions = hydrateInitialCatalogOptions({
    apiClient,
    catalogTypeID: splunkEPCatalogType?.id ?? "",
  });

  if (loadingCatalogTypes) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col">
      <div>
        <div className="text-base-bold">Who do you need?</div>
        <div className="text-xs-med text-content-primary pt-[2px] pb-1">
          Pick the users and/or escalation policies that you want to notify.
        </div>
      </div>
      <div className="bg-surface-secondary p-4 rounded-3 flex flex-col gap-6 mt-3">
        <DynamicMultiSelectV2
          loadOptions={loadEPOptions}
          hydrateOptions={hydrateEPOptions}
          name="escalation_policy_targets"
          formMethods={formMethods}
          label={
            <span>
              Escalation policies{" "}
              <span className="text-content-tertiary text-sm-normal">
                (optional)
              </span>
            </span>
          }
        />
        <DynamicMultiSelectV2
          loadOptions={loadUserOptions}
          hydrateOptions={hydrateUserOptions}
          name="user_targets"
          formMethods={formMethods}
          label={
            <span>
              Users{" "}
              <span className="text-content-tertiary text-sm-normal">
                (optional)
              </span>
            </span>
          }
        />
      </div>
      <InputV2
        label={<span className="text-sm-bold">Notification message</span>}
        name="title"
        className="mt-10"
        formMethods={formMethods}
        placeholder="Something's wrong with the database"
      />
    </div>
  );
};
