import {
  AlertRoute,
  AlertRouteAlertSourcePayload,
  AlertRouteIncidentTemplatePayload,
  AlertRouteIncidentTemplatePayloadPrioritySeverityEnum,
  AlertRoutesCreateAlertRouteRequestBody,
  CatalogType,
  ConditionGroup,
  ConditionSubjectIconEnum,
  CustomField,
  CustomFieldFieldTypeEnum,
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineScope,
} from "@incident-io/api";
import { isEmptyBinding } from "@incident-shared/engine";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { makeExpressionReference } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionDeletionUsages } from "@incident-shared/engine/expressions/ExpressionsEditor";
import {
  ExpressionFormData,
  expressionToPayload,
} from "@incident-shared/engine/expressions/expressionToPayload";
import { containsExpressionReference } from "src/components/legacy/workflows/common/utils";
import { purgeEmpty } from "src/utils/utils";

import { resourceTypeForCustomField } from "./CreateIncidentSection";

export type AlertRouteFormData = {
  enabled: boolean;
} & AlertRouteTitleFormData &
  ChooseAlertSourcesFormData &
  FilterFormData &
  CreateIncidentsFormData &
  EscalateFormData;

export type ChooseAlertSourcesFormData = {
  alertSources: {
    [id: string]: {
      condition_groups: ConditionGroup[];
      selected: boolean;
    };
  };
  expressions: ExpressionFormData[];
};

export type FilterFormData = {
  hasEverConfiguredFilters: boolean;
  conditionGroups: Array<ConditionGroup>;
  expressions: ExpressionFormData[];
};

export type CreateIncidentsFormData = {
  hasEverConfiguredIncidents: boolean;
  enableIncidents?: boolean;
  incidentConditionGroups: Array<ConditionGroup>;

  // grouping content
  enableGrouping?: boolean;
  groupingKeys: Array<{ key: string; label: string }>;
  groupingWindow: string;
  deferTime: number;

  template?: AlertRouteIncidentTemplatePayload & {
    custom_fields?: {
      [key: string]: EngineParamBindingPayload & {
        was_automatically_set?: boolean;
      };
    };
  };
  autoDeclineEnabled: boolean;
  expressions: ExpressionFormData[];
};

export type EscalateFormData = {
  hasEverConfiguredEscalations: boolean;
  enableEscalations: boolean;
  escalationBinding?: EngineParamBindingPayload;
  autoCancelEscalations: boolean;
  expressions: ExpressionFormData[];
  mode: "dynamic" | "static";
};

export type AlertRouteTitleFormData = {
  name: string;
};

export const parseAlertRoute = (
  alertRoute: AlertRoute,
  initialSourceID: string | null,
): AlertRouteFormData => {
  const alertSources = {};

  alertRoute.alert_sources.forEach((source) => {
    alertSources[source.alert_source_id] = {
      condition_groups: source.condition_groups,
      selected: true,
    };
  });

  if (initialSourceID) {
    alertSources[initialSourceID] = {
      condition_groups: [],
      selected: true,
    };
  }

  const escalationBinding = alertRoute.escalation_bindings[0]?.binding;
  return {
    name: alertRoute.name,
    enabled: alertRoute.enabled,
    hasEverConfiguredEscalations: true,
    hasEverConfiguredFilters: true,
    hasEverConfiguredIncidents: true,
    // If this is 0, it means the grouping is disabled, so it gets set to the default
    // in case the user wants to enable it. It will be reset to 0 if disabled on submit.
    groupingWindow:
      alertRoute.grouping_window_seconds > 0
        ? alertRoute.grouping_window_seconds.toString()
        : "1800",
    groupingKeys: alertRoute.grouping_keys.flatMap((x) => {
      return { key: x.id, label: x.reference?.label || "" };
    }),
    enableGrouping: alertRoute.grouping_window_seconds > 0,
    conditionGroups: alertRoute.condition_groups,
    deferTime: alertRoute.defer_time_seconds / 60,
    // response body is a superset of request body, this is fine
    template:
      alertRoute.template as unknown as AlertRouteIncidentTemplatePayload,
    expressions: alertRoute.expressions || [],
    autoDeclineEnabled: alertRoute.auto_decline_enabled,
    enableIncidents: alertRoute.incident_enabled,
    incidentConditionGroups: alertRoute.incident_condition_groups,
    // Right now, the frontend only ever shows one escalation binding
    escalationBinding: escalationBinding,
    alertSources,
    enableEscalations: alertRoute.escalation_bindings.length > 0,
    autoCancelEscalations: alertRoute.auto_cancel_escalations,

    // If there are references (e.g. expressions or variables) then show 'dynamic',
    // otherwise show 'static'
    mode: [
      escalationBinding?.value,
      ...(escalationBinding?.array_value ?? []),
    ].some((b) => !!b?.reference)
      ? "dynamic"
      : "static",
  };
};

export const parseFormData = (
  formData: AlertRouteFormData,
  customFields: CustomField[],
): AlertRoutesCreateAlertRouteRequestBody => {
  const conditionGroupsString = JSON.stringify(formData.conditionGroups);
  const incidentConditionsString = JSON.stringify(
    formData.incidentConditionGroups,
  );
  const parsedFormData: AlertRoutesCreateAlertRouteRequestBody = {
    name: formData.name,
    enabled: formData.enabled,
    grouping_window_seconds: formData.enableGrouping
      ? parseInt(formData.groupingWindow)
      : 0,
    grouping_keys: formData.enableGrouping
      ? formData.groupingKeys.map((x) => {
          return { id: x.key };
        })
      : [],
    condition_groups: conditionGroupsToGroupPayloads(
      formData.conditionGroups || [],
    ),
    defer_time_seconds: formData.deferTime * 60,
    template: parseIncidentTemplateData(purgeEmpty(formData.template)),
    expressions: formData.expressions
      ?.filter((e) => {
        // Remove any expressions that don't have any usages
        const usageTypes = getAlertRouteExpressionUsages(
          e,
          formData,
          customFields,
          formData.escalationBinding,
        );

        // Or if the expression is used in a condition, it should be included
        if (
          conditionGroupsString.includes(e.reference) ||
          incidentConditionsString.includes(e.reference)
        ) {
          return true;
        }

        const sourceContainsExpression = Object.values(
          formData.alertSources,
        ).some((source) => {
          const sourceConditionString = JSON.stringify(
            source.condition_groups || [],
          );
          return sourceConditionString.includes(e.reference);
        });

        if (sourceContainsExpression) {
          return true;
        }

        return usageTypes.some((u) => u.usages.length > 0);
      })
      .map(expressionToPayload),
    auto_decline_enabled: formData.autoDeclineEnabled,
    incident_enabled: formData.enableIncidents || false,
    incident_condition_groups: formData.enableIncidents
      ? conditionGroupsToGroupPayloads(formData.incidentConditionGroups || [])
      : [],
    alert_sources: alertSourcesToPayload(formData.alertSources),
    auto_cancel_escalations: formData.autoCancelEscalations,
    escalation_bindings: [],
  };
  if (formData.enableEscalations) {
    parsedFormData.escalation_bindings = formData.escalationBinding
      ? [
          {
            binding: formData.escalationBinding,
          },
        ]
      : [];
  }
  return parsedFormData;
};

export const alertSourcesToPayload = (
  alertSources: ChooseAlertSourcesFormData["alertSources"],
): AlertRouteAlertSourcePayload[] => {
  return Object.entries(alertSources)
    .filter(([_, value]) => value.selected)
    .map(([id, value]) => ({
      alert_source_id: id,
      condition_groups: conditionGroupsToGroupPayloads(
        value.condition_groups || [],
      ),
    }));
};

export const parseIncidentTemplateData = (
  template?: AlertRouteIncidentTemplatePayload,
): AlertRouteIncidentTemplatePayload | undefined => {
  if (!template) {
    return template;
  }
  if (template.name) {
    if (isEmptyBinding(template.name)) {
      template.name = undefined;
    }
  }
  if (template.summary) {
    if (isEmptyBinding(template.summary)) {
      template.summary = undefined;
    }
  }
  if (template.severity) {
    if (isEmptyBinding(template.severity)) {
      template.severity = undefined;
    }
  }
  if (template.incident_type) {
    if (isEmptyBinding(template.incident_type)) {
      template.incident_type = undefined;
    }
  }
  if (template.incident_mode) {
    if (isEmptyBinding(template.incident_mode)) {
      template.incident_mode = undefined;
    }
  }
  if (template.workspace) {
    if (isEmptyBinding(template.workspace)) {
      template.workspace = undefined;
    }
  }
  if (template.custom_fields) {
    Object.entries(template.custom_fields).forEach(([fieldID, binding]) => {
      if (isEmptyBinding(binding)) {
        // @ts-expect-error this can't be undefined, ts is being dumb.
        delete template.custom_fields[fieldID];
      }
    });
  }
  return template;
};

export enum AlertRouteCreationSource {
  StatusPages = "status-pages",
}

export const findApplicableCustomFields = ({
  customFields,
  catalogTypes,
  scope,
}: {
  customFields: CustomField[];
  catalogTypes: CatalogType[];
  scope: EngineScope;
}) => {
  const defaultCustomFields: {
    [key: string]: EngineParamBinding & {
      was_automatically_set?: boolean;
    };
  } = {};

  for (const customField of customFields) {
    const resourceType = resourceTypeForCustomField(customField, catalogTypes);
    const applicableRefs = scope.references.filter(
      (r) => r.type === resourceType,
    );

    if (customField.field_type === CustomFieldFieldTypeEnum.MultiSelect) {
      const ref = applicableRefs.find((r) => r.array);
      if (ref) {
        defaultCustomFields[customField.id] = {
          array_value: [
            {
              reference: ref.key,
              label: ref.label,
              sort_key: ref.label,
            },
          ],
        };
      }
    } else {
      const ref = applicableRefs.find((r) => !r.array);
      if (ref) {
        defaultCustomFields[customField.id] = {
          value: {
            reference: ref.key,
            label: ref.label,
            sort_key: ref.label,
          },
        };
      }
    }
  }

  return defaultCustomFields;
};

export const getDefaultAlertRouteValues = (
  scope: EngineScope,
  customFields: CustomField[],
  initialSourceID: string | null,
  existingAlertRoutes: AlertRoute[],
  catalogTypes: CatalogType[],
  source: AlertRouteCreationSource | undefined,
): AlertRouteFormData => {
  const defaultCustomFields = findApplicableCustomFields({
    customFields: customFields,
    catalogTypes: catalogTypes,
    scope: scope,
  });

  for (const customField of Object.keys(defaultCustomFields)) {
    defaultCustomFields[customField].was_automatically_set = true;
  }

  let defaults: AlertRouteFormData = {
    name:
      existingAlertRoutes.length > 0
        ? `Alert route ${existingAlertRoutes.length + 1}`
        : "Default alert route",
    enabled: true,
    hasEverConfiguredEscalations: false,
    hasEverConfiguredFilters: false,
    hasEverConfiguredIncidents: false,
    incidentConditionGroups: [],
    groupingKeys: [],
    conditionGroups: [],
    groupingWindow: "1800",
    enableGrouping: true,
    expressions: [],
    deferTime: 2,
    template: {
      name: defaultIncidentTemplateName,
      priority_severity:
        AlertRouteIncidentTemplatePayloadPrioritySeverityEnum.Max,
      custom_fields: defaultCustomFields,
      custom_field_priorities: customFields.reduce((lookup, customField) => {
        if (customField.field_type === CustomFieldFieldTypeEnum.MultiSelect) {
          lookup[customField.id] = "append";
        } else {
          lookup[customField.id] = "first-wins";
        }
        return lookup;
      }, {}),
    },
    escalationBinding: {
      array_value: [],
    },
    mode: "dynamic",
    autoDeclineEnabled: false,
    alertSources: initialSourceID
      ? { [initialSourceID]: { selected: true, condition_groups: [] } }
      : {},
    enableEscalations: false,
    autoCancelEscalations: false,
  };

  // this is a lot of hard coding - we decided it wasn't worth any of the effort or data wrangling
  // it would take to not hard code it, we think this is pretty static and safe so it's fine
  const statusPageDefaults: Partial<AlertRouteFormData> = {
    name: "High status page views",
    conditionGroups: [
      {
        conditions: [
          {
            operation: {
              label: "is one of",
              value: "one_of",
            },
            params: [
              {
                array: true,
                description: "",
                infer_reference: false,
                label: "set",
                name: "set",
                optional: false,
                type: "AlertSourceType",
              },
            ],
            param_bindings: [
              {
                array_value: [
                  {
                    label: "Status Page Views",
                    value: "status_page_views",
                    literal: "status_page_views",
                    sort_key: "Status Page Views",
                  },
                ],
              },
            ],
            subject: {
              label: "Alert → Source type",
              icon: "alert" as unknown as ConditionSubjectIconEnum,
              reference: "alert.source_type",
            },
          },
        ],
      },
    ],
    // 3 hours - status pages are probably spiking for a while after an issue as everyone processes their notifs
    groupingWindow: "10800",
  };

  if (source === AlertRouteCreationSource.StatusPages) {
    defaults = { ...defaults, ...statusPageDefaults };
  }

  return defaults;
};

const defaultIncidentTemplateName: EngineParamBinding = {
  value: {
    label: "title",
    literal: JSON.stringify({
      content: [
        {
          content: [
            {
              attrs: {
                label: "Alert -> Title",
                missing: false,
                name: "alert.title",
              },
              type: "varSpec",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
    }),
    sort_key: "title",
  },
};

export const getAlertRouteExpressionUsages = (
  expression: ExpressionFormData,
  formData: AlertRouteFormData,
  customFields: CustomField[],
  escalationBinding?: EngineParamBindingPayload,
): ExpressionDeletionUsages => {
  const containsExpressionRef = (serialisable: unknown) =>
    containsExpressionReference(expression, serialisable);
  const expressionReference = makeExpressionReference(expression);
  // In order to find usages, we're going to just stringify each section
  // of the form and look for our expression reference in the JSON.
  const usedInIncidentType =
    formData.template?.incident_type &&
    containsExpressionRef(formData.template.incident_type);
  const usedInSeverity =
    formData.template?.severity &&
    containsExpressionRef(formData.template.severity);
  const customFieldUses =
    formData.template?.custom_fields &&
    Object.entries(formData.template.custom_fields).filter(
      ([_, customField]) =>
        customField.value?.reference === expressionReference ||
        customField.array_value?.some(
          (val) => val.reference === expressionReference,
        ),
    );

  const usedInIncidentMode =
    formData.template?.incident_mode &&
    containsExpressionRef(formData.template.incident_mode);

  const escalationUses =
    escalationBinding &&
    (escalationBinding.value?.reference === expressionReference ||
      escalationBinding.array_value?.some(
        (val) => val.reference === expressionReference,
      ));

  const usedInName =
    formData.template?.name && containsExpressionRef(formData.template.name);
  const usedInSummary =
    formData.template?.summary &&
    containsExpressionRef(formData.template.summary);

  const usedInWorkspace =
    formData.template?.workspace &&
    containsExpressionRef(formData.template.workspace);

  const usedInPrioritySeverity =
    formData.template?.priority_severity &&
    containsExpressionRef(formData.template.priority_severity);

  const usages: string[] = [];
  if (usedInIncidentType) {
    usages.push("Incident type");
  }
  if (usedInIncidentMode) {
    usages.push("Incident mode");
  }
  if (usedInSeverity) {
    usages.push("Severity");
  }
  if (usedInName) {
    usages.push("Name");
  }
  if (usedInSummary) {
    usages.push("Summary");
  }
  if (usedInWorkspace) {
    usages.push("Workspace");
  }
  if (usedInPrioritySeverity) {
    usages.push("Priority Severity");
  }
  if (customFieldUses) {
    usages.push(
      ...customFieldUses.map((customField, index) => {
        const field = customFields.find((cf) => cf.id === customField[0]);
        return field?.name ?? `Custom field ${index}`;
      }),
    );
  }
  const escalationUsages: string[] = [];
  if (escalationUses) {
    escalationUsages.push("Who should we escalate to?");
  }
  return [
    { label: "Incident fields", usages: usages },
    { label: "Escalation fields", usages: escalationUsages },
  ];
};
