import { NonPrimitiveEntryList } from "@incident-shared/attribute";
import { FieldInfo } from "@incident-shared/filters/IncidentsFilterControlSearchBar";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Checkbox,
  Icon,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  LocalDateTime,
  SeverityBadge,
  SlackTeamAvatar,
  Tooltip,
} from "@incident-ui";
import _, { capitalize } from "lodash";
import React from "react";
import {
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  CustomFieldEntry,
  CustomFieldTypeInfo,
  CustomFieldTypeInfoFieldTypeEnum,
  CustomFieldValue,
  Incident,
  IncidentRoleAssignment,
  IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum,
  IncidentStatusCategoryEnum,
  IncidentVisibilityEnum,
  SlackTeamConfig,
} from "src/contexts/ClientContext";
import { useIsMSTeamsTabFriendlyView } from "src/contexts/MSTeamsTabContext";
import { tcx } from "src/utils/tailwind-classes";

import { formatDurationInSecondsShort } from "../../../utils/datetime";
import { getDurationInSeconds } from "../../../utils/presenters";
import { CopyDebugID } from "../../../utils/ShowDebugIDProvider";
import styles from "./IncidentListItem.module.scss";
import { useIncidentsListContext } from "./IncidentsListContext";

export type IncidentListItemProps = {
  incident: Incident;
  slackTeamConfigs: SlackTeamConfig[] | null;
  showIncidentTypes: boolean;
  onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  onChangeSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedIncidentIDs: string[];
  enableSelection: boolean;
};

export function IncidentListItem({
  incident,
  showIncidentTypes,
  onClick,
  onChangeSelection,
  selectedIncidentIDs,
  enableSelection,
  slackTeamConfigs,
}: IncidentListItemProps): React.ReactElement {
  const { fieldsToDisplay } = useIncidentsListContext();
  const duration = getDurationInSeconds(incident);
  const reportedAgo = formatDurationInSecondsShort(
    (new Date().getTime() - incident.reported_at.getTime()) / 1000,
  );
  const leadRole = incident.incident_role_assignments.find(
    ({ role }) => role.role_type === RoleTypeEnum.Lead,
  );
  const leadAlias = leadRole?.role?.name;
  const lead = leadRole?.assignee;

  // Compute this just once, in case performance gets nasty.
  const isChecked = _.includes(selectedIncidentIDs, incident.id);

  const slackTeam =
    (slackTeamConfigs ?? []).length > 1
      ? slackTeamConfigs?.find(
          (config) => config.slack_team_id === incident.slack_team_id,
        )
      : undefined;

  const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();

  return (
    <li
      className={tcx(
        styles.incidentListItem,
        "hover:bg-surface-secondary group/incident",
        { "bg-surface-secondary": isChecked },
      )}
      onClick={onClick}
    >
      {enableSelection && (
        <span className="hidden h-full md:block my-auto">
          <Checkbox
            id={incident.id}
            className={
              "px-2 mx-1 my-2 rounded hover:bg-surface-tertiary py-5 z-50"
            }
            onChange={onChangeSelection}
            checked={isChecked}
          />
        </span>
      )}
      <Button
        className={tcx("w-full flex-1 min-w-0 text-sm", {
          "pl-4": !enableSelection,
          "pl-4 md:pl-0": enableSelection,
        })}
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId="incident-list-item"
        analyticsTrackingMetadata={{ incidentID: incident.id }}
        href={
          isMSTeamsTabFriendlyView && incident.ms_teams_channel_url
            ? incident.ms_teams_channel_url.replace("https://", "msteams://")
            : `/incidents/${incident.external_id}`
        }
        target={isMSTeamsTabFriendlyView ? "_blank" : undefined}
      >
        <div className={styles.info}>
          <div className="font-regular mb-2 items-center text-content-primary flex min-w-0">
            {incident.visibility === IncidentVisibilityEnum.Private && (
              <div className="-ml-0.5 mr-1">
                <Icon
                  id={IconEnum.LockClosed}
                  size={IconSize.Small}
                  className="text-slate-600"
                />
              </div>
            )}
            <div className="truncate xl:max-w-[95%] xl:!block">
              <span className="font-semibold truncate">
                {" "}
                INC-{incident.external_id}
              </span>{" "}
              <span className="truncate">{incident.name}</span>
              <CopyDebugID id={incident.id} className={"ml-4"} />
            </div>
          </div>
          <div className={styles.badges}>
            <SeverityBadge
              className="font-normal"
              severity={incident.severity}
              size={BadgeSize.Medium}
            />
            <IncidentStatusBadge
              className="font-normal"
              status={incident.incident_status}
              size={BadgeSize.Medium}
            />
            {showIncidentTypes && incident.incident_type ? (
              <DetailItem
                icon={IconEnum.IncidentType}
                content={incident.incident_type.name}
              />
            ) : null}
            {[
              IncidentStatusCategoryEnum.Declined,
              IncidentStatusCategoryEnum.Merged,
              IncidentStatusCategoryEnum.Canceled,
            ].includes(incident.incident_status.category)
              ? null
              : duration && (
                  <DetailItem
                    icon={IconEnum.Clock}
                    content={formatDurationInSecondsShort(duration)}
                  />
                )}
            <DetailItem
              icon={IconEnum.Calendar}
              content={
                <LocalDateTime
                  timestamp={incident.reported_at}
                  className="hover:!no-underline"
                >
                  <div>Reported {reportedAgo} ago</div>
                </LocalDateTime>
              }
            />

            <DetailItem
              iconNode={
                <Avatar
                  url={lead?.avatar_url}
                  name={lead?.name}
                  title={lead?.name ?? "Unassigned"}
                  size={IconSize.Small}
                  className="flex mx-1"
                />
              }
              content={
                <Tooltip content={<>{leadAlias}</>}>
                  <div>{lead?.name ?? "Unassigned"}</div>
                </Tooltip>
              }
            />
            {slackTeam && (
              <DetailItem
                iconNode={
                  slackTeam.icon_url ? (
                    <SlackTeamAvatar
                      url={slackTeam.icon_url}
                      name={slackTeam.name}
                      title={slackTeam.name}
                      size={IconSize.Medium}
                      className="flex mx-1 border border-stroke"
                    />
                  ) : (
                    <Icon id={IconEnum.SlackTeam} />
                  )
                }
                content={
                  <Tooltip content={<>Workspace</>}>
                    <div>{slackTeam.name}</div>
                  </Tooltip>
                }
              />
            )}
          </div>
          {fieldsToDisplay && fieldsToDisplay.length > 0 && (
            <div className={styles.customDisplayedInfo}>
              {fieldsToDisplay.map((field) => {
                switch (field.type) {
                  case "role":
                    return (
                      <IncidentRoleDetailItem
                        key={field.id}
                        fieldInfo={field}
                        roleAssignments={incident.incident_role_assignments}
                      />
                    );
                  case "custom_field":
                    return (
                      <CustomFieldDetailItem
                        key={field.id}
                        fieldInfo={field}
                        fieldEntries={incident.custom_field_entries}
                      />
                    );
                  case "other":
                    switch (field.id) {
                      case "mode":
                        return (
                          <IncidentListDetailItem
                            label="Mode"
                            value={capitalize(incident.mode)}
                          />
                        );
                      case "external_ref":
                        return (
                          <IncidentJiraReferenceDetailItem
                            incident={incident}
                          />
                        );
                    }
                    return <></>;
                  default:
                    return <></>;
                }
              })}
            </div>
          )}
        </div>
        <div className={tcx(styles.chevron, "shrink")}>
          <Icon
            className="text-slate-400 group-hover/incident:text-content-primary transition"
            id={IconEnum.ChevronRight}
            size={IconSize.Large}
          />
        </div>
      </Button>
    </li>
  );
}

function DetailItem({
  icon,
  iconNode,
  content,
}: {
  icon?: IconEnum;
  iconNode?: React.ReactElement;
  content: React.ReactElement | string;
}): React.ReactElement {
  return (
    <Badge
      theme={BadgeTheme.Naked}
      icon={icon}
      className="font-normal"
      size={BadgeSize.Medium}
    >
      {iconNode}
      {content}
    </Badge>
  );
}

const IncidentJiraReferenceDetailItem = ({
  incident,
}: {
  incident: Incident;
}) => {
  const getExternalIssueDetails = (incident: Incident) => {
    if (incident.external_issue_reference) {
      const { provider, issue_name } = incident.external_issue_reference;

      return { label: IntegrationConfigFor(provider).label, value: issue_name };
    }

    return { label: "Ticket", value: "Not set" };
  };

  const { label, value } = getExternalIssueDetails(incident);
  return <IncidentListDetailItem label={label} value={value} />;
};

const IncidentRoleDetailItem = ({
  fieldInfo,
  roleAssignments,
}: {
  fieldInfo: FieldInfo;
  roleAssignments: IncidentRoleAssignment[];
}) => {
  if (fieldInfo.type !== "role") {
    return null;
  }
  const roleAssignment = roleAssignments.find(
    (assignment) => assignment.role.id === fieldInfo.id,
  );

  if (!roleAssignment) {
    return null;
  }

  return (
    <IncidentListDetailItem
      label={roleAssignment.role.name}
      value={roleAssignment.assignee?.name ?? "Unassigned"}
    />
  );
};

const CustomFieldDetailItem = ({
  fieldInfo,
  fieldEntries,
}: {
  fieldInfo: FieldInfo;
  fieldEntries: CustomFieldEntry[];
}): React.ReactElement => {
  if (fieldInfo.type !== "custom_field") {
    return <></>;
  }
  const entry = fieldEntries.find(
    (entry) => entry.custom_field.id === fieldInfo.id,
  );

  if (!entry) {
    return <></>;
  }

  const key = entry.custom_field.name;

  const getValue = (
    customField: CustomFieldTypeInfo,
    values: CustomFieldValue[],
  ): React.ReactNode => {
    if (values.length === 0) {
      return <>Not set</>;
    }
    switch (customField.field_type) {
      case CustomFieldTypeInfoFieldTypeEnum.SingleSelect:
      case CustomFieldTypeInfoFieldTypeEnum.MultiSelect: {
        if (customField.catalog_info) {
          return (
            <NonPrimitiveEntryList
              collapseList
              outerClassName="inline-flex"
              innerClassName="truncate"
              key={customField.id}
              arrayValue={values.map(({ value_catalog_entry }, idx) => {
                return {
                  catalog_entry: {
                    catalog_entry_id: value_catalog_entry?.id as string,
                    catalog_type_id:
                      value_catalog_entry?.catalog_type_id as string,
                    catalog_entry_name: value_catalog_entry?.name as string,
                  },
                  label: value_catalog_entry?.name as string,
                  sort_key: String(idx),
                };
              })}
              catalogResource={{
                icon: customField.catalog_info
                  .CatalogTypeIcon as unknown as CatalogResourceIconEnum,
                color: customField.catalog_info
                  .CatalogTypeColor as unknown as CatalogResourceColorEnum,
              }}
            />
          );
        }

        return (
          <>
            {values.map(({ value_option }) => value_option?.value).join(", ")}
          </>
        );
      }

      case CustomFieldTypeInfoFieldTypeEnum.Text: {
        return (
          <>
            {values.map(({ value_text }, i) => (
              <span key={`${customField.id}-${i}`} className="max-w-full">
                {value_text}
              </span>
            ))}
          </>
        );
      }

      case CustomFieldTypeInfoFieldTypeEnum.Link: {
        return (
          <>
            {values.flatMap(({ value_link }) => {
              if (value_link) {
                return <span>{value_link.replace(/http[s]?:\/\//gi, "")}</span>;
              }
              return undefined;
            })}
          </>
        );
      }

      case CustomFieldTypeInfoFieldTypeEnum.Numeric: {
        return <>{values.map(({ value_numeric }) => value_numeric)}</>;
      }

      default:
        return <></>;
    }
  };
  return (
    <IncidentListDetailItem
      label={key}
      value={getValue(entry.custom_field, entry.values)}
    />
  );
};

const IncidentListDetailItem = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => {
  return (
    <div className="font-regular flex items-center space-x-2">
      <span className="text-slate-600">{label}</span>{" "}
      <span className="text-content-primary">{value}</span>
    </div>
  );
};
