import {
  TimelineItemComment as TimelineItemCommentAPI,
  User,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import {
  Avatar,
  Button,
  ButtonSize,
  ButtonTheme,
  IconEnum,
  IconSize,
  LocalDateTime,
  Textarea,
} from "@incident-ui";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

type TimelineItemCommentBoxProps = {
  incidentId: string;
  timelineItemId: string;
  comments: TimelineItemCommentAPI[];
};

export const TimelineItemCommentBox = ({
  incidentId,
  timelineItemId,
  comments,
}: TimelineItemCommentBoxProps) => {
  const hasComments = comments.length > 0;
  return (
    <div className="bg-surface-secondary rounded-2">
      {hasComments && (
        <div className="p-4 border-b flex flex-col gap-4">
          {comments.map((comment) => (
            <TimelineItemComment
              key={comment.id}
              id={comment.id}
              incidentId={incidentId}
              comment={comment.text}
              user={comment.user}
              timestamp={comment.created_at}
            />
          ))}
        </div>
      )}
      <div className="p-4">
        <TimelineItemCommentEditor
          incidentId={incidentId}
          timelineItemId={timelineItemId}
        />
      </div>
    </div>
  );
};

type TimelineItemCommentProps = {
  id: string;
  incidentId: string;
  comment: string;
  user: User;
  timestamp: Date;
};

const TimelineItemComment = ({
  id,
  incidentId,
  comment,
  user,
  timestamp,
}: TimelineItemCommentProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  // Use a state variable here so we can optimisticalyl set this
  // when a comment is edited
  const [commentText, setCommentText] = useState(comment);
  const [optimisticallyDeleted, setOptimisticallyDeleted] = useState(false);
  const [editing, setEditing] = useState(false);

  const { identity } = useIdentity();

  const { trigger: editComment } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    {
      incidentId,
    },
    async (client, data: { text: string }) => {
      await client.timelineItemCommentsUpdate({
        id: id,
        updateRequestBody: {
          text: data.text,
        },
      });
    },
    {
      onSuccess: () => {
        setEditing(false);
      },
      onError: () => {
        setCommentText(comment);
      },
    },
  );

  const { trigger: deleteComment } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    {
      incidentId,
    },
    async (client) => {
      await client.timelineItemCommentsDestroy({
        id,
      });
    },
    {
      onError: () => {
        setOptimisticallyDeleted(false);
      },
    },
  );

  // we need this in a use effect as otherwise the ref will be null
  // as the textarea hasn't been rendered yet
  useEffect(() => {
    if (editing && textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.selectionStart = textAreaRef.current?.value.length;
    }
  }, [editing]);

  const onEdit = () => {
    setEditing(true);
  };

  const onSubmitEdit = () => {
    setEditing(false);
    editComment({ text: commentText });
  };

  const onDelete = async () => {
    setOptimisticallyDeleted(true);
    await deleteComment({});
  };

  const isAuthor = identity?.user_id === user.id;
  const commentIsEmpty = commentText.trim().length === 0;

  if (optimisticallyDeleted) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Avatar
          size={IconSize.Medium}
          url={user.avatar_url}
          title={user.name}
        />
        <div className="text-sm-bold">{user.name}</div>
        <LocalDateTime
          timestamp={timestamp}
          format="HH:mm"
          className="text-xs-med text-content-tertiary"
        />
        {isAuthor && (
          <div className="ml-auto">
            {!editing && (
              <>
                <Button
                  title="Edit comment"
                  analyticsTrackingId={null}
                  icon={IconEnum.Edit}
                  theme={ButtonTheme.Naked}
                  onClick={onEdit}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                  className={tcx(`mt-auto transition-colors`)}
                />
                <Button
                  title="Delete comment"
                  analyticsTrackingId={null}
                  icon={IconEnum.Delete}
                  theme={ButtonTheme.Naked}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                  onClick={() => onDelete()}
                  className={tcx(`mt-auto transition-colors`)}
                />
              </>
            )}
          </div>
        )}
      </div>
      {!editing && <div>{commentText}</div>}
      {editing && (
        <Textarea
          ref={textAreaRef}
          id="comment-box"
          className="bg-transparent border-none p-0 flex-grow resize-none !min-h-0 rounded-none focus:ring-0"
          rows={1}
          placeholder="Leave a comment"
          value={commentText}
          onChange={(e) => {
            // We have to specificy a manual onChange handler to update the form value
            // so that the textarea height can be adjusted to match the content
            //
            // Not nice, but the UX is slick.
            const target = e.target as HTMLTextAreaElement;

            setCommentText(target.value);

            // update the height to match the content
            target.style.height = `inherit`;
            target.style.height = `${target.scrollHeight}px`;
          }}
          onKeyDown={(e) => {
            // submit on Enter, but not Shift+Enter
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              const target = e.target as HTMLTextAreaElement;
              if (target.value.length > 0) {
                onSubmitEdit();
              }
            }
          }}
        />
      )}
      {editing && (
        <div className="flex justify-end gap-2">
          <Button
            title="Cancel"
            analyticsTrackingId={null}
            size={ButtonSize.Small}
            theme={ButtonTheme.Tertiary}
            onClick={() => {
              setEditing(false);
              setCommentText(comment);
            }}
          >
            Cancel
          </Button>
          <Button
            title="Save"
            onClick={onSubmitEdit}
            analyticsTrackingId={null}
            size={ButtonSize.Small}
            disabled={commentIsEmpty}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

type TimelineCommentForm = {
  comment: string;
};

type TimelineItemCommentEditorProps = {
  incidentId: string;
  timelineItemId: string;
};

const TimelineItemCommentEditor = ({
  incidentId,
  timelineItemId,
}: TimelineItemCommentEditorProps) => {
  const { identity } = useIdentity();
  const formMethods = useForm<TimelineCommentForm>({
    defaultValues: {
      comment: "",
    },
  });

  const comment = formMethods.watch("comment");

  const { trigger: createComment, isMutating } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    { incidentId },
    async (client, data: TimelineCommentForm) => {
      await client.timelineItemCommentsCreate({
        createRequestBody: {
          incident_id: incidentId,
          text: data.comment,
          incident_timeline_item_id: timelineItemId,
        },
      });
    },
    {
      onSuccess: () => {
        formMethods.reset();
      },
    },
  );

  const onSubmit = async (data: TimelineCommentForm) => {
    if (data.comment.trim().length === 0) {
      return;
    }

    await createComment(data);
  };

  const commentIsEmpty = comment.trim().length === 0;

  return (
    <Form.Root formMethods={formMethods} onSubmit={onSubmit}>
      <div className="flex gap-2">
        <Avatar
          size={IconSize.Medium}
          url={identity?.user_avatar_url}
          title={identity?.user_name}
        />
        <Textarea
          id="comment-box"
          data-timeline-item-id={timelineItemId}
          disabled={isMutating}
          className="bg-transparent border-none p-0 flex-grow resize-none !min-h-0 rounded-none focus:ring-0"
          rows={1}
          placeholder="Leave a comment"
          {...formMethods.register("comment")}
          onChange={(e) => {
            // We have to specificy a manual onChange handler to update the form value
            // so that the textarea height can be adjusted to match the content
            //
            // Not nice, but the UX is slick.
            const target = e.target as HTMLTextAreaElement;

            formMethods.setValue("comment", target.value);

            // update the height to match the content
            target.style.height = `inherit`;
            target.style.height = `${target.scrollHeight}px`;
          }}
          onKeyDown={(e) => {
            // submit on Enter, but not Shift+Enter
            if (e.key === "Enter" && e.shiftKey === false) {
              e.preventDefault();
              const target = e.target as HTMLTextAreaElement;
              if (target.value.length > 0) {
                formMethods.handleSubmit(onSubmit)();
              }
            }
          }}
        />
        <Button
          title="Add comment"
          analyticsTrackingId={null}
          icon={IconEnum.ArrowCircleUp}
          type="submit"
          theme={ButtonTheme.Naked}
          iconProps={{
            size: IconSize.Medium,
          }}
          disabled={commentIsEmpty || isMutating}
          className={tcx(
            `mt-auto transition-colors`,
            commentIsEmpty
              ? "text-content-tertiary pointer-events-none"
              : "text-content-secondary",
          )}
        />
      </div>
    </Form.Root>
  );
};
