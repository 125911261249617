import {
  Schedule,
  ScheduleEntry,
  ScheduleExternalProviderEnum,
  ScopeNameEnum,
  User,
  UsersShowResponseBody,
} from "@incident-io/api";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Avatar,
  ButtonTheme,
  DeprecatedTable,
  DeprecatedTableHeaderCell,
  DeprecatedTableHeaderRow,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  Link,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import { useSearchContext } from "@incident-ui/SearchBar/SearchBar";
import { Searcher } from "fast-fuzzy";
import _ from "lodash";
import { DateTime } from "luxon";
import { useState } from "react";
import { getEscalationPathLevelNodes } from "src/components/escalation-paths/common/helpers";
import { useAPI } from "src/utils/swr";
import useSWRImmutable from "swr/immutable";

import { useClient } from "../../../../contexts/ClientContext";
import { useIdentity } from "../../../../contexts/IdentityContext";
import { CopyDebugID } from "../../../../utils/ShowDebugIDProvider";
import { useOnCallEntityCount } from "../utils";
import {
  ConfirmDeleteScheduleModal,
  ScheduleEscalationPathBadge,
} from "./SchedulesViewPage";

type ScheduleContent = "ALL_SCHEDULES" | "MY_SCHEDULES";

const ScheduleList = ({
  schedules,
  users,
  onEdit,
  onDuplicate,
  context,
}: {
  schedules: Schedule[];
  users: User[];
  onEdit: (id: string) => void;
  onDuplicate: (id: string) => void;
  context: ScheduleContent;
}) => {
  return (
    <div>
      <Txt bold>
        {context === "ALL_SCHEDULES" ? "All schedules" : "My schedules"}
      </Txt>
      <Txt grey className="mb-4">
        {context === "ALL_SCHEDULES"
          ? "All schedules for your organisation."
          : "Schedules you are a part of."}
      </Txt>
      <DeprecatedTable className="w-fit min-w-full">
        {/* Header */}
        <DeprecatedTableHeaderRow>
          <DeprecatedTableHeaderCell className="w-1/5 min-w-1/5">
            <span>Name</span>
          </DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell className="w-1/4">
            <span>{`Who's on call?`}</span>
          </DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell className="w-1/4">
            <span>On call next</span>
          </DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell className="w-1/4">
            <span>Escalation paths</span>
          </DeprecatedTableHeaderCell>
          <DeprecatedTableHeaderCell className="w-[10px]">
            {/* Dropdown */}
            <span />
          </DeprecatedTableHeaderCell>
        </DeprecatedTableHeaderRow>

        {/* Body */}
        <tbody>
          {schedules.map((schedule) => {
            return (
              <SchedulesListItem
                key={`schedule-entry-${schedule.id}`}
                schedule={schedule}
                users={users || []}
                onEdit={onEdit}
                onDuplicate={onDuplicate}
              />
            );
          })}
        </tbody>
      </DeprecatedTable>
    </div>
  );
};

export const SchedulesList = ({
  onEdit,
  onDuplicate,
}: {
  onEdit: (id: string) => void;
  onDuplicate: (id: string) => void;
}) => {
  const { identity } = useIdentity();
  const {
    data: schedulesResp,
    isLoading: schedulesIsLoading,
    error: schedulesError,
  } = useAPI("schedulesList", undefined);

  const {
    data: entityCount,
    isLoading: onCallEntityLoading,
    error: onCallEntityError,
  } = useOnCallEntityCount();

  const nativeSchedules = (schedulesResp?.schedules ?? []).filter(
    (s) => s.external_provider === ScheduleExternalProviderEnum.Native,
  );

  const { value: searchTerm } = useSearchContext();

  // build a structure of {"scheduleId": ["userId1", "userId2"]}
  const currentShiftUserIDs = _.chain(nativeSchedules)
    .map((schedule) => ({
      scheduleId: schedule.id,
      userIds: schedule.current_shifts?.map((shift) => shift.external_user_id),
    }))
    .value();

  const searcher = new Searcher(nativeSchedules, {
    keySelector: (schedule) => [
      schedule.name,
      schedulesResp?.users
        .filter(
          (user) =>
            currentShiftUserIDs
              .find((x) => x.scheduleId === schedule.id)
              ?.userIds?.includes(user.id),
        )
        .map((user) => user.name)
        .join(", ") || "",
    ],
  });

  let filteredSchedules = nativeSchedules;
  if (searchTerm) {
    filteredSchedules = searcher.search(searchTerm);
  }

  if (schedulesIsLoading || onCallEntityLoading) {
    return <Loader />;
  }

  if (schedulesError || onCallEntityError) {
    return <GenericErrorMessage error={schedulesError} />;
  }

  if (entityCount === 0) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  const userIsOnSchedule = (schedule: Schedule) => {
    let userIsOnSchedule = false;
    schedule.config?.rotations.forEach((rotation) => {
      if (rotation.user_ids.includes(identity.user_id || "")) {
        userIsOnSchedule = true;
      }
    });
    return userIsOnSchedule;
  };

  const userOnSchedules = _.filter(filteredSchedules, userIsOnSchedule);

  return (
    <div className="space-y-16 mt-4">
      {userOnSchedules.length > 0 && !searchTerm ? (
        <ScheduleList
          schedules={userOnSchedules}
          users={schedulesResp?.users || []}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          context={"MY_SCHEDULES"}
        />
      ) : null}
      {filteredSchedules.length > 0 && (
        <ScheduleList
          schedules={filteredSchedules}
          users={schedulesResp?.users || []}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          context={"ALL_SCHEDULES"}
        />
      )}
      {searchTerm && filteredSchedules.length === 0 && (
        <tr>
          <td colSpan={5}>
            <div className="flex items-center justify-center h-[32px]">
              <span>No results found</span>
            </div>
          </td>
        </tr>
      )}
    </div>
  );
};

const UserDisplayPictureGroup = ({ users }: { users: User[] }) => {
  // if there's no users, display nothing
  if (users.length === 0) {
    return null;
  }

  // if there's only one user, display their picture
  if (users.length === 1) {
    return (
      <Avatar
        url={users[0].avatar_url}
        name={users[0].name}
        size={IconSize.Large}
        className="w-8 h-8"
        title={users[0].name}
      />
    );
  }
  // if there are more than one user, display on the top left and bottom right
  return (
    <div className="relative w-8 h-8">
      <Avatar
        url={users[0].avatar_url}
        name={users[0].name}
        size={IconSize.Medium}
        title={users[0].name}
        className="absolute top-0 overflow-hidden z-10"
      />
      {/* make the second one have an inherited border */}
      {users.length === 2 ? (
        <Avatar
          url={users[1].avatar_url}
          name={users[1].name}
          size={IconSize.Medium}
          title={users[1].name}
          className="absolute bottom-0 right-0 overflow-hidden z-10 outline outline-2 outline-white group-hover:outline-slate-100 z-20"
        />
      ) : (
        <Tooltip side="top" content={users.map((u) => u.name).join(", ")}>
          <div className="absolute bottom-0 right-0 w-5 h-5 rounded-full overflow-hidden outline outline-2 outline-white group-hover:outline-slate-100 z-20 bg-surface-secondary flex items-center justify-center">
            <Txt>+{users.length - 1}</Txt>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

const RichOnCallPreview = ({
  users,
  shifts,
  timezone,
  context,
}: {
  users: User[];
  shifts: ScheduleEntry[] | undefined;
  timezone: string;
  context: "current" | "next";
}) => {
  const { identity } = useIdentity();

  const shiftsByUser = _.chain(shifts)
    .filter((shift) => shift.external_user_id !== "NOBODY")
    .groupBy("external_user_id")
    .map((shifts, userId) => ({
      user: users.find((u) => u.id === userId),
      shifts,
    }))
    .value();

  const nextShiftSwapDate =
    context === "next"
      ? shifts?.sort((a, b) => a.start_at.getTime() - b.start_at.getTime())?.[0]
          ?.start_at
      : shifts?.sort((a, b) => a.end_at.getTime() - b.end_at.getTime())?.[0]
          ?.end_at;

  const userTimezone = DateTime.local().zoneName;

  return (
    <div className="flex items-center gap-2">
      {/* Display pictures */}
      <UserDisplayPictureGroup
        users={
          shiftsByUser.map((user) => user.user).filter((user) => user) as User[]
        }
      />
      <div className="flex flex-wrap w-fit flex-col">
        <Txt>
          {shiftsByUser
            .filter(({ user }) => user && user.id !== "NOBODY")
            .map(({ user }) => {
              if (!user) {
                return null;
              }
              if (user.id === identity?.user_id) {
                return `${user.name} (you)`;
              }
              return user.name;
            })
            .reduce((acc, curr) => {
              if (acc === "") {
                return curr;
              }
              return `${acc}, ${curr}`;
            }, "")}
        </Txt>
        {nextShiftSwapDate &&
        shiftsByUser.filter((user) => user.user).length > 0 ? (
          <Txt xs grey>
            {context === "current" ? "Until" : "On"}{" "}
            {DateTime.fromJSDate(nextShiftSwapDate)
              .setZone(timezone)
              .toLocaleString({
                weekday: "short",
                day: "numeric",
                month: "short",
                hour: "numeric",
                hourCycle: "h12",
                minute: "numeric",
                timeZoneName: userTimezone !== timezone ? "short" : undefined,
              })}
          </Txt>
        ) : (
          <Txt className="text-slate-300">{"\u2014"}</Txt>
        )}
      </div>
    </div>
  );
};

const SchedulesListItem = ({
  schedule,
  users,
  onEdit,
  onDuplicate,
}: {
  schedule: Schedule;
  users: User[];
  onEdit: (id: string) => void;
  onDuplicate: (id: string) => void;
}) => {
  const navigate = useOrgAwareNavigate();
  const { hasScope } = useIdentity();
  const apiClient = useClient();

  const [canEditSchedules, canDestroySchedules] = [
    hasScope(ScopeNameEnum.SchedulesUpdate),
    hasScope(ScopeNameEnum.SchedulesDestroy),
  ];

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data: escalationPathData, isLoading: escalationPathLoading } = useAPI(
    "escalationPathsList",
    undefined,
  );

  const epTargets = escalationPathData?.escalation_paths?.flatMap((ep) =>
    getEscalationPathLevelNodes(ep.path).flatMap((level) =>
      level.targets
        .filter((t) => t.type === "schedule")
        .map((t) => ({ epId: ep.id, scheduleTargetId: t.id })),
    ),
  );

  const epTargetsForSchedule = epTargets?.filter(
    (t) => t.scheduleTargetId === schedule.id,
  );

  const nextUpUsers = schedule?.next_shifts
    ?.map((shift) => shift.external_user_id)
    .filter((u) => u !== "NOBODY");

  const { data: nextUserOnCallData, isLoading: nextUserOnCallLoading } =
    useSWRImmutable(
      `next-users-${schedule.id}`,
      async (): Promise<UsersShowResponseBody[]> => {
        // TODO swap these user look ups for a single call
        // https://linear.app/incident-io/issue/ONC-3143/add-a-users-fetch-by-id-endpoint
        const userResponses = await Promise.all(
          (nextUpUsers ?? []).map((u) => apiClient.usersShow({ id: u })),
        );

        return userResponses || [];
      },
    );

  return (
    <>
      <tr
        className="hover:bg-surface-secondary cursor-pointer group"
        onClick={() => navigate(schedule.id)}
      >
        {/* Name */}
        <td>
          <div className="flex items-center gap-2">
            <Link
              analyticsTrackingId={"schedules-list-view"}
              to={schedule.id}
              noHoverColor
              noUnderline
            >
              <div className="text-sm font-semibold truncate max-w-[30ch]">
                {schedule.name}
              </div>
              <CopyDebugID id={schedule.id} />
            </Link>
            <ManagementMetaBadge
              management={schedule.management_meta}
              resourceName="schedule"
            />
          </div>
        </td>

        {/* Who's on call? */}
        <td>
          <div className="flex flex-wrap gap-2 w-fit">
            <RichOnCallPreview
              users={users}
              shifts={schedule.current_shifts}
              timezone={schedule.timezone}
              context={"current"}
            />
          </div>
        </td>

        {/* Next changeover */}
        <td>
          {nextUserOnCallLoading ? (
            <Txt className="text-slate-300">{"\u2014"}</Txt>
          ) : null}
          {nextUserOnCallData ? (
            <RichOnCallPreview
              users={nextUserOnCallData.map((u) => u.user as unknown as User)}
              shifts={schedule?.next_shifts}
              timezone={schedule.timezone}
              context={"next"}
            />
          ) : (
            <span className="text-slate-300">{"\u2014"}</span>
          )}
        </td>

        {/* Escalation Paths */}
        <td className="relative">
          {escalationPathLoading ? <Loader large={false} /> : null}
          {escalationPathData ? (
            <div className="flex flex-wrap gap-2 w-fit">
              <ScheduleEscalationPathBadge
                escalationPathsForSchedule={escalationPathData.escalation_paths.filter(
                  (ep) =>
                    epTargetsForSchedule?.map((t) => t.epId).includes(ep.id),
                )}
                scheduleID={schedule.id}
              />
            </div>
          ) : (
            <span className="text-slate-300">{"\u2014"}</span>
          )}
        </td>

        {/* Dropdown */}
        <td onClick={(e) => e.stopPropagation()}>
          <DropdownMenu
            analyticsTrackingId={"schedules.dropdown-click"}
            menuClassName={"w-[120px] !py-1 mr-5"}
            triggerButtonTheme={ButtonTheme.Unstyled}
            triggerIcon={IconEnum.DotsVertical}
            screenReaderText="Schedule management options"
            side={"bottom"}
            scroll={false}
          >
            <DropdownMenuItem
              analyticsTrackingId={"schedules.edit"}
              onSelect={() => onEdit(schedule.id)}
              label="Edit"
              disabled={!canEditSchedules}
              icon={IconEnum.Edit}
              tooltipContent={
                !canEditSchedules &&
                "You do not have permission to edit schedules."
              }
            />
            <DropdownMenuItem
              analyticsTrackingId={"schedules.duplicate"}
              onSelect={() => onDuplicate(schedule.id)}
              label="Duplicate"
              disabled={!canEditSchedules}
              icon={IconEnum.Copy}
              tooltipContent={
                !canEditSchedules &&
                "You do not have permission to edit schedules."
              }
            />
            <DropdownMenuItem
              analyticsTrackingId={"schedules.view"}
              onSelect={() => navigate(`${schedule.id}`)}
              label="View"
              icon={IconEnum.View}
            />
            <DropdownMenuItem
              analyticsTrackingId={"schedules.delete"}
              onSelect={() => setDeleteModalOpen(true)}
              label="Delete"
              disabled={!canDestroySchedules}
              tooltipContent={
                !canDestroySchedules &&
                "You do not have permission to delete schedules."
              }
              icon={IconEnum.Delete}
              destructive
            />
          </DropdownMenu>
        </td>
      </tr>

      {deleteModalOpen && (
        <ConfirmDeleteScheduleModal
          onConfirm={() => navigate("/on-call/schedules")}
          onCancel={() => setDeleteModalOpen(false)}
          schedule={schedule}
        />
      )}
    </>
  );
};
