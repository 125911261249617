import {
  addMonths,
  addSeconds,
  endOfMonth,
  parseISO,
  startOfMonth,
} from "date-fns";

// calculatePreviousPeriod expects start and end dates as ISO-formatted strings.
// It returns the start and end dates of the previous period as ISO-formatted strings.
export const calculatePreviousPeriod = (
  startDateISO: string,
  endDateISO: string,
) => {
  const [startDate, endDate] = [parseISO(startDateISO), parseISO(endDateISO)];

  // The previous period should end one second before the start of the current period,
  // i.e. it should be the end of the day before.
  const prevEndDate = addSeconds(startDate, -1);

  // If the start date and end date describe the beginning and end of some months,
  // the length of the previous period should be the same number of entire months,
  // rather than the same number of days in those months - which might not be the same.
  if (
    startOfMonth(startDate).toDateString() === startDate.toDateString() &&
    endOfMonth(endDate).toDateString() === endDate.toDateString()
  ) {
    const numberOfMonths = endDate.getMonth() - startDate.getMonth() + 1;
    const prevStartDate = addMonths(startDate, -numberOfMonths);

    return {
      prevStartDate: prevStartDate.toISOString(),
      prevEndDate: prevEndDate.toISOString(),
    };
  }

  // However if instead the start/end dates just represent some arbitrary days,
  // we'll just substract that number of days instead.
  const range = new Date(endDate).getTime() - new Date(startDate).getTime();
  const prevStartDate = new Date(prevEndDate.getTime() - range);

  return {
    prevStartDate: prevStartDate.toISOString(),
    prevEndDate: prevEndDate.toISOString(),
  };
};
