import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import graphic from "./introducing-trend-tiles.svg";

export const InsightsTrendsModal = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title={""} // hidden
      hideHeader
      onClose={onClose}
      analyticsTrackingId={null}
      className="!w-[600px]"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <img className="w-full" src={graphic} />
        <div className="flex flex-col items-center p-10 gap-3 text-center">
          <div className="text-2xl-bold">Introducing Trends</div>
          <div className="text-sm-normal flex flex-col gap-2">
            <div>
              Stay ahead of the curve by uncovering patterns in your incident
              data.
            </div>
            <div>
              From tracking response times and paging schedules to follow-up
              completion durations, Trends proactively highlight what&apos;s
              important.
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="w-4"></div>
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Primary}
          loading={loading}
          disabled={loading}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};
