import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { startCase } from "lodash";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import {
  ActionStatusChangeNextStatusEnum,
  ActivityLogEntry,
  Incident,
} from "src/contexts/ClientContext";

import { ActivityBadge } from "./ActivityBadge";
import { iconForActionStatus } from "./ActivityItemActionUpdated";

export const ActivityItemFollowUpUpdated = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.follow_up_updated) {
    throw new Error(
      "malformed timeline item: follow_up_updated was missing follow_up_updated field",
    );
  }

  let previousTitle: string | undefined;
  let nextTitle: string | undefined;
  let previousDescription: string | undefined;
  let nextDescription: string | undefined;

  if (item.content.follow_up_updated.title_change) {
    previousTitle = item.content.follow_up_updated.title_change.previous_title;
    nextTitle = item.content.follow_up_updated.title_change.next_title;
  }
  if (item.content.follow_up_updated.description_change) {
    previousDescription =
      item.content.follow_up_updated.description_change.previous_description ||
      "Empty description";
    nextDescription =
      item.content.follow_up_updated.description_change.next_description ||
      "Empty description";
  }

  const quotedContent = (
    <>
      {nextTitle && <Markdown className="font-semibold">{nextTitle}</Markdown>}
      {nextDescription && <Markdown>{nextDescription}</Markdown>}
      {previousTitle && (
        <Markdown className="font-semibold line-through">
          {previousTitle}
        </Markdown>
      )}
      {previousDescription && (
        <Markdown className="line-through">{previousDescription}</Markdown>
      )}
    </>
  );

  return {
    title: "Follow-up updated",
    icon: IconEnum.FollowUps,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.follow_up_updated.updater,
    quotedContent: quotedContent,
    unquotedContent: (
      <div className="flex-center-y gap-2 flex-wrap">
        {item.content.follow_up_updated.status_change && (
          <ActivityBadge
            icon={iconForActionStatus(
              item.content.follow_up_updated.status_change
                .next_status as unknown as ActionStatusChangeNextStatusEnum,
            )}
            value={startCase(
              item.content.follow_up_updated.status_change.next_status,
            )}
            previousValue={startCase(
              item.content.follow_up_updated.status_change.previous_status,
            )}
          />
        )}
        {item.content.follow_up_updated.follow_up_priority_option_change && (
          <ActivityBadge
            icon={IconEnum.Warning}
            value={
              item.content.follow_up_updated.follow_up_priority_option_change
                .next_follow_up_priority_option?.name || "Unset"
            }
            previousValue={
              item.content.follow_up_updated.follow_up_priority_option_change
                .previous_follow_up_priority_option?.name
            }
          />
        )}
        {item.content.follow_up_updated.assignee_change && (
          <ActivityBadge
            icon={IconEnum.User}
            value={
              item.content.follow_up_updated.assignee_change.next_user?.name ||
              "Unassigned"
            }
            previousValue={
              item.content.follow_up_updated.assignee_change.previous_user?.name
            }
          />
        )}
      </div>
    ),
  };
};
