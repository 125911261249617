import { CallParticipant, Incident } from "@incident-io/api";
import { IconEnum } from "@incident-ui";

import { TimelineItemObject } from "../../../contexts/ClientContext";
import { getProviderNameAndLogo } from "../../incident-calls/helpers";
import {
  AvatarList,
  AvatarListClickableType,
  MaybeUser,
} from "../../legacy/incident/sidebar/AvatarList";
import { TimelineItemRenderProps } from "./TimelineItem";
import { TimelineItemContentBox } from "./TimelineItemContentBox";

export const TimelineItemIncidentCallEnded = (
  inc: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_call_ended) {
    throw new Error(
      "malformed timeline item: missing incident_call_ended field",
    );
  }

  const { name, logo } = getProviderNameAndLogo(
    item.incident_call_ended.incident_call.external_provider,
  );

  return {
    icon: IconEnum.Phone,
    avatarUrl: logo,
    description: (
      <>
        <strong>{name} </strong>
        call ended
      </>
    ),
    children: ParticipantsList({
      participants: item.incident_call_ended.call_session?.participants,
    }),
  };
};

const ParticipantsList = ({
  participants,
}: {
  participants?: CallParticipant[];
}) => {
  if (!participants || participants.length === 0) {
    return null;
  }

  return (
    <TimelineItemContentBox>
      <dl className="flex-center-y gap-3">
        <dt className="text-content-secondary">Participants</dt>
        <AvatarList
          users={participants.map((p) => convertToMaybeUser(p))}
          modalTitle={"Call participants"}
          maxToShow={5}
          clickableType={AvatarListClickableType.OnlyOnSeeMore}
        />
      </dl>
    </TimelineItemContentBox>
  );
};

const convertToMaybeUser = (participant: CallParticipant): MaybeUser => {
  const maybeUser = {} as MaybeUser;

  if (participant.user) {
    maybeUser.user = participant.user;
  } else {
    maybeUser.nonUserLabel = participant.email;
  }

  return maybeUser;
};
