import { CatalogSetupWidget } from "@incident-shared/catalog/CatalogSetupWidget";
import { captureException } from "@sentry/react";
import _ from "lodash";
import { useAPI } from "src/utils/swr";

import { useCatalogSetup } from "./useCatalogSetup";

export const AlertsCatalogSetupWidget = ({
  populatedAttributes,
  openAttributesDrawer,
}: {
  populatedAttributes?: string[];
  openAttributesDrawer?: () => void;
}): React.ReactElement => {
  const widgetConfig = useCatalogSetup();

  const {
    data: schemaData,
    isLoading: schemaIsLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", {});

  if (!widgetConfig || !schemaData) {
    return <></>;
  }

  if (schemaError) {
    captureException(schemaError, {
      tags: { component: "AlertsCatalogSetupWidget" },
    });
    return <></>;
  }

  const { catalogConfig, props } = widgetConfig;

  if (props) {
    return <CatalogSetupWidget {...props} />;
  }

  if (!schemaData || schemaIsLoading) {
    return <></>;
  }

  if (catalogConfig.escalation_types.length === 0) {
    return (
      <CatalogSetupWidget
        title="Connect teams to escalation paths"
        description="Your Catalog has teams but they don’t link to escalation paths. We recommend tagging each team with an escalation path so that alert routes know where to send alerts."
        fixButton={{
          href: `/catalog/${catalogConfig.team_types[0].catalog_type_id}/edit`,
          title: "Link escalation paths",
        }}
      />
    );
  }

  if (populatedAttributes) {
    // Check that we have at least one bound attribute which has a type that is in
    // the escalation types.
    const hasBoundEscalationAttribute = _.some(
      schemaData.alert_schema.attributes,
      (attribute) => {
        // If we're not bound, we don't care.
        if (!populatedAttributes.includes(attribute.id)) {
          return false;
        }

        return _.some(catalogConfig.escalation_types, (escalationType) => {
          return attribute.type === escalationType.engine_type_name;
        });
      },
    );
    if (!hasBoundEscalationAttribute) {
      return (
        <CatalogSetupWidget
          title="Tag alerts with their owner"
          description="Alert routes use attributes to know where to send alerts. Currently, none of your attributes can be linked to an escalation path. We recommend tagging alerts with a Team and having alert routes send alerts to that team’s escalation path."
          fixButton={
            openAttributesDrawer
              ? {
                  title: "Review attributes",
                  onClick: () => openAttributesDrawer(),
                }
              : undefined
          }
        />
      );
    }
  }

  return <></>;
};
