import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  PostIncidentTask,
  PostIncidentTaskConfig,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ErrorMessage } from "@incident-ui";
import {
  Button,
  ButtonTheme,
  ContentBox,
  IconEnum,
  IconSize,
  Loader,
  ProgressBar,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import _ from "lodash";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Confetti from "react-confetti";
import { TaskDueDateBadge } from "src/components/post-incident/post-incident-flow/TaskDueDateBadge";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";
import { usePrevious } from "use-hooks";

import { useIncident } from "../hooks";
import { Category, isCategory } from "../statuses/status-utils";
import { useAllStatuses } from "../useIncidentCrudResources";
import { CompletePostIncidentTaskButton } from "./CompletePostIncidentTaskButton";
import { EmptyPostIncidentStatus } from "./EmptyPostIncidentStatus";
import { EmptyPostIncidentStatusDetails } from "./EmptyPostIncidentStatusDetails";
import { PostIncidentTaskActionButton } from "./PostIncidentTaskActionButton";
import { PostIncidentTaskAssignee } from "./PostIncidentTaskAssignee";
import {
  CompletedPostIncidentTaskTag,
  RejectedPostIncidentTaskTag,
} from "./PostIncidentTaskStatusTags";
import { ReadMore } from "./ReadMore";
import { RejectPostIncidentTaskButton } from "./RejectPostIncidentTaskButton";
import { TaskCanBeManuallyCompleted } from "./taskCanBeManuallyCompleted";
import { UnresolvePostIncidentTaskButton } from "./UnresolvePostIncidentTaskButton";
import {
  findById,
  groupTasksByStatuses,
  PostIncidentTabSelection,
  usePostIncidentTabSelection,
} from "./usePostIncidentTaskSelection";
import { taskConfigToSlim, taskToSlim } from "./utils";

// PostIncidentTab shows the tasks that need to be completed after an incident is resolved. We show the
// same tab for post-incident + closed incidents, but in the future we might want to change this so we
// just show a summary of the post-incident for closed incidents (e.g. takeaways from the postmortem).
export const PostIncidentTab = ({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement | null => {
  const { incident, isLoading: incidentLoading } = useIncident(incidentId);

  const {
    data: { incident_tasks: incidentTasks },
    isLoading: incidentTasksLoading,
  } = useAPI(
    incidentId === "" ? null : "postIncidentFlowListTasks",
    { incidentId: incidentId ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  const { data: flowData } = useAPI(
    incident?.post_incident_flow_id ? "postIncidentFlowShow" : null,
    {
      id: incident?.post_incident_flow_id ?? "",
    },
  );

  // Rather than looking at the current lifecycle for the incident here, we want
  // to load the statuses for whatever flow the incident entered when it was
  // resolved, even if that is not the currently-default flow for this incident
  // type.
  const flowStatuses = flowData?.post_incident_flow.incident_statuses ?? [];
  const { allStatuses, allStatusesLoading } = useAllStatuses();

  const statuses = [
    ...flowStatuses,
    ...allStatuses.filter(isCategory(Category.Closed)),
  ];

  if (
    incidentId == null ||
    incident == null ||
    incidentLoading ||
    incidentTasksLoading ||
    allStatusesLoading ||
    !flowData
  ) {
    return <LoadingBar className="w-full h-28 mt-4" />;
  }

  return (
    <PostIncidentTabRender
      incident={incident}
      incidentTasks={incidentTasks}
      statuses={statuses}
      isSavingTask={false}
    />
  );
};

const PostIncidentTabRender = ({
  incident,
  incidentTasks,
  statuses,
  isSavingTask,
}: {
  incident: Incident;
  incidentTasks: PostIncidentTask[];
  statuses: IncidentStatus[];
  isSavingTask: boolean;
}): React.ReactElement | null => {
  const sortedStatuses = useMemo(() => {
    let sorted = groupTasksByStatuses({
      incidentTasks,
      statuses,
    });

    // If the current incident status has been deleted, we want to inject it into the list
    // so that the rest of our UI doesn't get real sad.
    const currentStatus = incident.incident_status;
    if (
      currentStatus &&
      !sorted.some(([status]) => status.id === currentStatus.id)
    ) {
      // Inject it before the first status that has uncompleted tasks.
      const firstStatusWithUncompletedTasks = sorted.find(([, tasks]) =>
        tasks.some((task) => !task.completed_at && !task.rejected_at),
      );
      if (firstStatusWithUncompletedTasks) {
        const index = sorted.indexOf(firstStatusWithUncompletedTasks);
        sorted = [
          ...sorted.slice(0, index),
          [currentStatus, []],
          ...sorted.slice(index),
        ];
      } else {
        // If there are no statuses with uncompleted tasks, just put it at the end.
        if (currentStatus.category !== IncidentStatusCategoryEnum.Closed) {
          sorted = [...sorted, [currentStatus, []]];
        }
      }
    }

    return sorted;
  }, [incidentTasks, statuses, incident.incident_status]);

  const { setSelection, selection } = usePostIncidentTabSelection(
    incident,
    sortedStatuses,
  );

  // We default to a fixed tab height but allow users to expand for a specific
  // task if the text overflows.
  const [expanded, setExpanded] = useState(false);
  useEffect(() => setExpanded(false), [selection]);

  const numCompleted = incidentTasks.filter(
    (task) => task.completed_at || task.rejected_at,
  ).length;
  const numTotal = incidentTasks.length;
  const perc = Math.round((numCompleted / numTotal) * 100);

  const { isConfettiing, setIsConfettiing } = useCompletionConfetti(
    incident,
    incidentTasks,
  );

  const selectedTask =
    selection?.type === "task"
      ? findById(incidentTasks, selection.taskId)
      : undefined;

  if (!selection) {
    return null;
  }

  return (
    <>
      <ContentBox className="text-sm mb-5 mt-2">
        <Confetti
          run={isConfettiing}
          recycle={false}
          numberOfPieces={250}
          onConfettiComplete={() => setIsConfettiing(false)}
        />
        {numTotal > 0 && (
          <div
            className={
              "flex items-center p-4 space-x-4 rounded-t-lg border-stroke border-b"
            }
          >
            <>
              <div className="flex grow items-center space-x-2">
                <span className="text-sm text-slate-600">{perc}% complete</span>
                <ProgressBar numCompleted={numCompleted} numTotal={numTotal} />
              </div>
            </>
          </div>
        )}

        <div className={`flex ${expanded ? "min-h-[600px]" : "h-[500px]"}`}>
          <div
            className={tcx(
              "border-r border-md border-stroke w-[230px] flex flex-col space-y-1 flex-none py-4 bg-surface-secondary overflow-y-auto rounded-bl-lg",
            )}
          >
            {sortedStatuses.map((entry) => {
              const [status, tasksForStatus] = entry;

              if (tasksForStatus.length === 0) {
                return (
                  <EmptyPostIncidentStatus
                    key={status.id}
                    status={status}
                    setSelection={setSelection}
                    selection={selection}
                    incidentId={incident.id}
                  />
                );
              }

              return (
                <PostIncidentStatusAndTasks
                  key={status.id}
                  status={status}
                  incidentTasks={tasksForStatus}
                  setSelection={setSelection}
                  selection={selection}
                />
              );
            })}
          </div>
          {isSavingTask ? (
            <Loader />
          ) : selection.type === "placeholder" ? (
            <EmptyPostIncidentStatusDetails
              statusId={selection.statusId}
              incidentId={incident.id}
              statuses={statuses}
            />
          ) : (
            selectedTask && (
              <PostIncidentTaskDetails
                incidentId={incident.id}
                incidentTask={selectedTask}
                expanded={expanded}
                onChangeExpanded={setExpanded}
                statuses={statuses}
              />
            )
          )}
        </div>
      </ContentBox>
    </>
  );
};

const PostIncidentStatusAndTasks = ({
  status,
  incidentTasks,
  setSelection,
  selection,
}: {
  status: IncidentStatus;
  incidentTasks: PostIncidentTask[];
  selection: PostIncidentTabSelection;
  setSelection: (selection: PostIncidentTabSelection) => void;
}): React.ReactElement => {
  const sortedIncidentTasks = _.sortBy(
    incidentTasks,
    (task) => task.config.rank,
  );

  return (
    <div>
      <div className="pl-4 py-2 text-slate-600 tracking-widest text-xs font-medium">
        {status.name.toUpperCase()}
      </div>
      {sortedIncidentTasks.map((incidentTask) => (
        <PostIncidentTaskMenuItem
          key={incidentTask.id}
          incidentTask={incidentTask}
          task={incidentTask.config}
          isSelected={
            selection.type === "task" && selection.taskId === incidentTask.id
          }
          onSelect={() =>
            setSelection({
              taskId: incidentTask.id,
              type: "task",
              statusId: status.id,
            })
          }
        />
      ))}
    </div>
  );
};

const PostIncidentTaskMenuItem = ({
  incidentTask,
  task,
  isSelected,
  onSelect,
}: {
  incidentTask: PostIncidentTask;
  task: PostIncidentTaskConfig | undefined;
  isSelected: boolean;
  onSelect: () => void;
}): React.ReactElement => {
  if (!task) {
    throw new Error(
      "task not found for building menu item with incidentTask id " +
        incidentTask.id,
    );
  }
  const iconProps = getIconProps(incidentTask);

  return (
    <Button
      onClick={onSelect}
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={"post-incident-tasks-select-task"}
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      className={tcx(
        "w-full rounded-l p-2 pl-4 text-md text-left hover:bg-white hover:!no-underline",
        {
          "font-semibold bg-white": isSelected,
        },
      )}
      icon={getIconId(incidentTask)}
      iconProps={{
        ...iconProps,
        size: IconSize.Medium,
      }}
    >
      <span className={"text-content-primary"}>{task.title}</span>
    </Button>
  );
};

const PostIncidentTaskDetails = ({
  incidentId,
  incidentTask,
  expanded,
  onChangeExpanded,
  statuses,
}: {
  incidentId: string;
  incidentTask: PostIncidentTask;
  expanded: boolean;
  onChangeExpanded: (expanded: boolean) => void;
  statuses: IncidentStatus[];
}): React.ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [showReadMore, setShowReadMore] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const textIsOverflowing =
      !!textRef &&
      !!textRef.current &&
      textRef.current.scrollHeight > textRef.current.clientHeight;
    setShowReadMore(textIsOverflowing || expanded);
  }, [textRef, incidentTask, expanded]);

  const isPending = !incidentTask.completed_at && !incidentTask.rejected_at;
  const isSkippable = !incidentTask.config.unskippable;

  return (
    <div className="flex flex-row w-full overflow-hidden">
      <div className="flex flex-col w-full p-6 space-y-4">
        <div className="flex justify-between w-full">
          <div className="flex flex-wrap space-y-1">
            <h4 className="items-center font-semibold text-lg mr-2 flex gap-2">
              {incidentTask.config.title}
              <TaskDueDateBadge
                task={taskToSlim(incidentTask)}
                statuses={statuses}
              />
            </h4>
          </div>
          {isPending && isSkippable && (
            <RejectPostIncidentTaskButton
              incidentTask={incidentTask}
              onSuccess={() => {
                setError(null);
              }}
              onError={setError}
            />
          )}
        </div>

        {incidentTask.config.description && (
          <>
            <TemplatedTextDisplay
              ref={textRef}
              style={TemplatedTextDisplayStyle.Compact}
              value={incidentTask.config.description}
              className="mt-1 text-sm text-slate-700 overflow-hidden"
            />
            {showReadMore && (
              <ReadMore
                shownText=""
                hidden=""
                onExpandClick={() => onChangeExpanded(true)}
                onHideClick={() => onChangeExpanded(false)}
              />
            )}
          </>
        )}
        <div className="flex items-start justify-between">
          <div className={"flex gap-2 items-center flex-wrap"}>
            <PostIncidentTaskActions
              incidentId={incidentId}
              incidentTask={incidentTask}
              task={incidentTask.config}
              setError={setError}
            />
          </div>
          <PostIncidentTaskAssignee
            incidentId={incidentId}
            incidentTask={taskToSlim(incidentTask)}
          />
        </div>
        <ErrorMessage message={error} />
      </div>
    </div>
  );
};

const PostIncidentTaskActions = ({
  incidentId,
  incidentTask,
  task,
  setError,
}: {
  incidentId: string;
  incidentTask: PostIncidentTask;
  task: PostIncidentTaskConfig;
  setError: (error: string | null) => void;
}): React.ReactElement => {
  const isCompleted = !!incidentTask.completed_at;
  const isRejected = !!incidentTask.rejected_at;

  // We use the incidentsList API endpoint to fetch
  // all tasks under incidents, so we want to bust
  // the cache here so when you get to those pages,
  // you get a fresh view.
  const revalidate = useRevalidate([
    "postIncidentFlowListTasks",
    "incidentsList",
  ]);

  if (isCompleted) {
    return (
      <div className="flex flex-col gap-1">
        <div className="flex flex-row flex-wrap gap-2">
          <CompletedPostIncidentTaskTag />
          {TaskCanBeManuallyCompleted(task) && (
            <UnresolvePostIncidentTaskButton
              incidentTask={incidentTask}
              onSuccess={() => {
                setError(null);
              }}
              onError={setError}
              text="Mark as incomplete"
            />
          )}
        </div>
        {incidentTask.completer?.user?.name && (
          <p className="text-xs text-slate-600">
            Completed by {incidentTask.completer?.user?.name}
          </p>
        )}
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="flex flex-col space-y-1">
        <div className="flex flex-row space-x-2">
          <RejectedPostIncidentTaskTag />
          <UnresolvePostIncidentTaskButton
            incidentTask={incidentTask}
            onSuccess={() => {
              setError(null);
            }}
            onError={setError}
            text="Undo skip"
          />
        </div>
        {incidentTask.rejecter?.user?.name && (
          <p className="text-xs text-slate-600">
            Skipped by {incidentTask.rejecter?.user?.name}
          </p>
        )}
      </div>
    );
  }

  // Task is pending
  return (
    <>
      {TaskCanBeManuallyCompleted(task) && (
        <CompletePostIncidentTaskButton
          incidentTask={incidentTask}
          onTaskComplete={() => {
            revalidate();
            setError(null);
          }}
          onError={setError}
        />
      )}
      <PostIncidentTaskActionButton
        incidentId={incidentId}
        task={taskConfigToSlim(task)}
        incidentTask={taskToSlim(incidentTask)}
        onTaskComplete={() => {
          revalidate();
          setError(null);
        }}
        onError={setError}
        onClick={() => undefined}
      />
    </>
  );
};

function getIconId(task: PostIncidentTask) {
  if (task.completed_at) {
    return IconEnum.Checkmark;
  } else if (task.rejected_at) {
    return IconEnum.Close;
  } else if (task.due_at && new Date(task.due_at) < new Date()) {
    return IconEnum.Clock;
  } else {
    return IconEnum.DottedCircle;
  }
}

function getIconProps(task: PostIncidentTask) {
  // Matching the styles within "../../follow-ups/FollowUps.module.scss"
  let iconProps = {};
  if (task.completed_at) {
    iconProps = {
      className: "!text-green-content flex-none mr-1.5",
      round: true,
    };
  } else if (task.rejected_at) {
    iconProps = {
      // the cross icon is slightly larger than the tick one - sad. Add a padding to make them look the same size
      className: "text-slate-700 flex-none mr-1.5",
      round: true,
    };
  } else if (task.due_at && new Date(task.due_at) < new Date()) {
    iconProps = {
      className: "!text-orange-600 flex-none mr-1.5",
      round: true,
    };
  } else {
    iconProps = {
      className: "flex-none !w-5 !h-4 mr-1.5",
      round: true,
    };
  }

  return iconProps;
}

function useCompletionConfetti(
  incident: Incident,
  incidentTasks: PostIncidentTask[],
) {
  const [isConfettiing, setIsConfettiing] = useState(false);

  const previousIncident = usePrevious(incident);
  useEffect(() => {
    if (incidentTasks.length === 0) {
      return;
    }

    const isClosed =
      incident?.incident_status.category === IncidentStatusCategoryEnum.Closed;
    const wasPostIncident =
      previousIncident?.incident_status.category ===
      IncidentStatusCategoryEnum.PostIncident;
    const allTasksComplete = incidentTasks.every(
      (t) => t.completed_at || t.rejected_at,
    );

    if (isClosed && wasPostIncident && allTasksComplete) {
      setIsConfettiing(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incident]);

  return { isConfettiing, setIsConfettiing };
}
