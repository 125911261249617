import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { InsightsDatePicker } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import {
  EditDashboardFormData,
  useInsightsContext,
} from "../common/useInsightsContext";
import { DashboardDetailsFormData } from "./CreateCustomDashboardModal";
import { EditCustomDashboardDetailsModal } from "./EditCustomDashboardDetailsModal";
import { InsightsEditDashboardProvider } from "./InsightsEditDashboardContext";

export const InsightsCustomDashboardEditRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { insightsV3 } = useFlags();

  const { id } = useParams<{ id: string }>() as { id: string };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCustomDashboard", {
    id,
  });

  if (!insightsV3) {
    return <NotFoundPage />;
  }

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights-v3" />;
  }

  const error = dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const loading = dashboardLoading;
  if (loading || !dashboardData) {
    return <FullPageLoader />;
  }

  return (
    <InsightsEditDashboardProvider
      dashboard={dashboardData.dashboard}
      filterFields={[]}
    >
      <InsightsCustomDashboardEditPage />
    </InsightsEditDashboardProvider>
  );
};

const InsightsCustomDashboardEditPage = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const formMethods = useFormContext<EditDashboardFormData>();
  const { saving } = useInsightsContext();
  const [name, icon, color, panels] = formMethods.watch([
    "name",
    "icon",
    "color",
    "panels",
  ]);

  const onSubmitDetailsModal = (data: DashboardDetailsFormData) => {
    formMethods.setValue<"name">("name", data.name);
    formMethods.setValue<"icon">("icon", data.icon);
    formMethods.setValue<"color">("color", data.color);
    setShowDetailsModal(false);
  };

  return (
    <>
      <PageWrapper
        width={PageWidth.Wide}
        icon={icon}
        color={color as unknown as ColorPaletteEnum}
        title={name}
        className="bg-surface-secondary"
        crumbs={[
          {
            title: "Insights",
            to: "/insights-v3",
          },
        ]}
        backHref="/insights-v3"
        noPadding
        titleAccessory={
          <Button
            icon={IconEnum.Edit}
            onClick={() => setShowDetailsModal(true)}
            title="Edit details"
            analyticsTrackingId={null}
            theme={ButtonTheme.Naked}
          />
        }
        accessory={
          <div className="flex items-center gap-2">
            <InsightsDatePicker />
            {/* TODO: filters */}
            {/*   <FilterPopover
              renderTriggerButton={({ onClick }) => (
                <Button
                  onClick={() => onClick()}
                  analyticsTrackingId={"insights-core-dashboard-filter"}
                  icon={IconEnum.Filter}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                >
                  Add filter
                </Button>
              )}
            /> */}
            {/* TODO: delete! */}
            {/* <GatedButton
              theme={ButtonTheme.DestroySecondary}
              requiredScope={ScopeNameEnum.InsightsCustomDashboardsDestroy}
              analyticsTrackingId="insights-custom-dashboard-delete"
              icon={IconEnum.Delete}
              title="Delete dashboard"
            /> */}
            <GatedButton
              theme={ButtonTheme.Primary}
              requiredScope={ScopeNameEnum.InsightsCustomDashboardsUpdate}
              analyticsTrackingId="insights-custom-dashboard-save"
              icon={IconEnum.Tick}
              type="submit"
              loading={saving}
            >
              Save changes
            </GatedButton>
          </div>
        }
      >
        <div className="flex flex-col grow gap-6 p-6">
          {panels.map((panel, idx) => (
            <InsightsPanel key={idx} panel={panel} panelIdx={idx} editable />
          ))}
        </div>
      </PageWrapper>
      {showDetailsModal && (
        <EditCustomDashboardDetailsModal
          initialData={{ name, icon, color }}
          onClose={() => setShowDetailsModal(false)}
          onSubmit={onSubmitDetailsModal}
        />
      )}
    </>
  );
};
