import { Icon, IconEnum, Link } from "@incident-ui";
import _ from "lodash";
import {
  AtlassianStatuspageUpdate,
  ExternalResource,
} from "src/contexts/ClientContext";

import { QuoteContentBox } from "./QuoteContextBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const statuspageUpdateTitle = (
  statuspage_update: AtlassianStatuspageUpdate,
): React.ReactElement => {
  const actor = <TimelineItemSource actor={statuspage_update.updater} />;

  if (statuspage_update.is_link_from_existing) {
    return <>{actor} linked an existing Statuspage</>;
  }

  if (!statuspage_update.previous_statuspage) {
    return <>{actor} created a Statuspage incident</>;
  }

  if (statuspage_update.statuspage?.status === "resolved") {
    return <>{actor} resolved a Statuspage incident</>;
  }

  return <>{actor} updated a Statuspage incident</>;
};

export const TimelineItemAtlassianStatuspageUpdateContentBox = ({
  statuspageUpdates,
  externalResource,
  hidden,
}: {
  statuspageUpdates: AtlassianStatuspageUpdate[];
  externalResource?: ExternalResource;
  hidden: boolean | undefined;
}): React.ReactElement => {
  return (
    <QuoteContentBox hidden={hidden}>
      {externalResource?.atlassian_statuspage_incident ? (
        <Link
          href={externalResource.permalink}
          openInNewTab
          analyticsTrackingId={null}
        >
          <b>{externalResource.atlassian_statuspage_incident.title}</b>
        </Link>
      ) : (
        <b>{statuspageUpdates[0]?.statuspage?.name}</b>
      )}
      <br />
      {statuspageUpdates.map(
        (statuspage_update) =>
          statuspage_update.statuspage && (
            <>
              {statuspage_update.statuspage?.incident_updates[0]?.body}
              <div className="space-x-2 flex text-slate-600">
                <span className="flex-center-y">
                  <Icon
                    id={IconEnum.Status}
                    className="text-content-tertiary"
                  />
                  {_.upperFirst(statuspage_update.statuspage.status)}
                </span>
                {externalResource?.atlassian_statuspage_incident && (
                  <span className="flex-center-y">
                    <Icon
                      id={IconEnum.Severity}
                      className="text-content-tertiary"
                    />{" "}
                    <span>
                      {_.startCase(
                        externalResource.atlassian_statuspage_incident.impact,
                      )}
                    </span>
                  </span>
                )}
              </div>
            </>
          ),
      )}
    </QuoteContentBox>
  );
};
