import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Link, Markdown } from "@incident-ui";
import { Modal } from "@incident-ui/Modal/Modal";
import { ModalContent } from "@incident-ui/Modal/ModalContent";
import { ModalFooter } from "@incident-ui/Modal/ModalFooter";
import React from "react";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import styles from "src/components/timeline/TimelineItems.module.scss";
import { ActivityLogEntry, Actor, Incident } from "src/contexts/ClientContext";

export const ActivityItemSlackPin = (
  _: Incident,
  item: ActivityLogEntry,
  zoomImageSource: string | undefined,
  setZoomImageSource: (value: React.SetStateAction<string | undefined>) => void,
): ActivityItemRenderProps | null => {
  const slackPin = item.content.slack_pin;
  if (!slackPin) {
    throw new Error(
      "malformed timeline item: slack_pin was missing slack_pin field",
    );
  }

  // Don't render an item if the pin has no message, or the message has been deleted.
  if (!slackPin.message || slackPin.message.deleted) {
    return null;
  }

  let title: string | React.ReactElement = "Message pinned";
  if (slackPin.message.permalink) {
    title = (
      <>
        <Link
          href={slackPin.message.permalink}
          openInNewTab
          analyticsTrackingId={null}
        >
          Message
        </Link>{" "}
        pinned
      </>
    );
  }

  const imageZoomModal = (
    <Modal
      title="No title shown"
      isOpen={!!zoomImageSource}
      analyticsTrackingId={null}
      onClose={() => setZoomImageSource(undefined)}
      hideHeader
      maximised
    >
      <ModalContent>
        <img src={zoomImageSource} className={""} />
      </ModalContent>
      <ModalFooter
        hideConfirmButton={true}
        confirmButtonType="button"
        onConfirm={() => setZoomImageSource(undefined)}
        onClose={() => setZoomImageSource(undefined)}
        cancelButtonText={"Close"}
      />
    </Modal>
  );

  const userActor: Actor = {
    user: slackPin.message_user,
  };
  // An actor should only be shown if there is a user associated with the message.
  // This could happen if the message was posted by a bot!
  const actor = slackPin.message_user && userActor;

  // This means we'll only add context if the message has text OR an image.
  // There might be neither if the message just contains a file attachment.
  if (slackPin.message.text !== "" || slackPin.images) {
    return {
      title: title,
      actor: actor,
      icon: IconEnum.Pin,
      colour: ColorPaletteEnum.Cyan,
      unquotedContent: (
        <>
          {imageZoomModal}
          <>
            <Markdown className={styles.contentBox}>
              {slackPin.message.text}
            </Markdown>
            {slackPin.images &&
              slackPin.images.map((im) =>
                im ? (
                  <img
                    key={im.id}
                    src={im.url}
                    className={"rounded-2 border border-stroke shadow-sm"}
                    onClick={() => setZoomImageSource(im.url)}
                  />
                ) : null,
              )}
          </>
        </>
      ),
    };
  } else {
    return {
      title: title,
      actor: actor,
      icon: IconEnum.Pin,
      colour: ColorPaletteEnum.Pink,
    };
  }
};
