import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import {
  Attachment,
  AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Actor, Incident } from "src/contexts/ClientContext";

export const ActivityItemPagerdutyIncidentTriggered = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.pagerduty_incident_triggered) {
    throw new Error(
      "malformed timeline item: pagerduty_incident_triggered was missing pagerduty_incident_triggered field",
    );
  }

  const actor: Actor = {
    external_resource:
      item.content.pagerduty_incident_triggered.external_resource,
  };

  return {
    title:
      item.content.pagerduty_incident_triggered.external_resource
        .resource_type_label + " triggered",
    icon: IconEnum.Attachment,
    colour: ColorPaletteEnum.Purple,
    actor: actor,
    quotedContent: (
      <Attachment
        context={AttachmentContexts.Timeline}
        resource={item.content.pagerduty_incident_triggered.external_resource}
      />
    ),
  };
};
