import { IconEnum, Markdown } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  Incident,
  TimelineItem,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemActionCreated = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.action_created) {
    throw new Error(
      "malformed timeline item: action_created was missing action_created field",
    );
  }

  return {
    description: actionCreateText(item),
    avatarUrl: item.action_created.creator?.user?.avatar_url,
    icon: IconEnum.AddCircle,
    children: (
      <TimelineItemContentBox>
        <Markdown>{item.action_created.action.description}</Markdown>
      </TimelineItemContentBox>
    ),
  };
};

const actionCreateText = (item: TimelineItem) => {
  return (
    <>
      <TimelineItemSource actor={item.action_created?.creator} /> created an
      action
    </>
  );
};
