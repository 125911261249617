import { IconEnum } from "@incident-ui";
import React from "react";
import {
  Attachment,
  AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  StatusChange,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";
import { StreamDetails } from "./TimelineItemStreamDetails";

const StatusChangeText = ({
  incident,
  status_change,
}: {
  incident: Incident;
  status_change: StatusChange;
  isPostMortem?: boolean;
}): React.ReactElement => {
  const source = <TimelineItemSource actor={status_change.updater} />;
  if (!status_change.previous_incident_status) {
    if (incident?.mode === IncidentModeEnum.Retrospective) {
      return <>{source} opened this retrospective incident</>;
    } else {
      return <>{source} reported the incident</>;
    }
  }

  if (
    status_change.previous_incident_status?.category ===
    IncidentStatusCategoryEnum.Closed
  ) {
    return (
      <>
        {source} re-opened the incident and updated the state to{" "}
        <strong>{status_change.incident_status.name}</strong>
      </>
    );
  }

  return (
    <>
      {source} updated the state to{" "}
      <strong>{status_change.incident_status.name}</strong>
    </>
  );
};

// DEPRECATED: This type of timeline item is deprecated because we rely on incident updates instead.
// We will still render it for old incidents though (before we built incident updates).
export const TimelineItemStatusChange = (
  incident: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.status_change) {
    throw new Error(
      "malformed timeline item: status_change was missing status_change field",
    );
  }
  const updater = item.status_change.updater;

  // Special case for stream created
  if (item.is_stream && !item.status_change.previous_incident_status) {
    return {
      avatarUrl: updater?.user?.avatar_url,
      icon: IconEnum.GitBranchNew,
      description: (
        <>
          <TimelineItemSource actor={updater} /> created a new stream
        </>
      ),
      children: <StreamDetails streamId={item.incident_id} hidden={false} />,
    };
  }

  const icon = IconEnum.Incident;
  // Special case for when an incident is created (in triage) from an external resource
  if (updater?.external_resource != null) {
    return {
      avatarUrl: item.status_change.updater?.user?.avatar_url,
      icon,
      description: (
        <>
          We created an incident from a{" "}
          {updater.external_resource.resource_type_label}
        </>
      ),
      children: (
        <TimelineItemContentBox>
          <Attachment
            resource={updater.external_resource}
            context={AttachmentContexts.Timeline}
          />
        </TimelineItemContentBox>
      ),
    };
  }

  return {
    description: (
      <StatusChangeText
        incident={incident}
        status_change={item.status_change}
      />
    ),
    icon,
    avatarUrl: item.status_change.updater?.user?.avatar_url,
  };
};
