import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { ButtonTheme, Card, FullPageEmptyState, IconEnum } from "@incident-ui";

import { ReactComponent as Graphic } from "./empty-state.svg";

export const WorkflowsListEmptyState = ({
  onOpenCreateDrawer,
}: {
  onOpenCreateDrawer: () => void;
}) => {
  return (
    <FullPageEmptyState
      title="Automate your incidents"
      graphic={<Graphic />}
      subtitle="Create workflows in seconds, and bring consistency to your incidents. Configure your processes once and we'll ensure they're followed every time."
      cta={
        <GatedButton
          analyticsTrackingId="workflows-empty-state-create"
          onClick={onOpenCreateDrawer}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.WorkflowsCreate}
        >
          Create your first workflow
        </GatedButton>
      }
      gradientClasses="from-alarmalade-100 to-white"
      cards={
        <>
          <Card
            className="h-full"
            icon={IconEnum.Action}
            title="Bring consistency to incidents"
            description="Guide responders through each incident step by step"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.Users}
            title="Empower your team"
            description="Create and run incidents autonomously, so you can scale your team with confidence"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
          <Card
            className="h-full"
            icon={IconEnum.FastForward}
            title="Eliminate busywork"
            description="We'll guide the process, so your time is freed up to focus on fixing"
            color={ColorPaletteEnum.Red}
            clickable={false}
          />
        </>
      }
    />
  );
};
