import { IconEnum } from "@incident-ui";
import {
  Attachment,
  AttachmentContexts as AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemIncidentAttachmentRemoved = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_attachment_removed) {
    throw new Error(
      "malformed timeline item: incident_attachment_removed was missing incident_attachment_removed field",
    );
  }

  return {
    icon: IconEnum.LinkBreak,
    avatarUrl: item.incident_attachment_removed.remover?.user?.avatar_url,
    description: (
      <>
        <TimelineItemSource actor={item.incident_attachment_removed.remover} />{" "}
        detached a{" "}
        {item.incident_attachment_removed.external_resource.resource_type_label}
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Attachment
          context={AttachmentContexts.Timeline}
          resource={item.incident_attachment_removed.external_resource}
        />
      </TimelineItemContentBox>
    ),
  };
};
