import {
  IncidentsBuildScopeContextEnum,
  InternalStatusPage,
  InternalStatusPageUpdateBrandingRequestBodyThemeEnum,
  InternalStatusPageUpdatePageRequestBody,
  ScopeNameEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { SecondaryNavDesktop } from "@incident-shared/layout/SecondaryNav";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  ErrorMessage,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  ModalFooter,
  ToastTheme,
} from "@incident-ui";
import { TabPane, TabSection } from "@incident-ui/TabSection/TabSection";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Route, Routes, useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { useIncidentScope } from "../../../../hooks/useIncidentScope";
import { DeletionConfirmationFormModal } from "../../../settings/DeletionConfirmationModal";
import { AutomationRulesForm } from "./AutomationRulesForm";
import { BasicSettingsFormContent } from "./BasicSettingsFormContent";
import {
  BrandingFormContent,
  BrandingFormType,
  toBrandingForm,
} from "./BrandingForm";
import { OverviewTab } from "./OverviewTab";
import { DisplayCustomFieldsForm } from "./settings/DisplayCustomFieldsForm";
import { StructureForm } from "./settings/StructureForm";
import {
  OverviewTabIds,
  SETTINGS_TABS,
  SettingsTabIds,
  TabId,
  TABS,
} from "./tabs";

type DetailsPageProps =
  | { overviewTab: OverviewTabIds; settingsTab?: never }
  | { overviewTab?: never; settingsTab?: SettingsTabIds };

export const InternalStatusPageDetailsPage = () => {
  return (
    <Routes>
      <Route path="overview">
        {Object.values(OverviewTabIds).map((tab) => (
          <Route
            key={tab}
            path={tab}
            element={<DetailsPage overviewTab={tab} />}
          />
        ))}
        {/* Default tab should be 'Now' */}
        <Route
          path=""
          element={<OrgAwareNavigate to={OverviewTabIds.Now} replace />}
        />
      </Route>
      <Route path="settings">
        {SETTINGS_TABS.map((tab) => (
          <Route
            key={tab.slug}
            path={tab.slug}
            element={<DetailsPage settingsTab={tab.slug} />}
          />
        ))}
        {/* Default tab should be 'Basic Settings' */}
        <Route
          path=""
          element={
            <OrgAwareNavigate to={SettingsTabIds.BasicSettings} replace />
          }
        />
      </Route>
      <Route
        path="*"
        element={<OrgAwareNavigate to="overview/now" replace />}
      />
    </Routes>
  );
};

const DetailsPage = ({ overviewTab, settingsTab }: DetailsPageProps) => {
  const navigate = useOrgAwareNavigate();
  const { pageId } = useParams() as { pageId: string };

  const {
    data: pageData,
    error: pageError,
    isLoading: pageLoading,
  } = useAPI("internalStatusPageShowPage", { id: pageId });
  const page = pageData?.internal_status_page;
  const structure = pageData?.internal_status_page_structure;

  const error = pageError;
  if (error) {
    captureException(error, { extra: { internal_status_page_id: pageId } });
    console.error(error);
    return <GenericErrorMessage error={error} />;
  }

  return (
    <>
      <PageWrapper
        width={PageWidth.Medium}
        icon={IconEnum.StatusPage}
        loading={pageLoading}
        title={page?.name || ""}
        banner={
          <HeaderBanner className="text-content-primary gap-2">
            <Icon id={IconEnum.LockClosed} className="text-slate-600" /> This is
            an internal status page that cannot be viewed by users outside of
            your organisation.
          </HeaderBanner>
        }
        subtitle={
          <Button
            href={page?.page_url}
            openInNewTab
            theme={ButtonTheme.Naked}
            analyticsTrackingId={"status-page-subtitle"}
          >
            {page?.page_url}
            <Icon
              id={IconEnum.ExternalLink}
              size={IconSize.Small}
              className="ml-1 text-slate-400 group-hover:text-slate-700 transition"
            />
          </Button>
        }
        backHref="/status-pages"
        overflowY={false}
      >
        <TabSection
          value={overviewTab != null ? TabId.Overview : TabId.Settings}
          tabs={TABS}
          withIndicator
          rootClassName="overflow-y-hidden h-full"
          tabBarClassName="mb-6 border-b border-1 border-stroke sticky top-0 bg-white z-10"
          onTabChange={(tabId) => {
            navigate(
              tabId === TabId.Settings
                ? `/status-pages/internal/${pageId}/settings`
                : `/status-pages/internal/${pageId}/${OverviewTabIds.Now}`,
              { replace: true },
            );
          }}
        >
          <TabPane tabId={TabId.Overview} className="h-full pb-6">
            {page && structure && (
              <OverviewTab
                page={page}
                structure={structure}
                overviewTab={overviewTab}
              />
            )}
          </TabPane>
          <TabPane tabId={TabId.Settings} className="h-full pb-6">
            {page && (
              <div className="flex items-start gap-10 h-full">
                {/* We don't support this nav on mobile */}
                <SecondaryNavDesktop
                  applyPadding={false}
                  pathPrefix={`status-pages/internal/${pageId}/settings`}
                  itemGroups={[{ items: SETTINGS_TABS }]}
                />
                <div
                  className={tcx(
                    "w-full flex flex-col gap-8 h-full pb-24 overflow-y-auto grow",
                  )}
                >
                  <SettingsTabContent
                    tab={settingsTab || SettingsTabIds.BasicSettings}
                    page={page}
                  />
                </div>
              </div>
            )}
          </TabPane>
        </TabSection>
      </PageWrapper>
    </>
  );
};

const SettingsTabContent = ({
  tab,
  page,
}: {
  tab: SettingsTabIds;
  page: InternalStatusPage;
}): React.ReactElement | null => {
  const { scope, scopeLoading } = useIncidentScope(
    IncidentsBuildScopeContextEnum.AutomationRules,
  );

  switch (tab) {
    case SettingsTabIds.BasicSettings:
      return <BasicSettings page={page} />;
    case SettingsTabIds.Branding:
      return <Branding page={page} />;
    case SettingsTabIds.Components:
      return <StructureForm page={page} />;
    case SettingsTabIds.Automation: {
      return scopeLoading ? null : (
        <AutomationRulesForm statusPageId={page.id} incidentScope={scope} />
      );
    }
    default:
      return assertUnreachable(tab);
  }
};

const BasicSettings = ({ page }: { page: InternalStatusPage }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      {showDeleteModal && (
        <DeletePageModal
          page={page}
          onClose={() => setShowDeleteModal(false)}
        />
      )}

      <BasicSettingsForm page={page} />

      <DisplayCustomFieldsForm page={page} />

      <DeletePageSection onDelete={() => setShowDeleteModal(true)} />
    </>
  );
};

type DeletePageFormType = {
  confirmString: string;
};
const DeletePageModal = ({
  page,
  onClose,
}: {
  page: InternalStatusPage;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<DeletePageFormType>();

  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { trigger, isMutating: saving } = useAPIMutation(
    "internalStatusPageListPages",
    undefined,
    async (apiClient, _: DeletePageFormType) => {
      await apiClient.internalStatusPageDestroyPage({ id: page.id });
    },
    {
      onSuccess: () => {
        onClose();

        showToast({
          title: "Internal status page successfully deleted",
          theme: ToastTheme.Success,
        });
        navigate("/status-pages");
      },
      onError: () => {
        showToast({
          title: "Unexpected error",
          description: "We weren't able to delete this internal status page",

          theme: ToastTheme.Error,
        });
      },
    },
  );

  return (
    <DeletionConfirmationFormModal
      onClose={onClose}
      onDelete={trigger}
      resourceTitle={page.name}
      isOpen={true}
      title="Delete internal status page"
      analyticsTrackingId="internal-status-page-delete-page-modal"
      dependentResources={[]}
      formMethods={formMethods}
      footer={
        <ModalFooter
          analyticsTrackingId={"internal-status-page-delete-page-modal-confirm"}
          onClose={onClose}
          confirmButtonType="submit"
          confirmButtonText="Delete status page"
          cancelButtonText="Cancel"
          saving={saving}
          disabled={!formMethods.formState.isValid}
          disabledTooltipContent="Please type the name of the page to confirm deletion."
        />
      }
    >
      <>
        <p>
          This action cannot be undone. This will permanently delete the page{" "}
          <span className="font-semibold">{page.name}</span>.
        </p>
        <p>Your colleagues will no longer be able to see view this page.</p>
        <InputV2
          helptext={
            <p className="text-content-primary">
              Please type <span className="font-semibold">{page.name}</span> to
              confirm.
            </p>
          }
          rules={{ validate: (value) => value.trim() === page.name.trim() }}
          formMethods={formMethods}
          name="confirmString"
        />
      </>
    </DeletionConfirmationFormModal>
  );
};

const BasicSettingsForm = ({ page }: { page: InternalStatusPage }) => {
  const { data: internalSPList, isLoading } = useAPI(
    "internalStatusPageListPages",
    undefined,
  );

  const formMethods = useForm<InternalStatusPageUpdatePageRequestBody>({
    defaultValues: { ...page },
  });

  const { trigger, isMutating, genericError } = useAPIMutation(
    "internalStatusPageShowPage",
    { id: page.id },
    async (apiClient, data: InternalStatusPageUpdatePageRequestBody) => {
      await apiClient.internalStatusPageUpdatePage({
        id: page.id,
        updatePageRequestBody: data,
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: (res) => formMethods.reset({ ...res.internal_status_page }),
    },
  );
  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const numPages = internalSPList?.internal_status_pages.length ?? 1;

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating || isLoading}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
    >
      <h3 className="font-medium">Basic settings</h3>
      <ErrorMessage message={genericError} />
      <BasicSettingsFormContent
        page={page}
        showSubpath={numPages > 1 || page.subpath !== "default"}
        formMethods={formMethods}
      />

      <GatedButton
        type="submit"
        theme={ButtonTheme.Primary}
        analyticsTrackingId={"status-page-edit-basics"}
        analyticsTrackingMetadata={{ status_page_id: page.id }}
        loading={isMutating}
        disabled={missingPermission || !formMethods.formState.isDirty}
        disabledTooltipContent={
          missingPermission
            ? "You do not have permission to configure this internal status page"
            : undefined
        }
      >
        Save
      </GatedButton>
    </Form.Root>
  );
};

const DeletePageSection = ({
  onDelete,
}: {
  onDelete: () => void;
}): React.ReactElement => {
  return (
    <div className="bg-surface-secondary rounded-[6px] p-4 border border-stroke text-sm">
      <div className="font-medium mb-2">Delete page</div>
      <p className="text-slate-600 mb-3">
        Deleting your internal status page can&apos;t be undone. Your page will
        no longer be available to view for your users.
      </p>

      <Button
        analyticsTrackingId={"internal-status-page-delete-page"}
        theme={ButtonTheme.Destroy}
        onClick={onDelete}
      >
        Delete page
      </Button>
    </div>
  );
};

const Branding = ({ page }: { page: InternalStatusPage }) => {
  const formMethods = useForm<BrandingFormType>({
    defaultValues: toBrandingForm(page),
  });

  const { hasScope } = useIdentity();
  const missingPermission = !hasScope(ScopeNameEnum.StatusPagesConfigure);

  const { trigger, isMutating, genericError } = useAPIMutation(
    "internalStatusPageShowPage",
    { id: page.id },
    async (apiClient, data) => {
      const { internal_status_page } =
        await apiClient.internalStatusPageUpdateBranding({
          id: page.id,
          updateBrandingRequestBody: {
            theme: data.theme,
            darkmode_logo_key:
              data.theme ===
              InternalStatusPageUpdateBrandingRequestBodyThemeEnum.Dark
                ? data.logo?.key
                : undefined,
            lightmode_logo_key:
              data.theme ===
              InternalStatusPageUpdateBrandingRequestBodyThemeEnum.Light
                ? data.logo?.key
                : undefined,
          },
        });

      formMethods.reset(toBrandingForm(internal_status_page));
    },
  );

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      genericError={genericError}
      innerClassName="bg-surface-secondary rounded-[6px] p-4 border border-stroke"
    >
      <BrandingFormContent subpath={page.subpath} formMethods={formMethods} />

      <GatedButton
        type="submit"
        theme={ButtonTheme.Primary}
        analyticsTrackingId={"status-page-edit-basics"}
        analyticsTrackingMetadata={{ status_page_id: page.id }}
        loading={isMutating}
        disabled={missingPermission || !formMethods.formState.isDirty}
        disabledTooltipContent={
          missingPermission
            ? "You do not have permission to configure this internal status page"
            : undefined
        }
      >
        Save
      </GatedButton>
    </Form.Root>
  );
};
