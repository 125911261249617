import {
  CatalogTypeCategoriesEnum,
  EngineParamBindingPayload,
  Resource,
} from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { WizardLayout } from "../WizardLayout";

const USER_TYPE_NAME = `CatalogEntry["User"]`;

type ManualEntry = {
  attribute_values?: { [key: string]: EngineParamBindingPayload };
  name?: string;
};

type FormData = {
  manual_entries: ManualEntry[];
};

export const TeamWizardManualTeamsStep = () => {
  const navigate = useOrgAwareNavigate();

  const defaultValues: FormData = { manual_entries: [{}, {}, {}, {}] };
  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const { append: addEntry, remove: removeEntry } = useFieldArray({
    name: "manual_entries",
    control: formMethods.control,
  });

  const entries = formMethods.watch("manual_entries");
  const nonEmpty = entries.filter(
    (entry) =>
      !!entry.name ||
      (entry.attribute_values?.members.array_value?.length || 0) > 0,
  );

  const {
    data: resourcesData,
    error: resourcesError,
    isLoading: resourcesIsLoading,
  } = useAPI("catalogListResources", undefined);

  const {
    trigger: bootstrapTeamType,
    isMutating: bootstrapping,
    genericError: bootstrapError,
  } = useAPIMutation(
    "catalogListTypes",
    {},
    async (apiClient) => {
      const createTypeResponse = await apiClient.catalogBootstrapTeamType({
        bootstrapTeamTypeRequestBody: {
          manual_entries: nonEmpty.map((entry) => ({
            name: entry.name || "",
            members: entry.attribute_values?.members.array_value?.map(
              (member) => member.literal || "",
            ),
          })),
        },
      });
      navigate(
        `/catalog/team-wizard/${createTypeResponse.catalog_type.id}/add-attributes`,
      );
    },
    {
      setError: formMethods.setError,
    },
  );

  if (resourcesIsLoading || !resourcesData) {
    return <Loader />;
  }

  if (resourcesError) {
    return <GenericErrorMessage error={resourcesError} />;
  }

  const engineResources: Resource[] = resourcesData.resources.map(
    (res) => res.config,
  );

  return (
    <WizardLayout
      category={CatalogTypeCategoriesEnum.Team}
      title="Add your teams"
      subtitle="Enter the name and any members of the teams you wish to manage in Catalog. You'll be able to add more attributes to this in the next step"
      stepID="choose-teams"
      footer={
        <div className="flex gap-2">
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.Secondary}
            loading={false}
            disabled={false}
            href="/catalog"
          >
            Exit
          </Button>
          <Button
            form="team-wizard-manual-teams-form"
            type={"submit"}
            analyticsTrackingId={null}
            theme={ButtonTheme.Primary}
            loading={bootstrapping}
            disabled={nonEmpty.length === 0}
          >
            Save and continue
          </Button>
        </div>
      }
    >
      <Form.Root
        id="team-wizard-manual-teams-form"
        genericError={bootstrapError}
        onSubmit={bootstrapTeamType}
        formMethods={formMethods}
        saving={bootstrapping}
      >
        <div className="flex flex-col gap-4">
          <Table
            wrappedInBox
            data={entries}
            gridTemplateColumns="1fr 1fr min-content"
            header={
              <>
                <TableHeaderCell title="Team name" className="px-4" />
                <TableHeaderCell
                  title="Members (optional)"
                  className="pl-2 !pr-4"
                />
                <TableHeaderCell className="pr-2" />
              </>
            }
            renderRow={(_entry: ManualEntry, index) => {
              return (
                <TableRow isLastRow={index === entries.length - 1} key={index}>
                  <TableCell key={`${index}-name`} className="py-3 items-start">
                    <InputV2
                      formMethods={formMethods}
                      name={`manual_entries.${index}.name`}
                      placeholder="Team name"
                      className="w-full"
                    />
                  </TableCell>
                  <TableCell
                    key={`${index}-members`}
                    className="py-3 pl-2 !pr-4 items-start"
                  >
                    <EngineFormElement
                      mode="plain_input"
                      key={index}
                      name={`manual_entries.${index}.attribute_values.members`}
                      resources={engineResources}
                      required={false}
                      array={true}
                      resourceType={USER_TYPE_NAME}
                      className="w-full"
                      showPlaceholder
                    />
                  </TableCell>
                  <TableCell
                    key={`${index}-delete`}
                    className="py-3 pl-2 !pr-4"
                  >
                    <Button
                      analyticsTrackingId={null}
                      icon={IconEnum.Delete}
                      onClick={() => removeEntry(index)}
                      title="Remove"
                      theme={ButtonTheme.Tertiary}
                    />
                  </TableCell>
                </TableRow>
              );
            }}
          />
          <Button
            analyticsTrackingId={null}
            icon={IconEnum.Add}
            theme={ButtonTheme.Secondary}
            onClick={() => addEntry({})}
            className="py-2 px-3 w-fit"
          >
            Add another
          </Button>
        </div>
      </Form.Root>
    </WizardLayout>
  );
};
