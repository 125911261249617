import {
  GitLabBulkExportableFields,
  GitlabFormData,
} from "@incident-shared/issue-trackers";
import { ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  FollowUp,
  IssueTrackersGitLabTypeaheadOptionsFieldEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  BulkExportBlurb,
  BulkExportCallouts,
  BulkExportTitleAndDescription,
  getBulkExportTitle,
  getBulkToastTitle,
  NoFollowUpsToExportModal,
} from "./helpers";

export const BulkExportToGitLabModal = ({
  followUpsToExport,
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  onClose,
  onCancel,
  updateCallback,
}: {
  followUpsToExport: FollowUp[];
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => void;
}): React.ReactElement => {
  const formMethods = useForm<GitlabFormData>();
  const { setError, watch } = formMethods;

  const userSelectedRepo = watch("repo");

  const showToast = useToast();
  const providerName = "GitLab";

  const {
    data: { typeahead_options: labels },
    isLoading: labelsLoading,
  } = useAPI(
    userSelectedRepo ? "issueTrackersGitLabTypeaheadOptions" : null,
    {
      field: IssueTrackersGitLabTypeaheadOptionsFieldEnum.Label,
      repo: userSelectedRepo?.value,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, body: GitlabFormData) => {
      await Promise.all(
        followUpsToExport.map((followUp) =>
          apiClient.issueTrackersGitLabCreateIssue({
            gitLabCreateIssueRequestBody: {
              ...body,
              follow_up_id: followUp.id,
              title: followUp.title,
              description: followUp.description,
              repo: body.repo?.value,
              assignee_username: body.assignee_username?.value,
            },
          }),
        ),
      );
    },
    {
      setError,
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: getBulkToastTitle(followUpsToExport.length, providerName),
        });
        updateCallback();
        onClose();
      },
    },
  );

  const title = getBulkExportTitle(followUpsToExport.length);

  if (followUpsToExport.length === 0) {
    return (
      <NoFollowUpsToExportModal
        numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
        onClose={onClose}
      />
    );
  } else {
    return (
      <Form.Modal
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId="bulk-add-to-gitlab"
        onClose={onCancel}
        footer={
          <ModalFooter
            confirmButtonText={title}
            saving={saving}
            onClose={onCancel}
            confirmButtonType="submit"
          />
        }
      >
        <BulkExportCallouts
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          accountName="GitLab repository"
        />

        <BulkExportBlurb
          providerIssues="GitLab issues"
          providerName={providerName}
        />
        <BulkExportTitleAndDescription providerName={providerName} />
        <GitLabBulkExportableFields
          labels={labels || []}
          labelsLoading={labelsLoading}
        />
      </Form.Modal>
    );
  }
};
