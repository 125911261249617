import {
  EscalationPath,
  EscalationPathTargetTypeEnum,
  Schedule,
  SchedulesShowResponseBody,
  SchedulesUpdateNameRequestBody,
  ScopeNameEnum,
} from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { ManagementMetaBadge } from "@incident-shared/management-meta/ManagementMetaBadge";
import { isDashboard } from "@incident-shared/management-meta/utils";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ScheduleEntryComponent } from "@incident-shared/schedules/ScheduleOverview/ScheduleEntryComponent";
import { ScheduleOverviewV2 } from "@incident-shared/schedules/ScheduleOverview/ScheduleOverviewV2";
import {
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  ModalFooter,
  OrgAwareLink,
  Txt,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useOutlet, useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { getEscalationPathLevelNodes } from "src/components/escalation-paths/common/helpers";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../../hooks/usePrimaryCommsPlatform";
import { CalendarFeedModal } from "./CalendarFeedModal";
import { ScheduleConstantToTerraformDrawer } from "./schedule-create-edit-form/ScheduleCopyToTerraform";
import { ScheduleCreateEditDrawer } from "./ScheduleCreateEditDrawer";
import { ScheduleRestoreDrawer } from "./ScheduleRestoreDrawer";
import { SyncToSlackModal } from "./SyncToSlackUserGroupModal";

export const SchedulesViewPage = () => {
  const drawer = useOutlet();
  const { id: scheduleId } = useParams<{ id: string }>();
  const { hasScope } = useIdentity();
  const commsPlatform = usePrimaryCommsPlatform();
  const navigate = useOrgAwareNavigate();
  const [editRotaId, setEditRotaId] = useState<string | undefined>(undefined);

  // Modals
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [calendarFeedModalOpen, setCalendarFeedModalOpen] = useState(false);
  const [syncToSlackModalOpen, setSyncToSlackModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Drawers
  const [showScheduleDrawer, setShowScheduleDrawer] = useState(false);
  const [showRestoreDrawer, setShowRestoreDrawer] = useState(false);
  const [showTerraformDrawer, setShowTerraformDrawer] = useState(false);

  const {
    data: scheduleResp,
    isLoading: scheduleIsLoading,
    error: scheduleError,
  } = useAPI(scheduleId ? "schedulesShow" : null, {
    id: scheduleId ?? "",
  });

  const { data: escalationPathsData } = useAPI(
    scheduleResp?.schedule ? "escalationPathsList" : null,
    {},
    {
      fallbackData: { escalation_paths: [], first_level_users: {} },
    },
  );

  const escalationPathsForSchedule =
    escalationPathsData?.escalation_paths.filter((ep) =>
      getEscalationPathLevelNodes(ep.path).some((level) =>
        level.targets.some(
          (t) =>
            t.type === EscalationPathTargetTypeEnum.Schedule &&
            t.id === scheduleId,
        ),
      ),
    );

  const { data: typeData } = useAPI(
    "catalogListTypes",
    {},
    { fallbackData: { catalog_types: [] } },
  );

  const slackUserGroupTypeID = typeData?.catalog_types.find(
    (ct) => ct.registry_type === "SlackUserGroup",
  )?.id;

  const { data: slackUserGroupCatalogEntryData } = useAPI(
    slackUserGroupTypeID ? "catalogListEntries" : null,
    {
      catalogTypeId: slackUserGroupTypeID as string,
      pageSize: 250,
      search: "",
      includeReferences: true,
    },
  );

  const slackUserGroupEntry =
    slackUserGroupCatalogEntryData?.catalog_entries.find(
      (entry) =>
        entry.external_id ===
        scheduleResp?.schedule.mirrored_slack_user_group_id,
    );

  // We don't want this data til further down, but we _do_
  // want to refetch it when we edit the schedule
  const revalidateScheduleEntries = useRevalidate(["schedulesListEntries"]);

  const {
    data: { schedule_versions },
    isLoading: versionsLoading,
  } = useAPI(
    scheduleId ? "schedulesListVersions" : null,
    {
      scheduleId: scheduleId ?? "",
    },
    {
      fallbackData: { schedule_versions: [] },
    },
  );

  if (scheduleError) {
    if (scheduleError.status === 404) {
      return <NotFoundPage />;
    }
    return <GenericErrorMessage error={scheduleError} />;
  }

  return (
    <PageWrapper
      width={PageWidth.Full}
      icon={IconEnum.Calendar}
      loading={scheduleIsLoading}
      overflowY={false}
      crumbs={[
        { title: "On-call", to: `/on-call` },
        { title: "Schedules", to: "/on-call/schedules" },
      ]}
      backHref="/on-call/schedules"
      title={scheduleResp?.schedule.name ?? ""}
      onEditTitle={() => setRenameModalOpen(true)}
      noPadding
      accessory={
        <div className={"flex flex-center gap-2"}>
          <ManagementMetaBadge
            management={scheduleResp?.management_meta}
            resourceName="schedule"
          />
          <DropdownMenu
            triggerButton={
              <Button
                theme={ButtonTheme.Secondary}
                type="button"
                analyticsTrackingId="schedule-dropdown-menu-click"
                icon={IconEnum.DotsVerticalNopad}
                title="Schedule dropdown menu options"
              />
            }
            align={"end"}
          >
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => setCalendarFeedModalOpen(true)}
              label="Sync to calendar"
              icon={IconEnum.CalendarPlus}
              disabled={!hasScope(ScopeNameEnum.SchedulesFeed)}
              tooltipContent={
                !hasScope(ScopeNameEnum.SchedulesFeed) &&
                "You do not have permission to sync calendars to your feed."
              }
            />
            {!versionsLoading && schedule_versions.length > 1 && (
              <DropdownMenuItem
                analyticsTrackingId={null}
                onSelect={() => setShowRestoreDrawer(true)}
                label="Restore previous versions"
                icon={IconEnum.ClockRotate}
                disabled={!hasScope(ScopeNameEnum.SchedulesUpdate)}
                tooltipContent={
                  !hasScope(ScopeNameEnum.SchedulesUpdate) &&
                  "You do not have permission to restore previous schedule versions."
                }
              />
            )}
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => navigate(`/catalog/Schedule/${scheduleId}`)}
              label="View in catalog"
              icon={IconEnum.Book}
            />
            {isDashboard(scheduleResp?.management_meta) && (
              <DropdownMenuItem
                analyticsTrackingId={null}
                icon={IconEnum.Terraform}
                onSelect={() => setShowTerraformDrawer(true)}
                label="Manage in Terraform"
              >
                Manage in Terraform
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              analyticsTrackingId={null}
              onSelect={() => setDeleteModalOpen(true)}
              icon={IconEnum.Delete}
              label="Delete"
              disabled={!hasScope(ScopeNameEnum.SchedulesDestroy)}
              tooltipContent={
                !hasScope(ScopeNameEnum.SchedulesDestroy) &&
                "You do not have permission to delete schedules."
              }
            >
              <span className="text-red-500">Delete</span>
            </DropdownMenuItem>
          </DropdownMenu>
          <GatedButton
            analyticsTrackingId={null}
            onClick={() => setShowScheduleDrawer(true)}
            theme={ButtonTheme.Secondary}
            disabled={!hasScope(ScopeNameEnum.SchedulesUpdate)}
            disabledTooltipContent={
              !hasScope(ScopeNameEnum.SchedulesUpdate) &&
              "You do not have permission to update schedules."
            }
          >
            Edit
          </GatedButton>
          <GatedButton
            analyticsTrackingId={null}
            onClick={() => {
              navigate(`/on-call/schedules/${scheduleId}/overrides/create`);
            }}
            theme={ButtonTheme.Primary}
            disabled={!hasScope(ScopeNameEnum.ScheduleOverridesCreate)}
            disabledTooltipContent={
              !hasScope(ScopeNameEnum.ScheduleOverridesCreate) &&
              "You do not have permission to create overrides."
            }
          >
            Create override
          </GatedButton>
        </div>
      }
      banner={
        <>
          {scheduleResp && (
            <HeaderBanner
              className={tcx(
                "flex flex-col gap-4 lg:flex-row lg:justify-between items-start lg:items-center lg:min-h-16 bg-surface-primary border-b border-stroke-secondary",
              )}
            >
              <div className="flex-center-y gap-2">
                <OnCallNow scheduleResponse={scheduleResp} />
              </div>

              <div className="flex-center-y gap-2">
                {commsPlatform === CommsPlatform.Slack && (
                  <SlackGroupBadge
                    slackGroup={slackUserGroupEntry?.aliases[0]}
                    setSyncToSlackModalOpen={setSyncToSlackModalOpen}
                  />
                )}
                <ScheduleEscalationPathBadge
                  escalationPathsForSchedule={escalationPathsForSchedule}
                  scheduleID={scheduleResp.schedule.id}
                />
              </div>
            </HeaderBanner>
          )}
        </>
      }
    >
      {/* This means any sub-routes (e.g. create/edit override) get a fully-animated drawer */}
      <AnimatePresence>{drawer}</AnimatePresence>

      {scheduleResp && <ScheduleOverviewV2 schedule={scheduleResp.schedule} />}
      {renameModalOpen && scheduleResp && (
        <UpdateNameModal
          schedule={scheduleResp.schedule}
          onClose={() => setRenameModalOpen(false)}
        />
      )}
      {calendarFeedModalOpen && scheduleResp && (
        <CalendarFeedModal
          onCancel={() => setCalendarFeedModalOpen(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      {syncToSlackModalOpen && scheduleResp && (
        <SyncToSlackModal
          onClose={() => setSyncToSlackModalOpen(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      {deleteModalOpen && scheduleResp && (
        <ConfirmDeleteScheduleModal
          onConfirm={() => navigate("/on-call/schedules")}
          onCancel={() => setDeleteModalOpen(false)}
          schedule={scheduleResp.schedule}
        />
      )}
      <AnimatePresence>
        {showScheduleDrawer && (
          <ScheduleCreateEditDrawer
            onClose={() => {
              setEditRotaId(undefined);
              setShowScheduleDrawer(false);
            }}
            onScheduleSaved={() => revalidateScheduleEntries()}
            editId={scheduleId}
            initialRotaId={editRotaId}
          />
        )}
      </AnimatePresence>
      {showRestoreDrawer && scheduleResp && (
        <ScheduleRestoreDrawer
          schedule={scheduleResp.schedule}
          isOpen={showRestoreDrawer}
          onClose={() => setShowRestoreDrawer(false)}
        />
      )}
      {showTerraformDrawer && scheduleResp && (
        <ScheduleConstantToTerraformDrawer
          isOpen={showTerraformDrawer}
          onClose={() => setShowTerraformDrawer(false)}
          resourceID={scheduleId}
          schedule={scheduleResp.schedule}
          managementMeta={scheduleResp.management_meta}
        />
      )}
    </PageWrapper>
  );
};

const OnCallNow = ({
  scheduleResponse,
}: {
  scheduleResponse: SchedulesShowResponseBody;
}) => {
  const shiftsByUser = _.chain(scheduleResponse.schedule.current_shifts ?? [])
    .groupBy("external_user_id")
    .map((shifts, userId) => ({
      user: scheduleResponse.users.find((u) => u.id === userId),
      shifts,
    }))
    .value();

  return (
    <div className={"flex flex-row flex-wrap items-center gap-3"}>
      <span className={"text-sm text-slate-600"}>On call now:</span>
      {shiftsByUser.length > 0 &&
      shiftsByUser.filter((userShift) => userShift.user !== undefined).length >
        0 ? (
        shiftsByUser.map(({ user, shifts }) => {
          if (!user) {
            return null;
          }

          return (
            <ScheduleEntryComponent
              key={user.id}
              user={user}
              enableTooltip
              enableCreateOverride
              entries={shifts}
              timezone={scheduleResponse.schedule.timezone}
            />
          );
        })
      ) : (
        <ScheduleEntryComponent
          userId={"NOBODY"}
          enableCreateOverride
          enableTooltip
          entries={[]}
          timezone={scheduleResponse.schedule.timezone}
        />
      )}
    </div>
  );
};

const SlackGroupBadge = ({
  slackGroup,
  setSyncToSlackModalOpen,
}: {
  slackGroup?: string;
  setSyncToSlackModalOpen: (open: boolean) => void;
}) => {
  return (
    <div
      className={
        "flex flex-row items-center gap-1 hover:cursor-pointer align-middle hover:bg-surface-tertiary rounded-[6px] p-1 px-1.5"
      }
      onClick={() => setSyncToSlackModalOpen(true)}
    >
      {slackGroup ? (
        <Icon id={IconEnum.Slack} size={IconSize.Small} />
      ) : (
        <Icon id={IconEnum.SlackGreyscale} className="text-content-tertiary" />
      )}
      {slackGroup ? (
        <Txt>
          <span className={"text-sm monospace whitespace-nowrap"}>
            @{slackGroup}
          </span>
        </Txt>
      ) : (
        <Txt lightGrey>
          <span className={"text-sm whitespace-nowrap"}>
            Connect Slack group
          </span>
        </Txt>
      )}
    </div>
  );
};

export const ScheduleEscalationPathBadge = ({
  escalationPathsForSchedule,
  scheduleID,
}: {
  escalationPathsForSchedule: EscalationPath[];
  scheduleID: string;
}) => {
  const navigate = useOrgAwareNavigate();

  return escalationPathsForSchedule?.length === 1 ? (
    <OrgAwareLink
      to={`/on-call/escalation-paths/${escalationPathsForSchedule[0].id}`}
      className="align-middle items-center"
    >
      <div
        className={tcx(
          "flex flex-row items-center gap-1 hover:cursor-pointer align-middle hover:bg-surface-tertiary rounded-[6px] p-1 px-1.5",
        )}
      >
        <Icon
          id={IconEnum.EscalationPath}
          size={IconSize.Medium}
          className="stroke-brand text-brand"
        />
        <span className={"text-sm whitespace-nowrap"}>
          {escalationPathsForSchedule?.[0].name}
        </span>
      </div>
    </OrgAwareLink>
  ) : escalationPathsForSchedule?.length > 1 ? (
    <div className={"flex flex-row items-center gap-1"}>
      <DropdownMenu
        scroll
        align={"end"}
        triggerButton={
          <div
            className={tcx(
              "flex flex-row items-center gap-1 hover:cursor-pointer align-middle hover:bg-surface-tertiary rounded-[6px] p-1 px-1.5",
            )}
          >
            <Button
              theme={ButtonTheme.Unstyled}
              analyticsTrackingId={null}
              className="flex flex-row items-center gap-1"
              icon={IconEnum.EscalationPath}
              iconProps={{
                size: IconSize.Medium,
                className: "stroke-brand text-brand",
              }}
            >
              <span className={"text-sm whitespace-nowrap"}>
                {escalationPathsForSchedule?.length} escalation paths
              </span>
            </Button>
          </div>
        }
      >
        {escalationPathsForSchedule.map((ep) => (
          <DropdownMenuItem
            key={ep.id}
            label={ep.name}
            onSelect={() => {
              navigate(`/on-call/escalation-paths/${ep.id}`);
            }}
            analyticsTrackingId={null}
          >
            <span className="whitespace-nowrap">{ep.name}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </div>
  ) : (
    <OrgAwareLink
      to={`/on-call/escalation-paths/create?schedule_id=${scheduleID}`}
      className="align-middle"
    >
      <div
        className={tcx(
          "flex flex-row items-center gap-1 hover:cursor-pointer hover:bg-surface-tertiary rounded-[6px] p-1 px-1.5",
        )}
      >
        <Icon
          id={IconEnum.EscalationPath}
          size={IconSize.Medium}
          className="text-content-tertiary"
        />
        <Txt lightGrey className="whitespace-nowrap">
          <span className={"text-sm"}>No escalation path</span>
        </Txt>
      </div>
    </OrgAwareLink>
  );
};

export function UpdateNameModal({
  schedule,
  onClose,
}: {
  schedule: Schedule;
  onClose: () => void;
}): React.ReactElement {
  const { hasScope } = useIdentity();

  const canEdit = hasScope(ScopeNameEnum.SchedulesUpdate);

  const formMethods = useForm<SchedulesUpdateNameRequestBody>({
    defaultValues: {
      name: schedule.name,
    },
  });

  const refreshSchedulesList = useRevalidate(["schedulesList"]);
  const refreshSchedulesShowResponseBody = useRevalidate(["schedulesShow"]);

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "schedulesShow",
    { id: schedule.id },
    async (apiClient, args: SchedulesUpdateNameRequestBody) => {
      await apiClient.schedulesUpdateName({
        id: schedule.id,
        updateNameRequestBody: {
          ...args,
        },
      });
    },
    {
      setError: formMethods.setError,
      onSuccess: () => {
        refreshSchedulesList();
        refreshSchedulesShowResponseBody();
        onClose();
      },
    },
  );

  return (
    <Form.Modal
      onClose={onClose}
      title="Rename schedule"
      analyticsTrackingId="rename-schedule"
      formMethods={formMethods}
      onSubmit={onSubmit}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
          disabled={!canEdit}
          disabledTooltipContent={"You do not have permission to do this."}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </Form.Modal>
  );
}

export const ConfirmDeleteScheduleModal = ({
  onConfirm,
  onCancel,
  schedule,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  schedule: Schedule;
}) => {
  const {
    trigger: onSubmit,
    isMutating: isDestroying,
    genericError: destroyError,
  } = useAPIMutation(
    "schedulesList",
    undefined,
    async (apiClient, { id }: { id: string }) => {
      await apiClient.schedulesDestroy({
        id,
      });
    },
    {
      onSuccess: onConfirm,
    },
  );

  const onDelete = () => {
    onSubmit({ id: schedule.id });
  };

  if (destroyError) {
    return <GenericErrorMessage />;
  }

  return (
    <DeletionConfirmationModal
      resourceTitle={schedule.name}
      onDelete={onDelete}
      isDeleting={isDestroying}
      isOpen={true}
      onClose={onCancel}
      title="Delete schedule"
      deleteConfirmationContent={
        "Are you sure you want to delete this schedule?"
      }
      analyticsTrackingId="delete-schedule"
      fetchDependentResources={[
        {
          resource_type: `CatalogEntry["Schedule"]`,
          id: schedule.id,
        },
      ]}
    />
  );
};
