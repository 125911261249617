import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemIncidentRename = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_rename) {
    throw new Error(
      "malformed timeline item: incident_rename was missing incident_rename field",
    );
  }

  return {
    icon: IconEnum.Announcement,
    avatarUrl: item.incident_rename.actor?.user?.avatar_url,
    description: (
      <>
        <TimelineItemSource actor={item.incident_rename.actor} /> renamed the
        incident
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <strong className="break-words">{item.incident_rename.to}</strong>
      </TimelineItemContentBox>
    ),
  };
};
