import {
  AlertAttribute,
  ExpressionOperationOperationTypeEnum,
  Reference,
} from "@incident-io/api";
import { ReferenceExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { v4 as uuidv4 } from "uuid";

export function createDefaultParseExpression(
  existingAttribute: AlertAttribute | undefined,
  payload:
    | (Reference & {
        example: ReferenceExample | undefined | null;
      })
    | undefined,
  initialPath: string | undefined,
) {
  // We'll default to string if we have no return type, otherwise evaluating is very unhappy.
  const defaultReturnType = {
    type: existingAttribute?.type || "String",
    array: existingAttribute?.array,
  };
  return {
    label: existingAttribute?.name,
    root_reference: payload?.key,
    reference: uuidv4().split("-")[0],
    operations: [
      {
        returns: defaultReturnType,
        operation_type: ExpressionOperationOperationTypeEnum.Parse,
        parse: {
          source: initialPath || "$",
          returns: defaultReturnType,
        },
      },
    ],
  };
}
