import {
  CoreDashboard,
  CoreDashboardFilterContextsEnum,
  EligibilityInformation,
} from "@incident-io/api";
import {
  AppliedFiltersBanner,
  AvailableFilter,
  enrichAvailableFilterFields,
  FilterPopover,
  useInitialiseInsightsFilters,
} from "@incident-shared/filters";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  FullPageEmptyState,
  GenericErrorMessage,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useIdentity } from "src/contexts/IdentityContext";
import { useQueryParams } from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { InsightsDatePicker } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import graphic from "./empty-state-dashboard-graphic.svg";
import { InsightsShowDashboardProvider } from "./InsightsShowDashboardContext";

export const InsightsCoreDashboardRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { insightsV3 } = useFlags();

  const { id } = useParams<{ id: string }>() as { id: string };
  const urlParams = useQueryParams();

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCoreDashboard", {
    id,
  });

  const {
    insightsV3FilterFields,
    loading: filtersLoading,
    error: filtersError,
  } = useInitialiseInsightsFilters(urlParams.toString());

  if (!insightsV3) {
    return <NotFoundPage />;
  }

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights-v3" />;
  }

  const error = filtersError || dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const loading = dashboardLoading || filtersLoading;
  if (loading || !dashboardData) {
    return <FullPageLoader />;
  }

  return (
    <InsightsCoreDashboardPage
      dashboard={dashboardData.dashboard}
      filterFields={insightsV3FilterFields}
    />
  );
};

const InsightsCoreDashboardPage = ({
  dashboard,
  filterFields,
}: {
  dashboard: CoreDashboard;
  filterFields: Record<CoreDashboardFilterContextsEnum, AvailableFilter[]>;
}) => {
  // Currently, all dashboards only have a single filter context, so we can just take the first one.
  const enrichedFields = enrichAvailableFilterFields(
    filterFields[dashboard.filter_contexts[0]],
  );

  return (
    <InsightsShowDashboardProvider
      filterFields={enrichedFields}
      dashboard={dashboard}
    >
      <PageWrapper
        width={PageWidth.Wide}
        icon={dashboard.icon}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        className="bg-surface-secondary"
        crumbs={[
          {
            title: "Insights",
            to: "/insights-v3",
          },
        ]}
        backHref="/insights-v3"
        noPadding
        accessory={
          <div className="flex items-center gap-2">
            <InsightsDatePicker />
            <FilterPopover
              renderTriggerButton={({ onClick }) => (
                <Button
                  onClick={() => onClick()}
                  analyticsTrackingId={"insights-core-dashboard-filter"}
                  icon={IconEnum.Filter}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                >
                  Add filter
                </Button>
              )}
            />
          </div>
        }
        banner={
          <AppliedFiltersBanner
            totalNumberOfItems={null}
            itemsLabel={null}
            style="wholeOfPage"
            badgeTheme="slate"
            className="bg-white border-b"
          />
        }
      >
        {dashboard.eligibility.is_eligible ? (
          <div className="flex flex-col grow gap-6 p-6">
            {dashboard.panels.map((panel, idx) => (
              <InsightsPanel key={idx} panelIdx={idx} panel={panel} editable />
            ))}
          </div>
        ) : (
          <InsightsDashboardEmptyState eligibility={dashboard.eligibility} />
        )}
      </PageWrapper>
    </InsightsShowDashboardProvider>
  );
};

const InsightsDashboardEmptyState = ({
  eligibility,
}: {
  eligibility: EligibilityInformation;
}) => {
  return (
    <div className="flex flex-col grow p-6 bg-white">
      <FullPageEmptyState
        graphic={<img src={graphic} className="my-6" />}
        title={eligibility.ineligible_explanation_title || ""}
        subtitle={eligibility.ineligible_explanation_text}
        gradientClasses={"from-[#F7F7F8] to-[#FFFFFF]"}
        cards={<></>}
        cta={
          eligibility.ineligible_call_to_action_path ? (
            <Button
              href={eligibility.ineligible_call_to_action_path}
              analyticsTrackingId="insights-empty-state-cta"
              theme={ButtonTheme.Primary}
            >
              {eligibility.ineligible_call_to_action_text}
            </Button>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};
