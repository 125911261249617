import {
  ExternalIssueReference,
  Incident,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  Stream,
} from "@incident-io/api";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  IssueTemplateContextEnum,
  useAllIssueTemplates,
} from "@incident-shared/issue-trackers";
import {
  ProviderIconLookup,
  SyncFailureTooltip,
} from "@incident-shared/issue-trackers/SyncFailureTooltip";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Heading,
  Icon,
  IconEnum,
  IconSize,
  OrgAwareLink,
  Tooltip,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIsMSTeamsTabFriendlyView } from "src/contexts/MSTeamsTabContext";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../hooks";
import { ExternalLink } from "./ExternalLink";
import { StatusPageLink, StatusPageLinkProps } from "./StatusPageLinks";

const MissingCommsPlatformWarning = ({
  commsPlatform,
}: {
  commsPlatform: string;
}) => {
  return (
    <div className="inline-flex items-center">
      <Icon id={IconEnum.Warning} className="mr-2 text-alarmalade-content" />
      <span className="text-content-tertiary">No {commsPlatform} channel</span>
    </div>
  );
};

const TeamsLink = ({
  url,
  channelDeleted,
}: {
  url: string | undefined;
  channelDeleted: boolean;
}) => {
  const isInMSTeamsTab = useIsMSTeamsTabFriendlyView();

  if (!channelDeleted) {
    return url ? (
      <ExternalLink
        analyticsTrackingId={"incident-sidebar-teams"}
        href={isInMSTeamsTab ? url.replace("https://", "msteams://") : url}
        label={"View Microsoft Teams channel"}
        icon={IconEnum.MicrosoftTeams}
      />
    ) : (
      <MissingCommsPlatformWarning commsPlatform="Microsoft Teams" />
    );
  }

  return (
    <div className="flex">
      <Icon
        id={IconEnum.MicrosoftTeamsGreyscale}
        className="mr-1 text-content-tertiary"
      />
      <p className="text-content-tertiary">Teams channel deleted</p>
      <Tooltip
        buttonClassName="ml-1"
        content="The Microsoft Teams channel for this incident has been deleted. You may still be able to restore it from within Microsoft Teams."
      />
    </div>
  );
};

const SlackLink = ({ incident }: { incident: Incident | Stream }) => {
  const { identity } = useIdentity();

  const { data: slackTeamConfigData, isLoading } = useAPI(
    incident.slack_team_id ? "slackTeamConfigsGetBySlackTeamID" : null,
    {
      slackTeamId: incident.slack_team_id ?? "",
    },
  );
  const slackTeamName = slackTeamConfigData?.slack_team_config?.name ?? "";

  // User can access Slack channel if no team ids set (not Grid install)
  // or if the incident's slack team id is in the user's slack team ids
  const slackTeamIDs = identity.slack_info?.team_ids || [];
  const userCanAccessSlackChannel =
    slackTeamIDs.length === 0 || slackTeamIDs.includes(incident.slack_team_id);

  if (isLoading) return <LoadingBar />;

  return incident.slack_channel_url ? (
    <>
      {userCanAccessSlackChannel ? (
        <ExternalLink
          analyticsTrackingId={"incident-sidebar-slack"}
          href={incident.slack_channel_url}
          label={"View Slack channel"}
          icon={IconEnum.Slack}
          iconProps={{ size: IconSize.Small }}
        />
      ) : (
        <div className="flex items-center">
          <Icon id={IconEnum.Slack} className={"opacity-50"} />
          <Tooltip
            content={`You do not have access to this incident's Slack channel as you are not a member of the ${slackTeamName}Slack workspace.`}
          >
            <span className="text-content-tertiary">View Slack channel</span>
          </Tooltip>
        </div>
      )}
    </>
  ) : (
    <MissingCommsPlatformWarning commsPlatform="Slack" />
  );
};

export const ExternalLinksSection = ({
  incidentId,
  statusPageLink,
}: {
  incidentId: string | null;
  statusPageLink: StatusPageLinkProps | null;
}) => {
  const { incident } = useIncident(incidentId);
  const { identity } = useIdentity();

  const { data: incidentSyncFailures } = useAPI(
    incidentId ? "issueTrackersGetIncidentIssueTrackerIssueSyncFailures" : null,
    {
      incidentId: incidentId ?? "",
    },
  );
  const syncFailures = incidentSyncFailures?.sync_failures
    ? incidentSyncFailures.sync_failures
    : [];

  if (incident == null) {
    return <LoadingBar />;
  }

  const { external_issue_reference } = incident;

  const commsPlatform = identity.slack_info
    ? IntegrationProvider.Slack
    : IntegrationProvider.MicrosoftTeams;

  return (
    <div className="flex flex-col space-y-3 py-2">
      {commsPlatform === IntegrationProvider.Slack ? (
        <SlackLink incident={incident} />
      ) : (
        <TeamsLink
          url={incident.ms_teams_channel_url}
          channelDeleted={incident.chat_channel_archived_or_deleted || false}
        />
      )}

      {/* Show the incident ticket. */}
      <ExternalIssueReferenceComponent
        externalIssue={external_issue_reference}
      />
      {/* Show the most recent Jira sync failure error message. */}
      {!external_issue_reference && syncFailures.length > 0 && (
        <ExternalLink
          to="/settings/integrations/jira"
          className={
            syncFailures[0].is_transient
              ? "decoration-amber-500"
              : "decoration-red-500"
          }
          analyticsTrackingId="recent-jira-sync-failure"
          label={
            <SyncFailureTooltip syncFailures={syncFailures}>
              <div
                className={
                  syncFailures[0].is_transient
                    ? "text-amber-500"
                    : "text-red-500"
                }
              >
                Failed to create incident issue
                <Icon
                  id={IconEnum.Warning}
                  className="inline pl-0.5 pb-0.5"
                  size={IconSize.Medium}
                />
              </div>
            </SyncFailureTooltip>
          }
          icon={ProviderIconLookup[syncFailures[0].provider]}
        />
      )}

      {statusPageLink && (
        <StatusPageLink
          key={statusPageLink.id}
          {...statusPageLink}
          title={
            statusPageLink.type === "internal"
              ? "View internal status page"
              : "View status page"
          }
        />
      )}
    </div>
  );
};

type ExternalIssueReferenceComponentProps = {
  externalIssue: ExternalIssueReference | undefined;
};

const ExternalIssueReferenceComponent = ({
  externalIssue,
}: ExternalIssueReferenceComponentProps) => {
  const { allTemplates } = useAllIssueTemplates(
    IssueTemplateContextEnum.IncidentTicket,
  );
  const issueTemplate = allTemplates.find(
    (template) => template.id === externalIssue?.issue_template_id,
  );

  if (!externalIssue) {
    return null;
  }

  const tooltipContent = issueTemplate ? (
    <OrgAwareLink
      analyticsTrackingId={"incident-sidebar-jira-issue-template"}
      to={"/settings/incident-tickets"}
    >
      <p>
        Created from the template &apos;{issueTemplate.name}
        &apos;
      </p>
    </OrgAwareLink>
  ) : null;

  const config = IntegrationConfigFor(externalIssue.provider);

  return (
    <div className="flex gap-1 items-center">
      <ExternalLink
        analyticsTrackingId={config.label}
        href={externalIssue.issue_permalink}
        label={`View ${config.label} incident`}
        icon={config.icon}
        iconProps={{ size: IconSize.Small }}
      />
      <Tooltip content={tooltipContent}>
        <Badge
          theme={BadgeTheme.Tertiary}
          size={BadgeSize.Small}
          className="align-middle"
        >
          <code>{externalIssue.issue_name}</code>
        </Badge>
      </Tooltip>
    </div>
  );
};

export const StreamExternalLinksSection = ({ stream }: { stream: Stream }) => {
  return (
    <div className="space-y-2 pb-1">
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} size="small">
          Links
        </Heading>
      </div>
      <SlackLink incident={stream} />
    </div>
  );
};
