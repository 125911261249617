import { SavedViewsListContextEnum } from "@incident-io/api";
import {
  AvailableFilter,
  FiltersContextKind,
  FiltersContextProvider,
  useInitialiseInsightsFilters,
} from "@incident-shared/filters";
import {
  isValidForEnum,
  useStatefulQueryParamFilters,
} from "@incident-shared/filters/useStatefulQueryParamFilters";
import {
  DateRangePickerMode,
  DateRangePickerState,
  dateRangePickerStateFromString,
  dateRangePickerStateToString,
  isValidDateRangePickerState,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";
import { Loader } from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useParams } from "react-router";
import {
  SavedViewsContext,
  SavedViewsProvider,
} from "src/components/saved-views/SavedViewContext";
import { useQueryParams } from "src/utils/query-params";

import { InsightsContext } from "./InsightsContext";
import { DateAggregation, FieldKey, StateConfig } from "./types";
import { UnsavedViewChangesPrompt } from "./UnsavedViewChangesPrompt";

export const InsightsContextProvider = <SC extends StateConfig>({
  stateConfig,
  children,
  filtersKind = "incident",
}: {
  stateConfig: SC;
  children: React.ReactElement;
  filtersKind?: FiltersContextKind;
}) => {
  const urlParams = useQueryParams();
  const {
    availableIncidentFilterFields,
    availableUserFilterFields,
    availableEscalationTargetFilterFields,
    loading,
    refetchFilters,
  } = useInitialiseInsightsFilters(urlParams.toString());

  const { slug } = useParams();

  const filterFields =
    filtersKind === "user"
      ? availableUserFilterFields
      : filtersKind === "escalation_targets"
      ? availableEscalationTargetFilterFields
      : availableIncidentFilterFields;

  if (loading) {
    return <Loader />;
  }

  return (
    <SavedViewsProvider
      context={SavedViewsListContextEnum.InsightsV2}
      subpathRedirect={{
        // TODO: lets have this live closer to InsightsV2Route
        param: "dashboard",
        mapping: {
          "pager-load": `/${slug}/insights/pager-load`,
          "external-pager-load": `/${slug}/insights/external-pager-load`,
          overview: `/${slug}/insights/overview`,
          "post-incident-flow": `/${slug}/insights/post-incident-flow`,
          "follow-ups": `/${slug}/insights/follow-ups`,
          "time-spent-on-incidents": `/${slug}/insights/time-spent-on-incidents`,
          mttx: `/${slug}/insights/mttx`,
        },
      }}
    >
      <SavedViewsContext.Consumer>
        {({ savedViews }) =>
          !savedViews.isLoading ? (
            <InsightsContextProviderInner
              stateConfig={stateConfig}
              availableFilterFields={filterFields}
              refetchFilters={refetchFilters}
              filtersKind={filtersKind}
            >
              {children}
            </InsightsContextProviderInner>
          ) : (
            <Loader />
          )
        }
      </SavedViewsContext.Consumer>
    </SavedViewsProvider>
  );
};

enum InsightsParam {
  DateRange = "date_range",
  DateAggregation = "date_aggregation",
  ComparePreviousPeriod = "compare_previous_period",
}

const InsightsContextProviderInner = <SC extends StateConfig>({
  stateConfig,
  availableFilterFields,
  refetchFilters,
  children,
  filtersKind,
}: {
  stateConfig: SC;
  availableFilterFields: AvailableFilter[];
  refetchFilters: () => Promise<void>;
  children: React.ReactElement;
  filtersKind: FiltersContextKind;
}) => {
  const {
    getSelectedFilters,
    setSelectedFilters,
    useQueryParam,
    useQueryParamArray,
  } = useStatefulQueryParamFilters<
    {
      [InsightsParam.DateRange]: string;
      [InsightsParam.DateAggregation]: DateAggregation;
      [InsightsParam.ComparePreviousPeriod]: string;
    } & {
      [key in FieldKey]: string;
    }
  >({
    availableFilterFields,
    availableParams: [
      "dashboard",
      ...Object.values(InsightsParam),
      ...Object.keys(stateConfig),
    ],
  });

  const [dateRangeStr, setDateRangeStr] = useQueryParam({
    param: InsightsParam.DateRange,
    isValid: (val: string | null) =>
      val != null &&
      isValidDateRangePickerState(dateRangePickerStateFromString(val)),
    defaultValue: dateRangePickerStateToString(
      DEFAULT_INSIGHTS_DATE_RANGE_PICKER_STATE,
    ),
  });

  const dateRange = dateRangePickerStateFromString(dateRangeStr);
  const setDateRange = (newValue: DateRangePickerState) =>
    setDateRangeStr(dateRangePickerStateToString(newValue));

  const [comparePreviousStr, setComparePreviousStr] = useQueryParam({
    param: InsightsParam.ComparePreviousPeriod,
    isValid: (val: string | null) => val === "true",
    defaultValue: "",
  });

  const comparePreviousPeriod = comparePreviousStr === "true";
  const setComparePreviousPeriod = (newValue: boolean) =>
    setComparePreviousStr(newValue ? "true" : "");

  const [dateAggregation, setDateAggregation] = useQueryParam({
    param: InsightsParam.DateAggregation,
    isValid: isValidForEnum(Object.values(DateAggregation)),
    defaultValue: DateAggregation.Month,
  });

  const getOptions = <K extends FieldKey>(key: K) => {
    const config = stateConfig[key];
    if (config.isGetOptions) {
      return config.getOptions(useState);
    }
    return config.options;
  };

  // this is to add support for grouped options
  const getFlatOptions = <K extends FieldKey>(key: K) => {
    const config = stateConfig[key];
    if (config.isGroupedOptions) {
      return config.options.flatMap((group) => group.options);
    } else {
      return getOptions(key);
    }
  };

  const getDefaultFunc = (
    key: FieldKey,
  ): ((opts: SelectOption[]) => string) => {
    const config = stateConfig[key];

    const fallback = (opts: SelectOption[]) => opts?.[0]?.value;

    // this only matters for non-array fields
    if (config.isArray) {
      return fallback;
    }

    if ("default" in config && config.default) {
      return config.default;
    }

    return fallback;
  };

  const useState = <K extends FieldKey>(key: K) => {
    const config = stateConfig[key];
    const options = getFlatOptions(key);
    const defaultFunc = getDefaultFunc(key);

    const queryParamArrayState = useQueryParamArray({
      key,
      isValid: (newID) => options.map(({ value }) => value).includes(newID),
    });

    const queryParamState = useQueryParam({
      param: key,
      isValid: (value) =>
        !!value && options.map((field) => field.value).includes(value),
      defaultValue: defaultFunc(options),
    });

    if (config.isArray) {
      return queryParamArrayState;
    } else {
      return queryParamState;
    }
  };

  // We want to only alow one user filter to be selected at a time.
  // Usually the filters popover handles this based on the key and field ID
  // of the filter. This doesn't work for us though, as those need
  // to be different for our filters to work. This is a bit hacky, but
  // sets the available fields to only the selected field once a user
  // filter has been selected, so you can't select any others.
  let filterFields = availableFilterFields;
  const selectedFilters = getSelectedFilters();
  if (filtersKind === "user" && selectedFilters.length > 0) {
    filterFields = filterFields.filter((field) =>
      selectedFilters.map((f) => f.filter_id).includes(field.filter_id),
    );
  }

  return (
    <InsightsContext.Provider
      value={{
        dateAggregation,
        setDateAggregation,
        dateRange,
        setDateRange,
        refetchFilters,
        useState,
        getOptions,
        getFlatOptions,
        comparePreviousPeriod,
        setComparePreviousPeriod,
      }}
    >
      <FiltersContextProvider
        filters={selectedFilters}
        setFilters={setSelectedFilters}
        availableFilterFields={filterFields}
        kind={filtersKind}
      >
        {/* TODO StateViewStatePreviewContext.Provider, this should be defined by the caller */}
        {children}
        <UnsavedViewChangesPrompt />
      </FiltersContextProvider>
    </InsightsContext.Provider>
  );
};
export const DEFAULT_INSIGHTS_DATE_RANGE_PICKER_STATE: DateRangePickerState = {
  mode: DateRangePickerMode.QuickSelect,
  quick_select: QuickSelectInterval.Last6Months,
};
