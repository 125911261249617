import { AIConfigEnabledFeaturesEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  IconSize,
  Link,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { AssistantBannerV2 } from "src/components/insights/assistant/AssistantBannerV2";
import { LevelUpIncidentResponseBanner } from "src/components/settings/LevelUpIncidentResponseBanner";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import InsightsGatedVisual from "../../insights-empty-state.png";
import { InsightsHeader } from "../common/InsightsHeader";
import { InsightsTrendsModal } from "../common/InsightsTrendsModal";
import { InsightsHomepageTrendsTiles } from "../common/TrendsTiles";
import { InsightsContextProvider } from "../context/InsightsContextProvider";
import { StateConfig } from "../context/types";
import { InsightsListRow, useDashboardGroups } from "./dashboards";

export type OverviewStateConfig = StateConfig;

const INSIGHTS_TRENDS_CTA = "insights-trends";

const ProductAvailableDashboard = ({
  requiredProduct,
  children,
}: {
  requiredProduct?: Product;
  children: React.ReactNode;
}) => {
  const { hasProduct } = useProductAccess();

  if (!requiredProduct) {
    return <>{children}</>;
  }

  if (hasProduct(requiredProduct)) {
    return <>{children}</>;
  }

  return null;
};

export const InsightsListPage = () => {
  const { insightsV3 } = useFlags();
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;
  const canUseAI = useAIFeatureForOrg();
  const DashboardGroups = useDashboardGroups();
  const { hasResponse } = useProductAccess();

  const { hasDismissedCTA } = useIdentity();

  const [showModal, setShowModal] = useState(
    !hasDismissedCTA(INSIGHTS_TRENDS_CTA) && insightsFeatureGate,
  );

  const { trigger: dismissBanner, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: INSIGHTS_TRENDS_CTA,
          for_whole_organisation: false,
        },
      });
    },
  );
  const closeAndDismissModal = () => {
    setShowModal(false);
    dismissBanner({});
  };

  return (
    <InsightsContextProvider<StateConfig> stateConfig={{}}>
      <PageWrapper
        width={PageWidth.Medium}
        headerNode={
          insightsFeatureGate ? (
            <InsightsHeader
              title={"Insights"}
              disableFilters={true}
              hideFilters={true}
              icon={IconEnum.Chart}
            />
          ) : null
        }
        title="Insights"
        icon={IconEnum.Chart}
      >
        {insightsV3 && (
          <Callout
            theme={CalloutTheme.Plain}
            title="We're building V3 insights (incident.io staff only)"
            showIcon={false}
            subtitle="We're working on a new version of insights that will provide more powerful features and a better user experience."
            cta={
              <Button href="/insights-v3" analyticsTrackingId={null}>
                Check it out
              </Button>
            }
            className="mb-6 text-white bg-pink-600"
          />
        )}
        {!insightsFeatureGate ? (
          <InsightsLandingPage />
        ) : (
          <div className="flex flex-col h-full gap-4">
            <InsightsHomepageTrendsTiles />
            {canUseAI(AIConfigEnabledFeaturesEnum.Assistant) && (
              <AssistantBannerV2 />
            )}
            <div className="space-y-4 pb-4">
              {DashboardGroups.map((group) => (
                <ProductAvailableDashboard
                  key={group.title}
                  requiredProduct={group.requiredProduct}
                >
                  <DashboardSection
                    key={group.title}
                    title={group.title}
                    icon={group.icon}
                    iconColour={group.homepageIconColour}
                    dashboards={group.dashboards}
                  />
                </ProductAvailableDashboard>
              ))}
              {!hasResponse ? <LevelUpIncidentResponseBanner /> : null}
            </div>
          </div>
        )}
        <AnimatePresence>
          {showModal && (
            <InsightsTrendsModal
              onClose={closeAndDismissModal}
              loading={dismissing}
            />
          )}
        </AnimatePresence>
      </PageWrapper>
    </InsightsContextProvider>
  );
};

const InsightsLandingPage = (): React.ReactElement => (
  <div className="flex flex-grow flex-col h-full justify-center items-center">
    <img className="w-[646px] mb-10" src={InsightsGatedVisual} />
    <div className="w-96 flex flex-col gap-2 justify-center items-center mb-6">
      <PlanBadge planName="Pro" />
      <p className="font-semibold text-base">Dig deeper into your incidents</p>
      <p className="font-normal text-sm text-center">
        Identify incident trends and recurring pain points with Insights. See
        what types of incidents are occurring across your organization and
        improve how you respond to them.
      </p>
    </div>
    <div className="flex gap-2">
      <Button
        analyticsTrackingId="insights-upgrade"
        theme={ButtonTheme.Primary}
        href={"/settings/billing"}
      >
        Upgrade
      </Button>
      <Button
        analyticsTrackingId="insights-learn-more"
        theme={ButtonTheme.Secondary}
        href="https://incident.io/changelog/insights-updates"
      >
        Learn more
      </Button>
    </div>
  </div>
);

type DashboardSectionProps = {
  title: string;
  icon: IconEnum;
  iconColour: string;
  dashboards: { name: string; to: string; description: string }[];
};

const DashboardSection = ({
  title,
  icon,
  iconColour,
  dashboards,
}: DashboardSectionProps) => {
  return (
    <div className="bg-surface-secondary p-4 rounded-xl">
      <div className="flex items-center">
        <Icon
          id={icon}
          className={tcx(
            "!p-1 w-7 h-7 rounded border-radius text-white",
            iconColour,
          )}
        />

        <p className="ml-2 text-base font-semibold">{title}</p>
      </div>
      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        {dashboards.map(({ name, to, description }) => (
          <Link
            noUnderline
            noHoverColor
            analyticsTrackingId={`insights-core-dashboard-${name}`}
            to={to}
            key={name}
            className="w-full px-4 py-3 rounded-2 bg-white shadow-sm border border-stroke hover:shadow hover:border-stroke transition-colors"
          >
            <h3 className="text-sm font-semibold mb-0">{name}</h3>
            <p className="text-sm text-slate-600 mt-1">{description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export const RecommendedInsightsCard = ({
  item,
}: {
  item: InsightsListRow;
}): React.ReactElement => {
  return (
    <div className="flex flex-col border shadow-sm rounded-2 border-radius border-stroke hover:border-slate-500 hover:shadow-md transition bg-white min-w-64 w-full">
      <Link
        openInNewTab={false}
        className={"no-underline p-4"}
        analyticsTrackingId={`insights-core-dashboard-${item.name}`}
        to={`/insights/${item.slug}`}
      >
        <div className="space-y-1.5 text-content-primary">
          {item.icon && (
            <Icon
              id={item.icon.icon}
              size={IconSize.XL}
              className={tcx(
                "mb-8 !p-1 rounded-[6px] border-radius",
                item.icon.iconColour,
                item.icon.bgColour,
              )}
            />
          )}
          <p className="text-sm font-medium">{item.name}</p>
          <div>
            <p className="text-sm font-normal">{item.description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};
