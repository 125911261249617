import { IconEnum } from "@incident-ui";
import {
  Attachment,
  AttachmentContexts,
} from "src/components/legacy/incident/attachments/IncidentAttachment";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemIncidentAttachmentAdded = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_attachment_added) {
    throw new Error(
      "malformed timeline item: incident_attachment_added was missing incident_attachment_added field",
    );
  }

  const creator = item.incident_attachment_added.creator;
  return {
    icon: IconEnum.Attachment,
    avatarUrl: creator?.user?.avatar_url,
    description: (
      <>
        <TimelineItemSource actor={creator} /> attached a{" "}
        {item.incident_attachment_added.external_resource.resource_type_label}
        {/*  If an external resource attached this, then append a `from a PagerDuty Incident" */}
        {creator?.external_resource ? (
          <span>
            {" "}
            from a{" "}
            <a
              href={creator.external_resource.permalink}
              target="_blank"
              rel="noreferrer"
            >
              {creator.external_resource.resource_type_label}
            </a>
          </span>
        ) : (
          ""
        )}
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Attachment
          context={AttachmentContexts.Timeline}
          resource={item.incident_attachment_added.external_resource}
        />
      </TimelineItemContentBox>
    ),
  };
};
