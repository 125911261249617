import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Markdown } from "@incident-ui";
import { startCase } from "lodash";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import {
  ActionStatusChangeNextStatusEnum,
  ActivityLogEntry,
  Incident,
} from "src/contexts/ClientContext";

import { ActivityBadge } from "./ActivityBadge";

export const ActivityItemActionUpdated = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.action_updated) {
    throw new Error(
      "malformed timeline item: action_updated was missing action_updated field",
    );
  }

  const update = item.content.action_updated;

  return {
    title: "Action updated",
    icon: IconEnum.Action,
    colour: ColorPaletteEnum.Purple,
    actor: update.updater,
    quotedContent: update.description_change && (
      <>
        <Markdown>{update.description_change.next_description}</Markdown>
        <div className="line-through">
          <Markdown>{update.description_change.previous_description}</Markdown>
        </div>
      </>
    ),
    unquotedContent: (
      <div className="flex-center-y gap-2 flex-wrap">
        {update.status_change && (
          <ActivityBadge
            icon={iconForActionStatus(update.status_change.next_status)}
            value={startCase(update.status_change.next_status)}
            previousValue={startCase(update.status_change.previous_status)}
          />
        )}
        {update.assignee_change && (
          <ActivityBadge
            icon={IconEnum.User}
            value={update.assignee_change.next_user?.name || "Unset"}
            previousValue={update.assignee_change.previous_user?.name}
          />
        )}
      </div>
    ),
  };
};

export const iconForActionStatus = (
  status: ActionStatusChangeNextStatusEnum,
): IconEnum => {
  switch (status) {
    case ActionStatusChangeNextStatusEnum.Completed:
      return IconEnum.Checkmark;
    case ActionStatusChangeNextStatusEnum.Deleted:
      return IconEnum.Delete;
    case ActionStatusChangeNextStatusEnum.NotDoing:
      return IconEnum.Close;
    case ActionStatusChangeNextStatusEnum.Outstanding:
      return IconEnum.Loader;
    default:
      return IconEnum.Status;
  }
};
