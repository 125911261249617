import {
  ShortcutBulkExportableFields,
  ShortcutFormData,
} from "@incident-shared/issue-trackers/ExportToShortcutModal";
import { ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { FollowUp } from "src/contexts/ClientContext";
import { IssueTrackersShortcutTypeaheadOptionsFieldEnum } from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  BulkExportBlurb,
  BulkExportCallouts,
  BulkExportTitleAndDescription,
  getBulkExportTitle,
  getBulkToastTitle,
  NoFollowUpsToExportModal,
} from "./helpers";

export const BulkExportToShortcutModal = ({
  followUpsToExport,
  numFollowUpsToExportFromPrivate,
  numFollowUpsAlreadyExported,
  onClose,
  onCancel,
  updateCallback,
}: {
  followUpsToExport: FollowUp[];
  numFollowUpsToExportFromPrivate: number;
  numFollowUpsAlreadyExported: number;
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => void;
}): React.ReactElement | null => {
  const formMethods = useForm<ShortcutFormData>({});
  const { setError, watch, getValues, resetField } = formMethods;

  const showToast = useToast();
  const providerName = "Shortcut";

  const {
    data: { typeahead_options: teams },
    isLoading: teamsLoading,
    error: teamsError,
  } = useAPI(
    "issueTrackersShortcutTypeaheadOptions",
    {
      field: IssueTrackersShortcutTypeaheadOptionsFieldEnum.Team,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const {
    data: { typeahead_options: projects },
    isLoading: projectsLoading,
    error: projectsError,
  } = useAPI(
    "issueTrackersShortcutTypeaheadOptions",
    {
      field: IssueTrackersShortcutTypeaheadOptionsFieldEnum.Project,
    },
    {
      fallbackData: { typeahead_options: [] },
    },
  );

  const selectedTeamID = watch("team_id");

  const {
    data: { typeahead_options: members },
    isLoading: membersLoading,
    error: membersError,
  } = useAPI(
    "issueTrackersShortcutTypeaheadOptions",
    {
      field: IssueTrackersShortcutTypeaheadOptionsFieldEnum.Member,
      teamId: selectedTeamID ?? undefined,
    },
    {
      fallbackData: { typeahead_options: [] },
      onSuccess: ({ typeahead_options }) => {
        // If there's an assignee or reporter selected in the form, and it's no
        // longer in the list, clear the input
        const selectedAssigneeID = getValues("assignee_id");
        if (
          !!selectedAssigneeID &&
          typeahead_options?.every(({ value }) => value !== selectedAssigneeID)
        ) {
          resetField("assignee_id");
        }
        const selectedReporterID = getValues("reporter_id");
        if (
          !!selectedReporterID &&
          typeahead_options?.every(({ value }) => value !== selectedReporterID)
        ) {
          resetField("reporter_id");
        }
      },
    },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "followUpsList",
    {},
    async (apiClient, data: ShortcutFormData) => {
      await Promise.all(
        followUpsToExport.map((followUp) =>
          apiClient.issueTrackersShortcutCreateIssue({
            shortcutCreateIssueRequestBody: {
              follow_up_id: followUp.id,
              title: followUp.title,
              description: followUp.description,
              team_id: data.team_id,
              project_id: data.project_id,
              reporter_id: data.reporter_id,
              assignee_id: data.assignee_id,
            },
          }),
        ),
      );
    },
    {
      setError,
      onSuccess: async () => {
        showToast({
          theme: ToastTheme.Success,
          title: getBulkToastTitle(followUpsToExport.length, providerName),
        });
        updateCallback();
        onClose();
      },
    },
  );

  const title = getBulkExportTitle(followUpsToExport.length);

  const fetchDataError =
    teamsError || membersError || projectsError
      ? "There was a problem loading data from Shortcut."
      : null;

  if (followUpsToExport.length === 0) {
    return (
      <NoFollowUpsToExportModal
        numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
        onClose={onClose}
      />
    );
  } else {
    return (
      <Form.Modal
        formMethods={formMethods}
        onSubmit={onSubmit}
        title={title}
        analyticsTrackingId="add-to-shortcut"
        onClose={onCancel}
        loading={teamsLoading || projectsLoading}
        genericError={genericError || fetchDataError}
        footer={
          <ModalFooter
            confirmButtonText={title}
            saving={saving}
            onClose={onCancel}
            confirmButtonType="submit"
          />
        }
      >
        <BulkExportCallouts
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          accountName="Shortcut account"
        />
        <BulkExportBlurb
          providerIssues="Shortcut issues"
          providerName={providerName}
        />
        <BulkExportTitleAndDescription providerName={providerName} />
        <ShortcutBulkExportableFields
          teams={teams ?? []}
          teamsLoading={teamsLoading}
          projects={projects ?? []}
          projectsLoading={projectsLoading}
          members={members ?? []}
          membersLoading={membersLoading}
        />
      </Form.Modal>
    );
  }
};
