import {
  OnCallNotificationMethod,
  OnCallNotificationMethodMethodTypeEnum,
  OnCallNotificationMethodPhoneVerificationTypeEnum,
  OnCallNotificationMethodStatusEnum,
  OnCallNotificationsCreateMethodRequestBody,
  OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum,
  OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum,
} from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Link, ModalFooter, Txt } from "@incident-ui";
import { AsYouType, CountryCode } from "libphonenumber-js";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

const UNREACHABLE_COUNTRY_CODES: Partial<
  Record<CountryCode, { sms: boolean; call: boolean }>
> = {
  AE: {
    sms: true,
    call: false,
  },
  KE: {
    sms: true,
    call: false,
  },
  RU: {
    sms: true,
    call: false,
  },
};

type AddContactMethodFormData = OnCallNotificationsCreateMethodRequestBody;
export const AddContactMethodModal = ({
  onClose,
  showVerificationModal,
  allowEmail = true,
}: {
  onClose: () => void;
  showVerificationModal: (method: OnCallNotificationMethod) => void;
  allowEmail?: boolean;
}) => {
  const formMethods = useForm<AddContactMethodFormData>({
    defaultValues: {
      method_type:
        OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum.Phone,
      phone_verification_type:
        OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum.Sms,
    },
  });

  const identity = useIdentity();

  const phoneNumberFormat = identity?.identity?.user_locale
    ? identity?.identity?.user_locale.split("-")[1]
    : "US";

  const [methodType] = formMethods.watch(["method_type"]);

  const [phoneVerificationType] = formMethods.watch([
    "phone_verification_type",
  ]);
  const phoneNumber = formMethods.watch("phone_number");

  const asYouType = new AsYouType();
  asYouType.input(phoneNumber || "");

  const countryCode = asYouType.getCountry();
  const reachableCountryCode = (
    countryCode?: CountryCode,
    phoneVerificationType?: OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum,
  ) => {
    if (!countryCode || !phoneVerificationType) {
      return true;
    }
    const unreachableEntry = UNREACHABLE_COUNTRY_CODES[countryCode];
    if (!unreachableEntry) {
      return true;
    }
    if (!unreachableEntry[phoneVerificationType]) {
      return true;
    }
    return false;
  };

  const isUnreachableCountryCode = !reachableCountryCode(
    countryCode,
    phoneVerificationType,
  );

  const {
    trigger: onCreate,
    isMutating,
    genericError,
  } = useAPIMutation(
    "onCallNotificationsListMethodsV2",
    undefined,
    async (
      apiClient,
      createMethodRequestBody: OnCallNotificationsCreateMethodRequestBody,
    ) => {
      const { method } = await apiClient.onCallNotificationsCreateMethod({
        createMethodRequestBody,
      });
      if (
        method.status === OnCallNotificationMethodStatusEnum.PendingVerification
      ) {
        showVerificationModal(method);
      } else {
        onClose();
      }
    },
    {
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal<AddContactMethodFormData>
      formMethods={formMethods}
      onSubmit={onCreate}
      title={"Add contact method"}
      analyticsTrackingId={"add-on-call-notification-method-modal"}
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          confirmButtonType={"submit"}
          disabled={
            !formMethods.formState.isValid ||
            isMutating ||
            isUnreachableCountryCode ||
            !asYouType.isValid()
          }
          disabledTooltipContent={
            isUnreachableCountryCode && phoneVerificationType
              ? phoneVerificationType ===
                OnCallNotificationsCreateMethodRequestBodyPhoneVerificationTypeEnum.Call
                ? "We currently do not support voice call notifications in this country."
                : "We currently do not support SMS notifications in this country."
              : ""
          }
          saving={isMutating}
          confirmButtonText={
            methodType ===
            OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum.Phone
              ? "Send verification code"
              : "Add method"
          }
          onClose={onClose}
          analyticsTrackingId={"add-on-call-notification-method-modal"}
        />
      }
    >
      {allowEmail ? (
        <RadioButtonGroupV2
          name="method_type"
          formMethods={formMethods}
          label={"What would you like to add?"}
          srLabel="method_type"
          boxed
          horizontal
          options={[
            {
              label: "Phone number",
              value: OnCallNotificationMethodMethodTypeEnum.Phone,
            },
            {
              label: "Email",
              value: OnCallNotificationMethodMethodTypeEnum.Email,
            },
          ]}
        />
      ) : null}
      {methodType ===
        OnCallNotificationsCreateMethodRequestBodyMethodTypeEnum.Phone && (
        <>
          <Form.Label htmlFor="phone_number">Phone number</Form.Label>
          <PhoneInput
            name={"phone_number"}
            control={formMethods.control}
            rules={{ required: true }}
            defaultCountry={phoneNumberFormat as CountryCode}
            international
            countrySelectProps={{ unicodeFlags: true }}
            className="!mt-1"
            numberInputProps={{
              className: "border border-stroke rounded-[6px] p-2 tabular-nums",
            }}
          />
          <RadioButtonGroupV2
            name="phone_verification_type"
            formMethods={formMethods}
            label={
              "How would you like to receive the code to verify your number?"
            }
            srLabel="phone_verification_type"
            boxed
            horizontal
            options={[
              {
                label: "SMS",
                value: OnCallNotificationMethodPhoneVerificationTypeEnum.Sms,
              },
              {
                label: "Phone call",
                value: OnCallNotificationMethodPhoneVerificationTypeEnum.Call,
              },
            ]}
          />
          <Txt grey>
            By opting in, message and data rates may apply. The frequency of
            messages will vary depending on your On-call configuration. You will
            also receive 1 message if verifying via SMS.
          </Txt>
          <Txt grey>
            You may opt out at any time by unselecting in your preferences, or
            by texting STOP. You can also text HELP for help.
          </Txt>
          <Txt grey>
            See our{" "}
            <Link
              href={"https://incident.io/terms"}
              openInNewTab
              analyticsTrackingId={"terms-of-service"}
            >
              terms of service
            </Link>{" "}
            and our{" "}
            <Link
              href={"https://incident.io/privacy"}
              openInNewTab
              analyticsTrackingId={"privacy"}
            >
              privacy policy
            </Link>{" "}
            for more information.
          </Txt>
        </>
      )}
    </Form.Modal>
  );
};
