/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldEntryPayload,
    CustomFieldEntryPayloadFromJSON,
    CustomFieldEntryPayloadFromJSONTyped,
    CustomFieldEntryPayloadToJSON,
} from './CustomFieldEntryPayload';
import {
    IncidentRoleAssignmentPayload,
    IncidentRoleAssignmentPayloadFromJSON,
    IncidentRoleAssignmentPayloadFromJSONTyped,
    IncidentRoleAssignmentPayloadToJSON,
} from './IncidentRoleAssignmentPayload';
import {
    IncidentTimestampValuePayload,
    IncidentTimestampValuePayloadFromJSON,
    IncidentTimestampValuePayloadFromJSONTyped,
    IncidentTimestampValuePayloadToJSON,
} from './IncidentTimestampValuePayload';
import {
    TextDocumentPayload,
    TextDocumentPayloadFromJSON,
    TextDocumentPayloadFromJSONTyped,
    TextDocumentPayloadToJSON,
} from './TextDocumentPayload';

/**
 * 
 * @export
 * @interface IncidentsResolveRequestBody
 */
export interface IncidentsResolveRequestBody {
    /**
     * Set the incident's custom fields to these values
     * @type {Array<CustomFieldEntryPayload>}
     * @memberof IncidentsResolveRequestBody
     */
    custom_field_entries: Array<CustomFieldEntryPayload>;
    /**
     * Assign incident roles to these people
     * @type {Array<IncidentRoleAssignmentPayload>}
     * @memberof IncidentsResolveRequestBody
     */
    incident_role_assignments: Array<IncidentRoleAssignmentPayload>;
    /**
     * Assign the incident's timestamps to these values
     * @type {Array<IncidentTimestampValuePayload>}
     * @memberof IncidentsResolveRequestBody
     */
    incident_timestamp_values?: Array<IncidentTimestampValuePayload>;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsResolveRequestBody
     */
    message?: TextDocumentPayload;
    /**
     * Changes incident name, if provided
     * @type {string}
     * @memberof IncidentsResolveRequestBody
     */
    name: string;
    /**
     * An optional decision that was made about entering or exiting the post-incident flow
     * @type {string}
     * @memberof IncidentsResolveRequestBody
     */
    post_incident_flow_decision: IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum;
    /**
     * 
     * @type {TextDocumentPayload}
     * @memberof IncidentsResolveRequestBody
     */
    summary?: TextDocumentPayload;
    /**
     * The source of the summary, if provided
     * @type {string}
     * @memberof IncidentsResolveRequestBody
     */
    summary_source?: IncidentsResolveRequestBodySummarySourceEnum;
    /**
     * Update moved the incident to this severity
     * @type {string}
     * @memberof IncidentsResolveRequestBody
     */
    to_severity_id: string;
}

/**
* @export
* @enum {string}
*/
export enum IncidentsResolveRequestBodyPostIncidentFlowDecisionEnum {
    None = 'none',
    OptIn = 'opt_in',
    OptOut = 'opt_out',
    AutomaticOptIn = 'automatic_opt_in'
}/**
* @export
* @enum {string}
*/
export enum IncidentsResolveRequestBodySummarySourceEnum {
    Human = 'human',
    GeneratedAccepted = 'generated_accepted',
    GeneratedEdited = 'generated_edited'
}

export function IncidentsResolveRequestBodyFromJSON(json: any): IncidentsResolveRequestBody {
    return IncidentsResolveRequestBodyFromJSONTyped(json, false);
}

export function IncidentsResolveRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentsResolveRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryPayloadFromJSON)),
        'incident_role_assignments': ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentPayloadFromJSON)),
        'incident_timestamp_values': !exists(json, 'incident_timestamp_values') ? undefined : ((json['incident_timestamp_values'] as Array<any>).map(IncidentTimestampValuePayloadFromJSON)),
        'message': !exists(json, 'message') ? undefined : TextDocumentPayloadFromJSON(json['message']),
        'name': json['name'],
        'post_incident_flow_decision': json['post_incident_flow_decision'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentPayloadFromJSON(json['summary']),
        'summary_source': !exists(json, 'summary_source') ? undefined : json['summary_source'],
        'to_severity_id': json['to_severity_id'],
    };
}

export function IncidentsResolveRequestBodyToJSON(value?: IncidentsResolveRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryPayloadToJSON)),
        'incident_role_assignments': ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentPayloadToJSON)),
        'incident_timestamp_values': value.incident_timestamp_values === undefined ? undefined : ((value.incident_timestamp_values as Array<any>).map(IncidentTimestampValuePayloadToJSON)),
        'message': TextDocumentPayloadToJSON(value.message),
        'name': value.name,
        'post_incident_flow_decision': value.post_incident_flow_decision,
        'summary': TextDocumentPayloadToJSON(value.summary),
        'summary_source': value.summary_source,
        'to_severity_id': value.to_severity_id,
    };
}

