import {
  AlertRoute,
  AlertRouteMostRecentResourcesCreated,
  ScopeNameEnum,
} from "@incident-io/api";
import { FormDivider } from "@incident-shared/forms/v2/FormDivider";
import { GatedToggle } from "@incident-shared/gates/GatedToggle/GatedToggle";
import {
  prependSlugToPathIfNeeded,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  LocalRelativeDateTime,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useState } from "react";
import { useParams } from "react-router";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useOptimisticAutoSave } from "../../../hooks/useOptimisticAutoSave";
import { useProductAccess } from "../../../hooks/useProductAccess";
import { useAPIMutation } from "../../../utils/swr";
import { tcx } from "../../../utils/tailwind-classes";
import { DeletionConfirmationModal } from "../../settings/DeletionConfirmationModal";
import {
  parseAlertRoute,
  parseFormData,
} from "../alert-route-create-edit/types";

export const AlertRouteItem = ({ alertRoute }: { alertRoute: AlertRoute }) => {
  const showToast = useToast();
  const { slug } = useParams();
  const navigate = useOrgAwareNavigate();
  const { hasOnCall } = useProductAccess();

  const { trigger: onDelete } = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, { id }) => {
      await apiClient.alertRoutesDestroyAlertRoute({
        id,
      });
      return;
    },
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: `${alertRoute.name} deleted.`,
        });
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: `Could not delete ${alertRoute.name}`,
        });
      },
    },
  );

  return (
    <div
      className="p-4 rounded-xl shadow-sm border border-stroke-primary justify-start items-center gap-3 flex bg-white w-full hover:cursor-pointer hover:bg-surface-secondary"
      onClick={(e) => {
        let path = `/alerts/routes/${alertRoute.id}/edit`;
        if (slug) {
          path = prependSlugToPathIfNeeded(path, slug);
        }
        if (e.metaKey || e.ctrlKey) {
          const win = window.open(path, "_blank");
          win?.focus();
        } else {
          navigate(path);
        }
      }}
    >
      <div className="flex flex-col grow min-w-0 gap-1">
        <div className={"text-sm font-semibold truncate"}>
          {alertRoute.name}
        </div>
        <AlertRouteRecentlyCreatedText
          recentlyCreatedResources={alertRoute.most_recent_resources_created}
        />
      </div>
      <div className={"flex flex-row gap-3"}>
        {alertRoute.enabled ? (
          <div className={"flex flex-row gap-2"}>
            {hasOnCall ? (
              <AlertRouteCreatingIcon
                alertRoute={alertRoute}
                resourceType={"escalations"}
              />
            ) : undefined}
            <AlertRouteCreatingIcon
              alertRoute={alertRoute}
              resourceType={"incidents"}
            />
          </div>
        ) : (
          <Badge theme={BadgeTheme.Tertiary}>
            <Tooltip
              side={"left"}
              content={
                "This alert route has been manually deactivated and will not create incidents or escalations."
              }
            >
              <span>Inactive</span>
            </Tooltip>
          </Badge>
        )}
        <AlertRouteOverflowMenu
          alertRoute={alertRoute}
          onDelete={() => onDelete({ id: alertRoute.id })}
        />
      </div>
    </div>
  );
};

const AlertRouteRecentlyCreatedText = ({
  recentlyCreatedResources,
}: {
  recentlyCreatedResources?: AlertRouteMostRecentResourcesCreated;
}) => {
  const alertRouteHasCreatedSomething =
    recentlyCreatedResources?.escalation || recentlyCreatedResources?.incident;

  if (!alertRouteHasCreatedSomething) {
    return (
      <div className={"text-content-tertiary text-xs truncate"}>
        Never created an incident or an escalation
      </div>
    );
  }

  if (
    recentlyCreatedResources.escalation &&
    recentlyCreatedResources.incident
  ) {
    const maxTimestamp =
      new Date(recentlyCreatedResources.escalation.created_at) >
      new Date(recentlyCreatedResources.incident.created_at)
        ? recentlyCreatedResources.escalation.created_at
        : recentlyCreatedResources.incident.created_at;

    return (
      <div className={"text-content-tertiary text-xs truncate"}>
        Created an{" "}
        <span
          className={
            "text-content-primary font-medium hover:underline hover:cursor-pointer"
          }
        >
          incident
        </span>{" "}
        and{" "}
        <span
          className={
            "text-content-primary font-medium hover:underline hover:cursor-pointer"
          }
        >
          escalation
        </span>{" "}
        <LocalRelativeDateTime
          date={maxTimestamp}
          className={"hover:!no-underline text-xs"}
        />
      </div>
    );
  }

  // Handle cases where only one of escalation or incident exists
  const createdResource = recentlyCreatedResources.escalation
    ? "escalation"
    : "incident";
  const createdAt = recentlyCreatedResources.escalation
    ? recentlyCreatedResources.escalation.created_at
    : recentlyCreatedResources.incident.created_at;

  return (
    <div className={"text-content-tertiary text-xs"}>
      Created an{" "}
      <span
        className={
          "text-content-primary font-medium hover:underline hover:cursor-pointer"
        }
      >
        {createdResource}
      </span>{" "}
      <LocalRelativeDateTime
        date={createdAt}
        className={"hover:!no-underline text-xs"}
      />
    </div>
  );
};

const AlertRouteOverflowMenu = ({
  alertRoute,
  onDelete,
}: {
  alertRoute: AlertRoute;
  onDelete: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const canEdit = hasScope(ScopeNameEnum.AlertRouteUpdate);
  const hasDeletionPermissions = hasScope(ScopeNameEnum.AlertRouteDestroy);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const tooltipContent = canEdit
    ? null
    : "You don't have permission to edit this alert route.";

  const { trigger: toggleActivatedState } = useAPIMutation(
    "alertRoutesListAlertRoutes",
    undefined,
    async (apiClient, data: { enabled: boolean }) => {
      await apiClient.alertRoutesUpdateAlertRoute({
        id: alertRoute.id,
        updateAlertRouteRequestBody: {
          ...parseFormData(parseAlertRoute(alertRoute, null), []),
          enabled: data.enabled,
        },
      });
    },
  );

  const { setState: setActivatedState, saving } = useOptimisticAutoSave<{
    enabled: boolean;
  }>({
    initialState: alertRoute,
    saveState: async (data: { enabled: boolean }) => {
      await toggleActivatedState(data);
    },
  });

  const onToggleDeactivated = () => {
    if (saving) {
      return;
    }
    setActivatedState({
      enabled: !alertRoute.enabled,
    });
  };

  return (
    <>
      <DeletionConfirmationModal
        title="Delete alert route"
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        fetchDependentResources={[
          {
            resource_type: "AlertRoute",
            id: alertRoute.id,
          },
        ]}
        onDelete={onDelete}
        analyticsTrackingId="alert-routes.delete"
        resourceTitle={alertRoute.name}
        deleteConfirmationContent={
          <>
            Are you sure you want to delete{" "}
            <span className="font-bold">{alertRoute.name}</span>?
          </>
        }
      />
      <DropdownMenu
        menuClassName={"w-[140px]"}
        side={"bottom"}
        align={"end"}
        triggerButton={
          <Button
            theme={ButtonTheme.Naked}
            type="button"
            className="-ml-2"
            analyticsTrackingId="alert-routes.options"
            icon={IconEnum.DotsVerticalNopad}
            title="Alert route options"
            iconProps={{ size: IconSize.Large, className: "-my-2" }}
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        <DropdownMenuItem
          disabled={!canEdit}
          tooltipContent={tooltipContent}
          onSelect={() => navigate(`/alerts/routes/${alertRoute.id}/edit`)}
          analyticsTrackingId={"alert-routes.edit"}
          icon={IconEnum.Edit}
          label={"Edit"}
        >
          Edit
        </DropdownMenuItem>
        <DropdownMenuItem
          disabled={!hasDeletionPermissions}
          tooltipContent={
            hasDeletionPermissions
              ? undefined
              : "You don't have permission to delete this alert route"
          }
          onSelect={() => setShowDeleteModal(true)}
          analyticsTrackingId={"alert-routes.delete"}
          icon={IconEnum.Delete}
          label="Delete"
          destructive
        >
          Delete
        </DropdownMenuItem>
        <FormDivider className={"my-1"} />
        <DropdownMenuItem
          disabled={!canEdit}
          tooltipContent={tooltipContent}
          onSelect={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onToggleDeactivated();
          }}
          analyticsTrackingId={"alert-routes.edit"}
          label={"Enabled"}
        >
          <div className="flex flex-row justify-between">
            <GatedToggle
              id="deactivate"
              disabled={!canEdit}
              tooltipContent={<>{tooltipContent}</>}
              align="right"
              label="Deactivate"
              on={!alertRoute.enabled}
              onToggle={onToggleDeactivated}
              isLoading={saving}
            />
          </div>
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  );
};

const AlertRouteCreatingIcon = ({
  alertRoute,
  resourceType,
}: {
  alertRoute: AlertRoute;
  resourceType: "escalations" | "incidents";
}) => {
  const icon = {
    incidents: IconEnum.Incident,
    escalations: IconEnum.ArrowThreeUp,
  };
  const condition = {
    incidents: alertRoute.incident_enabled,
    escalations: alertRoute.escalation_bindings.length > 0,
  };
  return (
    <Tooltip
      content={
        <div className="text-center text-white text-xs font-medium">
          {condition[resourceType] ? "Creating" : "Not creating"} {resourceType}
        </div>
      }
      side={"bottom"}
    >
      <div className={"hover:cursor-pointer"}>
        <Icon
          id={icon[resourceType]}
          className={tcx({
            "text-green-600 hover:text-green-700": condition[resourceType],
            "text-slate-200 hover:text-slate-300": !condition[resourceType],
          })}
        />
      </div>
    </Tooltip>
  );
};
