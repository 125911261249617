/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityLogEntry,
    ActivityLogEntryFromJSON,
    ActivityLogEntryFromJSONTyped,
    ActivityLogEntryToJSON,
} from './ActivityLogEntry';
import {
    IncidentTimelineGap,
    IncidentTimelineGapFromJSON,
    IncidentTimelineGapFromJSONTyped,
    IncidentTimelineGapToJSON,
} from './IncidentTimelineGap';

/**
 * 
 * @export
 * @interface IncidentActivityLogElement
 */
export interface IncidentActivityLogElement {
    /**
     * 
     * @type {ActivityLogEntry}
     * @memberof IncidentActivityLogElement
     */
    activity_log?: ActivityLogEntry;
    /**
     * 
     * @type {IncidentTimelineGap}
     * @memberof IncidentActivityLogElement
     */
    timeline_gap?: IncidentTimelineGap;
    /**
     * Timestamp of when this element occurred
     * @type {Date}
     * @memberof IncidentActivityLogElement
     */
    timestamp: Date;
    /**
     * Type of the timeline element
     * @type {string}
     * @memberof IncidentActivityLogElement
     */
    type: IncidentActivityLogElementTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum IncidentActivityLogElementTypeEnum {
    ActivityLog = 'activity_log',
    TimeGap = 'time_gap'
}

export function IncidentActivityLogElementFromJSON(json: any): IncidentActivityLogElement {
    return IncidentActivityLogElementFromJSONTyped(json, false);
}

export function IncidentActivityLogElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentActivityLogElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity_log': !exists(json, 'activity_log') ? undefined : ActivityLogEntryFromJSON(json['activity_log']),
        'timeline_gap': !exists(json, 'timeline_gap') ? undefined : IncidentTimelineGapFromJSON(json['timeline_gap']),
        'timestamp': (new Date(json['timestamp'])),
        'type': json['type'],
    };
}

export function IncidentActivityLogElementToJSON(value?: IncidentActivityLogElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity_log': ActivityLogEntryToJSON(value.activity_log),
        'timeline_gap': IncidentTimelineGapToJSON(value.timeline_gap),
        'timestamp': (value.timestamp.toISOString()),
        'type': value.type,
    };
}

