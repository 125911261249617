import {
  Alert,
  AlertAttribute,
  AlertSource,
  AlertSourceConfig,
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  EngineParamBinding,
  EngineParamBindingPayload,
  EngineScope,
  Resource,
} from "@incident-io/api";
import { CatalogEntryBadge } from "@incident-shared/attribute";
import {
  EngineFormElement,
  getVariableScope,
  isExpression,
} from "@incident-shared/engine";
import { AddEditExpressionModal } from "@incident-shared/engine/expressions/AddEditExpressionModal";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import {
  ExpressionsMethodsOverrideProvider,
  ExpressionsMethodsProvider,
} from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import { ExpressionFixedResultType } from "@incident-shared/engine/expressions/ifelse/createDefaultExpressionFormValues";
import { createDefaultParseExpression } from "@incident-shared/engine/expressions/query/operations/createDefaultParseExpression";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  EmptyState,
  IconEnum,
  StaticSingleSelect,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { useWarnOnDrawerClose } from "@incident-ui/Drawer/DrawerFormStateContext";
import { JSONPreview } from "@incident-ui/JSONPreview/JSONPreview";
import { Popover, PopoverTitleBar } from "@incident-ui/Popover/Popover";
import { PopoverItem } from "@incident-ui/Popover/PopoverItem";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useForm, useFormContext, UseFormSetValue } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Prompt } from "src/components/@shared/utils/Prompt";
import {
  AttributeList,
  AttributeListItem,
} from "src/components/catalog/entry-view/AttributeList";

import { lookupInScopeByType } from "../../../../utils/scope";
import { AlertSourceSplitLayout } from "../AlertSourceLayout";
import { useAttributeExpressions } from "../useAttributeExpressions";
import { useScopeWithAlertPreviews } from "../useScopeWithAlertPreviews";
import { AlertsCatalogSetupWidget } from "./AlertsCatalogSetupWidget";
import { AlertSourceAttributeSuggestions } from "./AlertSourceAttributeSuggestions";
import {
  AlertSourceConfigureFormData,
  TemplateFormData,
} from "./AlertSourceConfigurePage";
import { AlertSourceFoundSuggestedAttributes } from "./AlertSourceFoundSuggestedAttributes";
import { AlertSourceSection } from "./AlertSourceSection";
import { getPopulatedAttributes } from "./useOutstandingSuggestions";

// Provides the attributes component that helps open the attributes drawer and
// displays attributes that are currently bound.
export const AlertSourceAttributes = ({
  scopeWithExpressions,
  resources,
  previewAlerts,
  alertSource,
  alertSourceConfig,
  isEditing,
  setIsEditing,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
  alertSource: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  previewAlerts: Alert[];
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}) => {
  const formMethods = useFormContext<AlertSourceConfigureFormData>();
  const { watch, setValue } = formMethods;
  const variableScope = getVariableScope(scopeWithExpressions, resources);

  const [attributes, bindings, expressions] = watch([
    "attributes",
    "template.bindings",
    "template.expressions",
  ]);

  // Filter for attributes that have values.
  const populatedAttributes = getPopulatedAttributes({
    attributes,
    attributeBindings: bindings,
  });

  const attributeListItems: AttributeListItem[] = populatedAttributes.map(
    (attribute) => {
      return {
        title: attribute.name,
        renderValues: () => (
          <EngineBinding
            binding={bindings[attribute.id as string] as EngineParamBinding}
            resourceType={attribute.type}
            variableScope={variableScope}
            displayExpressionAs={"pill"}
          />
        ),
      };
    },
  );

  return (
    <>
      <AnimatePresence>
        {isEditing && (
          <AttributesDrawer
            onClose={() => setIsEditing(false)}
            resources={resources}
            setValue={setValue}
            previewAlerts={previewAlerts}
            alertSource={alertSource}
            alertSourceConfig={alertSourceConfig}
            initialData={{
              template: { bindings, expressions },
              attributes: attributes as AlertAttribute[],
            }}
          />
        )}
      </AnimatePresence>
      <AlertSourceSection
        title={"Attributes"}
        icon={IconEnum.Tag}
        onEdit={() => setIsEditing(true)}
      >
        <AlertSourceFoundSuggestedAttributes
          alertSourceConfig={alertSourceConfig}
          attributes={attributes}
          attributeBindings={bindings}
          onReview={() => setIsEditing(true)}
        />
        <AttributeList items={attributeListItems} />
      </AlertSourceSection>
    </>
  );
};

export type AttributesFormData = {
  template: Pick<TemplateFormData, "bindings" | "expressions">;
  attributes: AlertAttribute[];
};

const AttributesDrawer = ({
  onClose: onCloseCallback,
  resources,
  setValue,
  initialData,
  alertSource,
  alertSourceConfig,
  previewAlerts,
}: {
  onClose: () => void;
  resources: Resource[];
  setValue: UseFormSetValue<AlertSourceConfigureFormData>;
  initialData: AttributesFormData;
  alertSource: AlertSource;
  alertSourceConfig: AlertSourceConfig;
  previewAlerts: Alert[];
}) => {
  const formMethods = useForm<AttributesFormData>({
    defaultValues: initialData,
  });

  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(
    previewAlerts[0],
  );

  const {
    onAddExpression,
    onEditExpression,
    showExpressionsModal,
    expressionDefaults,
    expressionsMethods,
    setExpressionDefaults,
    openExpressionsModal,
    closeExpressionsModal,
    initialExpression,
    addAcceptedSuggestionToFormState,
  } = useAttributeExpressions({
    formMethods,
  });

  // Get a scope with the alert JSON previews patched in
  const scopeWithPreviews = useScopeWithAlertPreviews({
    alerts: previewAlerts,
    alertSource,
  });

  const onClickAttribute = (attribute: AlertAttribute, path?: string) => {
    setExpressionDefaults({ attribute, path });
    openExpressionsModal();
  };

  const onClickNewAttribute = (path?: string) => {
    if (path) {
      setExpressionDefaults({ path });
    }
    openExpressionsModal();
  };

  const scopeWithExpressions = addExpressionsToScope<ReferenceWithExample>(
    scopeWithPreviews,
    expressionsMethods.fields as unknown as ExpressionFormData[],
  );

  const [bindings, attributes] = formMethods.watch([
    "template.bindings",
    "attributes",
  ]);

  const onSubmit = (data: AttributesFormData) => {
    setValue("template.bindings", data.template.bindings, {
      shouldDirty: true,
    });
    setValue("template.expressions", data.template.expressions);

    setValue("attributes", data.attributes);
    // Reset the form and close the drawer
    formMethods.reset();

    onClose();
  };

  const populatedOrEditingAttributes = getPopulatedAttributes({
    attributes,
    attributeBindings: bindings,
    includeLocallyEditing: true,
  });

  const { onCloseWithWarn } = useWarnOnDrawerClose(
    formMethods,
    onCloseCallback,
  );
  const onClose = () => onCloseWithWarn(formMethods.formState.isDirty);

  const payload = lookupInScopeByType(scopeWithExpressions, "JSON");

  return (
    <Drawer width="full" onClose={onClose}>
      <DrawerContents className="overflow-hidden">
        <DrawerTitle
          title="Attributes"
          onClose={onClose}
          icon={IconEnum.Tag}
          theme={DrawerTitleTheme.Bordered}
          compact
        />
        <DrawerBody className="p-0 overflow-y-hidden">
          <Form.Root
            id="alert-attributes"
            formMethods={formMethods}
            onSubmit={onSubmit}
            outerClassName="grow overflow-y-hidden"
            loadingWrapperClassName="h-full"
            innerClassName="h-full"
          >
            <Prompt
              when={formMethods.formState.isDirty}
              message={
                "Your changes have not been saved. Are you sure you want to navigate away?"
              }
            />
            <ExpressionsMethodsProvider
              expressionsMethods={expressionsMethods}
              allowAllOfACatalogType={false}
            >
              <AlertSourceSplitLayout
                left={
                  <div className={"flex flex-col gap-6 p-6"}>
                    <div className="text-content-secondary">
                      Attributes provide additional context on your alerts, such
                      as affected feature, affected customer or the environment
                      the alert occurred in.
                    </div>

                    {
                      <AlertsCatalogSetupWidget
                        populatedAttributes={populatedOrEditingAttributes.map(
                          (b) => b.id,
                        )}
                      />
                    }

                    <AlertSourceAttributeSuggestions
                      alertSourceConfig={alertSourceConfig}
                      scopeWithExpressions={scopeWithExpressions}
                      attributes={attributes}
                      attributeBindings={bindings}
                      addAcceptedSuggestionToFormState={
                        addAcceptedSuggestionToFormState
                      }
                    />

                    <ExistingAttributes
                      bindings={bindings}
                      attributes={attributes}
                      populatedOrEditingAttributes={
                        populatedOrEditingAttributes
                      }
                      resources={resources}
                      scopeWithExpressions={scopeWithExpressions}
                      onClickAttribute={onClickAttribute}
                      onClickNewAttribute={onClickNewAttribute}
                    />
                  </div>
                }
                right={
                  <JSONPreview
                    containerClassName={"min-h-[420px] h-full"}
                    payload={JSON.parse(selectedAlert?.payload || "{}")}
                    titleElement={
                      <div className="flex items-center justify-between p-6">
                        <div
                          className={
                            "text-content-tertiary text-xs font-medium uppercase tracking-widest"
                          }
                        >
                          Alert payload
                        </div>
                        <StaticSingleSelect
                          className={
                            "!w-[220px] rounded-[6px] border-none !text-slate-800"
                          }
                          value={selectedAlert?.id}
                          onChange={(value) => {
                            if (value && typeof value === "string") {
                              const selected = previewAlerts.find(
                                (a) => a.id === value,
                              );
                              if (selected) {
                                setSelectedAlert(selected);
                              }
                            }
                          }}
                          options={previewAlerts.map((a) => ({
                            label: a.title,
                            value: a.id,
                          }))}
                          isClearable={false}
                        />
                      </div>
                    }
                    getKeyPopoverContent={(path: string) => (
                      <JSONPreviewPopover
                        path={path}
                        attributes={attributes}
                        resources={resources}
                        onClickAttribute={onClickAttribute}
                        onClickNewAttribute={onClickNewAttribute}
                        assignedAttributes={
                          new Set(
                            populatedOrEditingAttributes.map(
                              (a) => a.id as string,
                            ),
                          )
                        }
                      />
                    )}
                    lightMode
                  />
                }
              />
            </ExpressionsMethodsProvider>
          </Form.Root>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex items-center justify-end gap-2">
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Secondary}
              onClick={onCloseCallback}
            >
              Cancel
            </Button>
            <Button
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              onClick={formMethods.handleSubmit(onSubmit)}
              form="alert-native-properties"
            >
              Apply
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContents>
      {/* We don't use EngineFormElement here because we don't know what the return type will be */}
      {showExpressionsModal && (
        <ExpressionsMethodsOverrideProvider showExpressionNames>
          <AddEditExpressionModal
            onClose={closeExpressionsModal}
            onEditExpression={(expr: ExpressionFormData) => {
              if (initialExpression) {
                onEditExpression(expr);
              } else {
                // Because we fake default data, `onEdit` can actually be invoking
                // `onAdd` if the initial expression is not provided.
                onAddExpression(expr, expressionDefaults.attribute);
              }
            }}
            onAddExpression={(expr: ExpressionFormData) => {
              onAddExpression(expr, expressionDefaults.attribute);
            }}
            initialExpression={
              initialExpression ??
              (createDefaultParseExpression(
                expressionDefaults.attribute,
                payload,
                expressionDefaults.path,
              ) as ExpressionFormData)
            }
            scope={scopeWithExpressions}
            resources={resources}
            analyticsTrackingContext={"add-edit-expression-modal"}
            existingExpressions={expressionsMethods.fields}
            elseBranchRequired={false}
            // We don't want people to duplicate our in-built alert priority attribute
            validateReturnType={({ resource }) => {
              return resource.type !== 'CatalogEntry["AlertPriority"]';
            }}
            fixedResult={createFixedResult(
              expressionDefaults.attribute,
              resources,
            )}
            labelOverrides={{
              nameLabel: "What should this attribute be called?",
              helpText:
                "This is how you'll refer to the attribute when routing your alert to incidents and escalations.",
              modalTitle: "Add attribute",
            }}
          />
        </ExpressionsMethodsOverrideProvider>
      )}
    </Drawer>
  );
};

export const createFixedResult = (
  attribute: AlertAttribute | undefined,
  resources: Resource[],
): ExpressionFixedResultType | undefined => {
  if (!attribute) return undefined;

  const expressionAttributeResource = resources.find(
    (r) => r.type === attribute.type,
  );
  return {
    type: attribute.type,
    array: attribute.array,
    label: attribute.name,
    typeIsAutocompletable:
      expressionAttributeResource?.autocompletable ?? false,
    typeLabel: expressionAttributeResource?.type_label ?? "",
  };
};

// Display the existing attributes if we have any, and an empty state otherwise.
const ExistingAttributes = ({
  bindings,
  attributes,
  populatedOrEditingAttributes,
  resources,
  scopeWithExpressions,
  onClickNewAttribute,
  onClickAttribute,
}: {
  bindings: Record<string, EngineParamBindingPayload>;
  attributes: AlertAttribute[];
  populatedOrEditingAttributes: AlertAttribute[];
  resources: Resource[];
  scopeWithExpressions: EngineScope;
  onClickNewAttribute: (path?: string) => void;
  onClickAttribute: (attribute: AlertAttribute, path?: string) => void;
}) => {
  const formMethods = useFormContext<AttributesFormData>();

  const noAttributesProps = {
    attributes,
    resources,
    onClickNewAttribute,
    onClickAttribute,
    populatedOrEditingAttributes: populatedOrEditingAttributes,
  };

  // Show the empty state or the copy with a link
  if (isEmpty(populatedOrEditingAttributes)) {
    return (
      <EmptyState
        icon={IconEnum.Tag}
        title="Attributes"
        content={<AddAttributesCTA {...noAttributesProps} />}
      />
    );
  }

  return (
    <>
      <div className={"flex flex-col"}>
        <div className={"text-content-primary text-sm-bold mb-3"}>
          Your attributes
        </div>

        <div>
          {populatedOrEditingAttributes.map((a) => {
            const binding = bindings[a.id];
            const bindingIsExpression = isExpression(
              binding.value?.reference ||
                binding.array_value?.[0]?.reference ||
                "",
            );

            return (
              <motion.div
                key={a.id}
                initial={{ opacity: 0, height: 0, marginBottom: 0 }}
                animate={{
                  opacity: 1,
                  height: "auto",
                  marginBottom: "1rem",
                  transition: {
                    height: { duration: 0.3, delay: 0.2 },
                    marginBottom: { duration: 0.3, delay: 0.2 },
                    opacity: { duration: 0.3, delay: 0.5 },
                  },
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  transition: {
                    height: { duration: 0.3, delay: 0.2 },
                    opacity: { duration: 0.2 },
                  },
                }}
              >
                <EngineFormElement
                  name={`template.bindings.${a.id}`}
                  resourceType={a.type}
                  labelNode={
                    <div className="flex w-full justify-between gap-2">
                      <span className="truncate text-xs-med text-content-tertiary">
                        {a.name}
                      </span>
                      {!bindingIsExpression && (
                        <Button
                          analyticsTrackingId={null}
                          theme={ButtonTheme.Unstyled}
                          className="text-red-content hover:text-red-800 font-normal"
                          onClick={() =>
                            formMethods.setValue<`template.bindings.${string}`>(
                              `template.bindings.${a.id}`,
                              {},
                            )
                          }
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                  }
                  resources={resources}
                  scope={scopeWithExpressions}
                  array={a.array}
                  required
                  mode="variables_and_expressions"
                  isAlertElement
                />
              </motion.div>
            );
          })}
        </div>
        <AddAttributesCTA
          className={"text-content-secondary text-sm"}
          {...noAttributesProps}
        />
      </div>
    </>
  );
};

// The copy for no attributes is the same whether it is shown in the empty state
// or outside of it, so we extract it into this component to keep it consistent.
const AddAttributesCTA = ({
  className,
  attributes,
  resources,
  onClickAttribute,
  onClickNewAttribute,
  populatedOrEditingAttributes,
}: {
  className?: string;
  attributes: AlertAttribute[];
  resources: Resource[];
  onClickAttribute: (attribute: AlertAttribute, path?: string) => void;
  onClickNewAttribute: (path?: string) => void;
  populatedOrEditingAttributes: AlertAttribute[];
}) => {
  const assignedAttributes = new Set(
    populatedOrEditingAttributes.map((a) => a.id as string),
  );

  const availableAttributes = attributes.filter(
    (a) => !assignedAttributes.has(a.id as string),
  );

  const createOneFromScratchButton = (
    <Button
      onClick={() => {
        onClickNewAttribute();
      }}
      analyticsTrackingId="add-new-attribute"
      theme={ButtonTheme.Naked}
      className={"text-content-primary underline"}
    >
      create one from scratch.
    </Button>
  );

  const linkAnExistingAttributeButton = (
    <LinkExistingAttributePopover
      availableAttributes={availableAttributes}
      resources={resources}
      onClickAttribute={onClickAttribute}
    />
  );

  return (
    <div className={className}>
      Create new attributes from your payload preview opposite,{" "}
      {linkAnExistingAttributeButton} or&nbsp;
      {createOneFromScratchButton}
    </div>
  );
};

type LinkExistingAttributePopoverState =
  | { type: "choose-attribute" }
  | {
      type: "choose-dynamic-or-static";
      attribute: AlertAttribute;
    };
export const LinkExistingAttributePopover = ({
  availableAttributes,
  resources,
  onClickAttribute,
}: {
  availableAttributes: AlertAttribute[];
  resources: Resource[];
  onClickAttribute: (attribute: AlertAttribute) => void;
}) => {
  const formMethods = useFormContext<AttributesFormData>();

  const [popoverState, setPopoverState] =
    useState<LinkExistingAttributePopoverState | null>(null);

  if (availableAttributes.length === 0) {
    return null;
  }

  return (
    <Popover
      side={"top"}
      onOpenChange={(open) => {
        if (!open) {
          setPopoverState(null);
        }
      }}
      open={!!popoverState}
      trigger={
        <Button
          analyticsTrackingId="add-new-attribute"
          theme={ButtonTheme.Naked}
          className={"text-content-primary underline"}
          onClick={() => {
            setPopoverState({
              type: "choose-attribute",
            });
          }}
        >
          link an existing attribute
        </Button>
      }
    >
      {popoverState ? (
        popoverState?.type === "choose-attribute" ? (
          <>
            <PopoverTitleBar title={"Choose an attribute"} />
            {availableAttributes.map((attr) => (
              <CatalogPopoverItem
                key={attr.id}
                onClick={() => {
                  setPopoverState({
                    type: "choose-dynamic-or-static",
                    attribute: attr,
                  });
                }}
                attribute={attr}
                resources={resources}
              />
            ))}
          </>
        ) : (
          <>
            <PopoverTitleBar
              title={"How will you set this value?"}
              handleBack={() => {
                setPopoverState({ type: "choose-attribute" });
              }}
            />
            <PopoverItem
              onClick={() => {
                formMethods.setValue<`template.bindings.${string}`>(
                  `template.bindings.${popoverState.attribute.id}`,
                  {
                    isLocallyEditing: true,
                  },
                );
                setPopoverState(null);
              }}
              icon={
                resources.find((r) => r.type === popoverState.attribute.type)
                  ?.field_config.icon ?? IconEnum.Code
              }
            >
              With a static value
            </PopoverItem>
            <PopoverItem
              onClick={() => {
                setPopoverState(null);
                onClickAttribute(popoverState.attribute);
              }}
              icon={IconEnum.Variable}
            >
              Dynamically from the payload
            </PopoverItem>
          </>
        )
      ) : null}
    </Popover>
  );
};

// Opens a popover with existing alert attributes that can be clicked to start
// binding them to the alert source.
const JSONPreviewPopover = ({
  path,
  attributes,
  resources,
  assignedAttributes,
  onClickAttribute,
  onClickNewAttribute,
}: {
  path?: string;
  attributes: AlertAttribute[];
  resources: Resource[];
  assignedAttributes: Set<string>;
  onClickAttribute: (attribute: AlertAttribute, path?: string) => void;
  onClickNewAttribute: (path?: string) => void;
}) => {
  const availableAttributes = attributes.filter(
    (a) => !assignedAttributes.has(a.id as string),
  );

  return (
    <>
      {availableAttributes.length > 0 && (
        <>
          {availableAttributes.map((a) => (
            <CatalogPopoverItem
              key={a.id}
              onClick={() => onClickAttribute(a, path)}
              attribute={a}
              resources={resources}
            />
          ))}
        </>
      )}
      <hr />
      <PopoverItem
        onClick={() => onClickNewAttribute(path)}
        icon={IconEnum.Add}
      >
        Add new attribute
      </PopoverItem>
    </>
  );
};

const CatalogPopoverItem = ({
  attribute,
  onClick,
  resources,
}: {
  attribute: AlertAttribute;
  onClick: () => void;
  resources: Resource[];
}) => {
  const resource = resources.find((r) => r.type === attribute.type);

  return (
    <PopoverItem key={attribute.id} onClick={onClick} className="p-1">
      <CatalogEntryBadge
        color={
          (resource?.field_config.color as unknown as ColorPaletteEnum) ||
          CatalogResourceColorEnum.Blue
        }
        icon={resource?.field_config.icon || CatalogResourceIconEnum.Box}
        label={attribute.name}
        className="cursor-pointer"
      />
    </PopoverItem>
  );
};
