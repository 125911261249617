import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

export const ActivityItemSummaryUpdate = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.summary_update) {
    throw new Error(
      "malformed timeline item: summary_update was missing summary_update field",
    );
  }

  const content = item.content.summary_update.to?.text_node && (
    <TemplatedTextDisplay
      value={item.content.summary_update.to?.text_node}
      style={TemplatedTextDisplayStyle.Compact}
    />
  );

  return {
    icon: IconEnum.TextAlignLeft,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.summary_update.actor,
    title: "Summary updated",
    quotedContent: content,
  };
};
