import { AlertSourceSourceTypeEnum, ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  ConnectModalProps,
  IntegrationConfig,
  IntegrationConfigFor,
  IntegrationListProvider,
  IntegrationsListObject,
  SCIMProviderEnum,
} from "@incident-shared/integrations";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  IconBadge,
  IconEnum,
  IconSize,
  LoadingBar,
  Modal,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { isDevelopment } from "src/utils/environment";

import { PlanBadge } from "../../PlanBadge";
import { useSCIMPortalURL } from "../../users/scim/ScimShowPageInner";
import { AboutThisIntegration } from "../common/AboutThisIntegration";
import { DisconnectButton } from "../common/DisconnectButton";
import { IntegrationCatalogTypeList } from "../common/IntegrationCatalogTypeList";
import { IntegrationErrorCallout } from "../common/IntegrationErrorCallout";

export const IntegrationDrawer = ({
  integrations,
  activeIntegration,
  backHref,
  forTeam,
  onClose,
}: {
  activeIntegration:
    | IntegrationListProvider
    | AlertSourceSourceTypeEnum
    | undefined;
  integrations: IntegrationsListObject[];
  backHref: string;
  forTeam?: boolean;
  onClose?: () => void;
}): React.ReactElement | null => {
  const activeIntegrationObject = integrations.find(
    (i) => i.provider === activeIntegration,
  );
  const navigate = useOrgAwareNavigate();

  const navigateBack = () => {
    navigate(backHref, { replace: true });
  };

  return (
    <Drawer onClose={onClose || navigateBack} width="medium">
      <DrawerContents>
        {activeIntegrationObject ? (
          <IntegrationDrawerContents
            allIntegrations={integrations}
            onClose={onClose || navigateBack}
            integration={activeIntegrationObject}
            forTeam={forTeam}
          />
        ) : (
          <></>
        )}
      </DrawerContents>
    </Drawer>
  );
};

const isScimProvider = (provider: string): boolean => {
  return Object.values(SCIMProviderEnum).includes(provider as SCIMProviderEnum);
};

const IntegrationDrawerContents = ({
  allIntegrations,
  integration,
  onClose,
  forTeam = false,
}: {
  allIntegrations: IntegrationsListObject[];
  integration: IntegrationsListObject;
  onClose: () => void;
  forTeam?: boolean;
}) => {
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  const config = IntegrationConfigFor(integration.provider, forTeam);
  const { scimPortalURL } = useSCIMPortalURL(forTeam);

  // If there's no connect modal, and there is an add integration URL, we want to tell the user
  // we are kicking them out to the provider's site. We don't do this for team-specific drawers.
  const subtitle =
    !config.CustomConnectModal && integration.add_integration_url && !forTeam
      ? `To install this integration, we will send you to ${integration.provider_name} where (if you have permission) you can set up the incident.io integration.`
      : config.description;

  const ConnectDrawerContents = GenericConnectDrawerContents;
  const ConfigureDrawerContents =
    config.CustomConfigureDrawer ?? GenericConfigureDrawerContents;

  const DrawerContents = integration.installed
    ? ConfigureDrawerContents
    : ConnectDrawerContents;

  const ReconnectModal = config.CustomConnectModal || GenericReconnectModal;

  // If this is a SCIM integration we'll show users a link that redirects them
  // to the WorkOS portal for SCIM setup.
  const link =
    isScimProvider(integration.provider) && scimPortalURL
      ? `${window.location.protocol}/${window.location.hostname}${scimPortalURL}`
      : integration.add_integration_url;

  return (
    <>
      <IntegrationDrawerTitle
        config={config}
        subtitle={subtitle}
        setShowConnectModal={setShowConnectModal}
        integration={integration}
        onClose={onClose}
      />
      <DrawerContents
        integration={integration}
        connectionError={
          <IntegrationErrorCallout
            config={config}
            integration={integration}
            onOpenReconnectModal={() => setShowReconnectModal(true)}
          />
        }
        copyLink={config.CopyLink ? config.CopyLink(link) : undefined}
        aboutThis={
          <AboutThisIntegration
            hexColor={config.hexColor || "#000000"}
            providerLabel={integration.provider_name}
            features={config.features}
          />
        }
        teaser={config.FeatureTeaser && <config.FeatureTeaser />}
        catalogTypeList={
          <IntegrationCatalogTypeList
            types={integration.available_catalog_types}
            integrationInstalled={integration.installed}
          />
        }
      />
      {config.CustomConnectModal && showConnectModal && (
        <config.CustomConnectModal
          integration={integration}
          allIntegrations={allIntegrations}
          onClose={() => setShowConnectModal(false)}
        />
      )}
      {showReconnectModal && (
        <ReconnectModal
          integration={integration}
          allIntegrations={allIntegrations}
          onClose={() => setShowReconnectModal(false)}
          isReconnect
        />
      )}
    </>
  );
};

const GenericReconnectModal = ({ integration, onClose }: ConnectModalProps) => {
  const config = IntegrationConfigFor(integration.provider);
  return (
    <Modal
      title={`Reconnect to ${config.label}`}
      isOpen
      analyticsTrackingId={null}
      onClose={onClose}
    >
      <div className="p-4 gap-4 flex flex-col">
        <p className="text-sm text-slate-700">
          Resolve the issue with {config.label} by authenticating the app again:
        </p>
        <div className="text-right mt-4">
          <GatedButton
            href={integration.add_integration_url}
            analyticsTrackingId="settings-integrations-reconnect"
            requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
            theme={ButtonTheme.Primary}
            icon={IconEnum.ExternalLink}
          >
            Reconnect
          </GatedButton>
        </div>
      </div>
    </Modal>
  );
};

const GenericConnectDrawerContents = ({
  connectionError,
  copyLink,
  aboutThis,
  catalogTypeList,
  teaser,
}: {
  connectionError: React.ReactNode;
  copyLink?: React.ReactNode;
  aboutThis: React.ReactNode;
  catalogTypeList: React.ReactNode;
  teaser: React.ReactNode;
  integration: IntegrationsListObject;
}) => {
  return (
    <DrawerBody className="overflow-y-auto">
      {connectionError}
      {copyLink}
      {aboutThis}
      {teaser}
      {catalogTypeList}
    </DrawerBody>
  );
};

export const GenericConfigureDrawerContents = ({
  connectionError,
  copyLink,
  aboutThis,
  catalogTypeList,
  children,
}: {
  connectionError: React.ReactNode;
  copyLink?: React.ReactNode;
  aboutThis: React.ReactNode;
  catalogTypeList: React.ReactNode;
  integration: IntegrationsListObject;
  children?: React.ReactNode;
}) => {
  return (
    <DrawerBody className="overflow-y-auto">
      {connectionError}
      {copyLink}
      {aboutThis}
      {children}
      {catalogTypeList}
    </DrawerBody>
  );
};

const DrawerTitleFooter = ({
  config,
  showConnectModal,
  integration,
  noMoreIntegrationsInPlan,
}: {
  config: IntegrationConfig;
  showConnectModal: () => void;
  integration: IntegrationsListObject;
  noMoreIntegrationsInPlan: boolean;
}) => {
  const { showArticle } = useIntercom();

  const learnMore = (
    <Button
      analyticsTrackingId="settings-integrations-learn-more"
      onClick={() => showArticle(config.helpcenterArticleID)}
      theme={ButtonTheme.Naked}
      className="text-white hover:text-slate-100"
    >
      Learn more
    </Button>
  );

  if (
    (!integration.installed || integration.provider === "calendar_feeds") &&
    config.CustomConnectCTAs
  ) {
    return <config.CustomConnectCTAs />;
  }

  if (noMoreIntegrationsInPlan && !integration.installed) {
    return (
      <div className="flex gap-4">
        <Button
          href="/settings/billing"
          analyticsTrackingId="settings-integrations-request"
          icon={IconEnum.ExternalLink}
          theme={ButtonTheme.Secondary}
          style={{
            color: config.hexColor,
          }}
        >
          View plans
        </Button>
        {learnMore}
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      {integration.installed && integration.provider !== "calendar_feeds" ? (
        <DisconnectButton provider={integration.provider} />
      ) : (
        <InstallIntegrationButton
          config={config}
          integration={integration}
          showConnectModal={showConnectModal}
        />
      )}
      {learnMore}
    </div>
  );
};

const InstallIntegrationButton = ({
  integration,
  config,
  showConnectModal,
}: {
  integration: IntegrationsListObject;
  config: IntegrationConfig;
  showConnectModal: () => void;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { scimPortalURL } = useSCIMPortalURL();

  if (integration.requires_upgrade) {
    return (
      <Button
        onClick={() => window.Intercom("show")}
        analyticsTrackingId="settings-integrations-request"
        icon={IconEnum.LockClosed}
        theme={ButtonTheme.Secondary}
        style={{
          color: config.hexColor,
        }}
      >
        Talk to us
      </Button>
    );
  }

  // If there's no connect modal OR add_integration_url is not a URL OR we're
  // not working with a SCIM provider, we can't do anything here. Show a big red
  // button in dev, but in prod, show nothing at all.
  if (
    !config.CustomConnectModal &&
    !integration.add_integration_url &&
    !isScimProvider(integration.provider)
  ) {
    if (isDevelopment()) {
      return (
        <Button
          analyticsTrackingId="settings-integrations-request"
          icon={IconEnum.LockClosed}
          theme={ButtonTheme.Destroy}
        >
          No connect modal or add_integration_url - this is broken!
        </Button>
      );
    } else {
      captureException(
        new Error(
          `Found integration with no add_integration_url OR ConnectModal. This means the integration cannot be installed`,
        ),
        {
          extra: {
            provider: integration.provider,
          },
        },
      );
      return null;
    }
  }

  const hasConnectModal = !!config.CustomConnectModal;

  return (
    <GatedButton
      href={
        hasConnectModal
          ? undefined
          : isScimProvider(integration.provider) && scimPortalURL
          ? scimPortalURL
          : integration.add_integration_url
      }
      onClick={hasConnectModal ? showConnectModal : undefined}
      analyticsTrackingId="settings-integrations-install"
      upgradeRequiredProps={{
        gate: {
          type: "numeric",
          value: identity.feature_gates.integrations_count,
          featureNameSingular: "integration",
        },
        featureName: "integrations",
      }}
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      theme={ButtonTheme.Secondary}
      style={{
        color: config.hexColor,
      }}
      icon={
        integration.add_integration_url ? IconEnum.ExternalLink : IconEnum.Key
      }
    >
      Connect
    </GatedButton>
  );
};

export const IntegrationDrawerTitle = ({
  config,
  subtitle,
  setShowConnectModal,
  integration,
  onClose,
}: {
  config: IntegrationConfig;
  subtitle: string;
  setShowConnectModal;
  integration: IntegrationsListObject;
  onClose: () => void;
}) => {
  const { noMoreIntegrationsInPlan } = useIntegrations();

  return (
    <DrawerTitle
      title={
        <div className="flex items-center gap-2">
          {config.label}
          {noMoreIntegrationsInPlan && (
            <PlanBadge planName="team" size={BadgeSize.ExtraSmall} invert />
          )}
        </div>
      }
      titleAccessory={
        <IconBadge
          icon={config.icon}
          size={IconSize.Small}
          hexColor={config.hexColor}
          // In integrations, icons are either coloured logos or we should let people
          // configure it and align it to the rest of the drawer with hexColor.
          color={ColorPaletteEnum.SlateOnWhite}
        />
      }
      subtitle={subtitle}
      footer={
        !config.hideDefaultCTAs && (
          <DrawerTitleFooter
            config={config}
            showConnectModal={() => setShowConnectModal(true)}
            integration={integration}
            noMoreIntegrationsInPlan={noMoreIntegrationsInPlan}
          />
        )
      }
      onClose={onClose}
      className="text-white"
      hexColor={config.hexColor}
    />
  );
};

export const IntegrationDrawerContentLoader = () => {
  return (
    <DrawerBody>
      <LoadingBar />
      <LoadingBar />
    </DrawerBody>
  );
};
