import { DynamicSingleSelectWithObj } from "@incident-ui/Select/DynamicSingleSelect";
import { SelectOption } from "@incident-ui/Select/types";
import React from "react";
import { FieldValues, Path, useController } from "react-hook-form";
import { getTypeaheadOptions } from "src/components/@shared/forms/Typeahead";
import {
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const GithLabUserEditorV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, GitLabUserEditorProps>,
): React.ReactElement => {
  const { name, rules, inputProps, wrapperProps } = parseProps(props);
  const { field } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <GitLabUserEditor {...inputProps} {...field} />
    </FormInputWrapper>
  );
};

type GitLabUserEditorProps = {
  value: SelectOption;
  onChange: (val: SelectOption | null) => void;
  onBlur: () => void;
};

const GitLabUserEditor = ({
  value,
  onChange,
  onBlur,
}: GitLabUserEditorProps): React.ReactElement => {
  const apiClient = useClient();

  return (
    <DynamicSingleSelectWithObj
      value={value}
      onChange={onChange}
      loadOptions={getTypeaheadOptions(
        apiClient,
        TypeaheadsListTypeaheadTypeEnum.GitlabUsers,
      )}
      onBlur={onBlur}
      placeholder="Select assignee"
      autoFocus={true}
    />
  );
};
