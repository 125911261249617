import { AppliedFiltersBanner } from "@incident-shared/filters";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { GenericErrorMessage } from "@incident-ui";
import { captureException } from "@sentry/react";

import {
  Dashboard,
  useDashboardGroups,
  useDashboardMap,
} from "../dashboards/dashboards";
import { InsightsHeader } from "./InsightsHeader";

type InsightsPageProps = {
  children: React.ReactNode;
  dashboard: Dashboard;
  overflowY?: boolean;
  disableFilters?: boolean;
  disableFiltersTooltipContent?: string;
};

export const InsightsPage = ({
  dashboard,
  children,
  overflowY = true,
  disableFilters,
  disableFiltersTooltipContent,
}: InsightsPageProps) => {
  const DashboardGroups = useDashboardGroups();
  const dashboardMap = useDashboardMap();

  const details = dashboardMap[dashboard];
  const groupDetails = DashboardGroups.find(
    (group) => group.title === details.group,
  );

  if (!groupDetails) {
    const msg = `No group found for dashboard ${dashboard}`;
    console.error(msg);
    captureException(new Error(msg));
    return <GenericErrorMessage />;
  }

  return (
    <PageWrapper
      width={PageWidth.Wide}
      title={details.name}
      headerNode={
        <InsightsHeader
          title={details.name}
          groupCrumb={details.group}
          icon={groupDetails.icon}
          iconColour={groupDetails.dashboardIconColour}
          disableFilters={disableFilters || details.disableFilters}
          disableFiltersTooltipContent={disableFiltersTooltipContent}
        />
      }
      banner={
        !details.disableFilters && (
          <AppliedFiltersBanner
            totalNumberOfItems={null}
            itemsLabel={"incidents"}
          />
        )
      }
      noPadding
      overflowY={overflowY}
    >
      <div className="flex flex-col items-stretch gap-2 h-full">{children}</div>
    </PageWrapper>
  );
};
