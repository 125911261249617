import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { Actor, Incident } from "src/contexts/ClientContext";
import { ActivityLogEntry } from "src/contexts/ClientContext";

export const ActivityItemVisibilityChanged = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  const changed = item.content.incident_visibility_changed;
  if (!changed) {
    throw new Error(
      "malformed timeline item: incident_visibility_changed was missing incident_visibility_changed field",
    );
  }

  const actor: Actor = {
    user: changed.user,
  };

  return {
    icon:
      changed.new_visibility === "public"
        ? IconEnum.LockOpen
        : IconEnum.LockClosed,
    colour: ColorPaletteEnum.Purple,
    actor: actor,
    title: `Incident converted to a ${changed.new_visibility} incident`,
  };
};
