import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ModalFooter } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum,
  TextDocumentPayload,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { Category, isCategory } from "../statuses/status-utils";
import { useStatusesForIncident } from "../useIncidentCrudResources";

type FormData = { reason: TextDocumentPayload };
export const OptOutOfPostIncidentFlowModal = ({
  incident,
  onClose,
}: {
  onClose: (inc?: Incident) => void;
  incident: Incident;
}) => {
  const formMethods = useForm<FormData>();

  const incidentId = incident.id;
  const refetchTimeline = useAPIRefetch("timelineItemsList", {
    incidentId,
  });
  const refetchUpdates = useAPIRefetch("incidentUpdatesListForIncident", {
    incidentId,
  });

  const { statuses, statusesLoading } = useStatusesForIncident({ incident });

  const {
    data: { incident_tasks: tasks },
    isLoading: tasksLoading,
  } = useAPI(
    incidentId === "" ? null : "postIncidentFlowListTasks",
    { incidentId: incidentId ?? "" },
    { fallbackData: { incident_tasks: [] } },
  );

  const numUnresolvedTasks = tasks.filter(
    (task) => !task.completed_at && !task.rejected_at,
  ).length;

  const {
    trigger: createUpdate,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incidentId },
    async (apiClient, formData: FormData) => {
      await apiClient.incidentsCreateUpdate({
        incidentId,
        createUpdateRequestBody: {
          message: formData.reason,
          post_incident_flow_decision:
            IncidentsCreateUpdateRequestBodyPostIncidentFlowDecisionEnum.OptOut,
          to_incident_status_id: statuses.find(isCategory(Category.Closed))?.id,
        },
      });

      refetchTimeline();
      refetchUpdates();
    },
    {
      onSuccess: () => onClose(),
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={createUpdate}
      title={"Close incident"}
      analyticsTrackingId={"opt-out-of-post-incident-flow-modal"}
      onClose={onClose}
      loading={statusesLoading || tasksLoading}
      genericError={genericError}
      footer={
        <ModalFooter
          saving={saving}
          onClose={onClose}
          confirmButtonText="Close incident"
          confirmButtonType="submit"
        />
      }
    >
      <p className="text-sm">
        By opting out of the post-incident flow, you&apos;ll be skipping{" "}
        <strong>{`${numUnresolvedTasks} unresolved tasks`}</strong>.
      </p>
      <TemplatedTextInputV2
        name="reason.text_node"
        placeholder={`E.g. Although this was a major incident, it was purely due to a third party outage and we're satisfied with our response.`}
        label="Reason"
        formMethods={formMethods}
        required
        includeVariables={false}
        includeExpressions={false}
        format="basic"
      />
    </Form.Modal>
  );
};
