import { EscalationPreview, Incident } from "@incident-io/api";
import {
  Button,
  ButtonTheme,
  Heading,
  IconEnum,
  IconSize,
  Link,
} from "@incident-ui";
import { ActorAvatar } from "@incident-ui/Avatar/ActorAvatar";
import React, { useState } from "react";
import { useAPI } from "src/utils/swr";

import { EscalationDrawer } from "../../../escalations/EscalateDrawer";

export function IncidentEscalations({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const [showEscalationModal, setShowEscalationModal] = useState(false);
  const { data: providerData } = useAPI("escalationsListProviders", undefined, {
    fallbackData: {
      providers: [],
    },
  });

  const { data: escalationsListResponse, isLoading: escalationsListLoading } =
    useAPI("escalationsList", {
      incident: {
        one_of: [incident.id],
      },
    });

  const allEscalations = escalationsListResponse?.escalations ?? [];

  // Only show up to 5
  const maxEscalationsToShow = 5;
  const escalations = allEscalations.slice(0, maxEscalationsToShow);

  return (
    <div className="space-y-2 pb-1.5">
      <div className="flex gap-2 items-center mb-2">
        <Heading level={3} size="small">
          Escalations
        </Heading>
      </div>
      {escalationsListLoading
        ? null
        : escalations.map((escalation: EscalationPreview) => {
            return (
              <div key={escalation.id} className="flex justify-between mb-2">
                <Link
                  className="hover:!text-content-primary truncate mr-2"
                  analyticsTrackingId={"view-related-incident"}
                  to={`/on-call/escalations/${escalation.id}`}
                >
                  {escalation.title}
                </Link>

                {escalation.latest_action_actor && (
                  <ActorAvatar
                    actor={escalation.latest_action_actor}
                    size={IconSize.Small}
                  />
                )}
              </div>
            );
          })}
      {
        // If you have more than 5, just say and 5 more
        allEscalations.length > maxEscalationsToShow && (
          <div className="flex justify-between">
            <span className="hover:!text-content-primary truncate mr-2">
              ...and {allEscalations.length - maxEscalationsToShow} more
            </span>
          </div>
        )
      }
      {providerData.providers.length > 0 && (
        <div>
          <Button
            icon={IconEnum.Escalate}
            iconProps={{
              size: IconSize.Medium,
            }}
            theme={ButtonTheme.Naked}
            onClick={() => {
              setShowEscalationModal(true);
            }}
            analyticsTrackingId="add-related-incident"
            className="-ml-0.5"
          >
            Escalate to someone
          </Button>
        </div>
      )}
      {showEscalationModal && (
        <EscalationDrawer
          onClose={() => setShowEscalationModal(false)}
          incidentId={incident.id}
          showDeclareIncident={false}
          shouldWarnWhenDirty={true}
        />
      )}
    </div>
  );
}
