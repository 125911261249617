import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityLogEntry, Actor, Incident } from "src/contexts/ClientContext";

import { ActivityItemRenderProps } from "./ActivityItem";
import {
  StatusPageQuotedContent,
  StatusPageUnquotedContent,
} from "./ActivityItemStatusPageIncidentUpdated";

export const ActivityItemStatusPageIncidentLinked = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  const link = item.content.status_page_incident_linked;
  if (!link) {
    throw new Error(
      "malformed timeline item: status_page_incident_linked was missing status_page_incident_linked field",
    );
  }

  const actor: Actor = {
    user: link.linker.user,
  };

  return {
    icon: IconEnum.StatusPage,
    colour: ColorPaletteEnum.Purple,
    actor: actor,
    title: "Status page incident linked",
    quotedContent: (
      <StatusPageQuotedContent spInc={link.status_page_incident} />
    ),
    unquotedContent: (
      <StatusPageUnquotedContent spInc={link.status_page_incident} />
    ),
  };
};
