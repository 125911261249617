import { SetupPageWrapper } from "@incident-shared/layout/SetupPageWrapper/SetupPageWrapper";
import { Link } from "@incident-ui";
import React from "react";
import userAvatars from "src/components/setup/common/user-avatars.json";
import { useQueryParams } from "src/utils/query-params";

import { IncidentFlameAvatar } from "./common/IncidentFlameAvatar";
import { InstallationStepID } from "./useSetupSteps";

// SetupLogin is the explanation and CTA for someone hitting the setup flow to
// log in (i.e. oauth using Slack to tell us who they are). If they log in, and
// we recognise that the org connected to that slack team is setup already,
// we'll send them to the dashboard.
export const SetupLogin = ({
  commsPlatform,
  loginButton,
}: {
  commsPlatform: "slack" | "msteams";
  loginButton: React.ReactNode;
}): React.ReactElement => {
  const queryParams = useQueryParams();
  const inviter = queryParams.get("inviter");
  const customer = queryParams.get("customer");

  const config =
    commsPlatform === "slack"
      ? {
          name: "Slack",
          tenant: "Slack workspace",
        }
      : {
          name: "Microsoft",
          tenant: "Teams organisation",
        };

  const inviterUser = userAvatars.find((av) => av.id === inviter);

  return (
    <SetupPageWrapper
      footer={
        <>
          By signing in with {config.name}, you are agreeing to our{" "}
          <Link
            href="https://incident.io/terms"
            analyticsTrackingId={null}
            className="no-underline text-white"
          >
            terms of service
          </Link>
          .
        </>
      }
      currentStepID={InstallationStepID.Login}
    >
      {inviterUser && customer ? (
        <InvitedByAEHeader inviterUser={inviterUser} customer={customer} />
      ) : (
        <>
          <IncidentFlameAvatar />
          <div className="text-5xl-bold">Create your workspace</div>
        </>
      )}
      <div className="">{loginButton}</div>
    </SetupPageWrapper>
  );
};

const InvitedByAEHeader = ({
  inviterUser,
  customer,
}: {
  inviterUser: {
    id: string;
    firstName: string;
    avatarURL: string;
  };
  customer: string;
}) => {
  return (
    <>
      <div className="flex flex-col gap-3 items-center">
        {inviterUser ? (
          <img
            src={inviterUser?.avatarURL}
            className="w-[70px] h-[70px] rounded-4 flex items-center justify-center shadow-button-light border border-stroke-invert"
          />
        ) : (
          <IncidentFlameAvatar />
        )}
        <div className="text-base-bold">
          {inviterUser.firstName} invites you to trial incident.io
        </div>
      </div>
      <div className="text-5xl-bold">Create the {customer} workspace</div>
    </>
  );
};
