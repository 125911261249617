import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { Incident } from "src/contexts/ClientContext";
import { ActivityLogEntry } from "src/contexts/ClientContext";

import { AttachmentContent } from "./AttachmentContent";

export const ActivityItemPagerdutyIncidentAcknowledged = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.pagerduty_incident_acknowledged) {
    throw new Error(
      "malformed timeline item: pagerduty_incident_acknowledged was missing pagerduty_incident_acknowledged field",
    );
  }

  return {
    title:
      item.content.pagerduty_incident_acknowledged.external_resource
        .resource_type_label + " acknowledged",
    icon: IconEnum.ThumbsUp,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.pagerduty_incident_acknowledged?.acknowledger,
    quotedContent: (
      <AttachmentContent
        resource={
          item.content.pagerduty_incident_acknowledged.external_resource
        }
      />
    ),
  };
};
