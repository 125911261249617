import { Tooltip } from "@incident-ui";
import React from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { tcx } from "src/utils/tailwind-classes";

import { FormErrorMessage } from "../ErrorMessage";
import styles from "./FormInputWrapperV2.module.scss";

export const FormLabel = ({
  htmlFor,
  children,
  className,
  required,
  accessory,
}: {
  htmlFor: string;
  children: React.ReactNode | string;
  className?: string;
  required?: boolean | string;
  accessory?: React.ReactNode;
}): React.ReactElement => {
  if (children == null && accessory == null) {
    return <> </>;
  }

  // If you don't set required, the field will be optional but we won't show the
  // label.
  const showOptional = required === false;

  if (showOptional || accessory) {
    return (
      <div className="flex flex-row align-center gap-1">
        <label
          htmlFor={htmlFor}
          className={tcx(
            "font-medium text-content-primary text-sm -mb-2",
            className,
          )}
        >
          {children}
        </label>
        {showOptional && (
          <span className="text-content-tertiary text-sm">(optional)</span>
        )}
        {accessory}
      </div>
    );
  }

  return (
    <label
      htmlFor={htmlFor}
      className={tcx("font-medium text-content-primary text-sm", className)}
    >
      {children}
    </label>
  );
};

// FormHelpText is for helper text that appears between the label
// and the input
export const FormHelpText = ({
  children,
  className,
}: {
  children: React.ReactElement | string | React.ReactNode;
  className?: string;
}): React.ReactElement => {
  if (children == null) {
    return <> </>;
  }
  return (
    <div
      className={tcx(
        "mt-1 mb-2 text-sm text-slate-700",
        styles.readableText,
        className,
      )}
    >
      {children}
    </div>
  );
};

// FormContextText is for helper text that appears below the input
// and is smaller (and lighter grey) than FormHelpTextV2.
export const FormContextText = ({
  children,
  className,
}: {
  children: React.ReactElement | string | React.ReactNode;
  className?: string;
}): React.ReactElement => (
  <p
    className={tcx(
      "mt-1 text-xs text-slate-700",
      styles.readableText,
      className,
    )}
  >
    {children}
  </p>
);

export type FormInputWrapperProps<TFormType extends FieldValues> = {
  name: Path<TFormType>;
  label?: React.ReactNode;
  labelAccessory?: React.ReactNode;
  helptext?: string | React.ReactElement | null;
  contextText?: string | React.ReactElement | null;
  children: React.ReactNode;
  className?: string;
  labelClassName?: string;
  labelWrapperClassName?: string;
  inputWrapperClassName?: string;
  contextTextClassName?: string;
  helptextClassName?: string;
  errorClassName?: string;
  showErrorAboveComponent?: boolean;
  disableGrowInput?: boolean;
  prefixNode?: React.ReactNode;
  suffixNode?: React.ReactNode;
  required?: boolean | string;
  // Use this if you want to hide any associated errors to do your own error handling
  hideErrors?: boolean;
  disabledTooltipContent?: React.ReactNode;
  disabled?: boolean;
};

export const FormInputWrapper = <FormType extends FieldValues>({
  name,
  label,
  labelAccessory,
  helptext,
  contextText,
  children,
  className,
  showErrorAboveComponent = false,
  labelWrapperClassName,
  labelClassName,
  required,
  inputWrapperClassName,
  helptextClassName,
  contextTextClassName,
  errorClassName,
  prefixNode,
  suffixNode,
  disableGrowInput,
  hideErrors,
  disabledTooltipContent,
  disabled,
}: FormInputWrapperProps<FormType>): React.ReactElement => {
  const formContext = useFormContext<FormType>();

  return (
    <div className={className} data-intercom-target={`form-input-${name}`}>
      {(label || helptext) && (
        <div className={tcx("mb-2", labelWrapperClassName)}>
          {label && (
            <FormLabel
              htmlFor={name}
              className={labelClassName}
              required={required}
              accessory={labelAccessory}
            >
              {label}
            </FormLabel>
          )}
          {helptext && (
            <div className={tcx("max-w-[75ch]", helptextClassName)}>
              <FormHelpText>{helptext}</FormHelpText>
            </div>
          )}
        </div>
      )}
      {formContext && !hideErrors && showErrorAboveComponent && (
        <FormErrorMessage
          errors={formContext.formState.errors}
          name={name}
          className={tcx("mb-1", errorClassName)}
        />
      )}
      <Tooltip content={disabled ? disabledTooltipContent : undefined}>
        <div className={tcx(inputWrapperClassName)}>
          {suffixNode || prefixNode ? (
            <div className="flex-center-y space-x-2">
              {prefixNode}
              <div className={disableGrowInput ? "shrink-0" : "grow"}>
                {children}
              </div>
              {suffixNode}
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </Tooltip>
      {contextText && (
        <div className={contextTextClassName}>
          <FormContextText>{contextText}</FormContextText>
        </div>
      )}
      {formContext && !hideErrors && !showErrorAboveComponent && (
        <FormErrorMessage
          errors={formContext?.formState.errors}
          name={name}
          className={tcx("mt-1", errorClassName)}
        />
      )}
    </div>
  );
};
