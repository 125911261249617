import { IconEnum, Markdown, StackedList } from "@incident-ui";
import { startCase } from "lodash";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  ActionUpdated,
  Incident,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemChangeRow } from "./TimelineItemChangeRow";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemActionUpdated = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.action_updated) {
    throw new Error(
      "malformed timeline item: action_updated was missing action_updated field",
    );
  }

  const update = item.action_updated;

  return {
    description: actionCreateText(update),
    avatarUrl: update.updater?.user?.avatar_url,
    icon: IconEnum.Edit,
    children: (
      <StackedList is="dl">
        {update.description_change && (
          <TimelineItemChangeRow
            icon={IconEnum.Message}
            label="Description"
            previousValue={
              <Markdown>
                {update.description_change.previous_description}
              </Markdown>
            }
            nextValue={
              <Markdown>{update.description_change.next_description}</Markdown>
            }
          />
        )}
        {update.status_change && (
          <TimelineItemChangeRow
            icon={IconEnum.Status}
            label="Status"
            previousValue={startCase(update.status_change.previous_status)}
            nextValue={startCase(update.status_change.next_status)}
          />
        )}
        {update.assignee_change && (
          <TimelineItemChangeRow
            icon={IconEnum.User}
            label="Owner"
            previousValue={update.assignee_change.previous_user?.name}
            nextValue={update.assignee_change.next_user?.name}
          />
        )}
      </StackedList>
    ),
  };
};

const actionCreateText = (update: ActionUpdated) => {
  return (
    <>
      <TimelineItemSource actor={update.updater} /> updated an action
      {update.description_change ? undefined : (
        <>
          {": "} <strong>{update.action.description}</strong>
        </>
      )}
    </>
  );
};
