/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedMicrosoftTeamsMessage,
    EnrichedMicrosoftTeamsMessageFromJSON,
    EnrichedMicrosoftTeamsMessageFromJSONTyped,
    EnrichedMicrosoftTeamsMessageToJSON,
} from './EnrichedMicrosoftTeamsMessage';

/**
 * 
 * @export
 * @interface MicrosoftTeamsPin
 */
export interface MicrosoftTeamsPin {
    /**
     * 
     * @type {EnrichedMicrosoftTeamsMessage}
     * @memberof MicrosoftTeamsPin
     */
    message?: EnrichedMicrosoftTeamsMessage;
}

export function MicrosoftTeamsPinFromJSON(json: any): MicrosoftTeamsPin {
    return MicrosoftTeamsPinFromJSONTyped(json, false);
}

export function MicrosoftTeamsPinFromJSONTyped(json: any, ignoreDiscriminator: boolean): MicrosoftTeamsPin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : EnrichedMicrosoftTeamsMessageFromJSON(json['message']),
    };
}

export function MicrosoftTeamsPinToJSON(value?: MicrosoftTeamsPin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': EnrichedMicrosoftTeamsMessageToJSON(value.message),
    };
}

