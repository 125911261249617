import { HeaderBar } from "@incident-shared/layout/HeaderBar/HeaderBar";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Heading,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { Incident, IncidentTimelineItem } from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";

import { ActivityLogPanelInEditor } from "./ActivityLogV2";
import { SortOrder, TimelineWrapper } from "./Timeline";
import { CreateEditCustomEventDrawer } from "./timeline-v2/CreateEditCustomEventDrawer";
import { ExpandTimelineItemsButton, Timeline } from "./timeline-v2/Timeline";

export const TimelineEditor = ({
  incident,
  onClose,
}: {
  incident: Incident;
  onClose: () => void;
}) => {
  const { featureTimelineV2 } = useFlags();
  const [timelineExpanded, setTimelineExpanded] = useState<string[]>([]);
  const [timelineCommentsOpen, setCommentsOpen] = useState<string[]>([]);
  const [itemEditing, setItemEditing] = useState<IncidentTimelineItem>();
  const [showAddCustomEventDrawer, setShowAddCustomEventDrawer] =
    useState(false);

  const { data: groupedTimelineItems } = useAPI(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incident.id,
    },
  );

  const timelineItems = groupedTimelineItems?.timeline_items ?? [];

  if (featureTimelineV2) {
    return (
      <AnimatePresence>
        <Drawer
          onClose={onClose}
          width={"full"}
          isInBackground={!!itemEditing || showAddCustomEventDrawer}
        >
          <DrawerContents>
            <DrawerTitle
              title="Edit timeline"
              onClose={onClose}
              compact
              sticky
              theme={DrawerTitleTheme.Bordered}
            />
            <DrawerBody className="!p-0 overflow-y-hidden">
              <div className={"flex flex-col lg:flex-row h-full"}>
                <div
                  className={
                    "flex flex-col p-6 overflow-y-auto h-full w-full lg:w-[50%]"
                  }
                >
                  <ActivityLogPanelInEditor
                    incidentId={incident.id}
                    sortOrder={SortOrder.OldestFirst}
                  />
                </div>
                <div
                  className={
                    "flex flex-col p-6 h-full overflow-y-auto w-full bg-surface-secondary lg:w-[50%] border-l"
                  }
                >
                  <div className="grow">
                    <div className="flex-center-y justify-between mb-2">
                      <div className="flex flex-col space-y-1">
                        <Heading level={2} size="small">
                          Timeline preview
                        </Heading>
                      </div>
                      <div className="flex gap-2">
                        <ExpandTimelineItemsButton
                          expanded={timelineExpanded}
                          setExpanded={setTimelineExpanded}
                          timelineItems={timelineItems}
                        />
                        <Button
                          analyticsTrackingId={"add-custom-event"}
                          icon={IconEnum.Add}
                          onClick={() => setShowAddCustomEventDrawer(true)}
                          size={ButtonSize.Small}
                        >
                          Add custom event
                        </Button>
                      </div>
                    </div>
                    <ContentBox className="bg-white p-4">
                      <Timeline
                        incidentId={incident.id}
                        editing
                        expanded={timelineExpanded}
                        setExpanded={setTimelineExpanded}
                        commentBoxOpen={timelineCommentsOpen}
                        setCommentBoxOpen={setCommentsOpen}
                        setEditing={setItemEditing}
                      />
                    </ContentBox>
                    {showAddCustomEventDrawer && (
                      <CreateEditCustomEventDrawer
                        incident={incident}
                        onClose={() => setShowAddCustomEventDrawer(false)}
                      />
                    )}
                    {itemEditing && (
                      <CreateEditCustomEventDrawer
                        incident={incident}
                        onClose={() => setItemEditing(undefined)}
                        existingItem={itemEditing}
                      />
                    )}
                  </div>
                </div>
              </div>
            </DrawerBody>
            <DrawerFooter className="flex justify-end">
              <Button
                analyticsTrackingId={null}
                onClick={onClose}
                theme={ButtonTheme.Primary}
              >
                Done
              </Button>
            </DrawerFooter>
          </DrawerContents>
        </Drawer>
      </AnimatePresence>
    );
  }

  return (
    <PageWrapper
      width={PageWidth.Full}
      noPadding
      headerNode={<TimelineEditorHeader incident={incident} />}
      overflowY={false}
      title={"Timeline editor"}
    >
      <div className={"flex flex-col lg:flex-row h-full"}>
        <div className={"flex flex-col px-8 py-6 h-full w-full lg:w-[50%]"}>
          <div className="overflow-y-auto grow">
            <Heading level={3} size="medium" className="mb-4">
              Timeline
            </Heading>
            <TimelineWrapper
              incidentId={incident.id}
              sortOrder={SortOrder.OldestFirst}
              activeFilters={[]}
              isEditMode={true}
            />
          </div>
        </div>
        <div className={"flex flex-col px-8 py-6 h-full w-full lg:w-[50%]"}>
          <div className="overflow-y-auto grow">
            <Heading level={3} size="medium" className="mb-4">
              Activity log
            </Heading>
            <ActivityLogPanelInEditor
              incidentId={incident.id}
              sortOrder={SortOrder.OldestFirst}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

const TimelineEditorHeader = ({
  incident,
}: {
  incident: Incident;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const backHref = `/incidents/${incident.external_id}?tab=timeline`;

  return (
    <>
      <HeaderBar
        title="Timeline editor"
        className="px-4 md:px-8"
        icon={IconEnum.Incident}
        backOnClick={() => navigate(backHref, { replace: true })}
        crumbs={[
          {
            title: "Incidents",
            to: "/incidents",
          },
          {
            title: `INC-${incident.external_id}: ${incident.name}`,
            onClick: (_) => navigate(backHref, { replace: true }),
          },
        ]}
        accessory={
          <Button
            analyticsTrackingId={"exit-timeline-editor"}
            title="Done"
            theme={ButtonTheme.Primary}
            onClick={() => navigate(backHref, { replace: true })}
          >
            Done
          </Button>
        }
      />
      <Callout theme={CalloutTheme.Plain} className="!pl-8 mb-2">
        Edit your timeline by adding events from the activity log, or by
        hovering between timeline items and clicking &apos;Add event&apos;. Any
        changes will be reflected in your postmortem. You will need to leave the
        editor to add comments.
      </Callout>
    </>
  );
};
