import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Prompt } from "@incident-shared/utils/Prompt";
import { ErrorMessage } from "@incident-ui";
import {
  Button,
  ButtonTheme,
  ContentBox,
  FloatingFooter,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Toggle,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { StaticMultiSelect } from "@incident-ui/Select/StaticMultiSelect";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { TimelineItemNames } from "src/components/timeline/timeline-items/utils";
import {
  IncidentsBuildScopeContextEnum,
  PostmortemsCreateTemplateRequestBody,
  PostmortemsUpdateTemplateRequestBody,
  PostmortemTemplate,
  PostmortemTemplateBlock,
  PostmortemTemplateBlockBlockTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useIsDirtyWithTemplatedText } from "src/hooks/useIsDirtyWithTemplatedText";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { v4 as uuid4 } from "uuid";

import { PostmortemTemplateContentBlocksEditor } from "./PostmortemTemplateContentBlocksEditor";

type FormData = Omit<
  PostmortemsCreateTemplateRequestBody | PostmortemsUpdateTemplateRequestBody,
  "blocks"
> & {
  // the state of this will be handled separately
  // keeping it so we can show errors in the form input wrapper for this field
  _blocks?: string | undefined;
  filter_timeline_types_enabled: boolean;
};

export const PostmortemTemplateCreateEditForm = ({
  initialData,
  mode,
}: CreateEditFormProps<PostmortemTemplate>): React.ReactElement => {
  const isEditing = mode === Mode.Edit;
  const { postmortemNameFormatted } = usePostmortemName(null);
  const formMethods = useForm<FormData>({
    defaultValues: {
      name: initialData?.name ?? undefined,
      is_default: initialData?.is_default ?? false,
      filter_timeline_types_enabled:
        !!initialData?.timeline_items_blocklist?.length,
      document_title: initialData?.document_title ?? {
        type: "doc",
        content: [
          {
            type: "paragraph",
            content: [
              {
                type: "varSpec",
                attrs: {
                  label: "Incident → Reference",
                  missing: false,
                  name: "incident.reference",
                },
              },
              {
                type: "text",
                text: ": ",
              },
              {
                type: "varSpec",
                attrs: {
                  label: "Incident → Name",
                  missing: false,
                  name: "incident.name",
                },
              },
            ],
          },
        ],
      },
    },
  });
  const { setValue, setError, clearErrors, reset, watch } = formMethods;

  const isDirty = useIsDirtyWithTemplatedText({
    values: formMethods.getValues(),
    formState: formMethods.formState,
    textFieldNames: ["document_title"],
  });

  const [filterTimelineTypesEnabled] = watch(["filter_timeline_types_enabled"]);

  const [selectedFilterTimelineItems, setSelectedTimelineItems] = useState<
    string[]
  >(initialData?.timeline_items_blocklist || []);

  const [blocks, _setBlocks] = useState<Array<
    PostmortemTemplateBlock & { id: string }
  > | null>(
    initialData?.blocks.map((blockResponse) => {
      return {
        ...blockResponse,
        id: uuid4(),
        block_type:
          blockResponse.block_type as string as PostmortemTemplateBlockBlockTypeEnum,
      };
    }) ?? null,
  );

  const checkTimelineBlockExistence = (
    blocks: PostmortemTemplateBlock[] | null,
  ): boolean => {
    return (
      blocks?.some((b) => {
        return b.block_type === PostmortemTemplateBlockBlockTypeEnum.Timeline;
      }) || false
    );
  };

  let hasIncidentTimelineBlock = checkTimelineBlockExistence(blocks);

  const setBlocks = (
    newBlocks: (PostmortemTemplateBlock & { id: string })[] | null,
  ) => {
    setValue("_blocks", undefined, { shouldDirty: blocks != null });
    _setBlocks(newBlocks);
    clearErrors("_blocks");

    hasIncidentTimelineBlock = checkTimelineBlockExistence(newBlocks);
  };

  const formDisabled = !useIdentity().hasScope(
    ScopeNameEnum.OrganisationSettingsUpdate,
  );

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
  );

  const navigate = useOrgAwareNavigate();
  const { mutate: updateExistingTemplate } = useAPI(
    initialData ? "postmortemsShowTemplate" : null,
    { id: initialData?.id ?? "" },
  );

  // This is a hack to stop us showing the "Your changes are unsaved, are you sure you want to navigate away?"
  // prompt when the form has been submitted and we are in the process of navigating back to the
  // postmortem list.
  const [hasSubmit, setHasSubmit] = useState(false);

  const {
    trigger: createTemplate,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsListTemplates",
    {},
    async (apiClient, formData: FormData) => {
      setHasSubmit(true);

      if (!blocks || blocks.length === 0) {
        setError("_blocks", {
          type: "minLength",
          message: "Template must contain at least one content block",
        });
        return Promise.reject();
      }

      const requestBody = {
        ...formData,
        blocks,
      } as PostmortemsUpdateTemplateRequestBody;
      requestBody.timeline_items_blocklist = selectedFilterTimelineItems || [];

      if (initialData) {
        const updatedTemplate = await apiClient.postmortemsUpdateTemplate({
          id: initialData.id,
          updateTemplateRequestBody: requestBody,
        });
        updateExistingTemplate(updatedTemplate);
      } else {
        if (!formData.document_title) {
          setError("document_title", {
            type: "required",
            message: "Please provide a title",
          });
          return Promise.reject();
        }
        await apiClient.postmortemsCreateTemplate({
          createTemplateRequestBody: {
            ...requestBody,
            document_title: formData.document_title,
          },
        });
      }
      return Promise.resolve();
    },
    {
      setError: (name, error, opt) => {
        if (name.startsWith("blocks")) {
          name = "_blocks";
        }

        setError(name, error, opt);
      },
      onSuccess: () => {
        reset();
        navigate("/settings/post-mortem");
      },
    },
  );

  // TODO [Somewhere in the timeline projects] - we're going to rip out this setting entirely.
  const filterTimelineItemOptions = Object.entries(TimelineItemNames).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );
  filterTimelineItemOptions.sort((a, b) => a.label.localeCompare(b.label));

  if (scopeLoading) {
    return <FullPageLoader />;
  }

  if (scopeError) {
    return (
      <GenericErrorMessage description="We couldn't load the postmortem template." />
    );
  }

  return (
    <SecondaryNavSubPageWrapper
      icon={IconEnum.Doc}
      crumbs={[
        {
          title: "Post-mortems",
          to: "/settings/post-mortem",
        },
      ]}
      title={
        isEditing
          ? `Update ${postmortemNameFormatted} document template`
          : `Create a new ${postmortemNameFormatted} document template`
      }
      backHref="/settings/post-mortem"
    >
      <Form.Root
        onSubmit={createTemplate}
        formMethods={formMethods}
        saving={saving}
      >
        <ContentBox className="p-4 space-y-2">
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            helptext="An easily identifiable name for this post-mortem document template."
            required="Please enter a name"
            placeholder="e.g. Security Breach Template"
            disabled={formDisabled}
          />
          <TemplatedTextInputV2
            className="space-y-2"
            scope={scope}
            formMethods={formMethods}
            name="document_title"
            format="plain"
            includeVariables
            includeExpressions={false}
            label="Document Title"
            required="Please provide a title"
            multiLine={false}
          />
          {(!initialData?.is_default && (
            <CheckboxV2
              formMethods={formMethods}
              name={"is_default"}
              label={"Make this your default template."}
              disabled={formDisabled}
            />
          )) || (
            <Form.Helptext>
              <div className="flex flex-row gap-1">
                <Icon id={IconEnum.Info} />
                This is your default template
              </div>
            </Form.Helptext>
          )}
        </ContentBox>
        <ContentBox className="p-4">
          <Form.InputWrapper
            name="_blocks"
            label="Content blocks"
            helptext="Structure the document template by adding, modifying and reordering content blocks."
          >
            <PostmortemTemplateContentBlocksEditor
              blocks={blocks}
              setBlocks={setBlocks}
              formDisabled={formDisabled}
            />
          </Form.InputWrapper>
        </ContentBox>
        {hasIncidentTimelineBlock && (
          <ContentBox className="p-4">
            <Toggle
              id="filter_timeline_types_enabled"
              disabled={false}
              align="left"
              label="Filter incident timeline items?"
              description='Which types of timeline items would you like to be filtered out from the "Incident timeline" content block?'
              on={filterTimelineTypesEnabled}
              onToggle={() => {
                // If the toggle is being disabled, then lets clear the selected options.
                if (filterTimelineTypesEnabled) {
                  setSelectedTimelineItems([]);
                }

                setValue(
                  "filter_timeline_types_enabled",
                  !filterTimelineTypesEnabled,
                );
              }}
            />
            {filterTimelineTypesEnabled && (
              <div className="pl-11 pt-3 flex items-center">
                <StaticMultiSelect
                  options={filterTimelineItemOptions}
                  value={selectedFilterTimelineItems}
                  isClearable
                  onChange={(o) => {
                    setSelectedTimelineItems(o as string[]);
                  }}
                />
              </div>
            )}
          </ContentBox>
        )}
        <FloatingFooter>
          <div>
            <ErrorMessage message={genericError} />
          </div>
          <Button
            analyticsTrackingId="policies-create-edit-form-cancel"
            href="/settings/post-mortem"
            className="flex-center"
          >
            Cancel
          </Button>
          <Button
            analyticsTrackingId="policies-create-edit-form-submit"
            type="submit"
            theme={ButtonTheme.Primary}
            className="flex-center"
            loading={saving}
            disabled={formDisabled}
          >
            {isEditing ? "Save" : "Create"}
          </Button>
        </FloatingFooter>
      </Form.Root>
      <Prompt
        when={isDirty && !hasSubmit}
        message="Your changes are unsaved, are you sure you want to navigate away?"
      />
    </SecondaryNavSubPageWrapper>
  );
};
