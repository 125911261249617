import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

import { AttachmentContent } from "./AttachmentContent";

export const ActivityItemIncidentAttachmentRemoved = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_attachment_removed) {
    throw new Error(
      "malformed timeline item: incident_attachment_removed was missing incident_attachment_removed field",
    );
  }

  return {
    title:
      "Detatched a " +
      item.content.incident_attachment_removed.external_resource
        .resource_type_label,
    icon: IconEnum.LinkBreak,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_attachment_removed.remover,
    quotedContent: (
      <AttachmentContent
        resource={item.content.incident_attachment_removed.external_resource}
      />
    ),
  };
};
