import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
} from "@incident-ui";

import graphic from "./teams-dashboard-empty-graphic.svg";

export const NoTeamsEmptyState = () => {
  return (
    <FullPageEmptyState
      title="Unlock Team-based insights"
      subtitle="By connecting users with their respective teams in Catalog, you can unlock powerful new insights"
      gradientClasses="from-[#F3E8FFFF] to-[#F3E8FF00]"
      graphic={<img src={graphic} className="my-6" />}
      className="p-6"
      cta={
        <Button
          analyticsTrackingId={null}
          theme={ButtonTheme.Primary}
          href="/catalog/team-wizard"
          openInNewTab={true}
        >
          Set up Teams in Catalog
        </Button>
      }
      cards={
        <>
          <Card
            icon={IconEnum.Activity}
            color={ColorPaletteEnum.Violet}
            title="Workload"
            description="Understand hours spent on incidents per team"
          />
          <Card
            icon={IconEnum.Action}
            color={ColorPaletteEnum.Violet}
            title="Follow-ups & Tasks"
            description="Spot trends in Follow-up completion rates, by team"
          />
          <Card
            icon={IconEnum.Users}
            color={ColorPaletteEnum.Violet}
            title="Split by"
            description="Break down data by team, or by users within a team"
          />
        </>
      }
    />
  );
};
