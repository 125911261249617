import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { Avatar, Icon, IconEnum, IconSize, StackedList } from "@incident-ui";
import React from "react";
import {
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  Severity,
  TimelineItemIncidentUpdate,
  TimelineItemObject,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { TimelineItemRenderProps } from "./TimelineItem";
import { TimelineItemChangeRow } from "./TimelineItemChangeRow";
import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";
import { StreamDetails } from "./TimelineItemStreamDetails";

type UpdateProps = {
  incidentUpdate: TimelineItemIncidentUpdate;
};

export const statusHasChanged = (
  incidentUpdate?: TimelineItemIncidentUpdate,
): boolean => {
  if (!incidentUpdate) {
    return false;
  }

  return (
    incidentUpdate.new_incident_status.id !==
    incidentUpdate.previous_incident_status?.id
  );
};

export const SeverityUpdateText = ({
  incidentUpdate,
}: UpdateProps): React.ReactElement => {
  return (
    <TimelineItemChangeRow
      icon={IconEnum.Severity}
      label="Severity"
      previousValue={severityNameOrFallback(incidentUpdate.previous_severity)}
      nextValue={severityNameOrFallback(incidentUpdate.new_severity)}
      hasNotChanged={
        incidentUpdate.previous_severity?.rank ===
        incidentUpdate.new_severity?.rank
      }
    />
  );
};

export function severityNameOrFallback(severity: Severity | undefined) {
  return severity?.name ?? "Unset";
}

export const StatusUpdateText = ({
  incidentUpdate,
}: {
  incidentUpdate: TimelineItemIncidentUpdate;
}): React.ReactElement | null => {
  if (!statusHasChanged(incidentUpdate)) {
    return null;
  }

  return (
    <>
      <div className="flex-center-y p-4">
        <Icon
          id={IconEnum.Status}
          size={IconSize.Large}
          className="text-slate-600 mr-2 shrink-0"
        />
        <dt className="mr-2">Status:</dt>
        {<StatusChangeText incidentUpdate={incidentUpdate} />}
      </div>
    </>
  );
};

export const StatusChangeText = ({
  incidentUpdate,
}: {
  incidentUpdate: TimelineItemIncidentUpdate;
}): React.ReactElement => {
  const newStatusName = incidentUpdate.new_incident_status.name;
  if (
    incidentUpdate.previous_incident_status?.id ===
    incidentUpdate.new_incident_status.id
  ) {
    return <dd className="font-semibold">{newStatusName}</dd>;
  }
  return (
    <div className="flex-center-y">
      {incidentUpdate.previous_incident_status && (
        <>
          <del className="line-through text-slate-600" aria-hidden>
            {incidentUpdate.previous_incident_status?.name}
          </del>
          <Icon id={IconEnum.ArrowRight} className="mx-1" />
        </>
      )}
      <dd className="font-semibold">{newStatusName}</dd>
    </div>
  );
};

export const NextUpdate = ({
  incidentUpdate,
}: UpdateProps): React.ReactElement => {
  return (
    <>
      <div className="flex-center-y p-4">
        <Icon
          id={IconEnum.Clock}
          size={IconSize.Large}
          className="text-slate-600 mr-2 shrink-0"
        />
        <dt className="mr-2">Next update:</dt>
        <dd className="font-semibold">{incidentUpdate.next_update_time}</dd>
      </div>
    </>
  );
};

export const UpdateText = ({
  incidentUpdate,
}: UpdateProps): React.ReactElement => {
  return (
    <div className="flex-center-y p-4">
      <Icon
        id={IconEnum.Message}
        size={IconSize.Large}
        className="text-slate-600 mr-2 shrink-0"
      />
      <TemplatedTextDisplay
        value={incidentUpdate.update_text?.text_node || ""}
        className="whitespace-pre-wrap"
        style={TemplatedTextDisplayStyle.Compact}
      />
    </div>
  );
};

export const TimelineItemIncidentUpdateComponent = (
  incident: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_update) {
    throw new Error(
      "malformed timeline item: incident_update was missing incident_update field",
    );
  }

  // Special case for stream created/closed
  const streamCreated =
    item.is_stream && !item.incident_update.previous_incident_status;
  const streamClosed =
    item.is_stream &&
    item.incident_update.previous_incident_status?.category !==
      IncidentStatusCategoryEnum.Closed &&
    item.incident_update.new_incident_status.category ===
      IncidentStatusCategoryEnum.Closed;

  if (streamCreated || streamClosed) {
    const streamItemChild = streamCreated ? (
      <StreamDetails streamId={item.incident_id} hidden={false} />
    ) : (
      item.incident_update.update_text && (
        <StackedList is="dl">
          <UpdateText incidentUpdate={item.incident_update} />
        </StackedList>
      )
    );

    return {
      avatarUrl: item.incident_update.updater?.user?.avatar_url,
      icon: IconEnum.GitBranchNew,
      description: (
        <>
          <TimelineItemSource actor={item.incident_update.updater} />{" "}
          {streamCreated ? "created a new" : "closed a"} stream
        </>
      ),
      children: streamItemChild && <>{streamItemChild}</>,
    };
  }

  const isStatusUpdate =
    !!item.incident_update?.new_incident_status &&
    item.incident_update?.new_incident_status.id !==
      item.incident_update?.previous_incident_status?.id;

  const incidentUpdate = item.incident_update;

  if (isStatusUpdate) {
    // If the incident was reported, show a special status change.
    if (!item.incident_update.previous_incident_status) {
      let description = (
        <>
          <TimelineItemSource actor={item.incident_update.updater} /> reported
          the incident
        </>
      );
      if (incident.mode === IncidentModeEnum.Retrospective) {
        description = (
          <>
            <TimelineItemSource actor={item.incident_update.updater} /> opened
            this retrospective incident
          </>
        );
      }
      return {
        icon: IconEnum.Incident,
        isStatusUpdate,
        description: description,
        avatarUrl: item.incident_update.updater?.user?.avatar_url,
        children: (
          <>
            <TimelineItemContentBox>
              <IncidentUpdateValue
                incidentUpdate={incidentUpdate}
                isStream={item.is_stream}
              />
            </TimelineItemContentBox>
          </>
        ),
      };
    }

    return {
      icon: IconEnum.Incident,
      isStatusUpdate,
      description: (
        <div className="flex-center-y">
          <dt className="mr-2">Status changed from</dt>
          <StatusChangeText incidentUpdate={incidentUpdate} />
        </div>
      ),
      children: (
        <>
          <TimelineItemContentBox>
            <div className="flex items-center gap-2 pb-4">
              <Icon
                id={IconEnum.Info}
                size={IconSize.Large}
                className="text-slate-600 shrink-0"
              />
              <Avatar
                size={IconSize.Large}
                name={item.incident_update.updater?.user?.name}
                url={item.incident_update.updater?.user?.avatar_url}
              />
              <TimelineItemSource actor={item.incident_update.updater} />{" "}
              <p>
                {item.incident_update.updater?.user
                  ? ` shared an update`
                  : ` updated the incident`}
              </p>
            </div>
            <IncidentUpdateValue
              incidentUpdate={incidentUpdate}
              isStream={item.is_stream}
            />
          </TimelineItemContentBox>
        </>
      ),
    };
  }
  return {
    avatarUrl: item.incident_update.updater?.user?.avatar_url,
    icon: IconEnum.Info,
    description: (
      <>
        <TimelineItemSource actor={item.incident_update.updater} />{" "}
        {item.incident_update.updater?.user
          ? `shared an update`
          : `updated the incident`}
      </>
    ),
    children: (
      <StackedList is="dl">
        {!item.is_stream && (
          <SeverityUpdateText incidentUpdate={incidentUpdate} />
        )}
        <StatusUpdateText incidentUpdate={incidentUpdate} />
        {incidentUpdate.next_update_time && (
          <NextUpdate incidentUpdate={incidentUpdate} />
        )}
        {incidentUpdate.update_text && (
          <UpdateText incidentUpdate={incidentUpdate} />
        )}
      </StackedList>
    ),
  };
};

export const IncidentUpdateValue = ({
  incidentUpdate,
  hidden,
  children,
  isStream,
}: {
  incidentUpdate: TimelineItemIncidentUpdate;
  hidden?: boolean;
  children?: React.ReactNode;
  isStream?: boolean;
}) => (
  <StackedList is="dl" className={tcx({ "!bg-surface-secondary": hidden }, "")}>
    {!isStream && <SeverityUpdateText incidentUpdate={incidentUpdate} />}
    <StatusUpdateText incidentUpdate={incidentUpdate} />
    {incidentUpdate.next_update_time && (
      <NextUpdate incidentUpdate={incidentUpdate} />
    )}
    {incidentUpdate.update_text && (
      <UpdateText incidentUpdate={incidentUpdate} />
    )}
    {children}
  </StackedList>
);
