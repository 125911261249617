/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionCreated,
    ActionCreatedFromJSON,
    ActionCreatedFromJSONTyped,
    ActionCreatedToJSON,
} from './ActionCreated';
import {
    ActionUpdated,
    ActionUpdatedFromJSON,
    ActionUpdatedFromJSONTyped,
    ActionUpdatedToJSON,
} from './ActionUpdated';
import {
    AtlassianStatuspageUpdate,
    AtlassianStatuspageUpdateFromJSON,
    AtlassianStatuspageUpdateFromJSONTyped,
    AtlassianStatuspageUpdateToJSON,
} from './AtlassianStatuspageUpdate';
import {
    CustomFieldValueUpdate,
    CustomFieldValueUpdateFromJSON,
    CustomFieldValueUpdateFromJSONTyped,
    CustomFieldValueUpdateToJSON,
} from './CustomFieldValueUpdate';
import {
    EscalationCreated,
    EscalationCreatedFromJSON,
    EscalationCreatedFromJSONTyped,
    EscalationCreatedToJSON,
} from './EscalationCreated';
import {
    FollowUpCreated,
    FollowUpCreatedFromJSON,
    FollowUpCreatedFromJSONTyped,
    FollowUpCreatedToJSON,
} from './FollowUpCreated';
import {
    FollowUpUpdated,
    FollowUpUpdatedFromJSON,
    FollowUpUpdatedFromJSONTyped,
    FollowUpUpdatedToJSON,
} from './FollowUpUpdated';
import {
    Handover,
    HandoverFromJSON,
    HandoverFromJSONTyped,
    HandoverToJSON,
} from './Handover';
import {
    IncidentAttachmentAdded,
    IncidentAttachmentAddedFromJSON,
    IncidentAttachmentAddedFromJSONTyped,
    IncidentAttachmentAddedToJSON,
} from './IncidentAttachmentAdded';
import {
    IncidentAttachmentRemoved,
    IncidentAttachmentRemovedFromJSON,
    IncidentAttachmentRemovedFromJSONTyped,
    IncidentAttachmentRemovedToJSON,
} from './IncidentAttachmentRemoved';
import {
    IncidentCallEnded,
    IncidentCallEndedFromJSON,
    IncidentCallEndedFromJSONTyped,
    IncidentCallEndedToJSON,
} from './IncidentCallEnded';
import {
    IncidentCallStarted,
    IncidentCallStartedFromJSON,
    IncidentCallStartedFromJSONTyped,
    IncidentCallStartedToJSON,
} from './IncidentCallStarted';
import {
    IncidentEscalate,
    IncidentEscalateFromJSON,
    IncidentEscalateFromJSONTyped,
    IncidentEscalateToJSON,
} from './IncidentEscalate';
import {
    IncidentMembershipRevoked,
    IncidentMembershipRevokedFromJSON,
    IncidentMembershipRevokedFromJSONTyped,
    IncidentMembershipRevokedToJSON,
} from './IncidentMembershipRevoked';
import {
    IncidentMerged,
    IncidentMergedFromJSON,
    IncidentMergedFromJSONTyped,
    IncidentMergedToJSON,
} from './IncidentMerged';
import {
    IncidentRename,
    IncidentRenameFromJSON,
    IncidentRenameFromJSONTyped,
    IncidentRenameToJSON,
} from './IncidentRename';
import {
    IncidentTimestampSet,
    IncidentTimestampSetFromJSON,
    IncidentTimestampSetFromJSONTyped,
    IncidentTimestampSetToJSON,
} from './IncidentTimestampSet';
import {
    IncidentTypeChanged,
    IncidentTypeChangedFromJSON,
    IncidentTypeChangedFromJSONTyped,
    IncidentTypeChangedToJSON,
} from './IncidentTypeChanged';
import {
    IncidentVisibilityChanged,
    IncidentVisibilityChangedFromJSON,
    IncidentVisibilityChangedFromJSONTyped,
    IncidentVisibilityChangedToJSON,
} from './IncidentVisibilityChanged';
import {
    MicrosoftTeamsPin,
    MicrosoftTeamsPinFromJSON,
    MicrosoftTeamsPinFromJSONTyped,
    MicrosoftTeamsPinToJSON,
} from './MicrosoftTeamsPin';
import {
    PagerdutyIncidentAcknowledged,
    PagerdutyIncidentAcknowledgedFromJSON,
    PagerdutyIncidentAcknowledgedFromJSONTyped,
    PagerdutyIncidentAcknowledgedToJSON,
} from './PagerdutyIncidentAcknowledged';
import {
    PagerdutyIncidentResolved,
    PagerdutyIncidentResolvedFromJSON,
    PagerdutyIncidentResolvedFromJSONTyped,
    PagerdutyIncidentResolvedToJSON,
} from './PagerdutyIncidentResolved';
import {
    PagerdutyIncidentTriggered,
    PagerdutyIncidentTriggeredFromJSON,
    PagerdutyIncidentTriggeredFromJSONTyped,
    PagerdutyIncidentTriggeredToJSON,
} from './PagerdutyIncidentTriggered';
import {
    RoleUpdate,
    RoleUpdateFromJSON,
    RoleUpdateFromJSONTyped,
    RoleUpdateToJSON,
} from './RoleUpdate';
import {
    SeverityUpdate,
    SeverityUpdateFromJSON,
    SeverityUpdateFromJSONTyped,
    SeverityUpdateToJSON,
} from './SeverityUpdate';
import {
    SlackImage,
    SlackImageFromJSON,
    SlackImageFromJSONTyped,
    SlackImageToJSON,
} from './SlackImage';
import {
    SlackInferGithub,
    SlackInferGithubFromJSON,
    SlackInferGithubFromJSONTyped,
    SlackInferGithubToJSON,
} from './SlackInferGithub';
import {
    SlackInferSentry,
    SlackInferSentryFromJSON,
    SlackInferSentryFromJSONTyped,
    SlackInferSentryToJSON,
} from './SlackInferSentry';
import {
    SlackPin,
    SlackPinFromJSON,
    SlackPinFromJSONTyped,
    SlackPinToJSON,
} from './SlackPin';
import {
    StatusChange,
    StatusChangeFromJSON,
    StatusChangeFromJSONTyped,
    StatusChangeToJSON,
} from './StatusChange';
import {
    StatusPageIncidentLinked,
    StatusPageIncidentLinkedFromJSON,
    StatusPageIncidentLinkedFromJSONTyped,
    StatusPageIncidentLinkedToJSON,
} from './StatusPageIncidentLinked';
import {
    StatusPageIncidentUpdated,
    StatusPageIncidentUpdatedFromJSON,
    StatusPageIncidentUpdatedFromJSONTyped,
    StatusPageIncidentUpdatedToJSON,
} from './StatusPageIncidentUpdated';
import {
    SummaryUpdate,
    SummaryUpdateFromJSON,
    SummaryUpdateFromJSONTyped,
    SummaryUpdateToJSON,
} from './SummaryUpdate';
import {
    TimelineItemIncidentUpdate,
    TimelineItemIncidentUpdateFromJSON,
    TimelineItemIncidentUpdateFromJSONTyped,
    TimelineItemIncidentUpdateToJSON,
} from './TimelineItemIncidentUpdate';

/**
 * 
 * @export
 * @interface ActivityLogContent
 */
export interface ActivityLogContent {
    /**
     * 
     * @type {ActionCreated}
     * @memberof ActivityLogContent
     */
    action_created?: ActionCreated;
    /**
     * 
     * @type {ActionUpdated}
     * @memberof ActivityLogContent
     */
    action_updated?: ActionUpdated;
    /**
     * 
     * @type {AtlassianStatuspageUpdate}
     * @memberof ActivityLogContent
     */
    atlassian_statuspage_update?: AtlassianStatuspageUpdate;
    /**
     * 
     * @type {CustomFieldValueUpdate}
     * @memberof ActivityLogContent
     */
    custom_field_value_update?: CustomFieldValueUpdate;
    /**
     * 
     * @type {EscalationCreated}
     * @memberof ActivityLogContent
     */
    escalation_created?: EscalationCreated;
    /**
     * 
     * @type {FollowUpCreated}
     * @memberof ActivityLogContent
     */
    follow_up_created?: FollowUpCreated;
    /**
     * 
     * @type {FollowUpUpdated}
     * @memberof ActivityLogContent
     */
    follow_up_updated?: FollowUpUpdated;
    /**
     * 
     * @type {Handover}
     * @memberof ActivityLogContent
     */
    handover?: Handover;
    /**
     * 
     * @type {IncidentAttachmentAdded}
     * @memberof ActivityLogContent
     */
    incident_attachment_added?: IncidentAttachmentAdded;
    /**
     * 
     * @type {IncidentAttachmentRemoved}
     * @memberof ActivityLogContent
     */
    incident_attachment_removed?: IncidentAttachmentRemoved;
    /**
     * 
     * @type {IncidentCallEnded}
     * @memberof ActivityLogContent
     */
    incident_call_ended?: IncidentCallEnded;
    /**
     * 
     * @type {IncidentCallStarted}
     * @memberof ActivityLogContent
     */
    incident_call_started?: IncidentCallStarted;
    /**
     * 
     * @type {IncidentEscalate}
     * @memberof ActivityLogContent
     */
    incident_escalate?: IncidentEscalate;
    /**
     * 
     * @type {IncidentMembershipRevoked}
     * @memberof ActivityLogContent
     */
    incident_membership_revoked?: IncidentMembershipRevoked;
    /**
     * 
     * @type {IncidentMerged}
     * @memberof ActivityLogContent
     */
    incident_merged?: IncidentMerged;
    /**
     * 
     * @type {IncidentRename}
     * @memberof ActivityLogContent
     */
    incident_rename?: IncidentRename;
    /**
     * 
     * @type {IncidentTimestampSet}
     * @memberof ActivityLogContent
     */
    incident_timestamp_set?: IncidentTimestampSet;
    /**
     * 
     * @type {IncidentTypeChanged}
     * @memberof ActivityLogContent
     */
    incident_type_changed?: IncidentTypeChanged;
    /**
     * 
     * @type {TimelineItemIncidentUpdate}
     * @memberof ActivityLogContent
     */
    incident_update?: TimelineItemIncidentUpdate;
    /**
     * 
     * @type {IncidentVisibilityChanged}
     * @memberof ActivityLogContent
     */
    incident_visibility_changed?: IncidentVisibilityChanged;
    /**
     * 
     * @type {MicrosoftTeamsPin}
     * @memberof ActivityLogContent
     */
    microsoft_teams_pin?: MicrosoftTeamsPin;
    /**
     * 
     * @type {PagerdutyIncidentAcknowledged}
     * @memberof ActivityLogContent
     */
    pagerduty_incident_acknowledged?: PagerdutyIncidentAcknowledged;
    /**
     * 
     * @type {PagerdutyIncidentResolved}
     * @memberof ActivityLogContent
     */
    pagerduty_incident_resolved?: PagerdutyIncidentResolved;
    /**
     * 
     * @type {PagerdutyIncidentTriggered}
     * @memberof ActivityLogContent
     */
    pagerduty_incident_triggered?: PagerdutyIncidentTriggered;
    /**
     * 
     * @type {RoleUpdate}
     * @memberof ActivityLogContent
     */
    role_update?: RoleUpdate;
    /**
     * 
     * @type {SeverityUpdate}
     * @memberof ActivityLogContent
     */
    severity_update?: SeverityUpdate;
    /**
     * 
     * @type {SlackImage}
     * @memberof ActivityLogContent
     */
    slack_image?: SlackImage;
    /**
     * 
     * @type {SlackInferGithub}
     * @memberof ActivityLogContent
     */
    slack_infer_github?: SlackInferGithub;
    /**
     * 
     * @type {SlackInferSentry}
     * @memberof ActivityLogContent
     */
    slack_infer_sentry?: SlackInferSentry;
    /**
     * 
     * @type {SlackPin}
     * @memberof ActivityLogContent
     */
    slack_pin?: SlackPin;
    /**
     * 
     * @type {StatusChange}
     * @memberof ActivityLogContent
     */
    status_change?: StatusChange;
    /**
     * 
     * @type {StatusPageIncidentLinked}
     * @memberof ActivityLogContent
     */
    status_page_incident_linked?: StatusPageIncidentLinked;
    /**
     * 
     * @type {StatusPageIncidentUpdated}
     * @memberof ActivityLogContent
     */
    status_page_incident_updated?: StatusPageIncidentUpdated;
    /**
     * 
     * @type {SummaryUpdate}
     * @memberof ActivityLogContent
     */
    summary_update?: SummaryUpdate;
}

export function ActivityLogContentFromJSON(json: any): ActivityLogContent {
    return ActivityLogContentFromJSONTyped(json, false);
}

export function ActivityLogContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action_created': !exists(json, 'action_created') ? undefined : ActionCreatedFromJSON(json['action_created']),
        'action_updated': !exists(json, 'action_updated') ? undefined : ActionUpdatedFromJSON(json['action_updated']),
        'atlassian_statuspage_update': !exists(json, 'atlassian_statuspage_update') ? undefined : AtlassianStatuspageUpdateFromJSON(json['atlassian_statuspage_update']),
        'custom_field_value_update': !exists(json, 'custom_field_value_update') ? undefined : CustomFieldValueUpdateFromJSON(json['custom_field_value_update']),
        'escalation_created': !exists(json, 'escalation_created') ? undefined : EscalationCreatedFromJSON(json['escalation_created']),
        'follow_up_created': !exists(json, 'follow_up_created') ? undefined : FollowUpCreatedFromJSON(json['follow_up_created']),
        'follow_up_updated': !exists(json, 'follow_up_updated') ? undefined : FollowUpUpdatedFromJSON(json['follow_up_updated']),
        'handover': !exists(json, 'handover') ? undefined : HandoverFromJSON(json['handover']),
        'incident_attachment_added': !exists(json, 'incident_attachment_added') ? undefined : IncidentAttachmentAddedFromJSON(json['incident_attachment_added']),
        'incident_attachment_removed': !exists(json, 'incident_attachment_removed') ? undefined : IncidentAttachmentRemovedFromJSON(json['incident_attachment_removed']),
        'incident_call_ended': !exists(json, 'incident_call_ended') ? undefined : IncidentCallEndedFromJSON(json['incident_call_ended']),
        'incident_call_started': !exists(json, 'incident_call_started') ? undefined : IncidentCallStartedFromJSON(json['incident_call_started']),
        'incident_escalate': !exists(json, 'incident_escalate') ? undefined : IncidentEscalateFromJSON(json['incident_escalate']),
        'incident_membership_revoked': !exists(json, 'incident_membership_revoked') ? undefined : IncidentMembershipRevokedFromJSON(json['incident_membership_revoked']),
        'incident_merged': !exists(json, 'incident_merged') ? undefined : IncidentMergedFromJSON(json['incident_merged']),
        'incident_rename': !exists(json, 'incident_rename') ? undefined : IncidentRenameFromJSON(json['incident_rename']),
        'incident_timestamp_set': !exists(json, 'incident_timestamp_set') ? undefined : IncidentTimestampSetFromJSON(json['incident_timestamp_set']),
        'incident_type_changed': !exists(json, 'incident_type_changed') ? undefined : IncidentTypeChangedFromJSON(json['incident_type_changed']),
        'incident_update': !exists(json, 'incident_update') ? undefined : TimelineItemIncidentUpdateFromJSON(json['incident_update']),
        'incident_visibility_changed': !exists(json, 'incident_visibility_changed') ? undefined : IncidentVisibilityChangedFromJSON(json['incident_visibility_changed']),
        'microsoft_teams_pin': !exists(json, 'microsoft_teams_pin') ? undefined : MicrosoftTeamsPinFromJSON(json['microsoft_teams_pin']),
        'pagerduty_incident_acknowledged': !exists(json, 'pagerduty_incident_acknowledged') ? undefined : PagerdutyIncidentAcknowledgedFromJSON(json['pagerduty_incident_acknowledged']),
        'pagerduty_incident_resolved': !exists(json, 'pagerduty_incident_resolved') ? undefined : PagerdutyIncidentResolvedFromJSON(json['pagerduty_incident_resolved']),
        'pagerduty_incident_triggered': !exists(json, 'pagerduty_incident_triggered') ? undefined : PagerdutyIncidentTriggeredFromJSON(json['pagerduty_incident_triggered']),
        'role_update': !exists(json, 'role_update') ? undefined : RoleUpdateFromJSON(json['role_update']),
        'severity_update': !exists(json, 'severity_update') ? undefined : SeverityUpdateFromJSON(json['severity_update']),
        'slack_image': !exists(json, 'slack_image') ? undefined : SlackImageFromJSON(json['slack_image']),
        'slack_infer_github': !exists(json, 'slack_infer_github') ? undefined : SlackInferGithubFromJSON(json['slack_infer_github']),
        'slack_infer_sentry': !exists(json, 'slack_infer_sentry') ? undefined : SlackInferSentryFromJSON(json['slack_infer_sentry']),
        'slack_pin': !exists(json, 'slack_pin') ? undefined : SlackPinFromJSON(json['slack_pin']),
        'status_change': !exists(json, 'status_change') ? undefined : StatusChangeFromJSON(json['status_change']),
        'status_page_incident_linked': !exists(json, 'status_page_incident_linked') ? undefined : StatusPageIncidentLinkedFromJSON(json['status_page_incident_linked']),
        'status_page_incident_updated': !exists(json, 'status_page_incident_updated') ? undefined : StatusPageIncidentUpdatedFromJSON(json['status_page_incident_updated']),
        'summary_update': !exists(json, 'summary_update') ? undefined : SummaryUpdateFromJSON(json['summary_update']),
    };
}

export function ActivityLogContentToJSON(value?: ActivityLogContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_created': ActionCreatedToJSON(value.action_created),
        'action_updated': ActionUpdatedToJSON(value.action_updated),
        'atlassian_statuspage_update': AtlassianStatuspageUpdateToJSON(value.atlassian_statuspage_update),
        'custom_field_value_update': CustomFieldValueUpdateToJSON(value.custom_field_value_update),
        'escalation_created': EscalationCreatedToJSON(value.escalation_created),
        'follow_up_created': FollowUpCreatedToJSON(value.follow_up_created),
        'follow_up_updated': FollowUpUpdatedToJSON(value.follow_up_updated),
        'handover': HandoverToJSON(value.handover),
        'incident_attachment_added': IncidentAttachmentAddedToJSON(value.incident_attachment_added),
        'incident_attachment_removed': IncidentAttachmentRemovedToJSON(value.incident_attachment_removed),
        'incident_call_ended': IncidentCallEndedToJSON(value.incident_call_ended),
        'incident_call_started': IncidentCallStartedToJSON(value.incident_call_started),
        'incident_escalate': IncidentEscalateToJSON(value.incident_escalate),
        'incident_membership_revoked': IncidentMembershipRevokedToJSON(value.incident_membership_revoked),
        'incident_merged': IncidentMergedToJSON(value.incident_merged),
        'incident_rename': IncidentRenameToJSON(value.incident_rename),
        'incident_timestamp_set': IncidentTimestampSetToJSON(value.incident_timestamp_set),
        'incident_type_changed': IncidentTypeChangedToJSON(value.incident_type_changed),
        'incident_update': TimelineItemIncidentUpdateToJSON(value.incident_update),
        'incident_visibility_changed': IncidentVisibilityChangedToJSON(value.incident_visibility_changed),
        'microsoft_teams_pin': MicrosoftTeamsPinToJSON(value.microsoft_teams_pin),
        'pagerduty_incident_acknowledged': PagerdutyIncidentAcknowledgedToJSON(value.pagerduty_incident_acknowledged),
        'pagerduty_incident_resolved': PagerdutyIncidentResolvedToJSON(value.pagerduty_incident_resolved),
        'pagerduty_incident_triggered': PagerdutyIncidentTriggeredToJSON(value.pagerduty_incident_triggered),
        'role_update': RoleUpdateToJSON(value.role_update),
        'severity_update': SeverityUpdateToJSON(value.severity_update),
        'slack_image': SlackImageToJSON(value.slack_image),
        'slack_infer_github': SlackInferGithubToJSON(value.slack_infer_github),
        'slack_infer_sentry': SlackInferSentryToJSON(value.slack_infer_sentry),
        'slack_pin': SlackPinToJSON(value.slack_pin),
        'status_change': StatusChangeToJSON(value.status_change),
        'status_page_incident_linked': StatusPageIncidentLinkedToJSON(value.status_page_incident_linked),
        'status_page_incident_updated': StatusPageIncidentUpdatedToJSON(value.status_page_incident_updated),
        'summary_update': SummaryUpdateToJSON(value.summary_update),
    };
}

