import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

import { ActivityBadge } from "./ActivityBadge";
import { ActivityItemRenderProps } from "./ActivityItem";

export const ActivityItemIncidentTimestampSet = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_timestamp_set) {
    throw new Error(
      "malformed timeline item: incident_timestamp_set was missing incident_timestamp_set field",
    );
  }

  const hasPrevious =
    !!item.content.incident_timestamp_set.incident_timestamp_old_value?.value;
  const hasNext =
    !!item.content.incident_timestamp_set.incident_timestamp_new_value?.value;
  let verb: string;
  if (hasPrevious && hasNext) {
    verb = "updated";
  } else if (hasPrevious && !hasNext) {
    verb = "unset";
  } else {
    verb = "set";
  }

  return {
    title:
      item.content.incident_timestamp_set.incident_timestamp.name +
      " timestamp " +
      verb,
    icon: IconEnum.Clock,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_timestamp_set.creator,
    unquotedContent: (
      <ActivityBadge
        icon={IconEnum.Clock}
        value={
          item.content.incident_timestamp_set.incident_timestamp_new_value.value
            ? item.content.incident_timestamp_set.incident_timestamp_new_value.value.toLocaleString()
            : "Unset"
        }
        previousValue={
          item.content.incident_timestamp_set.incident_timestamp_old_value
            ?.value
            ? item.content.incident_timestamp_set.incident_timestamp_old_value.value.toLocaleString()
            : undefined
        }
      />
    ),
  };
};
