import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
} from "@incident-shared/forms/Typeahead";
import { DynamicSingleSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import {
  Button,
  ButtonTheme,
  ContentBox,
  FloatingFooter,
  GenericErrorMessage,
  IconEnum,
  Loader,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntercom } from "react-use-intercom";
import { Form } from "src/components/@shared/forms";
import {
  IntegrationSettingsProviderEnum,
  LegacyIncidentTriggersGetConfigExternalResourceTypeEnum,
  PagerDutyConfig,
  ScopeNameEnum,
  TypeaheadsListTypeaheadTypeEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { useMutation } from "../../../../../utils/fetchData";
import { useIncidentTriageIncidentsFeatureGateEnabled } from "../../../../../utils/incident-manual-triage";
import {
  prefixErrors,
  stripConditionErrorDetails,
} from "../../../../../utils/prefixErrors";
import {
  LegacyIncidentTriggerFormData,
  useConfigureLegacyIncidentTriggerForm,
} from "../../common/ConfigureLegacyIncidentTrigger";

export const ConfigurePagerDutyPage = ({
  backHref,
}: {
  backHref: string;
}): React.ReactElement | null => {
  const navigate = useOrgAwareNavigate();

  const { data: pagerDutyConfigData, isLoading: pagerDutyConfigLoading } =
    useAPI("integrationsPagerDutyGetConfig", undefined);
  const pagerDutyConfig = pagerDutyConfigData?.pager_duty_config;

  const {
    onSubmit: onSubmitIncidentTriggerForm,
    loading: incidentTriggerFormLoading,
    renderForm,
    defaultValues,
    error: triggerError,
  } = useConfigureLegacyIncidentTriggerForm({
    externalResourceType:
      LegacyIncidentTriggersGetConfigExternalResourceTypeEnum.PagerDutyIncident,
    externalResourceTypeLabel: "PagerDuty incident",
    integration: IntegrationSettingsProviderEnum.Pagerduty,
    autoDeclineEnabledLabel: "if the PagerDuty incident is resolved",
    groupingWindowHelptext:
      "If we see multiple PagerDuty incidents against the same Service during this window, we'll ask whether they're related before creating a new incident.",
  });

  const isIncidentTriageIncidentsFeatureGateEnabled =
    useIncidentTriageIncidentsFeatureGateEnabled();

  const { trigger: updateBotUserID } = useAPIMutation(
    "integrationsPagerDutyGetConfig",
    undefined,
    async (apiClient, { bot_user_id }) =>
      await apiClient.integrationsPagerDutyUpdateConfig({
        updateConfigRequestBody: { bot_user_id },
      }),
  );

  // This form has two endpoints to submit to: the PagerDuty Config for the bot
  // user ID, and the Incident Trigger Config for the rest.
  const onSubmit = useCallback(
    async (data: FormData) => {
      // If the bot user ID has changed, update it
      const confP =
        data.bot_user_id !== pagerDutyConfig?.bot_user_id
          ? updateBotUserID(data)
          : Promise.resolve(pagerDutyConfig);

      // We only want to submit the update to incident triggers
      // if the user has this available to them as a feature.
      const triggerP = isIncidentTriageIncidentsFeatureGateEnabled
        ? // Working out if the triggers form is dirty is a bit tricky, so we just
          // submit it.
          onSubmitIncidentTriggerForm(data.incident_trigger_config)
        : Promise.resolve();

      await Promise.all([confP, triggerP]);
    },
    [
      updateBotUserID,
      onSubmitIncidentTriggerForm,
      pagerDutyConfig,
      isIncidentTriageIncidentsFeatureGateEnabled,
    ],
  );

  // When we first setup pager-duty we might not have any users at all - in which case
  // we want the user to wait before trying to do anything else.
  // We will keep trying to poll until we have some users.
  const [hasUsers, setHasUsers] = useState(false);
  useAPI(
    hasUsers ? null : "typeaheadsList",
    { typeaheadType: TypeaheadsListTypeaheadTypeEnum.PagerDutyUser },
    {
      fallbackData: { options: [], option_groups: [] },
      refreshInterval: 1000,
      onSuccess: ({ options }) => {
        if (options?.length > 0) {
          setHasUsers(true);
        }
      },
    },
  );

  if (triggerError != null) {
    if (triggerError.status === 404) {
      // We return 404 if the PagerDuty integration is not installed.
      // in this case go back to the integrations page so they can
      // see what they actually have installed.
      return <OrgAwareNavigate to="/settings/integrations" />;
    } else {
      return <GenericErrorMessage error={triggerError} />;
    }
  }

  return (
    <SecondaryNavSubPageWrapper
      title="Configure PagerDuty"
      backHref={backHref}
      icon={IconEnum.Pagerduty}
    >
      {incidentTriggerFormLoading ||
      pagerDutyConfigLoading ||
      !pagerDutyConfig ? (
        <Loader />
      ) : (
        <ConfigurePagerDutyForm
          pagerDutyConfig={pagerDutyConfig}
          defaultTriggerFormData={defaultValues}
          renderTriggerForm={renderForm}
          onClose={() => navigate(backHref)}
          onSubmit={onSubmit}
          hasUsers={!hasUsers}
        />
      )}
    </SecondaryNavSubPageWrapper>
  );
};

type FormData = {
  bot_user_id: string;
  incident_trigger_config: LegacyIncidentTriggerFormData;
};

const ConfigurePagerDutyForm = ({
  defaultTriggerFormData,
  pagerDutyConfig,
  onSubmit: _onSubmit,
  renderTriggerForm,
  onClose,
  hasUsers,
}: {
  defaultTriggerFormData: Partial<LegacyIncidentTriggerFormData> | null;
  pagerDutyConfig: PagerDutyConfig;
  onSubmit: (data: FormData) => Promise<void>;
  renderTriggerForm: () => React.ReactElement;
  onClose: () => void;
  hasUsers: boolean;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const { showArticle } = useIntercom();

  const formMethods = useForm<FormData>({
    defaultValues: {
      bot_user_id: pagerDutyConfig.bot_user_id || "",
      incident_trigger_config: defaultTriggerFormData || {},
    },
  });
  const { setError, watch } = formMethods;

  const triggerEnabled = watch("incident_trigger_config.enabled");

  const isFeatureGated = !useIncidentTriageIncidentsFeatureGateEnabled();
  const isTryingToEnableItButShouldnt = isFeatureGated && triggerEnabled;

  const showToast = useToast();
  const [onSubmit, { saving, genericError }] = useMutation(
    async (data: FormData) => {
      if (!data.bot_user_id && pagerDutyConfig.need_bot_user_id) {
        setError("bot_user_id", { message: "Please select a bot user" });
        return;
      }

      await _onSubmit(data);

      showToast({
        title: "PagerDuty configuration updated.",
        theme: ToastTheme.Success,
      });
      onClose();
    },
    {
      setError: stripConditionErrorDetails(
        prefixErrors(setError, "incident_trigger_config."),
      ),
    },
  );

  const apiClient = useClient();

  // If we do not actually have any users yet, don't even show the form - let the user know that we are polling them.
  if (hasUsers) {
    return (
      <ContentBox className="flex items-center">
        <Loader className="flex-none w-14 h-14" />
        <div className="text-sm flex-1">
          We&apos;re still syncing your users from PagerDuty. This should only
          take a few minutes. Please wait so we can finish setting up your
          integration.
        </div>
      </ContentBox>
    );
  }

  return (
    <Form.Root onSubmit={onSubmit} formMethods={formMethods}>
      {pagerDutyConfig.need_bot_user_id ? (
        <>
          <ContentBox className="p-4">
            <DynamicSingleSelectV2
              formMethods={formMethods}
              name="bot_user_id"
              label="PagerDuty bot user"
              isClearable={false}
              helptext={
                <span className="mb-1">
                  PagerDuty requires a user account to create incidents
                  directly. This should be a user that you won&apos;t escalate
                  to because PagerDuty will auto-acknowledge any incidents it
                  thinks came from that user. For more information, see our{" "}
                  <Button
                    onClick={() => showArticle(6180917)}
                    analyticsTrackingId="configure-pagerduty-help"
                    theme={ButtonTheme.Link}
                    className="underline"
                  >
                    help center
                  </Button>
                  .
                </span>
              }
              placeholder="Select user..."
              required
              isDisabled={!canEditSettings}
              loadOptions={getTypeaheadOptions(
                apiClient,
                TypeaheadsListTypeaheadTypeEnum.PagerDutyUser,
              )}
              hydrateOptions={hydrateInitialSelectOptions(
                apiClient,
                TypeaheadsListTypeaheadTypeEnum.PagerDutyUser,
              )}
            />
          </ContentBox>
        </>
      ) : null}
      {renderTriggerForm()}
      <FloatingFooter
        error={genericError}
        onClose={onClose}
        saving={saving}
        disabled={isTryingToEnableItButShouldnt}
        confirmButtonType="submit"
        containerClassName="!mt-3"
      />
    </Form.Root>
  );
};
