import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import React from "react";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import {
  ActivityLogEntry,
  Incident,
  IncidentModeEnum,
  IncidentStatusCategoryEnum,
  StatusChange,
} from "src/contexts/ClientContext";

import { AttachmentContent } from "./AttachmentContent";

const StatusChangeText = ({
  incident,
  status_change,
}: {
  incident: Incident;
  status_change: StatusChange;
  isPostMortem?: boolean;
}): React.ReactElement => {
  if (!status_change.previous_incident_status) {
    if (incident?.mode === IncidentModeEnum.Retrospective) {
      return <>Retrospective incident opened</>;
    } else {
      return <>Incident reported</>;
    }
  }

  if (
    status_change.previous_incident_status?.category ===
    IncidentStatusCategoryEnum.Closed
  ) {
    return (
      <>Incident re-opened in the {status_change.incident_status.name} state</>
    );
  }

  return <>Incident state updated to {status_change.incident_status.name}</>;
};

// DEPRECATED: This type of timeline item is deprecated because we rely on incident updates instead.
// We will still render it for old incidents though (before we built incident updates).
export const ActivityItemStatusChange = (
  incident: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.status_change) {
    throw new Error(
      "malformed timeline item: status_change was missing status_change field",
    );
  }
  const actor = item.content.status_change.updater;

  // Note: we do not need to handle stream items here because we built streams
  // recently, and stopped emitting this event a long time ago.

  // Special case for when an incident is created (in triage) from an external resource
  if (actor?.external_resource != null) {
    return {
      actor: actor,
      icon: IconEnum.Incident,
      colour: ColorPaletteEnum.Red,
      title: (
        <>
          Incident created from a {actor.external_resource.resource_type_label}
        </>
      ),
      quotedContent: <AttachmentContent resource={actor.external_resource} />,
    };
  }

  return {
    title: (
      <StatusChangeText
        incident={incident}
        status_change={item.content.status_change}
      />
    ),
    icon: IconEnum.Announcement,
    colour: ColorPaletteEnum.Purple,
    actor: actor,
  };
};
