import {
  IncidentsCreateRequestBodyModeEnum as IncidentModeEnum,
  IncidentStatus,
  ScopeNameEnum,
  TrialStatusTrialStatusEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { DeclareFormData } from "@incident-shared/incident-forms";
import background from "@incident-shared/layout/radar-circles-background-grey+red.png";
import {
  ButtonTheme,
  IconEnum,
  OrgAwareLink,
  TabSection,
  Txt,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import logo from "@incident-ui/MessagePreviews/images/incident_logo.png";
import { useState } from "react";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIsMSTeamsTabFriendlyView } from "src/contexts/MSTeamsTabContext";
import { useDisableNPS } from "src/contexts/SurvicateContext";
import { useQueryParams } from "src/utils/query-params";
import { useTrialData } from "src/utils/trial";
import { assertUnreachable } from "src/utils/utils";

import {
  ActiveIncidentCreateForm,
  useActiveIncidentCreateForm,
} from "../legacy/incident/ActiveIncidentCreateModal";
import {
  IncidentCreateRequiredProps,
  IncidentModeQueryParam,
  TABS,
} from "../legacy/incident/IncidentCreateModal";
import {
  RetrospectiveIncidentForm,
  RetrospectiveIncidentFormData,
  useRetrospectiveIncidentForm,
} from "../legacy/incident/RetrospectiveIncidentCreateModal";
import {
  IncidentCrudResourceTypes,
  useAllStatuses,
  useIncidentCrudResources,
} from "../legacy/incident/useIncidentCrudResources";
import { usePrefillDefaultValues } from "../legacy/incident/usePrefillDefaultValues";

export const DeclareIncidentFullPage = () => {
  const { loading, ...resources } = useIncidentCrudResources();
  const { prefillDefaultValues, prefillLoading } = usePrefillDefaultValues();
  const { allStatuses, allStatusesLoading } = useAllStatuses();
  useDisableNPS();
  const trialData = useTrialData();

  // if they are on a trial and it has expired, redirect them to the normal modal
  // which will display an error message telling them their trial has expired.
  if (
    trialData &&
    trialData.trialStatus === TrialStatusTrialStatusEnum.Expired
  ) {
    return <OrgAwareLink to="/incidents?createIncident=true" />;
  }

  if (loading || prefillLoading || allStatusesLoading) {
    return <FullPageLoader subtitle={<Txt>Building incident form...</Txt>} />;
  }

  return (
    <div
      className="flex flex-col justify-center items-center w-full min-h-full bg-surface-secondary"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col max-w-screen-sm items-center mt-10 p-4">
        <IncidentForm
          resources={resources}
          prefillDefaultValues={prefillDefaultValues}
          allStatuses={allStatuses}
        />
      </div>
    </div>
  );
};

const IncidentForm = ({
  resources,
  prefillDefaultValues,
  allStatuses,
}: {
  resources: IncidentCrudResourceTypes;
  prefillDefaultValues: Partial<DeclareFormData>;
  allStatuses: IncidentStatus[];
}) => {
  const activeForm = useActiveIncidentCreateForm({
    ...resources,
    mode: IncidentModeEnum.Standard,
    // this function get's overidden by api callback that redirects to the new teams channel
    // so we can leave it empty
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
    prefillDefaultValues,
  });
  const retroForm = useRetrospectiveIncidentForm({
    ...resources,
    allStatuses,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
  });
  const testForm = useActiveIncidentCreateForm({
    ...resources,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSuccess: () => {},
    mode: IncidentModeEnum.Test,
    prefillDefaultValues,
  });

  const { identity } = useIdentity();
  const isMSTeamsTabFriendlyView = useIsMSTeamsTabFriendlyView();
  const params = useQueryParams();

  const [mode, setMode] = useState<IncidentModeQueryParam>(
    (params.get("mode") ?? "real") as IncidentModeQueryParam,
  );

  let tabs = identity.can_create_retrospective_incidents
    ? TABS
    : TABS.filter((tab) => tab.id !== IncidentModeQueryParam.Retrospective);

  if (isMSTeamsTabFriendlyView) {
    tabs = tabs.filter((tab) => tab.id === IncidentModeQueryParam.Real);
  }

  const modeProps = (
    mode === IncidentModeQueryParam.Real
      ? activeForm
      : mode === IncidentModeQueryParam.Retrospective
      ? retroForm
      : mode === IncidentModeQueryParam.Test
      ? testForm
      : assertUnreachable(mode)
  ) as IncidentCreateRequiredProps<
    typeof mode extends IncidentModeQueryParam.Retrospective
      ? RetrospectiveIncidentFormData
      : DeclareFormData
  >;

  const form = (
    <>
      <div className="space-y-4">
        {/* The keys are required here to force a re-mount when you switch tabs */}
        {mode === IncidentModeQueryParam.Real ? (
          <ActiveIncidentCreateForm key="real" {...activeForm} {...resources} />
        ) : mode === IncidentModeQueryParam.Retrospective ? (
          <RetrospectiveIncidentForm
            key="retro"
            {...retroForm}
            {...resources}
          />
        ) : mode === IncidentModeQueryParam.Test ? (
          <ActiveIncidentCreateForm key="test" {...testForm} {...resources} />
        ) : (
          assertUnreachable(mode)
        )}
      </div>
      <div className="w-full flex justify-end">
        <GatedButton
          analyticsTrackingId="incident-declare-modal-open"
          theme={ButtonTheme.Primary}
          icon={IconEnum.Incident}
          type="submit"
          requiredScope={ScopeNameEnum.IncidentsCreate}
        >
          Declare incident
        </GatedButton>
      </div>
    </>
  );

  return (
    <Form.Root {...modeProps}>
      <div className="flex flex-col justify-center gap-4 bg-white p-8 rounded-2xl drop-shadow-sm">
        <div className="flex flex-col gap-10">
          <div className="flex justify-between">
            <div className="flex gap-3">
              <img src={logo} alt="logo" className="w-8 h-8 rounded-2" />
              <div className="!text-2xl leading-8 !font-bold">
                Declare an incident
              </div>
            </div>
            {!isMSTeamsTabFriendlyView && (
              <OrgAwareLink
                className="text-content-tertiary text-sm hover:text-content-primary"
                to={"/dashboard"}
              >
                Dashboard →
              </OrgAwareLink>
            )}
          </div>
        </div>
        {tabs.length > 1 ? (
          <TabSection
            rootClassName={"flex flex-col overflow-hidden space-y-4"}
            tabBarClassName={"border-b border-stroke -mt-2 mb-2"}
            withIndicator
            tabs={tabs}
            defaultTab={mode}
            onTabChange={(tabId) => setMode(tabId as IncidentModeQueryParam)}
            tabClassName={"!text-sm text-content-tertiary"}
          >
            {form}
          </TabSection>
        ) : (
          form
        )}
      </div>
    </Form.Root>
  );
};
