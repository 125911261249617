import { ToggleEnumV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { Path, UseFormReturn } from "react-hook-form";
import {
  IncidentsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  IncidentType,
  Settings,
} from "src/contexts/ClientContext";

export type VisibilityData = {
  visibility: VisibilityEnum;
};

export const VisibilityElement = <TFormType extends VisibilityData>({
  formMethods,
  selectedIncidentType,
  hasSelectedIncidentTypeDontKnow,
  settings,
}: {
  formMethods: UseFormReturn<TFormType>;
  selectedIncidentType?: IncidentType;
  hasSelectedIncidentTypeDontKnow: boolean;
  settings: Settings | null;
}) => {
  if (
    !settings?.misc?.private_incidents_enabled ||
    hasSelectedIncidentTypeDontKnow
  ) {
    return <></>;
  }
  const disabled = selectedIncidentType?.private_incidents_only ?? false;
  return (
    <div className="mt-2">
      <ToggleEnumV2<TFormType, VisibilityEnum>
        formMethods={formMethods}
        name={"visibility" as Path<TFormType>}
        label="Make this a private incident?"
        disabled={disabled}
        trueValue={VisibilityEnum.Private}
        falseValue={VisibilityEnum.Public}
        contextText={
          selectedIncidentType?.private_incidents_only
            ? `
${selectedIncidentType?.name} incidents are required to be private.
We'll create a private Slack channel
and only invited individuals can access incident information.
`
            : `If this incident is made private, a private Slack channel is created. Only invited individuals can access incident information.`
        }
      />
    </div>
  );
};
