import { IconEnum } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  Incident,
  IncidentMembershipRevoked,
} from "src/contexts/ClientContext";
import { TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemSource } from "./TimelineItemSource";

const message = (incidentMembership: IncidentMembershipRevoked) => {
  return (
    <>
      <TimelineItemSource actor={incidentMembership.revoker} /> revoked{" "}
      <strong>{incidentMembership.user.name}&apos;s</strong> access
    </>
  );
};

export const TimelineItemIncidentMembershipRevoked = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.incident_membership_revoked) {
    throw new Error(
      "malformed timeline item: incident_membership_revoked was missing incident_membership_revoked field",
    );
  }

  return {
    icon: IconEnum.LockClosed,
    avatarUrl: item.incident_membership_revoked.revoker?.user?.avatar_url,
    secondaryAvatarUrl: item.incident_membership_revoked.user.avatar_url,
    description: message(item.incident_membership_revoked),
  };
};
