import { getBrokenIntegrationsLookup } from "@incident-shared/integrations";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { SearchBar } from "@incident-ui/SearchBar/SearchBar";
import { Searcher } from "fast-fuzzy";
import { flatten, groupBy, sortBy } from "lodash";
import {
  CatalogType,
  CatalogTypeCategoriesEnum,
} from "src/contexts/ClientContext";
import { useCatalogResources } from "src/hooks/useCatalogResources";
import { useIntegrations } from "src/hooks/useIntegrations";
import {
  useQueryParams,
  useSafeUpdateQueryString,
} from "src/utils/query-params";
import { useAPI } from "src/utils/swr";

import { CatalogTypeCardGrid, CatalogTypeGroup } from "./CatalogTypeGroup";
import {
  CatalogHeroBanner,
  useShowCatalogBanner,
} from "./CatalogTypeSetupBanner";
import { CategoryFilterBar } from "./CategoryFilterBar";
import {
  BASE_TYPE_GROUP,
  CatalogGroupType,
  getIntegrationConfig,
  integrationGroup,
} from "./getIntegrationConfig";

export const catalogTypeSearcher = (types: CatalogType[]) =>
  new Searcher(types || [], {
    keySelector: (type) => [
      type.name,
      integrationGroup(type),
      type.description,
    ],
    threshold: 0.8, // trial and error magic number
  });

export const CatalogTypeListPage = () => {
  const {
    data: { catalog_types: types },
    isLoading: typesLoading,
    error: typesError,
  } = useAPI(
    "catalogListTypes",
    { includeCount: true },
    { fallbackData: { catalog_types: [] } },
  );

  // We don't want to load the page until we have the resources loaded
  const { resourcesLoading, resourcesError } = useCatalogResources();

  const { integrations, integrationsError } = useIntegrations();

  const setURLParams = useSafeUpdateQueryString();
  const queryParams = useQueryParams();
  const searchTerm = queryParams.get("search") || "";
  const category = queryParams.get("category") || "";
  const catalogBannerType = useShowCatalogBanner();

  const updateQueryParams = (key: string, value: string) => {
    const newParams = new URLSearchParams(queryParams);
    if (value === "") {
      newParams.delete(key);
    } else {
      newParams.set(key, value);
    }
    setURLParams(newParams.toString());
  };

  if (typesLoading || !types || !integrations || resourcesLoading) {
    return <Loader />;
  }

  let typesByCategory = types;
  if (category !== "") {
    typesByCategory = types.filter((type) =>
      type.categories.includes(category as CatalogTypeCategoriesEnum),
    );
  }

  // Array the types so we always display the custom types first, then the
  // synced types in alphabetical order.
  const typeGroups = groupBy(
    searchTerm
      ? catalogTypeSearcher(typesByCategory).search(searchTerm)
      : typesByCategory,
    (type) => integrationGroup(type),
  );
  if (!typeGroups["custom"]) {
    typeGroups["custom"] = [];
  }

  const typeGroupKeys = sortBy(Object.keys(typeGroups), (key) => {
    if (key === "custom") {
      return `___${key}`;
    }
    // Special case our own foundational types, so they appear at the top of the list
    if (key === BASE_TYPE_GROUP) {
      return `__${key}`;
    }
    if (key.startsWith("incident_io_")) {
      return `_${key}`;
    }
    return key;
  });

  if (typesError || integrationsError || resourcesError) {
    return (
      <GenericErrorMessage
        error={typesError || integrationsError || resourcesError}
      />
    );
  }

  const brokenIntegrationsLookup = getBrokenIntegrationsLookup(integrations);

  return (
    <>
      <PageWrapper
        width={PageWidth.Medium}
        title="Catalog"
        icon={IconEnum.Book}
      >
        <div className="space-y-6">
          {catalogBannerType && (
            <CatalogHeroBanner bannerType={catalogBannerType} />
          )}
          <div className="flex flex-col gap-10">
            <CategoryFilterBar
              value={category}
              onChange={(value: string) => updateQueryParams("category", value)}
            />
            <div className="flex flex-col gap-4">
              <SearchBar
                id="catalog-type-search"
                value={searchTerm}
                onChange={(value: string) => updateQueryParams("search", value)}
                className="mr-auto w-full"
                placeholder="Search catalog types"
                autoFocus={true}
              />
            </div>
            {category ? (
              <>
                <CatalogTypeCardGrid
                  isCustomTypes={false}
                  types={sortBy(
                    flatten(Object.values(typeGroups)),
                    (t) => t.name,
                  )}
                  brokenIntegrationsLookup={brokenIntegrationsLookup}
                  emptyStateText={
                    searchTerm
                      ? "No matching types"
                      : "No types for this category"
                  }
                />
              </>
            ) : (
              <>
                {/* No types at all: empty state */}
                {typeGroupKeys.length === 0 && !!searchTerm && (
                  <EmptyState
                    icon={IconEnum.Search}
                    title="No matching types"
                    content="We couldn't find any catalog types that match your search."
                  />
                )}
                {typeGroupKeys.map((group) => {
                  const typeConfig = getIntegrationConfig(
                    group as CatalogGroupType,
                  );

                  return (
                    <CatalogTypeGroup
                      label={typeConfig.label}
                      groupTypes={typeGroups[group]}
                      key={`catalog-group-${group}`}
                      icon={typeConfig.icon}
                      hexColor={typeConfig.hexColor}
                      group={group}
                      brokenIntegrationsLookup={brokenIntegrationsLookup}
                      compactCards={group !== "custom"}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
