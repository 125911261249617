import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { IconEnum } from "@incident-ui";
import React from "react";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  Incident,
  SummaryUpdate,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { QuoteContentBox } from "./QuoteContextBox";
import { TimelineItemSource } from "./TimelineItemSource";

const updateText = (summary_update: SummaryUpdate): React.ReactElement => {
  return (
    <>
      <TimelineItemSource actor={summary_update.actor} /> updated the summary
    </>
  );
};

export const TimelineItemSummaryUpdate = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.summary_update) {
    throw new Error(
      "malformed timeline item: summary_update was missing summary_update field",
    );
  }

  return {
    icon: IconEnum.Announcement,
    avatarUrl: item.summary_update.actor.user?.avatar_url,
    description: updateText(item.summary_update),
    children: (
      <QuoteContentBox>
        {item.summary_update.to?.text_node && (
          <TemplatedTextDisplay
            value={item.summary_update.to?.text_node}
            style={TemplatedTextDisplayStyle.Compact}
          />
        )}
      </QuoteContentBox>
    ),
  };
};
