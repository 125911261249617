import { DateRangeRelativeStateIntervalEnum as Interval } from "@incident-io/api";
import {
  DateRangePicker,
  DateRangePickerMode,
  DateRangePickerState,
  DisplayDateRange,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  Icon,
  IconEnum,
  IconSize,
  Toggle,
} from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";

import { DateAggregation, dateAggregationAdjective } from "../context/types";
import { useInsightsContext } from "../context/useInsightsContext";
import { DateAggregationPicker } from "./DateAggregationPicker";

export const InsightsDatePicker = () => {
  const { dateRange, setDateRange, dateAggregation, setDateAggregation } =
    useInsightsContext();

  const setDateRangeAndInterval = (s: DateRangePickerState) => {
    setDateRange(s);
    // If quick-selecting, also set a default aggregation interval
    if (s.mode === DateRangePickerMode.QuickSelect) {
      setDateAggregation(calculateDefaultAggregation(s.quick_select));
    }
  };

  return (
    <Popover
      align="end"
      trigger={
        <Button
          analyticsTrackingId={null}
          className="text-sm-med text-content-secondary pl-1.5"
        >
          <Badge
            size={BadgeSize.Medium}
            theme={BadgeTheme.Tertiary}
            className="hover:cursor-pointer"
            icon={IconEnum.Calendar}
          >
            <DisplayDateRange
              state={dateRange}
              hideParentheses={true}
              className="text-content-secondary"
            />
          </Badge>
          <Badge
            size={BadgeSize.Medium}
            theme={BadgeTheme.Tertiary}
            className="hover:cursor-pointer"
            icon={IconEnum.BarChart}
          >
            {dateAggregationAdjective(dateAggregation)}
          </Badge>
          <Icon id={IconEnum.ChevronDown} size={IconSize.Medium} />
        </Button>
      }
    >
      <div className="p-3 flex flex-col gap-4">
        <div className="font-normal flex flex-col gap-1">
          <span className="text-sm-bold">Time period</span>
          <DateRangePicker
            state={dateRange}
            setState={setDateRangeAndInterval}
            showRangeCaption={false}
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm-bold">Time interval</span>
          <DateAggregationPicker />
        </div>
        <ComparePreviousPeriodToggle />
      </div>
    </Popover>
  );
};

const ComparePreviousPeriodToggle = () => {
  const { comparePreviousPeriod, setComparePreviousPeriod } =
    useInsightsContext();

  return (
    <div className="flex gap-2">
      <Icon id={IconEnum.Compare} size={IconSize.Medium} />
      <span id="compare-label" className="text-sm-med grow">
        Compare with previous period
      </span>
      <Toggle
        id="toggle-compare-previous-period"
        analyticsTrackingId="toggle-compare-previous-period"
        labelledById="compare-label"
        on={comparePreviousPeriod}
        onToggle={() => setComparePreviousPeriod(!comparePreviousPeriod)}
      />
    </div>
  );
};

const intervalToAggregation: {
  [key in Interval]: DateAggregation;
} = {
  [Interval.Days]: DateAggregation.Day,
  [Interval.Weeks]: DateAggregation.Week,
  [Interval.Months]: DateAggregation.Month,
  [Interval.Quarters]: DateAggregation.Quarter,
};

const downsizedIntervals: {
  [key in Interval]: Interval;
} = {
  [Interval.Days]: Interval.Days,
  [Interval.Weeks]: Interval.Days,
  [Interval.Months]: Interval.Weeks,
  [Interval.Quarters]: Interval.Months,
};

const calculateDefaultAggregation = (s: QuickSelectInterval) => {
  const config = QUICK_SELECT_INTERVAL_CONFIG[s];

  // If e.g. 7 weeks, set the aggregation interval to weeks.
  // If only 1 week, set the aggregation interval to the smaller unit (days).
  const newInterval =
    config.state.numberOfIntervals > 1
      ? config.state.interval
      : downsizedIntervals[config.state.interval];

  return intervalToAggregation[newInterval];
};
