/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsMicrosoftDomainVerificationResponseBody,
    IntegrationsMicrosoftDomainVerificationResponseBodyFromJSON,
    IntegrationsMicrosoftDomainVerificationResponseBodyToJSON,
    IntegrationsMicrosoftGetConfigResponseBody,
    IntegrationsMicrosoftGetConfigResponseBodyFromJSON,
    IntegrationsMicrosoftGetConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsMicrosoftDownloadAppManifestRequest {
    organisationId: string;
}

export interface IntegrationsMicrosoftHandleInstallRequest {
    organisationId: string;
    returnPath?: string;
}

export interface IntegrationsMicrosoftHandleRedirectRequest {
    code?: string;
    state?: string;
    sessionState?: string;
    error?: string;
    errorDescription?: string;
    adminConsent?: boolean;
    tenant?: string;
}

export interface IntegrationsMicrosoftHandleTabLoginRequest {
    token: string;
    tenantId: string;
    channelId?: string;
    chatId?: string;
    subEntityId?: string;
}

export interface IntegrationsMicrosoftRedirectToDelegatedTokenAuthRequest {
    organisationId: string;
    purpose: IntegrationsMicrosoftRedirectToDelegatedTokenAuthPurposeEnum;
}

export interface IntegrationsMicrosoftRedirectToLoginRequest {
    returnPath?: string;
    product?: string;
    planName?: string;
    organisationSlug?: string;
    isSigningUp?: boolean;
    viaMobile?: boolean;
}

/**
 * 
 */
export class IntegrationsMicrosoftApi extends runtime.BaseAPI {

    /**
     * Verify domain ownership for Microsoft Azure apps
     * DomainVerification Integrations - Microsoft
     */
    async integrationsMicrosoftDomainVerificationRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsMicrosoftDomainVerificationResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/.well-known/microsoft-identity-association.json`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsMicrosoftDomainVerificationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Verify domain ownership for Microsoft Azure apps
     * DomainVerification Integrations - Microsoft
     */
    async integrationsMicrosoftDomainVerification(initOverrides?: RequestInit): Promise<IntegrationsMicrosoftDomainVerificationResponseBody> {
        const response = await this.integrationsMicrosoftDomainVerificationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Download the Microsoft Teams app manifest, for manual installation
     * DownloadAppManifest Integrations - Microsoft
     */
    async integrationsMicrosoftDownloadAppManifestRaw(requestParameters: IntegrationsMicrosoftDownloadAppManifestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsMicrosoftDownloadAppManifest.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft_teams_app_download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download the Microsoft Teams app manifest, for manual installation
     * DownloadAppManifest Integrations - Microsoft
     */
    async integrationsMicrosoftDownloadAppManifest(requestParameters: IntegrationsMicrosoftDownloadAppManifestRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftDownloadAppManifestRaw(requestParameters, initOverrides);
    }

    /**
     * Get Microsoft configuration for the organisation
     * GetConfig Integrations - Microsoft
     */
    async integrationsMicrosoftGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsMicrosoftGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/microsoft/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsMicrosoftGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Microsoft configuration for the organisation
     * GetConfig Integrations - Microsoft
     */
    async integrationsMicrosoftGetConfig(initOverrides?: RequestInit): Promise<IntegrationsMicrosoftGetConfigResponseBody> {
        const response = await this.integrationsMicrosoftGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * Handle Microsoft installation by redirecting to the Microsoft install endpoint
     * HandleInstall Integrations - Microsoft
     */
    async integrationsMicrosoftHandleInstallRaw(requestParameters: IntegrationsMicrosoftHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsMicrosoftHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft installation by redirecting to the Microsoft install endpoint
     * HandleInstall Integrations - Microsoft
     */
    async integrationsMicrosoftHandleInstall(requestParameters: IntegrationsMicrosoftHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Microsoft
     */
    async integrationsMicrosoftHandleRedirectRaw(requestParameters: IntegrationsMicrosoftHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.sessionState !== undefined) {
            queryParameters['session_state'] = requestParameters.sessionState;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        if (requestParameters.errorDescription !== undefined) {
            queryParameters['error_description'] = requestParameters.errorDescription;
        }

        if (requestParameters.adminConsent !== undefined) {
            queryParameters['admin_consent'] = requestParameters.adminConsent;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Microsoft
     */
    async integrationsMicrosoftHandleRedirect(requestParameters: IntegrationsMicrosoftHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Microsoft tab login
     * HandleTabLogin Integrations - Microsoft
     */
    async integrationsMicrosoftHandleTabLoginRaw(requestParameters: IntegrationsMicrosoftHandleTabLoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling integrationsMicrosoftHandleTabLogin.');
        }

        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling integrationsMicrosoftHandleTabLogin.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.tenantId !== undefined) {
            queryParameters['tenant_id'] = requestParameters.tenantId;
        }

        if (requestParameters.channelId !== undefined) {
            queryParameters['channel_id'] = requestParameters.channelId;
        }

        if (requestParameters.chatId !== undefined) {
            queryParameters['chat_id'] = requestParameters.chatId;
        }

        if (requestParameters.subEntityId !== undefined) {
            queryParameters['sub_entity_id'] = requestParameters.subEntityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft_tab_login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft tab login
     * HandleTabLogin Integrations - Microsoft
     */
    async integrationsMicrosoftHandleTabLogin(requestParameters: IntegrationsMicrosoftHandleTabLoginRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftHandleTabLoginRaw(requestParameters, initOverrides);
    }

    /**
     * Remove Microsoft OAuth token
     * HandleUninstall Integrations - Microsoft
     */
    async integrationsMicrosoftHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove Microsoft OAuth token
     * HandleUninstall Integrations - Microsoft
     */
    async integrationsMicrosoftHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftHandleUninstallRaw(initOverrides);
    }

    /**
     * Send someone into the auth flow so they can get a short-lived token
     * RedirectToDelegatedTokenAuth Integrations - Microsoft
     */
    async integrationsMicrosoftRedirectToDelegatedTokenAuthRaw(requestParameters: IntegrationsMicrosoftRedirectToDelegatedTokenAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsMicrosoftRedirectToDelegatedTokenAuth.');
        }

        if (requestParameters.purpose === null || requestParameters.purpose === undefined) {
            throw new runtime.RequiredError('purpose','Required parameter requestParameters.purpose was null or undefined when calling integrationsMicrosoftRedirectToDelegatedTokenAuth.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        if (requestParameters.purpose !== undefined) {
            queryParameters['purpose'] = requestParameters.purpose;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft_auth_short_lived_token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send someone into the auth flow so they can get a short-lived token
     * RedirectToDelegatedTokenAuth Integrations - Microsoft
     */
    async integrationsMicrosoftRedirectToDelegatedTokenAuth(requestParameters: IntegrationsMicrosoftRedirectToDelegatedTokenAuthRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftRedirectToDelegatedTokenAuthRaw(requestParameters, initOverrides);
    }

    /**
     * Send someone into the Microsoft login flow
     * RedirectToLogin Integrations - Microsoft
     */
    async integrationsMicrosoftRedirectToLoginRaw(requestParameters: IntegrationsMicrosoftRedirectToLoginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.returnPath !== undefined) {
            queryParameters['return_path'] = requestParameters.returnPath;
        }

        if (requestParameters.product !== undefined) {
            queryParameters['product'] = requestParameters.product;
        }

        if (requestParameters.planName !== undefined) {
            queryParameters['plan_name'] = requestParameters.planName;
        }

        if (requestParameters.organisationSlug !== undefined) {
            queryParameters['organisation_slug'] = requestParameters.organisationSlug;
        }

        if (requestParameters.isSigningUp !== undefined) {
            queryParameters['is_signing_up'] = requestParameters.isSigningUp;
        }

        if (requestParameters.viaMobile !== undefined) {
            queryParameters['via_mobile'] = requestParameters.viaMobile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/microsoft_login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send someone into the Microsoft login flow
     * RedirectToLogin Integrations - Microsoft
     */
    async integrationsMicrosoftRedirectToLogin(requestParameters: IntegrationsMicrosoftRedirectToLoginRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsMicrosoftRedirectToLoginRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IntegrationsMicrosoftRedirectToDelegatedTokenAuthPurposeEnum {
    Install = 'teams_bot_install',
    Upgrade = 'teams_bot_upgrade'
}
