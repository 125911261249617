import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import { CSMChattyProductMarketingModal } from "@incident-ui/ProductMarketingBanner/CSMChattyProductMarketingModal";
import { useState } from "react";

import { useIdentity } from "../../../contexts/IdentityContext";
import { useProductAccess } from "../../../hooks/useProductAccess";
import { useAPIMutation } from "../../../utils/swr";
import { tcx } from "../../../utils/tailwind-classes";
import {
  CATALOG_SERVICES_WIZARD_CTA,
  CATALOG_TEAMS_WIZARD_CTA,
  CatalogBannerType,
  CatalogHeroBanner,
  SetupCatalogGraphic,
  useShowCatalogBanner,
} from "../../catalog/type-list/CatalogTypeSetupBanner";
import AlertVisual from "../images/alerts-get-started.png";

const ALERTS_SETUP_CATALOG_MODAL_CTA = "alerts-setup-catalog-modal";

export const AlertsGetStartedPage = () => {
  const { hasOnCall } = useProductAccess();
  const catalogBanner = useShowCatalogBanner();

  const resourceName = hasOnCall ? "incidents and escalations" : "incidents";

  return (
    <PageWrapper
      icon={IconEnum.Alert}
      title="Alerts"
      width={PageWidth.Full}
      overflowY={false}
    >
      <div className="flex flex-col gap-6 h-full grow">
        {catalogBanner && (
          <CatalogSetupBannerOrModal bannerType={catalogBanner} />
        )}
        <div
          className={tcx(
            "bg-gradient-to-b from-[#F7F7F8] to-[#FFF]",
            "h-full flex flex-col gap-6 p-6 flex-center items-center rounded-2xl",
          )}
        >
          <img className="max-w-[714px] max-h-[300px]" src={AlertVisual} />
          <div className="flex flex-col items-center max-w-[450px] gap-6 mb-10">
            <div className={"text-center"}>
              <div className="text-content-primary font-medium text-base">
                Create {resourceName} from your alerts
              </div>
              <div className="text-content-tertiary">
                Connect your alerting tools and use alert routes to
                automatically create {resourceName} within incident.io
              </div>
            </div>
            <div className={"flex flex-row gap-4"}>
              <Button
                analyticsTrackingId="alerts-get-started-create"
                theme={ButtonTheme.Primary}
                href={"/alerts/routes/create"}
              >
                Get started
              </Button>
              <Button
                analyticsTrackingId="alerts-get-started-learn-more"
                theme={ButtonTheme.Secondary}
                href={
                  "https://help.incident.io/en/articles/9565315-creating-escalations-and-incidents-from-alerts"
                }
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

// CatalogSetupBannerOrModal is designed to push users to set up their catalog before configuring alerts.
// The first time someone hits the page, we'll show a modal over the alerts page. If they dismiss that modal,
// we'll show a banner instead.
const CatalogSetupBannerOrModal = ({
  bannerType,
}: {
  bannerType: CatalogBannerType;
}) => {
  const { hasDismissedCTA } = useIdentity();
  const [isOpen, setIsOpen] = useState(true);

  const config =
    bannerType === "team"
      ? {
          ctaId: CATALOG_TEAMS_WIZARD_CTA,
          title: "Set up your teams in Catalog",
          description:
            "Add your teams to the Catalog so that your alert routes can notify the right people when things go wrong",
          buttonText: "Set up teams",
          slug: "team",
          className: "bg-purple-surface",
        }
      : {
          ctaId: CATALOG_SERVICES_WIZARD_CTA,
          title: "Set up your services in Catalog",
          description:
            "Add your services to the Catalog so that your alert routes can notify the right people when things go wrong",
          buttonText: "Set up services",
          slug: "service",
          className: "bg-green-surface",
        };

  const { trigger: dismissModal, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: ALERTS_SETUP_CATALOG_MODAL_CTA,
          for_whole_organisation: false,
        },
      });
    },
  );

  const hasDismissedModal = hasDismissedCTA(ALERTS_SETUP_CATALOG_MODAL_CTA);

  if (hasDismissedModal) {
    return <CatalogHeroBanner bannerType={bannerType} />;
  }

  return (
    <CSMChattyProductMarketingModal
      {...config}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      analyticsTrackingId={`alerts-go-to-${config.slug}-wizard`}
      graphic={<SetupCatalogGraphic bannerType={bannerType} />}
      cta={
        <Button
          theme={ButtonTheme.Primary}
          href={`/catalog/${config.slug}-wizard`}
          analyticsTrackingId={`set-up-${config.slug}s-banner`}
        >
          {config.buttonText}
        </Button>
      }
      secondaryCta={
        <Button
          analyticsTrackingId={`set-up-${config.slug}s-later`}
          theme={ButtonTheme.Secondary}
          onClick={() => dismissModal({})}
          loading={dismissing}
        >
          Do it later
        </Button>
      }
    />
  );
};
