import { ScopeNameEnum, SlimDashboard } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItem } from "@incident-shared/settings/SettingsList/SettingsListItem";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  EmptyState,
  Heading,
  IconEnum,
  SearchBar,
  StackedList,
} from "@incident-ui";
import {
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { Searcher, sortKind } from "fast-fuzzy";
import React from "react";
import { UpsellNotice } from "src/components/settings/UpsellNotice";
import { useIdentity } from "src/contexts/IdentityContext";

export const CustomDashboardsSection = ({
  dashboards,
  hasExceededCustomDashboardGate,
  showCreateModal,
}: {
  dashboards: SlimDashboard[];
  hasExceededCustomDashboardGate: boolean;
  showCreateModal: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const customDashboardsGate = identity?.feature_gates.custom_dashboards_count;

  if (dashboards.length === 0) {
    // This means that the user doesn't have access to custom dashboards at all
    if (hasExceededCustomDashboardGate) {
      return (
        <UpsellNotice
          planName="pro"
          title="Custom dashboards"
          analyticsId="insights-custom-dashboards"
          // TODO: [FND-1209] help centre article
          articleId={0}
          description="Use custom dashboards to report on the specific metrics that are important to your team"
        />
      );
    }

    return (
      <EmptyState
        title="No custom dashboards"
        content="Create custom dashboards to track metrics that are important to your team."
        icon={IconEnum.InsightsDashboard}
        cta={
          <GatedButton
            icon={IconEnum.InsightsDashboard}
            analyticsTrackingId="insights-create-dashboard"
            requiredScope={ScopeNameEnum.InsightsCustomDashboardsCreate}
            upgradeRequired={hasExceededCustomDashboardGate}
            onClick={showCreateModal}
            upgradeRequiredProps={{
              gate: {
                type: "numeric",
                value: customDashboardsGate,
                featureNameSingular: "custom dashboard",
              },
              featureName: "custom dashboards",
            }}
          >
            Create dashboard
          </GatedButton>
        }
      />
    );
  }

  return (
    <SearchProvider>
      <CustomDashboardsList dashboards={dashboards} />
    </SearchProvider>
  );
};

const CustomDashboardsList = ({
  dashboards,
}: {
  dashboards: SlimDashboard[];
}) => {
  const searchBarProps = useSearchContext();
  const { value: search } = searchBarProps;

  const searcher = new Searcher(dashboards, {
    keySelector: (dashboard) => [dashboard.name],
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  const filteredDashboards = search ? searcher.search(search) : dashboards;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Heading level={2} size="medium">
          Custom dashboards
        </Heading>
        <SearchBar
          {...searchBarProps}
          placeholder="Search"
          autoFocus
          className="max-w-[300px]"
        />
      </div>
      <StackedList>
        {filteredDashboards.map((dashboard) => (
          <SettingsListItem
            icon={dashboard.icon as unknown as IconEnum}
            iconColor={dashboard.color as unknown as ColorPaletteEnum}
            title={dashboard.name}
            key={dashboard.id}
            rowHref={`/insights-v3/dashboards/custom/${dashboard.id}`}
          />
        ))}
      </StackedList>
    </div>
  );
};
