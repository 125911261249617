import { CommaSeparatedConditionGroups } from "@incident-shared/engine/conditions";
import { EngineReferenceBadge } from "@incident-shared/engine/labels/EngineReferenceBadge";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import pluralize from "pluralize";
import React from "react";
import {
  ColorPaletteEnum,
  getColorPalette,
} from "src/components/@shared/utils/ColorPalettes";
import {
  EngineScope,
  ExpressionOperation,
  ExpressionOperationOperationTypeEnum as OperationType,
  Resource,
} from "src/contexts/ClientContext";
import { lookupInScope, mergeScopes } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { EngineLiteralBadge, referenceSource } from "../..";
import { useExpressionsMethods } from "../ExpressionsMethodsProvider";
import { ExpressionFormData } from "../expressionToPayload";
import { ViewExpressionBranch } from "../ifelse/ViewIfElseExpression";
import { getOperationOptions } from "./QueryOperationsEditor";
import { useCatalogScope } from "./useCatalogScope";
import {
  getPreviousReference,
  ReferenceWithResource,
} from "./useGetPreviousReference";

export const ViewQueryExpression = ({
  expression,
  scope: inputScope,
  resources,
  miniBadges = false,
}: {
  expression: ExpressionFormData;
  scope: EngineScope;
  resources: Resource[];
  miniBadges?: boolean;
}): React.ReactElement => {
  const { allowAllOfACatalogType } = useExpressionsMethods();
  const { catalogScope, catalogScopeLoading, catalogScopeError } =
    useCatalogScope({
      allowAllOfACatalogType: !!allowAllOfACatalogType,
    });

  if (catalogScopeLoading) {
    return <Loader />;
  }
  if (catalogScopeError) {
    return <GenericErrorMessage error={catalogScopeError} />;
  }

  const scope = mergeScopes(inputScope, catalogScope);

  const rootReference = lookupInScope(scope, expression.root_reference);

  if (!rootReference) {
    throw new Error(`Root reference ${expression.root_reference} not found`);
  }

  return (
    <>
      <li className="px-4 py-3 text-sm flex items-center">
        <span className="text-xs uppercase font-semibold text-content-tertiary tracking-widest flex-shrink-0 mr-5">
          Start from{" "}
        </span>
        <EngineReferenceBadge
          referenceSource={referenceSource(rootReference.key)}
          label={rootReference.label}
          className="flex"
          iconOverride={rootReference.icon}
          colorOverride={rootReference.color}
          mini={miniBadges}
        />
      </li>
      {expression.operations?.map((operation, idx) => {
        const operationType = operation.operation_type;

        const previousRef = getPreviousReference({
          operationIdx: idx,
          operations: expression.operations || [],
          scope: scope,
          resources: resources,
          rootReference: expression.root_reference || "",
        });

        const operationOption = getOperationOptions({
          operationType: operationType,
          previousRefLabel: previousRef?.label || "",
          capitalizeDescription: false,
        });

        return (
          <li className="px-4 py-3 text-sm flex items-start gap-x-2" key={idx}>
            <div className="text-xs uppercase font-semibold text-content-tertiary tracking-widest flex-shrink-0 ml-9 mr-3.5 pt-2 w-[90px]">
              Then...
            </div>
            <div className="flex flex-col gap-3 w-full min-w-0">
              <div className="flex items-center gap-x-2 gap-y-2 flex-wrap min-w-0">
                <OperationDescription
                  operation={operation}
                  operationOption={operationOption}
                  previousRef={previousRef}
                  returnsResource={resources.find(
                    (r) => r.type === operation.returns.type,
                  )}
                  miniBadges={miniBadges}
                />
              </div>
              <OperationExtraNode
                operation={operation}
                miniBadges={miniBadges}
              />
            </div>
          </li>
        );
      })}
      {expression.else_branch && (
        <ViewExpressionBranch
          conditionGroups={[]}
          result={expression.else_branch.result}
          returns={expression.returns}
          isElse
          scope={scope}
          miniBadges={miniBadges}
        />
      )}
    </>
  );
};

const OperationDescription = ({
  previousRef,
  operation,
  operationOption,
  returnsResource,
  miniBadges,
}: {
  previousRef?: ReferenceWithResource;
  operation: ExpressionOperation;
  operationOption: SelectOption;
  returnsResource?: Resource;
  miniBadges: boolean;
}): React.ReactElement | null => {
  const operationPill = (
    <OperationPill operation={operation} operationOption={operationOption} />
  );

  if (!previousRef) {
    return null;
  }

  const previousRefTypeLabel = previousRef.resource?.type_label || "";

  const previousIcon = previousRef.resource?.field_config.icon || IconEnum.Box;
  const previousColor = previousRef.resource?.field_config.color;

  const returnsIcon = returnsResource?.field_config?.icon || IconEnum.Box;
  const returnsColor = returnsResource?.field_config?.color;

  switch (operation.operation_type) {
    case OperationType.Navigate:
      return (
        <>
          {operationPill}
          <span>to all related</span>
          <EngineLiteralBadge
            mini={miniBadges}
            label={pluralize(operation.navigate?.reference_label ?? "")}
            icon={returnsIcon}
            color={returnsColor}
          />
        </>
      );
    case OperationType.Parse:
      return (
        <>
          {operationPill}
          <EngineReferenceBadge
            mini={miniBadges}
            label={operation.parse?.source || ""}
            className="[&>*>*]:!font-mono"
          />
          <span>from</span>
          <EngineLiteralBadge mini={miniBadges} label={previousRef.label} />
        </>
      );
    case OperationType.Filter:
      return (
        <>
          {operationPill}
          <span>to</span>
          <EngineLiteralBadge
            mini={miniBadges}
            label={pluralize(previousRefTypeLabel)}
            icon={returnsIcon}
            color={returnsColor}
          />
          <span>matching</span>
        </>
      );
    case OperationType.Max:
      return (
        <>
          <span>take the</span>
          {operationPill}
          <EngineLiteralBadge
            mini={miniBadges}
            label={previousRef.label}
            icon={returnsIcon}
            color={returnsColor}
          />
          <span>from this list</span>
        </>
      );
    case OperationType.Min:
      return (
        <>
          <span>take the</span>
          {operationPill}
          <EngineLiteralBadge
            mini={miniBadges}
            label={previousRef.label}
            icon={returnsIcon}
            color={returnsColor}
          />
          <span>from this list</span>
        </>
      );
    case OperationType.Random:
      return (
        <>
          <span> choose a</span>
          {operationPill}
          <EngineLiteralBadge
            mini={miniBadges}
            label={previousRefTypeLabel}
            icon={returnsIcon}
            color={returnsColor}
          />
          <span>from the list</span>
        </>
      );
    case OperationType.First:
      return (
        <>
          <span> choose the</span>
          {operationPill}
          <EngineLiteralBadge
            mini={miniBadges}
            label={previousRefTypeLabel}
            icon={returnsIcon}
            color={returnsColor}
          />
          <span>from the list</span>
        </>
      );
    case OperationType.Count:
      return (
        <>
          {operationPill}
          <span>how many</span>{" "}
          <EngineLiteralBadge
            mini={miniBadges}
            label={pluralize(previousRefTypeLabel)}
            icon={previousIcon}
            color={previousColor}
          />
          <span>there are</span>
        </>
      );
    case OperationType.Branches:
      throw new Error(
        "Branches operation is not supported in our dashboard yet, it still calls these 'if/else' expressions.",
      );
    default:
      assertUnreachable(operation.operation_type);
  }
  throw new Error("fallthrough in unreachable switch-case");
};

const OperationExtraNode = ({
  operation,
  miniBadges,
}: {
  operation: ExpressionOperation;
  miniBadges: boolean;
}): React.ReactElement | null => {
  if (operation.operation_type === OperationType.Filter) {
    return (
      <CommaSeparatedConditionGroups
        groups={operation.filter?.condition_groups || []}
        mini={miniBadges}
      />
    );
  }

  return null;
};

export const OperationPill = ({
  operationOption,
  operation,
  mini = false,
}: {
  operation: ExpressionOperation;
  operationOption: SelectOption;
  mini?: boolean;
}) => {
  const queryOperationColors = getColorPalette(ColorPaletteEnum.Pink);

  let operationLabel = operationOption.label;

  // Fudge max and min so they look right in a sentence
  if (operation.operation_type === OperationType.Max) {
    operationLabel = "most highly ranked";
  } else if (operation.operation_type === OperationType.Min) {
    operationLabel = "least highly ranked";
  }

  return (
    <Badge
      className={tcx(
        "font-medium",
        queryOperationColors.background,
        queryOperationColors.text,
      )}
      theme={BadgeTheme.Unstyled}
      size={mini ? BadgeSize.Small : BadgeSize.Medium}
      icon={operationOption.icon as IconEnum}
    >
      {operationLabel}
    </Badge>
  );
};
