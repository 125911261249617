import { useInitialiseInsightsFilters } from "@incident-shared/filters";
import { Link, Loader } from "@incident-ui";
import { useState } from "react";
import { ExploDashboardID } from "src/components/insights/v2/dashboards/dashboards";
import { useQueryParams } from "src/utils/query-params";

import { GroupByCustomFieldDropdown } from "../../common/GroupByCustomFieldDropdown";
import { InsightsDashboardSection } from "../../common/InsightsDashboardSection";
import { InsightsPage } from "../../common/InsightsPage";
import { SelectUser } from "../../common/SelectUser";
import { useCustomFieldOptions } from "../../common/useCustomFieldOptions";
import { InsightsContextProvider } from "../../context/InsightsContextProvider";
import { FieldConfig, StateConfig } from "../../context/types";
import { useInsightsContext } from "../../context/useInsightsContext";

interface OverviewDashboardStateConfig extends StateConfig {
  split_by: FieldConfig;
}

export const OverviewDashboard = () => {
  const { customFieldOptions, loading } = useCustomFieldOptions();

  if (loading) {
    return <Loader />;
  }

  return (
    <InsightsContextProvider<OverviewDashboardStateConfig>
      stateConfig={{
        split_by: {
          options: customFieldOptions,
        },
      }}
    >
      <InsightsPage dashboard="overview">
        <IncidentCreationDashboard />
        <IncidentCreationBySourceDashboard />
        <IncidentCreationByUserDashboard />
        {customFieldOptions.length > 0 && <GroupByCustomFieldDashboard />}
      </InsightsPage>
    </InsightsContextProvider>
  );
};

const IncidentCreationDashboard = () => {
  const severityVariables = useSeverityVariables();
  return (
    <InsightsDashboardSection
      title="Incident creation"
      description="Explore the total number of incidents by severity and compare their frequency over time to identify patterns and trends."
      dashboardEmbedID={ExploDashboardID.OverviewIncidentCreation}
      dashboardVariables={severityVariables}
      initialHeight="742px"
    />
  );
};

const IncidentCreationBySourceDashboard = () => (
  <InsightsDashboardSection
    title="Incident creation by source"
    description="Explore how your incidents are being created over time. Look at trends in manual vs automatic incident creation to see how automated your configuration is. Dig deeper into which alert sources are creating the most incidents."
    dashboardEmbedID={ExploDashboardID.OverviewIncidentCreationBySource}
    noBottomDivider
    initialHeight="874px"
  />
);

const IncidentCreationByUserDashboard = () => {
  const [userID, setUserID] = useState<string | undefined>();

  return (
    <InsightsDashboardSection
      // Need to provide this key so that we can re-mount the dashboard with the
      // userID and actually filter by that user
      key={userID}
      description="Look more specifically into who in your organisation is manually creating incidents and find patterns about which teams are discovering and reporting incidents."
      dashboardEmbedID={ExploDashboardID.OverviewIncidentCreationByUser}
      dashboardVariables={{
        selected_user_id: userID,
      }}
      controls={<SelectUser userID={userID} setUserID={setUserID} />}
      initialHeight={"478px"}
    />
  );
};

const GroupByCustomFieldDashboard = () => {
  const severityVariables = useSeverityVariables();

  const { useState, getOptions } =
    useInsightsContext<OverviewDashboardStateConfig>();
  const [selectedCustomFieldID, setSelectedCustomFieldID] =
    useState("split_by");
  const customFieldOptions = getOptions("split_by");
  const customField = customFieldOptions.find(
    ({ value }) => value === selectedCustomFieldID,
  );
  return (
    <InsightsDashboardSection
      title="Group by a custom field"
      description={
        <p className="max-w-4xl mb-4">
          Group incidents by your chosen custom field — be it users, teams, or
          product features — to uncover specific patterns and trends unique to
          each segment (provided you have{" "}
          <Link to="/settings/custom-fields" analyticsTrackingId={null}>
            configured custom fields
          </Link>
          ).
        </p>
      }
      controls={
        <GroupByCustomFieldDropdown
          selectedCustomFieldID={selectedCustomFieldID}
          setSelectedCustomFieldID={setSelectedCustomFieldID}
          customFieldOptions={customFieldOptions}
        />
      }
      dashboardEmbedID={ExploDashboardID.OverviewSplitByCustomField}
      dashboardVariables={{
        ...severityVariables,
        custom_field_id: customField?.value,
        custom_field_name: customField?.label,
      }}
      initialHeight="1204px"
    />
  );
};

export const useSeverityVariables = () => {
  const urlParams = useQueryParams();
  const { availableIncidentFilterFields } = useInitialiseInsightsFilters(
    urlParams.toString(),
  );

  const severityFilter = availableIncidentFilterFields.find(
    ({ key }) => key === "severity",
  );

  const severityOptions =
    severityFilter?.operators.find(({ key }) => key === "one_of")?.select_config
      ?.options ?? [];

  // Order the severities so we get the most critical first.
  const sortedSeverities = [...severityOptions].sort((a, b) => {
    if (a.sort_key < b.sort_key) return -1;
    if (a.sort_key > b.sort_key) return 1;
    return 0;
  });

  // This is the max number of severity tiles in Explo
  const severityTilesInExplo = 7;

  const variables = {};

  for (let i = 0; i < severityTilesInExplo; i++) {
    if (i < sortedSeverities.length) {
      const severity = sortedSeverities[i];

      // Add the severity variables to the map.
      variables[`severity_${i + 1}_id`] = severity.value;
      variables[`severity_${i + 1}_name`] = severity.label;
    } else {
      // Otherwise, hide the tile.
      variables[`severity_${i + 1}.hide`] = true;
    }
  }

  return variables;
};
