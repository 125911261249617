import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { Incident } from "src/contexts/ClientContext";
import { ActivityLogEntry } from "src/contexts/ClientContext";

export const ActivityItemIncidentMembershipRevoked = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_membership_revoked) {
    throw new Error(
      "malformed timeline item: incident_membership_revoked was missing incident_membership_revoked field",
    );
  }
  const title =
    item.content.incident_membership_revoked.user.name + "'s access revoked";

  return {
    title: title,
    icon: IconEnum.LockClosed,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_membership_revoked.revoker,
  };
};
