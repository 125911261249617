import { IncidentRoleWithoutConditionsRoleTypeEnum as RoleTypeEnum } from "@incident-io/api";
import {
  Avatar,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  LoadingBar,
  Tooltip,
} from "@incident-ui";

import { useNavigateToModal } from "../../../utils/query-params";
import { useAPI } from "../../../utils/swr";
import { TimelineItemContentBox } from "./TimelineItemContentBox";

export const StreamDetails = ({
  streamId,
  hidden,
}: {
  streamId: string;
  hidden: boolean;
}) => {
  const {
    data: { stream },
    isLoading,
    error,
  } = useAPI(
    "streamsShow",
    {
      id: streamId,
    },
    { fallbackData: { stream: undefined } },
  );
  const incidentLead = stream?.incident_role_assignments?.find(
    (r) => r.role.role_type === RoleTypeEnum.Lead,
  );
  const navigateToDrawer = useNavigateToModal();

  if (isLoading) {
    return <LoadingBar />;
  }

  if (error || !stream) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <TimelineItemContentBox hidden={hidden}>
      <div className="flex justify-between gap-4">
        <div className="flex min-w-0">
          <Button
            theme={ButtonTheme.Unstyled}
            className="leading-5 line-clamp-1 min-w-0 shrink"
            onClick={() => navigateToDrawer(`streams/${stream.external_id}`)}
            analyticsTrackingId="view-stream"
          >
            <div className="text-content-primary text-sm-normal truncate">
              {stream.name}
            </div>
          </Button>
        </div>
        <div className="flex gap-2 items-center">
          <Button
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={`${stream.id}-slack-button`}
            href={stream.slack_channel_url}
            openInNewTab
            className="h-auto w-auto px-0 p-1 rounded-[6px]"
          >
            <Icon
              id={IconEnum.Slack}
              size={IconSize.XS}
              className="text-primary-500"
            />
          </Button>
          <Tooltip
            key={incidentLead?.assignee?.id}
            content={incidentLead?.assignee?.name}
            delayDuration={0}
          >
            <Button
              theme={ButtonTheme.Unstyled}
              analyticsTrackingId={"stream-lead-avatar"}
            >
              <Avatar
                size={IconSize.Medium}
                url={incidentLead?.assignee?.avatar_url}
                className={"inline mx-0.5"}
              />
            </Button>
          </Tooltip>
        </div>
      </div>
    </TimelineItemContentBox>
  );
};
