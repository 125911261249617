import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type FullPageEmptyStateProps = {
  graphic: React.ReactNode;
  cta: React.ReactNode;
  title: string;
  subtitle: string;
  cards: React.ReactNode;
  className?: string;
  gradientClasses: string;
};

export const FullPageEmptyState = ({
  graphic,
  cta,
  title,
  subtitle,
  cards,
  className,
  gradientClasses,
}: FullPageEmptyStateProps) => {
  return (
    <div className={tcx("flex flex-col grow gap-6", className)}>
      <div
        className={tcx(
          "flex flex-col grow justify-center items-center rounded-2 bg-gradient-to-b",
          gradientClasses,
        )}
      >
        <div className="flex flex-col gap-6 items-center justify-center max-w-[428px]">
          {graphic}
          <div className="flex flex-col gap-2 items-center">
            <div className="text-2xl-bold">{title}</div>
            <div className="text-base text-center">{subtitle}</div>
          </div>
          {cta}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6 items-stretch">{cards}</div>
    </div>
  );
};
