import {
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
  marshallCustomFieldsToFormData,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import {
  FormElements,
  SharedIncidentFormData,
  useElementBindings,
} from "@incident-shared/incident-forms";
import {
  Accordion,
  AccordionProvider,
  AccordionTriggerButton,
  LoadingModal,
  ModalFooter,
} from "@incident-ui";
import * as ReactAccordion from "@radix-ui/react-accordion";
import { sortBy } from "lodash";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  CustomField,
  CustomFieldFieldModeEnum as FieldModeEnum,
  Incident,
  IncidentFormsGetLifecycleElementBindingsRequestBodyIncidentFormTypeEnum as IncidentFormType,
  IncidentModeEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { useRevalidate } from "../../../../utils/use-revalidate";
import {
  getCustomFieldErrorPath,
  isCustomFieldError,
} from "../ActiveIncidentCreateModal";
import styles from "../header/EditTimestampsModal.module.scss";

type FormData = {
  custom_field_entries: FormCustomFieldEntries;
};

export const EditCustomFieldEntriesModal = (props: {
  incident: Incident;
  onClose: () => void;
}) => {
  const {
    data: { custom_fields },
    isLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  if (isLoading) {
    return <LoadingModal onClose={props.onClose} />;
  }
  const customFields = sortBy(custom_fields, (field) => field.rank);

  return (
    <EditSpecificCustomFieldEntriesModal
      {...props}
      allCustomFields={customFields}
    />
  );
};

export const EditSpecificCustomFieldEntriesModal = ({
  incident,
  allCustomFields,
  filterToCustomFieldIDs = [],
  onClose,
}: {
  incident: Incident;
  allCustomFields: CustomField[];
  filterToCustomFieldIDs?: string[];
  onClose: () => void;
}): React.ReactElement => {
  const entries = incident.custom_field_entries;
  const manualEdits = incident.manual_edits;

  const formMethods = useForm<FormData>({
    defaultValues: {
      custom_field_entries: marshallCustomFieldsToFormData({
        customFields: allCustomFields,
        entries,
        manualEdits,
      }),
    },
  });
  const { setError, reset, watch } = formMethods;

  const customFieldEntries = watch("custom_field_entries");
  const entryPayloads = marshallCustomFieldEntriesToRequestPayload(
    allCustomFields,
    formMethods.formState.touchedFields,
    customFieldEntries,
  );

  const refetchCustomFields = useAPIRefetch("customFieldsList", undefined);
  const refreshIncidentList = useRevalidate(["incidentsList"]);
  const refetchPostIncidentTasks = useAPIRefetch("postIncidentFlowListTasks", {
    incidentId: incident.id,
  });

  const elementBindingsPayload = {
    incident_form_type: IncidentFormType.CustomFields,
    incident_type_id: incident.incident_type?.id,
    incident_status_id: incident.incident_status.id,
    severity_id: incident.severity?.id,
    custom_field_entries: entryPayloads,
    incident_role_assignments: [],
    show_all_elements_override: filterToCustomFieldIDs.length > 0,
    incident_id: incident.id,
  };
  const { elementBindings } = useElementBindings<SharedIncidentFormData>({
    payload: elementBindingsPayload,
    setValue:
      formMethods.setValue as unknown as UseFormReturn<SharedIncidentFormData>["setValue"],
    manualEdits: manualEdits,
    touchedFields: formMethods.formState.touchedFields,
    initialValues: formMethods.formState.defaultValues,
  });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: FormData) => {
      await apiClient.incidentsUpdateCustomFieldEntries({
        id: incident.id,
        updateCustomFieldEntriesRequestBody: {
          custom_field_entries: marshallCustomFieldEntriesToRequestPayload(
            allCustomFields,
            formMethods.formState.touchedFields,
            formData.custom_field_entries,
          ),
        },
      });
    },
    {
      onSuccess: () => {
        reset();
        onClose();

        // In case we've just created some extra custom field options, reload
        // the custom fields data in memory.
        refetchCustomFields();
        // Refetch the post-incident tasks as we might have completed one
        refetchPostIncidentTasks();
        // When custom fields change, the incident list might change if the user has a custom view
        refreshIncidentList();
      },
      setError: (path, error) => {
        if (isCustomFieldError(path)) {
          const requestData = marshallCustomFieldEntriesToRequestPayload(
            allCustomFields,
            formMethods.formState.touchedFields,
            customFieldEntries,
          );
          path = getCustomFieldErrorPath<FormData>(path, requestData);
        }
        setError(path, error);
      },
    },
  );

  // Filter out un-editable elementBindings here
  const editableElementBindings = elementBindings.filter((binding) => {
    return (
      binding.element.available_element.custom_field?.field_mode !==
      FieldModeEnum.FullyDerived
    );
  });

  // Filter out any elementBindings for custom fields that we don't want to
  // show here
  let filteredElementBindings = editableElementBindings;

  if (filterToCustomFieldIDs.length > 0) {
    // If we're only showing a handful of custom fields, we should
    // filter our element bindings and make them all required.
    filteredElementBindings = elementBindings
      .filter((binding) => {
        const customFieldID =
          binding.element.available_element.custom_field?.id;
        return customFieldID && filterToCustomFieldIDs.includes(customFieldID);
      })
      .map((binding) => ({ ...binding, required: true }));
  }

  const uneditableCustomFields = allCustomFields.filter((customField) => {
    return customField.field_mode === FieldModeEnum.FullyDerived;
  });

  const isRetrospective = incident.mode === IncidentModeEnum.Retrospective;

  return (
    <FormProvider {...formMethods}>
      <Form.Modal
        formMethods={formMethods}
        analyticsTrackingId="incident-detail-edit-custom-field-entry"
        onClose={onClose}
        genericError={genericError}
        title="Edit custom fields"
        onSubmit={onSubmit}
        footer={
          <ModalFooter
            confirmButtonType="submit"
            onClose={onClose}
            saving={saving}
          />
        }
      >
        <FormElements<SharedIncidentFormData>
          elementBindings={filteredElementBindings}
          incident={incident}
          selectedIncidentType={incident.incident_type}
          formMethods={
            formMethods as unknown as UseFormReturn<SharedIncidentFormData>
          }
          incidentFormType={IncidentFormType.CustomFields}
          customFieldEntryPayloads={entryPayloads}
          manualEdits={manualEdits}
        />
        {uneditableCustomFields.length > 0 && (
          <AccordionProvider type="single" collapsible>
            <Accordion
              id="incident-custom-fields-set-automatically"
              className={styles.accordionContainer}
              header={
                <ReactAccordion.Trigger asChild>
                  <div className="flex items-center mb-2 hover:cursor-pointer">
                    <div className="grow mr-4">
                      <div className="text-slate-700 tracking-widest text-sm font-medium uppercase">
                        {"Automatically set"}
                      </div>
                      <Form.Helptext>
                        {isRetrospective
                          ? "We usually set these custom fields automatically based on your rules, but as this is a retrospective incident, you'll need to set them here."
                          : "This incident has custom fields that were automatically set by your rules. You can review them here."}
                      </Form.Helptext>
                    </div>
                    <AccordionTriggerButton
                      className={tcx("mr-0", styles.chevron)}
                    />
                  </div>
                </ReactAccordion.Trigger>
              }
            >
              <div className="space-y-4">
                {uneditableCustomFields?.map((cf) => {
                  return (
                    <div key={cf.id}>
                      <FormElements<SharedIncidentFormData>
                        elementBindings={elementBindings.filter(
                          (binding) =>
                            binding.element.available_element.custom_field
                              ?.id === cf.id,
                        )}
                        incident={incident}
                        selectedIncidentType={incident.incident_type}
                        formMethods={
                          formMethods as unknown as UseFormReturn<SharedIncidentFormData>
                        }
                        incidentFormType={IncidentFormType.CustomFields}
                        customFieldEntryPayloads={entryPayloads}
                        manualEdits={manualEdits}
                      />
                    </div>
                  );
                })}
              </div>
            </Accordion>
          </AccordionProvider>
        )}
      </Form.Modal>
    </FormProvider>
  );
};
