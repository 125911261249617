import { ScopeNameEnum } from "@incident-io/api";
import { ModalFooter, Steps } from "@incident-ui";
import { kebabCase } from "lodash";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";

import { BasicSettingsFormContent } from "../BasicSettingsFormContent";
import {
  CreatePageSteps,
  InternalStatusPageFormType,
  stepsConfig,
} from "./CreateInternalStatusPage";

export const BasicSettingsModal = ({
  formMethods,
  onClose,
  onSuccess,
  numPages,
}: {
  numPages: number;
  formMethods: UseFormReturn<InternalStatusPageFormType>;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const { hasScope } = useIdentity();
  const { setValue, watch } = formMethods;

  // Generate a sensible-looking path as you type in a title
  const [pathIsLinked, setPathIsLinked] = useState(true);
  useEffect(() => {
    const { unsubscribe } = watch((data, { name }) => {
      // If you're creating your first page, we don't even _show_ the subpath,
      // and don't want to change it.
      if (numPages === 0) {
        return;
      }

      if (name === "subpath") {
        if (data.subpath !== undefined) {
          setPathIsLinked(
            (data.name !== undefined && data.subpath === "") ||
              kebabCase(data.name) === data.subpath,
          );
        }
      }

      if (name === "name" && pathIsLinked && data.name !== undefined) {
        setValue("subpath", kebabCase(data.name));
      }
    });

    return () => unsubscribe();
  }, [pathIsLinked, setValue, watch, numPages]);

  return (
    <Form.Modal
      suppressInitialAnimation
      isExtraLarge
      title="Create internal status page"
      analyticsTrackingId={"create-internal-status-page"}
      disableQuickClose
      formMethods={formMethods}
      onSubmit={onSuccess}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onClose}
          cancelButtonText={"Cancel"}
          confirmButtonType="submit"
          confirmButtonText={"Continue"}
          disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
          disabledTooltipContent="You do not have permission to create an internal status page"
        />
      }
    >
      <Steps steps={stepsConfig} currentStep={CreatePageSteps.BasicSettings} />
      <BasicSettingsFormContent
        showSubpath={numPages > 0}
        page={null}
        formMethods={formMethods}
      />
    </Form.Modal>
  );
};
