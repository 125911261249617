/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface AIConfig
 */
export interface AIConfig {
    /**
     * Whether or not AI is enabled for the org
     * @type {boolean}
     * @memberof AIConfig
     */
    enabled: boolean;
    /**
     * Which AI features are enabled
     * @type {Array<string>}
     * @memberof AIConfig
     */
    enabled_features: Array<AIConfigEnabledFeaturesEnum>;
    /**
     * Lable to use for the causes section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_causes_label: string;
    /**
     * What condition groups must be met for the incident to have summaries suggested by AI?
     * @type {Array<ConditionGroup>}
     * @memberof AIConfig
     */
    incident_summaries_condition_groups: Array<ConditionGroup>;
    /**
     * Lable to use for the impact section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_impact_label: string;
    /**
     * Lable to use for the problem section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_problem_label: string;
    /**
     * Label to use for the steps section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_steps_label: string;
    /**
     * Content to use when a section is unknown in a summary
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_unknown_label: string;
}

/**
* @export
* @enum {string}
*/
export enum AIConfigEnabledFeaturesEnum {
    SummariseIncident = 'summarise_incident',
    IncidentSimilarity = 'incident_similarity',
    Assistant = 'assistant',
    SuggestFollowups = 'suggest_followups',
    CatalogSuggestions = 'catalog_suggestions',
    AlertAttributeSuggestions = 'alert_attribute_suggestions',
    PromptTester = 'prompt_tester'
}

export function AIConfigFromJSON(json: any): AIConfig {
    return AIConfigFromJSONTyped(json, false);
}

export function AIConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'enabled_features': json['enabled_features'],
        'incident_summaries_causes_label': json['incident_summaries_causes_label'],
        'incident_summaries_condition_groups': ((json['incident_summaries_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'incident_summaries_impact_label': json['incident_summaries_impact_label'],
        'incident_summaries_problem_label': json['incident_summaries_problem_label'],
        'incident_summaries_steps_label': json['incident_summaries_steps_label'],
        'incident_summaries_unknown_label': json['incident_summaries_unknown_label'],
    };
}

export function AIConfigToJSON(value?: AIConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'enabled_features': value.enabled_features,
        'incident_summaries_causes_label': value.incident_summaries_causes_label,
        'incident_summaries_condition_groups': ((value.incident_summaries_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'incident_summaries_impact_label': value.incident_summaries_impact_label,
        'incident_summaries_problem_label': value.incident_summaries_problem_label,
        'incident_summaries_steps_label': value.incident_summaries_steps_label,
        'incident_summaries_unknown_label': value.incident_summaries_unknown_label,
    };
}

