import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { ModalFooter, OrgAwareLink } from "@incident-ui";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { UserPreferences } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

export function UserPreferencesModal({
  onClose,
  incidentId,
}: {
  onClose: () => void;
  incidentId: string;
}) {
  const { identity } = useIdentity();
  const formMethods = useForm<UserPreferences>({});
  const { setError } = formMethods;

  const refetchPrefs = useAPIRefetch("userPreferencesShow", undefined);
  const { trigger: onSubmit, isMutating: saving } = useAPIMutation(
    "incidentSubscriptionsGet",
    { incidentId },
    async (apiClient, data: UserPreferences) => {
      await apiClient.userPreferencesUpdateSubscriptionPreferences({
        updateSubscriptionPreferencesRequestBody: {
          receives_subscriptions_via_email:
            data.receives_subscriptions_via_email || false,
          receives_subscriptions_via_slack_DM:
            data.receives_subscriptions_via_slack_DM || false,
          receives_subscriptions_via_sms:
            data.receives_subscriptions_via_sms || false,
        },
      });
      // Don't await this - it's not worth waiting for
      refetchPrefs();

      await apiClient.incidentSubscriptionsCreate({
        createRequestBody: { incident_id: incidentId },
      });
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  const validateAndSubmit = (data: UserPreferences) => {
    const hasNoMethods =
      !data.receives_subscriptions_via_slack_DM &&
      !data.receives_subscriptions_via_email &&
      !data.receives_subscriptions_via_sms;

    if (hasNoMethods) {
      // Attach the error to the email button as it's at the bottom - sad
      setError("receives_subscriptions_via_email", {
        type: "manual",
        message: "You must select at least one method of notification",
      });
      return;
    }
    onSubmit(data);
  };

  return (
    <Form.Modal
      formMethods={formMethods}
      title="Subscription preferences"
      analyticsTrackingId="incident-homepage-subscription-preferences-modal"
      onClose={onClose}
      disableQuickClose={false}
      onSubmit={validateAndSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Submit"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <div className="space-y-4">
        <div className="text-sm">
          {`To subscribe, first configure how you would like to be notified. You
            can change this later in your `}
          <OrgAwareLink to="/user-preferences" className="underline">
            user preferences.
          </OrgAwareLink>
        </div>
        {identity.slack_info?.user_slack_user_id && (
          <ToggleV2<UserPreferences>
            formMethods={formMethods}
            toggleClassName="mb-2"
            name="receives_subscriptions_via_slack_DM"
            disabled={false}
            align="left"
            label="Slack"
            description={`Receive live updates via Slack direct messages for incidents you're subscribed to.`}
          />
        )}
        <ToggleV2<UserPreferences>
          formMethods={formMethods}
          name="receives_subscriptions_via_email"
          disabled={false}
          align="left"
          label="Email"
          required={false}
          description={`Receive live updates via email for incidents you're subscribed to.`}
        />
      </div>
    </Form.Modal>
  );
}
