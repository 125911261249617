import { Schedule, ScheduleEntry, UserOption } from "@incident-io/api";
import { WeekdayIntervalWeekdayEnum } from "@incident-io/api/models/WeekdayInterval";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { TimeInputV2 } from "@incident-shared/forms/v2/inputs/TimeInputV2";
import {
  AddNewButton,
  Badge,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Tooltip,
  Txt,
} from "@incident-ui";
import { ButtonProps } from "@incident-ui/Button/Button";
import { InputType } from "@incident-ui/Input/Input";
import { roundToNearestMinutes } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import { FieldPath, useFieldArray, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { tcx } from "src/utils/tailwind-classes";

import { useNow } from "../../../../utils/use-now";
import { CustomHandoverElement } from "./rota-create-edit-form/CustomHandoverElement";
import { ScheduleFormData } from "./schedule-create-edit-form/ScheduleCreateEditForm";
import {
  ScheduleCreateEditFormUserSelector,
  ScheduleCreateEditFormUsersList,
} from "./ScheduleCreateFormUsersList";
import { useHandoverStartAtAdjustments } from "./useHandoverStartAtAdjustments";
import { UserCacheReturn } from "./useHydratedUserCache";

export type WorkingInterval = {
  days: {
    [key in WeekdayIntervalWeekdayEnum]: boolean;
  };
  start_time: string;
  end_time: string;
};

export type IntervalData = WorkingInterval[];

export enum RotaHandoverType {
  Daily = "daily",
  Weekly = "weekly",
  Custom = "custom",
}

export enum CustomHandoverRuleType {
  Daily = "daily",
  Weekly = "weekly",
  Hourly = "hourly",
}

export type CustomHandoverRule = {
  // This is a number, but we have to store it as a string to work with the input component
  handover_interval: string;
  handover_interval_type: CustomHandoverRuleType;
};

export type RotaFormData = {
  id?: string;
  name: string;
  users: { id: string }[];
  handover_start_at: Date;
  layer_count: number;
  has_working_intervals: "all_day" | "specific_times";
  working_intervals: IntervalData;
  custom_handovers: CustomHandoverRule[];
  rota_handover_type: RotaHandoverType;
  is_deferred: "true" | "false";
  effective_from?: Date;
};

export const RotaCreateEditForm = ({
  timezone,
  formMethods,
  index,
  onDelete,
  hideNameInput,
  userCache,
  isRotaCreateMode,
  initialData,
  currentShifts,
  scheduledEntries,
  isManagedByTerraform,
}: {
  formMethods: UseFormReturn<ScheduleFormData>;
  timezone: string;
  index: number;
  onDelete?: () => void;
  hideNameInput?: boolean;
  userCache: UserCacheReturn;
  isRotaCreateMode: boolean;
  initialData?: Schedule;
  currentShifts?: ScheduleEntry[];
  scheduledEntries?: ScheduleEntry[];
  isManagedByTerraform?: boolean;
}) => {
  const now = useNow(timezone);
  const [adjustHandoverStartAt, setAdjustHandoverStartAt] =
    useState<boolean>(false);

  const getPath = useCallback(
    (
      key: FieldPath<RotaFormData>,
    ): `rotations.${number}.${FieldPath<RotaFormData>}` => {
      return `rotations.${index}.${key}`;
    },
    [index],
  );

  const {
    fields: selectedUsers,
    replace: replaceUsers,
    append: addUser,
    remove: removeUser,
    insert: insertUser,
  } = useFieldArray({
    name: getPath("users") as `rotations.${number}.users`,
    keyName: "key",
    control: formMethods.control,
  });

  const usersDirtyFields =
    formMethods.formState.dirtyFields.rotations?.[index]?.users;
  // usersDirtyFields is a list of objects with a dirty flag (for each user in the rota), here we need to check if any of the flags are dirty
  const usersAreDirty =
    usersDirtyFields &&
    usersDirtyFields.length > 0 &&
    usersDirtyFields.some((u) => u.id);

  const { hydrating, getHydratedUser, cacheHydratedUsers } = userCache;

  const users = selectedUsers
    .map((x) => getHydratedUser(x.id))
    .filter((u) => !!u) as UserOption[];

  const hasWorkingIntervals =
    formMethods.watch(getPath("has_working_intervals")) === "specific_times";

  const {
    fields: customHandovers,
    append: addCustomHandover,
    remove: removeCustomHandover,
  } = useFieldArray({
    name: getPath("custom_handovers") as `rotations.${number}.custom_handovers`,
    keyName: "key",
    control: formMethods.control,
  });

  const rotaHandoverType = formMethods.watch(
    getPath("rota_handover_type"),
  ) as RotaHandoverType;

  const effectiveFrom = formMethods.watch(getPath("effective_from"));
  const effectiveFromTs: DateTime | undefined = effectiveFrom
    ? DateTime.fromJSDate(effectiveFrom as Date)
    : undefined;

  const isDeferred = formMethods.watch(getPath("is_deferred")) === "true";

  const laterRotationChanges =
    initialData?.config?.rotations.filter(
      (r) =>
        effectiveFromTs &&
        r.effective_from &&
        DateTime.fromJSDate(r.effective_from) > effectiveFromTs,
    ) || [];

  const {
    fields: workingIntervals,
    append: addWorkingInterval,
    remove: removeWorkingInterval,
  } = useFieldArray({
    name: getPath(
      "working_intervals",
    ) as `rotations.${number}.working_intervals`,
    control: formMethods.control,
  });

  const intervals = formMethods.watch(
    getPath("working_intervals"),
  ) as IntervalData;
  const duplicateInterval = (i) => {
    const newInterval = cloneDeep(intervals[i]);
    addWorkingInterval(newInterval);
  };

  const handoverDate = formMethods.watch(getPath("handover_start_at"));

  // Interval form errors need to be cleared manually
  useEffect(() => {
    if (Object.keys(formMethods.formState.errors).length > 0) {
      formMethods.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(intervals)]);

  const { suggestBump } = useHandoverStartAtAdjustments({
    rotaId: initialData?.id,
    currentShifts: currentShifts,
    scheduledEntries: scheduledEntries,
    now: now,
    adjustHandoverStartAt: adjustHandoverStartAt,
    selectedUsers: selectedUsers,
    usersAreDirty: usersAreDirty,
    customHandovers: customHandovers,
    initialData: initialData,
    index: index,
    formMethods: formMethods,
    getPath: getPath,
  });

  if (!users || hydrating) {
    return <Loader />;
  }

  const changeoverDay = handoverDate
    ? DateTime.fromJSDate(handoverDate as Date, {
        zone: timezone,
      })
    : undefined;
  const changeoverOnWeekday = (changeoverDay?.weekday ?? 0) < 6;
  const changeoverOutsideOfficeHours =
    (changeoverDay?.hour ?? 12) < 8 || (changeoverDay?.hour ?? 12) > 18;
  const changeoverOutsideWorkingHours =
    !changeoverOnWeekday || changeoverOutsideOfficeHours;

  let changeoverText: string;
  if (rotaHandoverType === RotaHandoverType.Daily) {
    changeoverText = `Every day at ${changeoverDay?.toLocaleString(
      DateTime.TIME_24_SIMPLE,
    )}`;
  } else if (rotaHandoverType === RotaHandoverType.Weekly) {
    changeoverText = `${changeoverDay?.toLocaleString({
      weekday: "long",
    })}s at ${changeoverDay?.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
  } else {
    changeoverText = "";
  }

  if (changeoverOutsideWorkingHours) {
    changeoverText += " — this is outside of typical business hours";
  }

  const startTimeInThePast =
    DateTime.fromJSDate(handoverDate as Date) <
    DateTime.now().minus({ minutes: 20 });

  const terraformDisabledTooltipContent =
    "You cannot edit a schedule that is managed by Terraform";

  const handoverIntervalOptions = [
    {
      label: "Daily",
      value: RotaHandoverType.Daily,
      isDisabled: isManagedByTerraform,
      isDisabledTooltipContent: isManagedByTerraform
        ? terraformDisabledTooltipContent
        : undefined,
    },
    {
      label: "Weekly",
      value: RotaHandoverType.Weekly,
      isDisabled: isManagedByTerraform,
      isDisabledTooltipContent: isManagedByTerraform
        ? terraformDisabledTooltipContent
        : undefined,
    },
    {
      label: "Custom",
      value: RotaHandoverType.Custom,
      isDisabled: isManagedByTerraform,
      isDisabledTooltipContent: isManagedByTerraform
        ? terraformDisabledTooltipContent
        : undefined,
    },
  ];

  const layerCount = (formMethods.watch(getPath("layer_count")) as number) ?? 0;

  return (
    <>
      {/* Name */}
      {!hideNameInput && (
        <InputV2
          formMethods={formMethods}
          name={getPath("name")}
          label="Rota name"
          placeholder="Enter a name for your rota"
          className="w-full my-6"
          required
          disabled={isManagedByTerraform}
        />
      )}

      {/* People */}
      <div
        className={tcx("my-6", {
          "cursor-not-allowed": isManagedByTerraform,
        })}
      >
        <Form.Label className="block w-fit mb-2" htmlFor={"search"}>
          Responders
        </Form.Label>
        <ScheduleCreateEditFormUserSelector
          selectedUsers={users}
          cacheHydratedUsers={cacheHydratedUsers}
          onSelect={(usr) => {
            addUser({ id: usr.value });
            formMethods.clearErrors(getPath("users"));
          }}
          isManagedByTerraform={isManagedByTerraform}
        />

        <div className="flex flex-col grow">
          <div className="mt-2">
            <ScheduleCreateEditFormUsersList
              users={users}
              onRemoveUser={(i) => removeUser(i)}
              onDuplicateUser={(i) => insertUser(i, selectedUsers[i])}
              onReorder={(newArray) => replaceUsers(newArray)}
              isDisabled={isManagedByTerraform}
            />
          </div>
          {formMethods.formState.errors?.rotations?.[index]?.users && (
            <Txt className="text-red-500">
              {formMethods.formState.errors.rotations?.[index]?.users?.message}
            </Txt>
          )}
        </div>
        {suggestBump &&
          (!adjustHandoverStartAt ? (
            <Callout
              theme={CalloutTheme.Info}
              className={"mt-4 items-start"}
              iconOverride={IconEnum.Bulb}
              cta={
                <Button
                  analyticsTrackingId={"accept-effective-changes-suggestion"}
                  onClick={() => setAdjustHandoverStartAt(true)}
                >
                  Defer changes
                </Button>
              }
            >
              <>
                <div className={"font-semibold"}>Recommendation</div>
                <div>
                  These changes will affect who&rsquo;s on call now. To avoid
                  this, you can defer these changes so that they only take
                  effect after this shift is complete.
                </div>
              </>
            </Callout>
          ) : (
            <Callout
              theme={CalloutTheme.Info}
              className={"mt-4"}
              showIcon={false}
              cta={
                <Button
                  analyticsTrackingId={"accept-effective-changes-suggestion"}
                  onClick={() => {
                    formMethods.resetField(getPath("handover_start_at"));
                    formMethods.resetField(getPath("effective_from"));
                    setAdjustHandoverStartAt(false);
                  }}
                >
                  Undo
                </Button>
              }
            >
              Changes deferred to take effect at the start of the next shift —
              you can edit this below
            </Callout>
          ))}
      </div>

      {/* Rotates & Starts-at */}
      <div
        className={tcx("flex flex-col space-y-6 my-6", {
          "mt-4": !hideNameInput,
        })}
      >
        {isManagedByTerraform ? (
          <Tooltip
            content={terraformDisabledTooltipContent}
            analyticsTrackingId={null}
            bubbleProps={{ className: "!w-80" }}
            side="bottom"
          >
            <div className="w-[50%]">
              <DateTimeInputV2
                name={getPath("handover_start_at")}
                formMethods={formMethods}
                label={
                  <Txt medium>
                    Handover time <Txt inline grey>{`(${timezone})`}</Txt>
                  </Txt>
                }
                className="flex-[2]"
                inputClassName="min-h-[44px]" // The select in the same row is 44px tall :(
                timezone={timezone}
                disabled={isManagedByTerraform}
              />
            </div>
          </Tooltip>
        ) : (
          <DateTimeInputV2
            name={getPath("handover_start_at")}
            formMethods={formMethods}
            label={
              <Txt medium>
                Handover time <Txt inline grey>{`(${timezone})`}</Txt>
              </Txt>
            }
            className="flex-[2]"
            inputClassName="min-h-[44px]" // The select in the same row is 44px tall :(
            timezone={timezone}
            disabled={isManagedByTerraform}
          />
        )}
        {isManagedByTerraform ? (
          <Tooltip
            content={terraformDisabledTooltipContent}
            analyticsTrackingId={null}
            bubbleProps={{ className: "!w-80" }}
            side="bottom"
          >
            <div className="w-[70%]">
              <InputV2
                formMethods={formMethods}
                name={getPath("layer_count")}
                type={InputType.Number}
                label={
                  <div className={"flex flex-row items-center"}>
                    <Txt medium>Concurrent shifts</Txt>
                  </div>
                }
                inputClassName="min-h-[44px]" // The select in the same row is 44px tall :(
                className={"flex-[1]"}
                min={1}
                required
                disabled={isManagedByTerraform}
              />
            </div>
          </Tooltip>
        ) : (
          <div className="flex flex-col gap-2 w-full">
            <Tooltip
              content={`Configure multiple, simultaneous on-call users. This might be useful for more critical systems where you'd rather default to involving more people.`}
            >
              <div className={"flex flex-row items-center"}>
                <Txt medium>Concurrent shifts</Txt>
                <Icon id={IconEnum.Info} className="text-content-tertiary" />
              </div>
            </Tooltip>
            <Badge
              theme={BadgeTheme.Unstyled}
              className="bg-surface-secondary rounded w-full flex-[1] min-h-[44px] flex items-center justify-between"
            >
              <Button
                analyticsTrackingId={null}
                theme={ButtonTheme.Unstyled}
                onClick={() => {
                  formMethods.setValue(getPath("layer_count"), layerCount - 1);
                }}
                disabled={layerCount === 0 || isManagedByTerraform}
                className="p-1"
              >
                <Icon
                  id={IconEnum.Minus}
                  className={tcx(
                    (isManagedByTerraform || layerCount === 0) &&
                      "text-slate-400",
                  )}
                />
              </Button>
              <Txt>{layerCount}</Txt>
              <Button
                analyticsTrackingId={null}
                theme={ButtonTheme.Unstyled}
                onClick={() => {
                  formMethods.setValue(getPath("layer_count"), layerCount + 1);
                }}
                disabled={
                  layerCount >= selectedUsers.length || isManagedByTerraform
                }
                className="p-1"
              >
                <Icon
                  id={IconEnum.Add}
                  className={tcx(
                    (isManagedByTerraform ||
                      layerCount >= selectedUsers.length) &&
                      "text-slate-400",
                  )}
                />
              </Button>
            </Badge>
          </div>
        )}
      </div>
      {
        // Only want to display this on creation, looks weird if it shows on edit
        startTimeInThePast && !initialData && (
          <Txt inline grey>
            This rota starts in the past, but shifts will be scheduled from the
            current time
          </Txt>
        )
      }
      {/* Active on-call intervals */}
      <div className={"my-6"}>
        <RadioButtonGroupV2
          boxed
          name={getPath("has_working_intervals")}
          label="Working hours"
          className="mt-4"
          formMethods={formMethods}
          inputWrapperClassName={tcx({
            "bg-surface-secondary rounded-t-lg": hasWorkingIntervals,
          })}
          srLabel={"working_intervals"}
          options={[
            {
              label: "All day",
              value: "all_day",
              isDisabled: isManagedByTerraform,
              isDisabledTooltipContent: isManagedByTerraform
                ? terraformDisabledTooltipContent
                : undefined,
            },
            {
              label: "Specific times",
              value: "specific_times",
              isDisabled: isManagedByTerraform,
              isDisabledTooltipContent: isManagedByTerraform
                ? terraformDisabledTooltipContent
                : undefined,
            },
          ]}
        />
        <AnimatePresence>
          {hasWorkingIntervals && (
            <motion.div
              className="p-4 bg-slate-50 border border-slate-100 rounded-b-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
              }}
            >
              <div className="flex flex-col space-y-1">
                {/**
                 * First we render the first interval with headings
                 * We could render a heading row and then all the inputs beneath, but
                 * getting flex to line everything up is a nightmare, so it's easier
                 * to simply attach the headings to the first row of inputs
                 */}
                <Form.ErrorMessage
                  errors={formMethods.formState.errors}
                  name={getPath("working_intervals")}
                />
                <div className="flex space-x-4">
                  <div className="w-[50%]">
                    <Txt bold className="mb-2">
                      Days
                    </Txt>
                    <WeekdayPicker
                      formMethods={formMethods}
                      name={getPath(`working_intervals.0`)}
                    />
                  </div>
                  <div className="flex-center-y w-[50%] space-x-2">
                    <div className="min-w-0">
                      <Txt bold className="mb-2">
                        Starting
                      </Txt>
                      <TimeInputV2
                        name={getPath(`working_intervals.0.start_time`)}
                        formMethods={formMethods}
                        className="!h-11"
                      />
                    </div>
                    <div className="min-w-0">
                      <Txt bold className="mb-2">
                        Ending
                      </Txt>
                      <TimeInputV2
                        name={getPath(`working_intervals.0.end_time`)}
                        formMethods={formMethods}
                        className="!h-11"
                      />
                    </div>
                    <Tooltip content="Duplicate">
                      <WeekdayPickerButton
                        title="duplicate"
                        icon={IconEnum.Copy}
                        className="mt-7"
                        analyticsTrackingId={null}
                        onClick={() => duplicateInterval(0)}
                      />
                    </Tooltip>
                    <Tooltip
                      side={"bottom"}
                      align={"end"}
                      content={
                        workingIntervals.length === 1
                          ? "You must have at least one interval"
                          : "Delete"
                      }
                    >
                      <WeekdayPickerButton
                        title="delete"
                        icon={IconEnum.Delete2}
                        className="mt-7"
                        analyticsTrackingId={null}
                        iconProps={{
                          size: IconSize.Large,
                        }}
                        disabled={workingIntervals.length === 1}
                        onClick={() => removeWorkingInterval(0)}
                      />
                    </Tooltip>
                  </div>
                </div>
                {workingIntervals.map((_, i) => {
                  if (i === 0) {
                    return null;
                  }
                  return (
                    <div
                      className="flex-center-y space-x-4"
                      key={getPath(`working_intervals.${i}`)}
                    >
                      <div className="w-[50%]">
                        <WeekdayPicker
                          formMethods={formMethods}
                          name={getPath(`working_intervals.${i}`)}
                        />
                      </div>
                      <div className="flex-center-y w-[50%] space-x-2">
                        <TimeInputV2
                          name={getPath(`working_intervals.${i}.start_time`)}
                          formMethods={formMethods}
                          className="min-w-0 h-11"
                        />
                        <TimeInputV2
                          name={getPath(`working_intervals.${i}.end_time`)}
                          formMethods={formMethods}
                          className="min-w-0 h-11"
                        />
                        <Tooltip content="Duplicate">
                          <WeekdayPickerButton
                            title="duplicate"
                            icon={IconEnum.Copy}
                            analyticsTrackingId={null}
                            onClick={() => duplicateInterval(i)}
                            iconProps={{
                              size: IconSize.Large,
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          side={"bottom"}
                          align={"end"}
                          content={
                            workingIntervals.length === 1
                              ? "You must have at least one interval"
                              : "Delete"
                          }
                        >
                          <WeekdayPickerButton
                            title="delete"
                            icon={IconEnum.Delete2}
                            analyticsTrackingId={null}
                            disabled={workingIntervals.length === 1}
                            onClick={() => removeWorkingInterval(i)}
                            iconProps={{
                              size: IconSize.Large,
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  );
                })}
                <AddNewButton
                  className="!ml-0 !mt-2 w-fit"
                  analyticsTrackingId={"schedules-add-new-working-interval"}
                  onClick={() =>
                    addWorkingInterval(getDefaultWorkingInterval())
                  }
                  title="Add interval"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Shift rotation and deferred config */}
      <div>
        <RadioButtonGroupV2
          boxed
          name={getPath("rota_handover_type")}
          label="Change shifts"
          className="mt-6"
          formMethods={formMethods}
          inputWrapperClassName={tcx({
            "bg-surface-secondary rounded-t-lg":
              rotaHandoverType === RotaHandoverType.Custom,
          })}
          srLabel={"handover_intervals"}
          options={handoverIntervalOptions.map((o) => ({
            ...o,
            suffixNode: rotaHandoverType === o.value && (
              <Txt
                className={tcx("w-full flex justify-end", {
                  "text-yellow-900": changeoverOutsideWorkingHours,
                  "text-slate-600": !changeoverOutsideWorkingHours,
                })}
              >
                {changeoverText}
              </Txt>
            ),
          }))}
        />
        <AnimatePresence>
          {rotaHandoverType === RotaHandoverType.Custom && (
            <motion.div
              className="p-4 bg-slate-50 border border-slate-100 rounded-b-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{
                opacity: 0,
              }}
            >
              <ContentBox
                className={
                  "flex items-center text-sm flex-wrap p-4 gap-2 justify-between"
                }
              >
                <div className="flex items-center text-xs gap-2 flex-wrap">
                  {customHandovers.map((handover, i) => {
                    return (
                      <CustomHandoverElement
                        key={handover.key}
                        index={i}
                        formMethods={formMethods}
                        getPath={getPath}
                        showDeleteButton={customHandovers.length > 1}
                        removeHandover={() => removeCustomHandover(i)}
                      />
                    );
                  })}
                </div>
                {/* If the flag is enabled, or you've somehow already got multiple handovers, show the add interval button */}
                {customHandovers.length < 3 && (
                  <Button
                    analyticsTrackingId={"add-handover-interval"}
                    theme={ButtonTheme.Secondary}
                    icon={IconEnum.Add}
                    size={ButtonSize.Small}
                    onClick={() =>
                      addCustomHandover({
                        handover_interval: "1",
                        handover_interval_type: CustomHandoverRuleType.Daily,
                      })
                    }
                  >
                    Add interval
                  </Button>
                )}
              </ContentBox>
            </motion.div>
          )}
        </AnimatePresence>
        {initialData && !isRotaCreateMode && (
          <>
            <Txt medium className="mt-6">
              When should these changes take effect?
            </Txt>
            <Txt grey className="mt-1">
              Have your changes take effect immediately or specify a time in the
              future.
            </Txt>
            <RadioButtonGroupV2
              boxed
              name={getPath("is_deferred")}
              label=""
              className="mt-2"
              formMethods={formMethods}
              inputWrapperClassName={tcx({
                "bg-surface-secondary rounded-t-lg": isDeferred,
              })}
              srLabel={"is_deferred"}
              options={[
                {
                  label: "Immediately",
                  value: "false",
                  isDisabled: isManagedByTerraform,
                  isDisabledTooltipContent: terraformDisabledTooltipContent,
                },
                {
                  label: "Later",
                  value: "true",
                  isDisabled: isManagedByTerraform,
                  isDisabledTooltipContent: terraformDisabledTooltipContent,
                },
              ]}
              onValueChange={(val) => {
                // Default to a useful time if we're deferring changes
                if (val === "true") {
                  formMethods.setValue(
                    getPath("effective_from"),
                    roundToNearestMinutes(now.plus({ days: 1 }).toJSDate(), {
                      nearestTo: 15,
                    }),
                  );
                }
                // Reset effective_from if we're disabling deferred changes
                if (val === "false") {
                  formMethods.setValue(
                    getPath("effective_from"),
                    initialData?.config?.rotations[index].effective_from,
                  );
                }
              }}
            />
            <AnimatePresence>
              {isDeferred && (
                <motion.div
                  className="p-4 bg-slate-50 border border-slate-100 rounded-b-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  <DateTimeInputV2
                    name={getPath("effective_from")}
                    formMethods={formMethods}
                    label={<Txt bold>Changes effective from</Txt>}
                    className="flex-[2]"
                    inputClassName="min-h-[44px]" // The select in the same row is 44px tall :(
                    timezone={timezone}
                  />
                  {/* Use 3m ago as the marker for 'now' so that we don't slide into
                   /* this warning if we spend a bit of time on the form. */}
                  {effectiveFromTs &&
                    effectiveFromTs < now.minus(3 * 1000 * 60) && (
                      <Form.Helptext className="text-xs mb-0 cursor-default">
                        You&rsquo;ve set your changes to start in the past, but
                        they will only come into effect from now. Did you mean
                        to make them effective from{" "}
                        <span
                          className={"underline hover:cursor-pointer"}
                          onClick={() =>
                            formMethods.setValue(
                              getPath("is_deferred"),
                              "false",
                              { shouldDirty: true },
                            )
                          }
                        >
                          now
                        </span>
                        ?
                      </Form.Helptext>
                    )}
                  {laterRotationChanges.length > 0 && (
                    <Callout theme={CalloutTheme.Warning} className={"mt-2"}>
                      You have {laterRotationChanges.length}{" "}
                      {pluralize("change", laterRotationChanges.length)}{" "}
                      scheduled after{" "}
                      {effectiveFromTs?.toLocaleString(DateTime.DATETIME_SHORT)}
                      . Saving this change will overwrite{" "}
                      {laterRotationChanges.length === 1 ? "it" : "them"}. If
                      you do make this change, you will still be able to restore
                      the previous version.
                    </Callout>
                  )}
                  {/* Callout about changing rota */}
                  {initialData && usersAreDirty && (
                    <div className="p-4 mt-4 bg-slate-50 rounded-2 border border-stroke justify-start items-center gap-3 inline-flex w-full">
                      <div>
                        <Icon
                          id={IconEnum.Bulb}
                          size={IconSize.Medium}
                          className="text-content-tertiary"
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <div className="flex flex-wrap gap-4 items-start md:flex-nowrap">
                          <Txt lightGrey>
                            You can use the{" "}
                            <span
                              className={"font-medium text-content-primary"}
                            >
                              handover time
                            </span>{" "}
                            input at the top of this form to amend your shift
                            handover and starting times.
                          </Txt>
                        </div>
                      </div>
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </div>

      {onDelete && (
        <Button
          className="mt-6"
          analyticsTrackingId={null}
          onClick={onDelete}
          theme={ButtonTheme.Destroy}
        >
          Delete rota
        </Button>
      )}
    </>
  );
};

const WeekdayPicker = ({
  formMethods,
  name,
}: {
  formMethods: UseFormReturn<ScheduleFormData>;
  name: FieldPath<ScheduleFormData>;
}) => {
  const vals = formMethods.watch(name) as unknown as WorkingInterval;

  const toggleDay = (day: WeekdayIntervalWeekdayEnum) => {
    // @ts-expect-error I can't get TS to understand that `name` is specifically the path to a WorkingInterval
    formMethods.setValue(`${name}.days.${day}`, !vals.days[day]);
  };
  return (
    <div className="flex-center-y w-full max-w-[24em] gap-x-2 min-h-11 flex-wrap">
      {Object.entries(WeekdayIntervalWeekdayEnum).map(([label, value]) => {
        const active = vals.days[value];
        return (
          <Button
            size={ButtonSize.Small}
            theme={ButtonTheme.UnstyledPill}
            className={tcx("transition justify-center aspect-square", {
              "!text-slate-700 !bg-white": !active,
              "!bg-surface-invert !text-white !border-slate-900 hover:!border-stroke":
                active,
            })}
            key={`${name}-${value}`}
            onClick={() => toggleDay(value)}
            analyticsTrackingId={null}
          >
            {label.slice(0, 1)}
          </Button>
        );
      })}
    </div>
  );
};

const WeekdayPickerButton = (props: ButtonProps) => {
  return (
    <Button
      theme={ButtonTheme.UnstyledPill}
      iconProps={{
        size: IconSize.Large,
      }}
      {...props}
      className={tcx(
        "bg-surface-secondary text-slate-600 border-none",
        "hover:bg-surface-tertiary active:bg-surface-tertiary",
        "disabled:bg-surface-secondary disabled:text-slate-300",
        "!h-11 !w-12",
        props.className,
      )}
    />
  );
};

export const getDefaultWorkingInterval = (): WorkingInterval => ({
  days: Object.values(
    WeekdayIntervalWeekdayEnum,
    // We want the default to be Mon-Fri, so we filter the weekend here
  )
    .filter(
      (day) =>
        ![
          WeekdayIntervalWeekdayEnum.Saturday,
          WeekdayIntervalWeekdayEnum.Sunday,
        ].includes(day),
    )
    .reduce(
      (acc, cur) => {
        acc[cur] = true;
        return acc;
      },
      {} as WorkingInterval["days"],
    ),
  start_time: "09:00",
  end_time: "17:00",
});
