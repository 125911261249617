import { tcx } from "src/utils/tailwind-classes";

type TimelineSpacerProps = {
  expanded?: boolean;
  children?: React.ReactNode;
  hideLine?: boolean;
};

export const TimelineSpacer = ({
  children,
  expanded = false,
  hideLine = false,
}: TimelineSpacerProps) => {
  return (
    <div className={tcx("flex py-[3px]", !expanded && "h-4")}>
      <div className="w-[4.3475rem] shrink-0"></div>
      <div
        className={tcx(
          "w-full h-full pl-[34px] flex flex-col gap-2",
          !hideLine && "border-l stroke-stroke-primary",
          expanded && "pb-4 pt-1",
        )}
      >
        {expanded && children}
      </div>
    </div>
  );
};
