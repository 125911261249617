import { EngineScope, Resource } from "@incident-io/api";
import { EngineFormElement } from "@incident-shared/engine";
import { useExpressionsMethods } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { FormErrorMessage } from "@incident-shared/forms/ErrorMessage";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { FrontEndIconEnum } from "@incident-ui/Icon/Icon";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import { useFormContext, useFormState } from "react-hook-form";

import { AlertsCatalogSetupWidget } from "../alert-source-create-edit-v2/configure/AlertsCatalogSetupWidget";
import { EscalationBindingSuggestions } from "./EscalationBindingSuggestions";
import { AlertRouteFormData } from "./types";

export const EscalationBindingInput = ({
  resources,
  scopeWithExpressions,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useFormContext<AlertRouteFormData>();
  const { expressionsMethods: expressionsMethods } = useExpressionsMethods();

  if (!expressionsMethods) {
    throw new Error("Expressions methods not found");
  }

  const bindingValue = formMethods.watch("escalationBinding");

  const formState = useFormState(formMethods);

  const formErrorMessage = (
    <FormErrorMessage errors={formState.errors} name={"escalationBinding"} />
  );

  const radioOptions: RadioButtonGroupOption[] = [
    {
      value: "dynamic",
      label: "A dynamic value",
      description: "Set an escalation path based on the alert's attributes.",
      renderWhenSelectedNode: () => {
        // If you've got a value set, we just show the regular form element view

        return (
          <div className={"flex w-full flex-col gap-4"}>
            <AlertsCatalogSetupWidget />

            {bindingValue?.value ||
            (bindingValue?.array_value ?? []).length > 0 ? (
              <EngineFormElement
                name="escalationBinding"
                key={""}
                resourceType={'CatalogEntry["EscalationPath"]'}
                array
                label=""
                resources={resources}
                scope={scopeWithExpressions}
                showPlaceholder
                mode="variables_and_expressions"
                required={false}
                optionIconOverride={FrontEndIconEnum.Close}
              />
            ) : (
              <EscalationBindingSuggestions
                resources={resources}
                scopeWithExpressions={scopeWithExpressions}
              />
            )}
            {formErrorMessage}
          </div>
        );
      },
    },
    {
      value: "static",
      label: "A static value",
      description: "All alerts will be escalated to the same path.",
      renderWhenSelectedNode: () => (
        <div className={"flex w-full flex-col gap-4"}>
          <EngineFormElement
            mode="plain_input"
            name="escalationBinding"
            resourceType={'CatalogEntry["EscalationPath"]'}
            array
            expressionLabelOverride="Escalation path"
            resources={resources}
            showPlaceholder
            required={false}
          />
          {formErrorMessage}
        </div>
      ),
    },
  ];

  return (
    <RadioButtonGroupV2
      formMethods={formMethods}
      options={radioOptions}
      name="mode"
      label="How should alerts be escalated?"
      srLabel="Escalation mode"
      boxed
      preValueChange={() => {
        // If you change the radio button, then your expression/reference/literal is no longer valid
        formMethods.setValue("escalationBinding", undefined);
      }}
    />
  );
};
