import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";

import { publicApiUrl } from "../../../../../utils/environment";
import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceMonteCarloSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <p>
        Log into Monte Carlo and navigate to the <strong>Settings</strong> page,
        then click on <strong>Notifications</strong> and create an audience.
      </p>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            Set the <strong>Audience name</strong> to your preference, and the{" "}
            <strong>Recepient channel</strong> to:
          </>
        }
        blocks={[
          {
            title: "Recipient channel",
            code: "Webhook",
          },
        ]}
      />

      <div className="overflow-hidden flex flex-col gap-2">
        <p>
          Then, set the <strong>Webhook URL</strong> to:
        </p>
        <SingleLineCodeBlock
          title="Webhook URL"
          code={`${publicApiUrl()}/v2/alert_events/monte_carlo/${
            alertSourceConfig.id
          }`}
        />
        <p>
          And the <strong>Display name</strong> to:
        </p>
        <SingleLineCodeBlock
          title="Display name"
          code={`${alertSourceConfig.name}`}
        />
      </div>
      <p>
        Ensure your connection is successful by clicking on the{" "}
        <strong>Test notification</strong> button.
      </p>
      <p>
        Save your audience and you&rsquo;ll start receiving alerts from Monte
        Carlo in incident.io.
      </p>
    </SetupInfoNumberedList>
  );
};
