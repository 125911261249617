/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffSetFeatureGatesRequestBody
 */
export interface StaffSetFeatureGatesRequestBody {
    /**
     * Whether this org is allowed to access advanced insights
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    advanced_insights: boolean;
    /**
     * Whether this org is allowed to use the AI assistant
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    ai_assistant: boolean;
    /**
     * Whether this org is allowed to use AI features
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    ai_features: boolean;
    /**
     * Whether this org is allowed to access the API
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    api_access: boolean;
    /**
     * How many Atlassian Statuspage pages is this org allowed to have configured (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    atlassian_statuspage_pages_count?: number;
    /**
     * Whether this org is allowed to access audit logs
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    audit_logs: boolean;
    /**
     * How many on call schedules is this org allowed? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    custom_catalog_types_count?: number;
    /**
     * How many custom insights dashboard is this org allowed to have
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    custom_dashboards_count?: number;
    /**
     * Whether or not this org can set conditions on custom fields
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    custom_field_conditions: boolean;
    /**
     * How many custom fields is this org allowed (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    custom_field_count?: number;
    /**
     * Whether this org is allowed to create and customise nudges
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    custom_nudges: boolean;
    /**
     * Whether this org is allowed to schedule debriefs in placeholder events
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    debrief_placeholders: boolean;
    /**
     * How many atlassian sites (used for Jira and/or Confluence) is this org allowed to have enabled? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    enabled_atlassian_sites_count?: number;
    /**
     * How many follow-up export templates is this org allowed to have configured? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    follow_up_issue_templates_count?: number;
    /**
     * Are follow-up priorities allowed
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    follow_up_priorities: boolean;
    /**
     * Is this org allowed to have customise their follow-up priorities
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    follow_up_priorities_customisable: boolean;
    /**
     * How many incident lifecycles is this org allowed to have configured? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    incident_lifeycle_count?: number;
    /**
     * How many incident ticket templates (used for Jira Cloud sync) is this org allowed to have configured? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    incident_ticket_issue_templates_count?: number;
    /**
     * Are incident types and conditional fields/roles allowed
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    incident_types: boolean;
    /**
     * How many integrations is this org allowed (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    integrations_count?: number;
    /**
     * How many internal incident.io status pages is this org allowed to have configured (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    internal_status_pages_count?: number;
    /**
     * Whether this org is allowed to use the on-call calculator
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    on_call_calculator: boolean;
    /**
     * How many on call responders is this org allowed? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    on_call_responders_count?: number;
    /**
     * How many on call schedules is this org allowed? (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    on_call_schedules_count?: number;
    /**
     * When should we manually disable this trial for an organisation
     * @type {Date}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    on_call_soft_deadline?: Date;
    /**
     * How many policies is this org allowed (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    policies_count?: number;
    /**
     * Whether this org is allowed to create custom post-incident flow tasks
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    post_incident_flow_custom_tasks: boolean;
    /**
     * Whether this org is allowed to have their post-incident tasks automatically assigned
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    post_incident_task_auto_assign: boolean;
    /**
     * Whether this org is allowed to set automatic reminders for their post-incident tasks
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    post_incident_task_auto_remind: boolean;
    /**
     * How many postmortem templates is this org allowed (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    postmortem_templates_count?: number;
    /**
     * Whether this org is allowed to enable private incidents
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    private_incidents: boolean;
    /**
     * Is this org allowed to customise their base rbac roles
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    rbac_base_roles_customisable: boolean;
    /**
     * Is this org allowed to have custom rbac roles
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    rbac_custom_roles: boolean;
    /**
     * Whether this org is allowed to use SAML SSO
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    saml: boolean;
    /**
     * How many saved views is this org allowed per context (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    saved_views_per_context_count?: number;
    /**
     * Whether this org is allowed to use SCIM
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    scim: boolean;
    /**
     * Whether this org is allowed to install us on slack enterprise grid level
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    slack_enterprise_grid_install: boolean;
    /**
     * How many incident.io status pages is this org allowed to have configured (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    status_pages_count?: number;
    /**
     * Whether this org is allowed to have customer specific status pages
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    status_pages_customer_pages: boolean;
    /**
     * Whether this org is allowed to have sub-pages on their status pages
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    status_pages_sub_pages: boolean;
    /**
     * How many streams is this org allowed to have per incident (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    streams_per_incident_count?: number;
    /**
     * Whether this org can manually create or auto-create triage incidents
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    triage_incidents: boolean;
    /**
     * Whether this org is allowed to use SMS, call and email features. This is to prevent abuse of these features
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    use_telecom: boolean;
    /**
     * Whether this org is allowed to use webhooks
     * @type {boolean}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    webhooks: boolean;
    /**
     * How many workflows is this org allowed (null means there's no limit)
     * @type {number}
     * @memberof StaffSetFeatureGatesRequestBody
     */
    workflows_count?: number;
}

export function StaffSetFeatureGatesRequestBodyFromJSON(json: any): StaffSetFeatureGatesRequestBody {
    return StaffSetFeatureGatesRequestBodyFromJSONTyped(json, false);
}

export function StaffSetFeatureGatesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffSetFeatureGatesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'advanced_insights': json['advanced_insights'],
        'ai_assistant': json['ai_assistant'],
        'ai_features': json['ai_features'],
        'api_access': json['api_access'],
        'atlassian_statuspage_pages_count': !exists(json, 'atlassian_statuspage_pages_count') ? undefined : json['atlassian_statuspage_pages_count'],
        'audit_logs': json['audit_logs'],
        'custom_catalog_types_count': !exists(json, 'custom_catalog_types_count') ? undefined : json['custom_catalog_types_count'],
        'custom_dashboards_count': !exists(json, 'custom_dashboards_count') ? undefined : json['custom_dashboards_count'],
        'custom_field_conditions': json['custom_field_conditions'],
        'custom_field_count': !exists(json, 'custom_field_count') ? undefined : json['custom_field_count'],
        'custom_nudges': json['custom_nudges'],
        'debrief_placeholders': json['debrief_placeholders'],
        'enabled_atlassian_sites_count': !exists(json, 'enabled_atlassian_sites_count') ? undefined : json['enabled_atlassian_sites_count'],
        'follow_up_issue_templates_count': !exists(json, 'follow_up_issue_templates_count') ? undefined : json['follow_up_issue_templates_count'],
        'follow_up_priorities': json['follow_up_priorities'],
        'follow_up_priorities_customisable': json['follow_up_priorities_customisable'],
        'incident_lifeycle_count': !exists(json, 'incident_lifeycle_count') ? undefined : json['incident_lifeycle_count'],
        'incident_ticket_issue_templates_count': !exists(json, 'incident_ticket_issue_templates_count') ? undefined : json['incident_ticket_issue_templates_count'],
        'incident_types': json['incident_types'],
        'integrations_count': !exists(json, 'integrations_count') ? undefined : json['integrations_count'],
        'internal_status_pages_count': !exists(json, 'internal_status_pages_count') ? undefined : json['internal_status_pages_count'],
        'on_call_calculator': json['on_call_calculator'],
        'on_call_responders_count': !exists(json, 'on_call_responders_count') ? undefined : json['on_call_responders_count'],
        'on_call_schedules_count': !exists(json, 'on_call_schedules_count') ? undefined : json['on_call_schedules_count'],
        'on_call_soft_deadline': !exists(json, 'on_call_soft_deadline') ? undefined : (new Date(json['on_call_soft_deadline'])),
        'policies_count': !exists(json, 'policies_count') ? undefined : json['policies_count'],
        'post_incident_flow_custom_tasks': json['post_incident_flow_custom_tasks'],
        'post_incident_task_auto_assign': json['post_incident_task_auto_assign'],
        'post_incident_task_auto_remind': json['post_incident_task_auto_remind'],
        'postmortem_templates_count': !exists(json, 'postmortem_templates_count') ? undefined : json['postmortem_templates_count'],
        'private_incidents': json['private_incidents'],
        'rbac_base_roles_customisable': json['rbac_base_roles_customisable'],
        'rbac_custom_roles': json['rbac_custom_roles'],
        'saml': json['saml'],
        'saved_views_per_context_count': !exists(json, 'saved_views_per_context_count') ? undefined : json['saved_views_per_context_count'],
        'scim': json['scim'],
        'slack_enterprise_grid_install': json['slack_enterprise_grid_install'],
        'status_pages_count': !exists(json, 'status_pages_count') ? undefined : json['status_pages_count'],
        'status_pages_customer_pages': json['status_pages_customer_pages'],
        'status_pages_sub_pages': json['status_pages_sub_pages'],
        'streams_per_incident_count': !exists(json, 'streams_per_incident_count') ? undefined : json['streams_per_incident_count'],
        'triage_incidents': json['triage_incidents'],
        'use_telecom': json['use_telecom'],
        'webhooks': json['webhooks'],
        'workflows_count': !exists(json, 'workflows_count') ? undefined : json['workflows_count'],
    };
}

export function StaffSetFeatureGatesRequestBodyToJSON(value?: StaffSetFeatureGatesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'advanced_insights': value.advanced_insights,
        'ai_assistant': value.ai_assistant,
        'ai_features': value.ai_features,
        'api_access': value.api_access,
        'atlassian_statuspage_pages_count': value.atlassian_statuspage_pages_count,
        'audit_logs': value.audit_logs,
        'custom_catalog_types_count': value.custom_catalog_types_count,
        'custom_dashboards_count': value.custom_dashboards_count,
        'custom_field_conditions': value.custom_field_conditions,
        'custom_field_count': value.custom_field_count,
        'custom_nudges': value.custom_nudges,
        'debrief_placeholders': value.debrief_placeholders,
        'enabled_atlassian_sites_count': value.enabled_atlassian_sites_count,
        'follow_up_issue_templates_count': value.follow_up_issue_templates_count,
        'follow_up_priorities': value.follow_up_priorities,
        'follow_up_priorities_customisable': value.follow_up_priorities_customisable,
        'incident_lifeycle_count': value.incident_lifeycle_count,
        'incident_ticket_issue_templates_count': value.incident_ticket_issue_templates_count,
        'incident_types': value.incident_types,
        'integrations_count': value.integrations_count,
        'internal_status_pages_count': value.internal_status_pages_count,
        'on_call_calculator': value.on_call_calculator,
        'on_call_responders_count': value.on_call_responders_count,
        'on_call_schedules_count': value.on_call_schedules_count,
        'on_call_soft_deadline': value.on_call_soft_deadline === undefined ? undefined : (value.on_call_soft_deadline.toISOString()),
        'policies_count': value.policies_count,
        'post_incident_flow_custom_tasks': value.post_incident_flow_custom_tasks,
        'post_incident_task_auto_assign': value.post_incident_task_auto_assign,
        'post_incident_task_auto_remind': value.post_incident_task_auto_remind,
        'postmortem_templates_count': value.postmortem_templates_count,
        'private_incidents': value.private_incidents,
        'rbac_base_roles_customisable': value.rbac_base_roles_customisable,
        'rbac_custom_roles': value.rbac_custom_roles,
        'saml': value.saml,
        'saved_views_per_context_count': value.saved_views_per_context_count,
        'scim': value.scim,
        'slack_enterprise_grid_install': value.slack_enterprise_grid_install,
        'status_pages_count': value.status_pages_count,
        'status_pages_customer_pages': value.status_pages_customer_pages,
        'status_pages_sub_pages': value.status_pages_sub_pages,
        'streams_per_incident_count': value.streams_per_incident_count,
        'triage_incidents': value.triage_incidents,
        'use_telecom': value.use_telecom,
        'webhooks': value.webhooks,
        'workflows_count': value.workflows_count,
    };
}

