import { IconEnum, Link } from "@incident-ui";
import _, { isEmpty } from "lodash";
import React from "react";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  CustomFieldTypeInfoFieldTypeEnum,
  CustomFieldValueUpdate,
  Incident,
  TimelineItem,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemCustomFieldValueUpdate = (
  _: Incident,
  item: TimelineItem,
): TimelineItemRenderProps => {
  if (!item.custom_field_value_update) {
    throw new Error(
      "malformed timeline item: custom_field_value_update was missing custom_field_value_update field",
    );
  }
  const hasPrevious = !isEmpty(
    item.custom_field_value_update?.previous_custom_field_values,
  );
  const hasNext = !isEmpty(item.custom_field_value_update?.custom_field_values);

  let verb: string;
  if (hasPrevious && hasNext) {
    verb = "updated";
  } else if (hasPrevious && !hasNext) {
    verb = "unset";
  } else {
    verb = "set";
  }

  return {
    icon: IconEnum.CustomField,
    avatarUrl: item.custom_field_value_update.actor?.user?.avatar_url,
    description: (
      <>
        <TimelineItemSource actor={item.custom_field_value_update.actor} />{" "}
        {verb} the{" "}
        <span className="font-semibold">
          {item.custom_field_value_update.custom_field.name}
        </span>{" "}
        field
      </>
    ),
    children: (
      <TimelineItemContentBox className="flex-center-y">
        <UpdateRenderer updateObj={item.custom_field_value_update} />
      </TimelineItemContentBox>
    ),
  };
};

export function PreviousValue({
  children,
  hideArrow = false,
  hidden,
}: {
  children: React.ReactNode;
  hideArrow?: boolean;
  hidden?: boolean;
}): React.ReactElement {
  return (
    <>
      <span className="sr-only">Previous value</span>
      <del
        className={tcx("line-through break-words", {
          "text-slate-600": !hidden,
        })}
      >
        {children}
      </del>
      {!hideArrow && (
        <span aria-hidden className="px-2">
          &rarr;
        </span>
      )}
    </>
  );
}

export function NewValue({
  children,
  hidden,
}: {
  children: React.ReactNode;
  hidden?: boolean;
}): React.ReactElement {
  return (
    <>
      <span className="sr-only">New value</span>
      <span className={tcx("break-words", { "text-content-primary": !hidden })}>
        {children}
      </span>
    </>
  );
}

export const UpdateRenderer = ({
  updateObj,
  isMultiUpdate = false,
  hidden,
}: {
  updateObj: CustomFieldValueUpdate;
  isMultiUpdate?: boolean;
  hidden?: boolean;
}): React.ReactElement | null => {
  const hasPrevious = !_.isEmpty(updateObj.previous_custom_field_values);
  const empty = <div className="text-slate-400 text-sm">Empty</div>;

  switch (updateObj.custom_field.field_type) {
    case CustomFieldTypeInfoFieldTypeEnum.SingleSelect:
    case CustomFieldTypeInfoFieldTypeEnum.MultiSelect: {
      return (
        <>
          {hasPrevious && updateObj.previous_custom_field_values && (
            <PreviousValue hidden={hidden}>
              {updateObj.previous_custom_field_values
                .map(
                  ({ value_option, value_catalog_entry }) =>
                    value_option?.value || value_catalog_entry?.name,
                )
                .join(", ")}
            </PreviousValue>
          )}
          <NewValue hidden={hidden}>
            {updateObj.custom_field_values
              ?.map(
                ({ value_option, value_catalog_entry }) =>
                  value_option?.value || value_catalog_entry?.name,
              )
              .join(", ") || empty}
          </NewValue>
        </>
      );
    }

    case CustomFieldTypeInfoFieldTypeEnum.Text:
      return (
        <>
          {hasPrevious && updateObj.previous_custom_field_values && (
            <PreviousValue hidden={hidden}>
              {updateObj.previous_custom_field_values
                .map(({ value_text }) => value_text)
                .join(", ")}
            </PreviousValue>
          )}
          <NewValue hidden={hidden}>
            {updateObj.custom_field_values
              ?.map(({ value_text }) => value_text)
              .join(", ") || empty}
          </NewValue>
        </>
      );

    case CustomFieldTypeInfoFieldTypeEnum.Link:
      return hasPrevious &&
        !isMultiUpdate &&
        updateObj.previous_custom_field_values ? (
        <div className="flex flex-col">
          <PreviousValue hideArrow>
            {updateObj.previous_custom_field_values.map(({ value_link }) => {
              if (value_link != null)
                return (
                  <Link
                    openInNewTab
                    className="!text-slate-600"
                    key={value_link}
                    href={value_link}
                    analyticsTrackingId={null}
                  >
                    {value_link}
                  </Link>
                );
              return <></>;
            })}
          </PreviousValue>

          <NewValue hidden={hidden}>
            <p>
              <span aria-hidden className="px-2 text-content-primary">
                &rarr;
              </span>
              {(updateObj.custom_field_values || []).map(({ value_link }) => {
                if (value_link != null)
                  return (
                    <Link
                      key={value_link}
                      href={value_link}
                      openInNewTab
                      analyticsTrackingId={null}
                    >
                      {value_link}
                    </Link>
                  );
                return <></>;
              }) || empty}
            </p>
          </NewValue>
        </div>
      ) : (
        <NewValue hidden={hidden}>
          {updateObj.custom_field_values
            ?.map(({ value_link }) => value_link)
            .join(", ") || empty}
        </NewValue>
      );

    case CustomFieldTypeInfoFieldTypeEnum.Numeric:
      return (
        <>
          {hasPrevious && updateObj.previous_custom_field_values && (
            <>
              <PreviousValue hidden={hidden}>
                {updateObj.previous_custom_field_values
                  .map(({ value_numeric }) => value_numeric)
                  .join(", ")}
              </PreviousValue>
            </>
          )}
          <NewValue hidden={hidden}>
            {updateObj.custom_field_values
              ?.map(({ value_numeric }) => value_numeric)
              .join(", ") || empty}
          </NewValue>
        </>
      );

    default:
      assertUnreachable(updateObj.custom_field.field_type);
      return null;
  }
};
