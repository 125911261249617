import {
  EngineParamBinding,
  EngineParamBindingValue,
  ExpressionOperationOperationTypeEnum,
} from "@incident-io/api";
import { ExpressionFormData } from "@incident-shared/engine/expressions/expressionToPayload";
import {
  InterpolatedRef,
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { Markdown } from "@incident-ui";
import React from "react";
import { EnrichedScope, lookupInScope } from "src/utils/scope";
import { tcx } from "src/utils/tailwind-classes";

import { useExpressionsMethods } from "../expressions/ExpressionsMethodsProvider";
import { ViewExpression } from "../expressions/ViewExpression";
import { isExpression } from "../referenceSource";
import { EngineBindingValueBadge } from "./EngineBindingValueBadge";

export type EngineBindingProps = {
  variableScope: EnrichedScope<InterpolatedRef>;
  binding: EngineParamBinding;
  resourceType: string;
  displayExpressionAs: "pill" | "full-view";
  className?: string;
  mini?: boolean;
};

export const EngineBinding = ({
  variableScope,
  binding,
  resourceType,
  className,
  displayExpressionAs,
  mini = false,
}: EngineBindingProps): React.ReactElement => {
  const { expressionsMethods: methods, showExpressionNames } =
    useExpressionsMethods();
  // janky
  if (resourceType.startsWith("TemplatedText") && binding.value?.literal) {
    return (
      <TemplatedTextDisplay
        style={TemplatedTextDisplayStyle.Compact}
        value={binding.value?.literal}
        variableScope={variableScope}
        className={className}
      />
    );
  }
  if (
    (resourceType === "Text" || resourceType === "PlainText") &&
    binding.value?.literal
  ) {
    return (
      <Markdown className={className}>
        {prettifyVariables(binding.value?.literal || "", variableScope)}
      </Markdown>
    );
  }

  const tryFindExpressionForRef = (
    ref: string,
  ): ExpressionFormData | undefined => {
    const isUsingExpression = !!ref && isExpression(ref);

    if (isUsingExpression) {
      return methods?.fields.find((expr) => ref.includes(expr.reference));
    }

    return undefined;
  };

  const prefersFullView = (e: ExpressionFormData | undefined): boolean => {
    if (!e) {
      return false;
    }

    // If we're showing expression names (e.g. we're in workflows)
    // then the user has probably named the expression and the
    // pill view should actually be useful, so don't force the full
    // expression view
    if (showExpressionNames) {
      return false;
    }

    const isBranchesExpression = e.operations.some(
      (o) => o.operation_type === ExpressionOperationOperationTypeEnum.Branches,
    );
    const isParseOnlyExpression = e.operations.every(
      (o) => o.operation_type === ExpressionOperationOperationTypeEnum.Parse,
    );
    return isBranchesExpression || isParseOnlyExpression;
  };

  if (binding.value) {
    const expression = tryFindExpressionForRef(binding.value.reference || "");

    if (displayExpressionAs === "full-view" || prefersFullView(expression)) {
      if (expression) {
        return (
          <ViewExpression
            scope={variableScope}
            expression={expression}
            hideTitleBar={true}
            miniBadges={mini}
          />
        );
      }
    }

    return (
      <EngineBindingValueBadge
        value={binding.value}
        resourceType={resourceType}
        className={className}
        mini={mini}
        scope={variableScope}
      />
    );
  }
  if (binding.array_value && binding.array_value.length > 0) {
    const ref = binding.array_value?.["0"]?.reference;
    const expression = tryFindExpressionForRef(ref || "");

    if (displayExpressionAs === "full-view" || prefersFullView(expression)) {
      if (expression) {
        return (
          <ViewExpression
            scope={variableScope}
            expression={expression}
            hideTitleBar={true}
            miniBadges={mini}
          />
        );
      }
    }

    return (
      <ArrayValueLabels
        resourceType={resourceType}
        arrayValue={binding.array_value}
        mini={mini}
        scope={variableScope}
      />
    );
  }

  return (
    <span className={tcx("text-slate-400 text-sm", className)}>Not set</span>
  );
};

const prettifyVariables = (
  str: string,
  variableScope: EnrichedScope<InterpolatedRef>,
): string => {
  // The pattern we use to detect string variables in text.
  // eslint-disable-next-line no-useless-escape
  const stringVariableRegex = /{{([a-zA-Z0-9"._§\]\[\\u00C0-\\u017F]*)?}}/g;

  let matches;

  while ((matches = str.match(stringVariableRegex)) != null) {
    const match = matches[0];
    const matchWithoutBrackets = match.slice(2, -2);

    const reference = lookupInScope(variableScope, matchWithoutBrackets);

    if (!reference) {
      // this would happen if someone wrote {{foo}} in their message. As we're
      // using a while loop, we need to change this so we don't keep finding the
      // same string, otherwise we'll be stuck in this loop forever and ever.
      // What we do, which is a hack, is to replace {{foo}} with just {foo}.
      // it's not pretty, but it works.
      str = str.replace(match, `{${matchWithoutBrackets}}`);
    } else {
      // replace {{incident.url}} => {Incident URL}
      str = str.replace(match, `{${reference.label}}`);
    }
  }
  return str;
};

function ArrayValueLabels({
  arrayValue,
  className,
  resourceType,
  mini,
  scope,
}: {
  arrayValue?: EngineParamBindingValue[];
  className?: string;
  resourceType: string;
  mini: boolean;
  scope: EnrichedScope<InterpolatedRef>;
}): React.ReactElement {
  // If everything is short, e.g. users, and there are no reference pills,
  // show it all on one line. Longer labels and pills get hard to read on one line,
  // so we use a div as a divider, to make them easier to scan.
  const labels = (arrayValue || []).map((a) => a.label || a.literal);

  // If our resource types are fairly short text, we'll display them as badges, otherwise
  // we'll just use text with commas to separate.
  const displayAsBadges =
    !resourceType.startsWith("TemplatedText") &&
    !labels.some((label) => label && label.length > 50);

  const useMultipleLines =
    arrayValue?.some((a) => a.reference) || displayAsBadges;

  const divider: React.ReactElement = useMultipleLines ? <div /> : <>{", "}</>;

  const res: React.ReactElement[] = [];

  (arrayValue || []).forEach((val, idx) => {
    if (idx > 0) {
      res.push(
        <React.Fragment key={`${idx}-divider`}>{divider}</React.Fragment>,
      );
    }

    res.push(
      displayAsBadges || val.reference ? (
        <EngineBindingValueBadge
          scope={scope}
          resourceType={resourceType}
          className={className}
          value={val}
          mini={mini}
        />
      ) : (
        <React.Fragment key={idx}>
          <span>{val.label || val.literal || ""}</span>
        </React.Fragment>
      ),
    );
  });

  return (
    <div className={tcx({ "flex flex-col gap-1 min-w-0": useMultipleLines })}>
      {res}
    </div>
  );
}
