import {
  EngineParamBinding,
  EngineScope,
  Resource,
  ScopeNameEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { getVariableScope } from "@incident-shared/engine";
import { addExpressionsToScope } from "@incident-shared/engine/expressions/addExpressionsToScope";
import { ExpressionsMethodsProvider } from "@incident-shared/engine/expressions/ExpressionsMethodsProvider";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import { BooleanRadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/BooleanRadioButtonGroupV2";
import { ToggleRowV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
  DrawerTitleTheme,
  getOnCloseWithWarning,
} from "@incident-ui/Drawer/Drawer";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { useProductAccess } from "src/hooks/useProductAccess";

import {
  AlertRouteFormSection,
  AlertRouteYesNoButtonGroup,
} from "./AlertRouteFormSection";
import { EscalationBindingInput } from "./EscalationBindingInput";
import { AlertRouteFormData, EscalateFormData } from "./types";

export const AlertRouteEscalateSection = ({
  scopeWithExpressions,
  resources,
}: {
  scopeWithExpressions: EngineScope;
  resources: Resource[];
}) => {
  const { hasOnCall } = useProductAccess();
  const formMethods = useFormContext<AlertRouteFormData>();

  const [editingContext, setEditingContext] = useState<{
    isEditing: boolean;
    initialData?: EscalateFormData;
  }>({
    isEditing: false,
  });

  const onSubmit = (data: EscalateFormData) => {
    formMethods.setValue<"enableEscalations">(
      "enableEscalations",
      data.enableEscalations,
      {
        shouldDirty: true,
      },
    );
    formMethods.setValue<"expressions">("expressions", data.expressions, {
      shouldDirty: true,
    });

    if (data.enableEscalations) {
      formMethods.setValue<"escalationBinding">(
        "escalationBinding",
        data.escalationBinding,
        {
          shouldDirty: true,
        },
      );
      formMethods.setValue<"autoCancelEscalations">(
        "autoCancelEscalations",
        data.autoCancelEscalations,
        {
          shouldDirty: true,
        },
      );
      formMethods.setValue<"mode">("mode", data.mode, {
        shouldDirty: true,
      });
    } else {
      formMethods.setValue<"escalationBinding">(
        "escalationBinding",
        undefined,
        {
          shouldDirty: true,
        },
      );
    }

    setEditingContext({ isEditing: false });
    formMethods.setValue<"hasEverConfiguredEscalations">(
      "hasEverConfiguredEscalations",
      true,
      {
        shouldDirty: true,
      },
    );
  };

  const hasEverConfiguredFilters = formMethods.watch(
    "hasEverConfiguredFilters",
  );
  const hasEverConfiguredEscalations = formMethods.watch(
    "hasEverConfiguredEscalations",
  );
  const enableEscalations = formMethods.watch("enableEscalations");
  const escalationBinding = formMethods.watch("escalationBinding");

  const variableScope = getVariableScope(scopeWithExpressions, resources);

  return (
    <>
      {editingContext.isEditing && (
        <EscalateDrawer
          onSubmit={onSubmit}
          onClose={() => setEditingContext({ isEditing: false })}
          initialData={editingContext.initialData}
          scope={scopeWithExpressions}
          resources={resources}
        />
      )}
      <AlertRouteFormSection
        title={
          !hasEverConfiguredEscalations
            ? "Escalate"
            : enableEscalations
            ? "Escalate alerts to:"
            : "Don't escalate alerts"
        }
        color={ColorPaletteEnum.Yellow}
        isCurrentFirstTimeStep={
          !hasEverConfiguredEscalations && hasEverConfiguredFilters
        }
        firstTimeContent={
          <>
            Connect this alert route to your escalation paths to automatically
            page people when an alert is triggered.
          </>
        }
        disabled={
          (hasEverConfiguredEscalations && !enableEscalations) || !hasOnCall
        }
        icon={IconEnum.Escalate}
        accessory={
          !hasEverConfiguredEscalations && hasOnCall ? (
            <AlertRouteYesNoButtonGroup
              disabled={!hasEverConfiguredFilters}
              onChange={(shouldEscalate) => {
                formMethods.setValue<"enableEscalations">(
                  "enableEscalations",
                  shouldEscalate,
                );
                if (shouldEscalate) {
                  setEditingContext({
                    isEditing: true,
                    initialData: formMethods.getValues(),
                  });
                } else {
                  formMethods.setValue<"hasEverConfiguredEscalations">(
                    "hasEverConfiguredEscalations",
                    true,
                  );
                }
              }}
            />
          ) : (
            <GatedButton
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              requiredProduct={Product.OnCall}
              theme={ButtonTheme.Naked}
              onClick={() => {
                setEditingContext({
                  isEditing: true,
                  initialData: formMethods.getValues(),
                });
              }}
              icon={IconEnum.Edit}
              className="text-content-tertiary"
              title="Edit escalations"
              analyticsTrackingId="alert-routes-edit-esca;atopms"
            />
          )
        }
      >
        {hasEverConfiguredEscalations &&
          escalationBinding &&
          (enableEscalations ? (
            <EngineBinding
              binding={escalationBinding as EngineParamBinding}
              resourceType={'CatalogEntry["EscalationPath"]'}
              variableScope={variableScope}
              className="shadow-none"
              displayExpressionAs="pill"
            />
          ) : (
            <Callout showIcon={false} theme={CalloutTheme.Warning}>
              By opting out of creating escalations, this route will not notify
              responders of new alerts.
            </Callout>
          ))}
      </AlertRouteFormSection>
    </>
  );
};

const EscalateDrawer = ({
  onSubmit,
  onClose: onCloseDrawer,
  initialData,
  scope,
  resources,
}: {
  onSubmit: (data: EscalateFormData) => void;
  onClose: () => void;
  initialData?: EscalateFormData;
  scope: EngineScope;
  resources: Resource[];
}) => {
  const formMethods = useForm<EscalateFormData>({
    defaultValues: initialData,
  });

  const expressionMethods = useFieldArray({
    name: "expressions",
    control: formMethods.control,
    keyName: "key",
  });
  const scopeWithExpressions = addExpressionsToScope(
    scope,
    expressionMethods.fields,
  );

  const { isDirty } = formMethods.formState;
  const onClose = () => getOnCloseWithWarning(onCloseDrawer)(isDirty);

  const enableEscalations = formMethods.watch("enableEscalations");

  const validateAndSubmit = useCallback(() => {
    const data = formMethods.getValues();
    if (data.enableEscalations) {
      const bindingVal = data.escalationBinding?.value
        ? [data.escalationBinding.value]
        : data.escalationBinding?.array_value;
      const hasSetBinding = bindingVal?.some(
        (a) => a.reference || (a.literal && a.literal !== ""),
      );

      if (!hasSetBinding) {
        formMethods.setError("escalationBinding", {
          type: "manual",
          message:
            "Please choose an escalation path, or turn off creating escalations",
        });
        return;
      }
    }

    onSubmit(data);
  }, [onSubmit, formMethods]);

  // If you change values, then clear the errors
  formMethods.watch(() => {
    formMethods.clearErrors();
  });

  return (
    <ExpressionsMethodsProvider
      expressionsMethods={expressionMethods}
      allowAllOfACatalogType={false}
    >
      <Drawer width="medium" onClose={onClose}>
        <DrawerContents>
          <DrawerTitle
            title="Escalate"
            onClose={onClose}
            icon={IconEnum.Escalate}
            compact
            theme={DrawerTitleTheme.Bordered}
          />
          <DrawerBody className="grow">
            <Form.Root
              id="alert-routes-escalate"
              fullHeight
              formMethods={formMethods}
              onSubmit={validateAndSubmit}
            >
              <Prompt
                when={formMethods.formState.isDirty}
                message={
                  "Your changes have not been saved. Are you sure you want to navigate away?"
                }
              />
              <div className="text-sm text-content-secondary">
                Connect this alert route to your escalation paths to
                automatically page people when an alert is triggered.
              </div>
              <BooleanRadioButtonGroupV2
                trueOption={{
                  label: "Yes",
                }}
                falseOption={{
                  label: "No",
                }}
                name="enableEscalations"
                label="Create escalations"
                formMethods={formMethods}
                srLabel="Enable escalations"
                boxed
              />
              <AnimatePresence>
                {enableEscalations ? (
                  <motion.div
                    className="space-y-6 rounded-b-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div>
                      <EscalationBindingInput
                        scopeWithExpressions={scopeWithExpressions}
                        resources={resources}
                      />
                    </div>
                    <ToggleRowV2
                      name="autoCancelEscalations"
                      description="Automatically cancel escalations when an alert is resolved"
                      label="Auto-cancel escalations"
                      formMethods={formMethods}
                      displayFullDescription
                    />
                  </motion.div>
                ) : (
                  <Callout showIcon={false} theme={CalloutTheme.Warning}>
                    By opting out of creating escalations, this route will not
                    notify responders of new alerts.
                  </Callout>
                )}
              </AnimatePresence>
            </Form.Root>
          </DrawerBody>
          <DrawerFooter className="flex gap-2 justify-end">
            <Button onClick={() => onClose()} analyticsTrackingId={null}>
              Back
            </Button>
            <GatedButton
              form="alert-routes-escalate"
              requiredScope={ScopeNameEnum.AlertRouteUpdate}
              type="submit"
              theme={ButtonTheme.Primary}
              analyticsTrackingId="alert-routes-edit-sources-save"
            >
              Apply
            </GatedButton>
          </DrawerFooter>
        </DrawerContents>
      </Drawer>
    </ExpressionsMethodsProvider>
  );
};
