import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useOutlet } from "react-router";

import { AlertDetailsPage } from "./alert-details/AlertDetails";
import { AlertRouteCreatePage } from "./alert-route-create-edit/AlertRouteCreatePage";
import { AlertRouteEditPage } from "./alert-route-create-edit/AlertRouteEditPage";
import { AlertSourceCreatePage as AlertSourceCreatePageV2 } from "./alert-source-create-edit-v2/AlertSourceCreatePage";
import { AlertSourceEditPage as AlertSourceEditPageV2 } from "./alert-source-create-edit-v2/AlertSourceEditPage";
import { AlertSourceSetupInfoPreviewPage } from "./alert-source-create-edit-v2/configure/alert-source-setup-info/AlertSourceSetupInfoPreviewPage";
import { AlertsListPage } from "./alerts-list/AlertsListPage";
import { AlertsPageWrapper } from "./AlertsPageWrapper";
import { AlertAttributesCreateEditDrawer } from "./attributes/AlertAttributesCreateEditDrawer";
import { AlertsConfigurationPage } from "./configuration/AlertsConfigurationPage";
import { AlertsGetStartedPage } from "./get-started/AlertsGetStartedPage";
import { PrioritiesCreateEditDrawer } from "./priorities/PrioritiesCreateEditDrawer";

export const AlertsRoute = () => {
  const navigate = useOrgAwareNavigate();

  return (
    <Routes>
      {/* --- Full-size pages --- */}
      <Route path=":id/details" element={<AlertDetailsPage />} />

      {/* Alert sources */}
      <>
        <Route path="sources/:id/edit" element={<AlertSourceEditPageV2 />} />
        <Route path="sources/create/" element={<AlertSourceCreatePageV2 />} />
      </>

      {/* Alert routes */}
      <>
        <Route path="routes/create" element={<AlertRouteCreatePage />} />
        <Route path="routes/:id/edit" element={<AlertRouteEditPage />} />
      </>

      <Route path="get-started" element={<AlertsGetStartedPage />} />

      {/* --- Secondary nav pages --- */}

      <Route element={<AlertsPageWrapper />}>
        <Route path="" element={<OrgAwareNavigate to={"/alerts/recent"} />} />
        <Route path="recent" element={<AlertsListPage />} />
        <Route path="configuration" element={<AlertsConfigurationOutlet />}>
          <Route
            path="attributes"
            element={
              <AlertAttributesCreateEditDrawer
                onClose={() => navigate("/alerts/configuration")}
              />
            }
          />
          <Route
            path="priorities"
            element={
              <PrioritiesCreateEditDrawer
                onClose={() => navigate("/alerts/configuration")}
              />
            }
          />
        </Route>
      </Route>

      <Route
        path="debug-preview-alert-source-setup"
        element={<AlertSourceSetupInfoPreviewPage />}
      />
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};

const AlertsConfigurationOutlet = () => {
  const modalOrDrawer = useOutlet();

  return (
    <>
      <AnimatePresence>{modalOrDrawer}</AnimatePresence>

      <AlertsConfigurationPage />
    </>
  );
};
