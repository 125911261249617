import {
  InternalStatusPageCreatePageRequestBodyThemeEnum,
  InternalStatusPageThemeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ModalFooter, Steps } from "@incident-ui";
import { UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPIMutation } from "src/utils/swr";

import { expressionToPayload } from "../../../../@shared/engine/expressions/expressionToPayload";
import { BrandingFormContent } from "../BrandingForm";
import {
  CreatePageSteps,
  InternalStatusPageFormType,
  stepsConfig,
} from "./CreateInternalStatusPage";

export const BrandingModal = ({
  onClose,
  onBack,
  formMethods,
}: {
  onClose: () => void;
  onBack: () => void;
  formMethods: UseFormReturn<InternalStatusPageFormType>;
}) => {
  const { hasScope } = useIdentity();
  const navigate = useOrgAwareNavigate();

  const { trigger, isMutating, genericError } = useAPIMutation(
    "statusPageList",
    undefined,
    async (apiClient, data: InternalStatusPageFormType) => {
      const { internal_status_page: page } =
        await apiClient.internalStatusPageCreatePage({
          createPageRequestBody: {
            ...data,
            theme:
              data.theme as unknown as InternalStatusPageCreatePageRequestBodyThemeEnum,
            darkmode_logo_key:
              data.theme === InternalStatusPageThemeEnum.Dark
                ? data.logo?.key
                : undefined,
            lightmode_logo_key:
              data.theme === InternalStatusPageThemeEnum.Light
                ? data.logo?.key
                : undefined,
            component_source_expression:
              data.component_source_expression_payload
                ? expressionToPayload(data.component_source_expression_payload)
                : undefined,
            auto_expand_groups: false,
            use_currently_affected_components_over_groups: false,
          },
        });
      navigate(`/status-pages/internal/${page.id}`);
    },
  );

  const subpath = formMethods.watch("subpath");

  return (
    <Form.Modal
      suppressInitialAnimation
      isExtraLarge
      title="Create internal status page"
      analyticsTrackingId={"create-internal-status-page"}
      disableQuickClose
      formMethods={formMethods}
      genericError={genericError}
      onSubmit={trigger}
      loading={isMutating}
      onClose={onClose}
      footer={
        <ModalFooter
          onClose={onBack}
          cancelButtonText={"Back"}
          confirmButtonType="submit"
          confirmButtonText={"Create status page"}
          disabled={!hasScope(ScopeNameEnum.StatusPagesConfigure)}
          disabledTooltipContent="You do not have permission to create an internal status page"
        />
      }
    >
      <Steps steps={stepsConfig} currentStep={CreatePageSteps.Branding} />
      <BrandingFormContent subpath={subpath} formMethods={formMethods} />
    </Form.Modal>
  );
};
