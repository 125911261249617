import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

export const ActivityItemEscalate = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_escalate) {
    throw new Error(
      "malformed timeline item: incident_escalate was missing incident_escalate field",
    );
  }

  let title = "Incident escalated";
  if (item.content.incident_escalate.assignees !== "") {
    title = "Escalated to " + item.content.incident_escalate.assignees;
  }

  return {
    title: title,
    icon: IconEnum.Escalate,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_escalate.escalator,
  };
};
