import {
  EngineParamBindingValue,
  Expression,
  ExpressionOperationOperationTypeEnum,
} from "src/contexts/ClientContext";
import { DeepNullablePartial } from "src/utils/utils";
import { v4 as uuidV4 } from "uuid";

import { CreateEditExpressionFormData } from "../AddEditExpressionModal";

export type ExpressionFixedResultType = {
  type: string;
  typeIsAutocompletable: boolean;
  label: string;
  typeLabel: string;
  array: boolean;
};
// If we don't default values to null, react-hook-form doesn't set them,
// it's like it's too lazy to create the nested object and instead is just like 'meh' 🤷.
export const createDefaultIfElseValues = (
  initialExpression: Partial<Expression> | undefined,
  fixedResultType?: ExpressionFixedResultType,
): CreateEditExpressionFormData => {
  const makeResultOrNull = (
    r: EngineParamBindingValue | undefined,
  ): DeepNullablePartial<EngineParamBindingValue> => ({
    // If we don't default these to null, the dynamic select form won't show a pre-existing value
    literal: r?.literal ?? null,
    reference: r?.reference ?? null,
    label: r?.label ?? null,
    sort_key: r?.sort_key ?? null,
    image_url: r?.image_url ?? null,
  });

  const isValidResult = (r: EngineParamBindingValue | undefined) =>
    r?.reference || r?.literal;

  const existingElseBranchValue = initialExpression?.else_branch?.result.value;
  const existingElseBranchArrayValue =
    initialExpression?.else_branch?.result.array_value;

  if (initialExpression) {
    return {
      ...initialExpression,
      else_branch: {
        result: {
          value: makeResultOrNull(existingElseBranchValue),
          array_value:
            existingElseBranchArrayValue?.map(makeResultOrNull) ?? null,
        },
        is_null:
          !isValidResult(initialExpression?.else_branch?.result.value) &&
          !(initialExpression?.else_branch?.result.array_value ?? []).some(
            isValidResult,
          ),
      },
    } as CreateEditExpressionFormData;
  }

  if (fixedResultType) {
    const ret: DeepNullablePartial<CreateEditExpressionFormData> = {
      reference: uuidV4().split("-")[0],
      root_reference: ".",
      label: fixedResultType.label,
      returns: {
        type: fixedResultType.type,
        array: fixedResultType.array,
      },
      operations: [
        {
          operation_type: ExpressionOperationOperationTypeEnum.Branches,
          branches: {
            returns: {
              array: fixedResultType.array,
              type: fixedResultType.type,
            },
            branches: [],
          },
        },
      ],
      else_branch: {
        result: {
          value: {
            literal: null,
            reference: null,
          },
          array_value: [],
        },
      },
    };
    return ret as CreateEditExpressionFormData;
  }

  const ret: DeepNullablePartial<CreateEditExpressionFormData> = {
    reference: uuidV4().split("-")[0],
    root_reference: ".",
    returns: {
      array: false,
      type: null,
    },
    label: null,
    operations: [
      {
        operation_type: ExpressionOperationOperationTypeEnum.Branches,
        branches: {
          returns: {
            array: false,
            type: null,
          },
          branches: [],
        },
      },
    ],
    else_branch: {
      result: {
        value: {
          literal: null,
          reference: null,
        },
        array_value: [],
      },
    },
  };
  return ret as CreateEditExpressionFormData;
};
