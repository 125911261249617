import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { Txt } from "@incident-ui";
import { RadioButtonGroupOption } from "@incident-ui/RadioButtonGroup/RadioButtonGroup";
import _ from "lodash";
import { useFormContext, UseFormReturn } from "react-hook-form";
import {
  IncidentsListSortByEnum,
  IncidentStatus,
} from "src/contexts/ClientContext";
import { useAPI } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import { Category, firstStatusOfCategory } from "./status-utils";
import { IncidentStatusFormData } from "./StatusFormElement";

export enum TriageDecision {
  Accept = "accept",
  Decline = "decline",
  Merge = "merge",
  LeaveInTriage = "leave_in_triage",
}

export const TriageDecisionRadio = ({
  statuses,
  incidentId,
}: {
  statuses: IncidentStatus[];
  incidentId: string;
}) => {
  const formMethods = useFormContext<IncidentStatusFormData>();

  const allowedDecisions = Object.values(TriageDecision);

  const onChooseDecision = (decision: string) => {
    const newStatus = calculateStatusToTransitionTo(
      decision as TriageDecision,
      statuses,
    );
    if (newStatus) {
      formMethods.setValue<"incident_status_id">(
        "incident_status_id",
        newStatus.id,
      );
    }
  };

  return (
    <RadioButtonGroupV2
      label="What do you want to do?"
      srLabel="Select decision"
      formMethods={formMethods}
      name="incident_status_decision"
      boxed
      options={_.map(allowedDecisions, (decision) =>
        radioButtonOption({ decision, formMethods, incidentId }),
      )}
      onValueChange={onChooseDecision}
    />
  );
};

const radioButtonOption = ({
  decision,
  incidentId,
  formMethods,
}: {
  decision: TriageDecision;
  incidentId: string;
  formMethods: UseFormReturn<IncidentStatusFormData>;
}): RadioButtonGroupOption => {
  const label = "";
  switch (decision) {
    case TriageDecision.Accept:
      return {
        label: "Accept: this is an incident",
        value: decision,
      };
    case TriageDecision.Decline:
      return {
        label: "Decline: this isn't an incident",
        value: decision,
        renderWhenSelectedNode: () => (
          <Txt grey xs className="-mt-2">
            We&apos;ll let everyone know you&apos;ve declined the incident, and
            archive the channel.
          </Txt>
        ),
      };

    case TriageDecision.Merge:
      return {
        label: "Merge: this is part of another incident",
        value: decision,
        renderWhenSelectedNode: () => (
          <ActiveIncidentInput
            incidentId={incidentId}
            formMethods={formMethods}
          />
        ),
      };
    case TriageDecision.LeaveInTriage:
      return {
        label: "Leave in triage",
        value: decision,
      };
    default:
      assertUnreachable(decision);
  }

  return {
    label,
    value: decision,
  };
};

const calculateStatusToTransitionTo = (
  decision: TriageDecision,
  statuses: IncidentStatus[],
): IncidentStatus => {
  switch (decision) {
    case TriageDecision.Accept:
      return firstStatusOfCategory(statuses, Category.Active);
    case TriageDecision.Decline:
      return firstStatusOfCategory(statuses, Category.Declined);
    case TriageDecision.Merge:
      return firstStatusOfCategory(statuses, Category.Merged);
    case TriageDecision.LeaveInTriage:
      return firstStatusOfCategory(statuses, Category.Triage);
    default:
      return assertUnreachable(decision);
  }
};

const ActiveIncidentInput = ({
  incidentId,
  formMethods,
}: {
  incidentId: string;
  formMethods: UseFormReturn<IncidentStatusFormData>;
}): React.ReactElement | null => {
  const {
    data: { incidents },
    isLoading,
    error: incidentsError,
  } = useAPI(
    "incidentsList",
    {
      pageSize: 10000,
      sortBy: IncidentsListSortByEnum.NewestFirst,
      statusCategory: {
        one_of: [Category.Triage, Category.Active, Category.Paused],
      },
    },
    { fallbackData: { incidents: [], available_statuses: [] } },
  );

  if (incidentsError) throw incidentsError;

  return (
    <>
      <StaticSingleSelectV2
        formMethods={formMethods}
        name="merged_into_incident_id"
        placeholder="Search for an active incident"
        isLoading={isLoading}
        isSearchable={true}
        options={incidents
          .filter((i) => i.id !== incidentId)
          .map(({ id, external_id, name, created_at }) => ({
            sort_key: created_at.toISOString(),
            value: id,
            label: `INC-${external_id}: ${name}`,
          }))}
        isClearable={false}
      />
      <Txt grey xs className="mt-2">
        We&apos;ll redirect everyone to the new incident channel, and then
        archive this one to keep things neat and tidy.
      </Txt>
    </>
  );
};
