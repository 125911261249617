import {
  Alert,
  AlertSchema,
  AlertsListAlertsRequest,
  AlertSourceConfig,
  CatalogResource,
  ScopeNameEnum,
} from "@incident-io/api";
import {
  AppliedFiltersBanner,
  enrichAvailableFilterFields,
  FilterPopover,
  FiltersContextProvider,
  filtersToListParams,
  SearchBar,
  useFiltersContext,
} from "@incident-shared/filters";
import { useStatefulQueryParamFilters } from "@incident-shared/filters/useStatefulQueryParamFilters";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  EmptyState,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  ToastTheme,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { Helmet } from "react-helmet";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { StatusDropdown } from "src/components/alerts/alerts-list/StatusDropdown";
import {
  AlertsTable,
  AlertTableColumn,
} from "src/components/alerts/common/AlertsTable";
import { CustomiseShownAttributesDropdown } from "src/components/alerts/common/ShownAttributes";
import { useAlertResources } from "src/components/alerts/common/useAlertResources";
import { useClient } from "src/contexts/ClientContext";
import { useMutation } from "src/utils/fetchData";
import { useAPI, useAPIInfinite } from "src/utils/swr";

export const AlertsListPage = () => {
  const [selectedAlerts, setSelectedAlerts] = useState<Set<string>>(new Set());

  const selectAlert = (alert: Alert) =>
    setSelectedAlerts((prev) => new Set(prev).add(alert.id));

  const deselectAlert = (alert: Alert) =>
    setSelectedAlerts((prev) => {
      const updated = new Set(prev);
      updated.delete(alert.id);
      return updated;
    });

  const [visibleColumns, setVisibleColumns] = useState<
    AlertTableColumn[] | null
  >(null);

  const {
    error: resourcesError,
    isLoading: resourcesLoading,
    schemaResponse,
    configsResp,
    resourcesListResp,
  } = useAlertResources();

  const {
    data: { fields: availableFilterFields },
    isLoading: filtersLoading,
    error: filtersError,
  } = useAPI("alertsListFilterFields", undefined, {
    fallbackData: { fields: [] },
  });

  const filterFields = enrichAvailableFilterFields(availableFilterFields);

  const isLoading = resourcesLoading || filtersLoading;
  const error = resourcesError || filtersError;

  const { getSelectedFilters, setSelectedFilters } =
    useStatefulQueryParamFilters({
      availableFilterFields: filterFields,
      availableParams: [],
    });

  const filters = getSelectedFilters();

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading || !schemaResponse || !resourcesListResp || !configsResp) {
    return <FullPageLoader />;
  }

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setSelectedFilters}
      availableFilterFields={filterFields}
      kind={"alert"}
    >
      <Helmet title={"All alerts - incident.io"} />

      <div className={"px-4 md:px-8 py-4 md:py-6 bg-white"}>
        {/* --- Title Bar --- */}
        <div className={"w-full flex justify-between mb-4"}>
          <AlertSearchBar />

          <div className={"flex flex-row space-x-4"}>
            <FilterPopover
              renderTriggerButton={({ onClick }) => (
                <Button
                  theme={ButtonTheme.Naked}
                  onClick={() => onClick()}
                  analyticsTrackingId={"alert-list-filter"}
                  icon={IconEnum.Filter}
                  iconProps={{
                    size: IconSize.Medium,
                  }}
                >
                  Add filter
                </Button>
              )}
            />
            <CustomiseShownAttributesDropdown
              setVisibleColumns={setVisibleColumns}
              visibleColumns={visibleColumns}
              schema={schemaResponse.alert_schema}
            />
            {selectedAlerts.size > 0 ? (
              <ResolveAlertsButton
                selectedAlerts={selectedAlerts}
                setSelectedAlerts={setSelectedAlerts}
              />
            ) : (
              <StatusDropdown />
            )}
          </div>
        </div>

        <InfiniteScrollingAlerts
          alertSourceConfigs={configsResp.alert_source_configs}
          visibleColumns={visibleColumns}
          schema={schemaResponse.alert_schema}
          resources={resourcesListResp.resources}
          selectAlert={selectAlert}
          deselectAlert={deselectAlert}
          selectedAlerts={selectedAlerts}
          setSelectedAlerts={setSelectedAlerts}
        />
      </div>
    </FiltersContextProvider>
  );
};

const ResolveAlertsButton = ({
  selectedAlerts,
  setSelectedAlerts,
}: {
  selectedAlerts: Set<string>;
  setSelectedAlerts: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
  const { filters } = useFiltersContext();
  const queryFilters: AlertsListAlertsRequest = filtersToListParams(filters);

  const { refetch } = useAPIInfinite("alertsListAlerts", queryFilters, {
    revalidateFirstPage: true,
  });

  const apiClient = useClient();
  const showToast = useToast();
  const [trigger, { saving: isMutating }] = useMutation(
    async () => {
      for (const alertID of selectedAlerts) {
        await apiClient.alertsResolve({
          id: alertID,
        });
      }
      await refetch();
    },
    {
      onSuccess: () => {
        setSelectedAlerts(new Set());
        showToast({
          theme: ToastTheme.Success,
          title: "Alerts resolved",
          description:
            selectedAlerts.size === 1
              ? "1 alert has been marked as resolved"
              : `${selectedAlerts.size} alerts have been marked as resolved`,
        });
      },
    },
  );

  return (
    // Min height keeps this the same size as the other components in the toolbar
    <Button
      theme={ButtonTheme.Primary}
      analyticsTrackingId={"resolve-alerts-button"}
      onClick={() => trigger({})}
      loading={isMutating}
    >
      Resolve {selectedAlerts.size} alert{selectedAlerts.size > 1 && "s"}
    </Button>
  );
};

const AlertSearchBar = () => {
  const {
    availableFilterFields,
    filters,
    addFilter,
    editFilter,
    deleteFilter,
  } = useFiltersContext();
  return (
    <SearchBar
      id="search_alerts"
      placeholder="Search alerts"
      availableFilterFields={availableFilterFields}
      appliedFilters={filters}
      onEditFilter={editFilter}
      onDeleteFilter={deleteFilter}
      onAddFilter={addFilter}
    />
  );
};

const InfiniteScrollingAlerts = ({
  selectedAlerts,
  selectAlert,
  setSelectedAlerts,
  deselectAlert,
  visibleColumns,
  schema,
  resources,
  alertSourceConfigs,
}: {
  selectedAlerts: Set<string>;
  selectAlert: (alert: Alert) => void;
  deselectAlert: (alert: Alert) => void;
  setSelectedAlerts: React.Dispatch<React.SetStateAction<Set<string>>>;
  visibleColumns: AlertTableColumn[] | null;
  schema: AlertSchema;
  resources: CatalogResource[];
  alertSourceConfigs: AlertSourceConfig[];
}) => {
  const { filters } = useFiltersContext();
  const queryFilters: AlertsListAlertsRequest = filtersToListParams(filters);

  const {
    responses,
    isLoading,
    isFullyLoaded,
    error: error,
    loadMore: onLoadMore,
  } = useAPIInfinite("alertsListAlerts", queryFilters, {
    revalidateFirstPage: true,
    refreshInterval: 10000, // Reload every 10s
  });

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !isFullyLoaded,
    onLoadMore,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry gets close to
    // the viewport, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 100px 0px",
  });

  const alerts = responses.flatMap(({ alerts }) => alerts);

  const hasNoAlertSources = alertSourceConfigs.length === 0;
  return (
    <>
      <AppliedFiltersBanner
        totalNumberOfItems={alerts.length}
        itemsLabel={"alert"}
        style={"partOfPage"}
        className={"mb-4"}
      />
      {alerts.length === 0 && isLoading ? (
        <FullPageLoader />
      ) : hasNoAlertSources ? (
        <EmptyState
          icon={IconEnum.Alert}
          title={"You have no alerts yet"}
          content={
            "Configure your alert sources to connect your external monitoring tools to incident.io."
          }
          cta={
            <GatedButton
              theme={ButtonTheme.Secondary}
              requiredScope={ScopeNameEnum.AlertSourceCreate}
              href="/alerts/sources/create"
              analyticsTrackingId="create-alert-source"
            >
              Configure an alert source
            </GatedButton>
          }
        />
      ) : (
        <div className="overflow-auto h-full min-h-full">
          <AlertsTable
            selectedAlerts={selectedAlerts}
            setSelectedAlerts={setSelectedAlerts}
            selectAlert={selectAlert}
            deselectAlert={deselectAlert}
            enableSelection={true}
            visibleColumns={visibleColumns}
            schema={schema}
            resources={resources}
            alertSourceConfigs={alertSourceConfigs}
            alerts={alerts}
            error={error}
            infiniteScrollRef={infiniteScrollRef}
            allEntriesLoaded={isFullyLoaded}
          />
        </div>
      )}
    </>
  );
};
