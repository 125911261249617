import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum, Link } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import {
  ActivityLogEntry,
  Incident,
  SlimIncidentVisibilityEnum,
} from "src/contexts/ClientContext";

export const ActivityItemMerged = (
  _: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.incident_merged) {
    throw new Error("malformed timeline item: merged was missing merged field");
  }
  let title: React.ReactNode = null;
  let content: React.ReactNode = null;

  if (
    item.content.incident_merged.incident.visibility ===
    SlimIncidentVisibilityEnum.Private
  ) {
    title = (
      <>
        <Link
          to={`/incidents/${item.content.incident_merged.incident.id}`}
          openInNewTab
          analyticsTrackingId={null}
        >
          Private incident
        </Link>{" "}
        was merged into this one
      </>
    );
  } else {
    title = "Another incident was merged into this one";

    content = (
      <>
        <div className="font-medium mb-2">
          <Link
            to={`/incidents/${item.content.incident_merged.incident.id}`}
            openInNewTab
            analyticsTrackingId={null}
          >
            {item.content.incident_merged.incident.reference}{" "}
            {item.content.incident_merged.incident.name}
          </Link>
        </div>
        {item.content.incident_merged.incident.summary && (
          <TemplatedTextDisplay
            value={item.content.incident_merged.incident.summary.text_node}
            style={TemplatedTextDisplayStyle.Compact}
          />
        )}
      </>
    );
  }

  return {
    title,
    icon: IconEnum.Merge,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.incident_merged.merger,
    quotedContent: content,
  };
};
