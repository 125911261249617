import { useAPI } from "../utils/swr";

export interface AvailableInsights {
  native: boolean;
  external: boolean;
  externalProvider?: string;
}

export const useAvailablePagerLoadInsights: () => AvailableInsights = () => {
  const { data: integrationsData } = useAPI("integrationsList", undefined);

  const available: AvailableInsights = {
    external: false,
    native: false,
  };

  // Next, check if they've installed an external paging system. If they
  // have, they can access external insights.
  const externalProvider = integrationsData?.integrations?.find(
    (i) =>
      i.installed && (i.provider === "pagerduty" || i.provider === "opsgenie"),
  );
  if (externalProvider) {
    available.external = true;
    available.externalProvider = externalProvider.provider_name;
  }

  if (
    integrationsData?.integrations?.some(
      (i) => i.installed && i.provider === "incident_io_on_call",
    )
  ) {
    available.native = true;
  }

  if (!available.native && !available.external) {
    // Finally, if they have neither, they should get access to native insights,
    // where we'll show demo data.
    available.native = true;
  }

  return available;
};
