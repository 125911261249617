import { IconEnum, Markdown } from "@incident-ui";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import {
  Incident,
  TimelineItem,
  TimelineItemObject,
} from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";
import { TimelineItemSource } from "./TimelineItemSource";

export const TimelineItemFollowUpCreated = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.follow_up_created) {
    throw new Error(
      "malformed timeline item: follow_up_created was missing follow_up_created field",
    );
  }

  return {
    description: followUpCreateText(item),
    avatarUrl: item.follow_up_created.creator?.user?.avatar_url,
    icon: IconEnum.AddCircle,
    children: (
      <TimelineItemContentBox>
        <Markdown>{item.follow_up_created.follow_up.title}</Markdown>
      </TimelineItemContentBox>
    ),
  };
};

const followUpCreateText = (item: TimelineItem) => {
  return (
    <>
      <TimelineItemSource actor={item.follow_up_created?.creator} /> created a
      follow-up
    </>
  );
};
