import {
  AIConfigEnabledFeaturesEnum,
  ScopeNameEnum,
  SlimDashboard,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareLink } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Card,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import React, { useState } from "react";
import { LevelUpIncidentResponseBanner } from "src/components/settings/LevelUpIncidentResponseBanner";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";

import { AssistantBannerV2 } from "../../assistant/AssistantBannerV2";
import InsightsGatedVisual from "../../insights-empty-state.png";
import { InsightsV3HomepageTrendsTiles } from "../../v2/common/TrendsTiles";
import { CreateCustomDashboardModal } from "../dashboards/create-edit/CreateCustomDashboardModal";
import { CustomDashboardsSection } from "./CustomDashboardsSection";

export const InsightsV3ListPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;
  const customDashboardsGate = identity?.feature_gates.custom_dashboards_count;
  const { hasResponse } = useProductAccess();
  const canUseAI = useAIFeatureForOrg();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const {
    data: { dashboards: customDashboards },
    isLoading,
    error,
  } = useAPI("insightsListCustomDashboards", undefined, {
    fallbackData: { dashboards: [] },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  const hasExceededCustomDashboardGate =
    customDashboardsGate !== undefined &&
    customDashboards.length >= customDashboardsGate;

  return (
    <PageWrapper
      width={PageWidth.Medium}
      title="Insights"
      icon={IconEnum.Chart}
      accessory={
        <GatedButton
          icon={IconEnum.InsightsDashboard}
          theme={ButtonTheme.Primary}
          analyticsTrackingId="insights-create-dashboard"
          requiredScope={ScopeNameEnum.InsightsCustomDashboardsCreate}
          upgradeRequired={hasExceededCustomDashboardGate}
          onClick={() => setShowCreateModal(true)}
          upgradeRequiredProps={{
            gate: {
              type: "numeric",
              value: customDashboardsGate,
              featureNameSingular: "custom dashboard",
            },
            featureName: "custom dashboards",
          }}
        >
          Create dashboard
        </GatedButton>
      }
    >
      {!insightsFeatureGate ? (
        <InsightsEmptyState />
      ) : (
        <div className="flex flex-col h-full gap-10">
          {canUseAI(AIConfigEnabledFeaturesEnum.Assistant) && (
            <AssistantBannerV2 />
          )}
          <InsightsV3HomepageTrendsTiles />
          <CoreDashboardsSection />
          <CustomDashboardsSection
            dashboards={customDashboards}
            hasExceededCustomDashboardGate={hasExceededCustomDashboardGate}
            showCreateModal={() => setShowCreateModal(true)}
          />
          {!hasResponse ? <LevelUpIncidentResponseBanner /> : null}
        </div>
      )}
      {showCreateModal && (
        <CreateCustomDashboardModal onClose={() => setShowCreateModal(false)} />
      )}
    </PageWrapper>
  );
};

const InsightsEmptyState = (): React.ReactElement => (
  <div className="flex flex-grow flex-col h-full justify-center items-center">
    <img className="w-[646px] mb-10" src={InsightsGatedVisual} />
    <div className="w-96 flex flex-col gap-2 justify-center items-center mb-6">
      <PlanBadge planName="Pro" />
      <p className="font-semibold text-base">Dig deeper into your incidents</p>
      <p className="font-normal text-sm text-center">
        Identify incident trends and recurring pain points with Insights. See
        what types of incidents are occurring across your organization and
        improve how you respond to them.
      </p>
    </div>
    <div className="flex gap-2">
      <Button
        analyticsTrackingId="insights-upgrade"
        theme={ButtonTheme.Primary}
        href={"/settings/billing"}
      >
        Upgrade
      </Button>
      <Button
        analyticsTrackingId="insights-learn-more"
        theme={ButtonTheme.Secondary}
        href="https://incident.io/changelog/insights-updates"
      >
        Learn more
      </Button>
    </div>
  </div>
);

const CoreDashboardsSection = (): React.ReactElement => {
  const { data, isLoading, error } = useAPI(
    "insightsListCoreDashboards",
    undefined,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
      {data?.dashboards.map((dashboard) => (
        <CoreDashboardCard key={dashboard.id} dashboard={dashboard} />
      ))}
    </div>
  );
};

const CoreDashboardCard = ({ dashboard }: { dashboard: SlimDashboard }) => {
  return (
    <OrgAwareLink to={`/insights-v3/dashboards/core/${dashboard.id}`}>
      <Card
        icon={dashboard.icon as unknown as IconEnum}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        description={dashboard.description}
        compact
      />
    </OrgAwareLink>
  );
};
