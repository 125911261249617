import { ContentBox, EmptyState, IconEnum } from "@incident-ui";
import _ from "lodash";
import React, { useState } from "react";
import {
  Incident,
  TimelineItem,
  TimelineItemItemTypeEnum,
  TimelineItemItemTypeEnum as TimelineItemType,
  TimelineItemsListResponseBody,
} from "src/contexts/ClientContext";
import { tcx } from "src/utils/tailwind-classes";

import { CreateTimelineCustomEventSection } from "./CreateTimelineCustomEventSection";
import { EnrichmentWarnings } from "./EnrichmentWarnings";
import { SortOrder } from "./Timeline";
import { TimelineItemTypeToComponentMap } from "./timeline-items/TimelineItem";
import { statusHasChanged } from "./timeline-items/TimelineItemIncidentUpdateComponent";
import styles from "./TimelineItems.module.scss";
import { TimelineItemTimeGap } from "./TimelineItemTimeGap";
import { TimelineItemUIWrapper } from "./TimelineItemUI";

export const TimelineUI = ({
  incident,
  filteredItems,
  isEditMode,
  onHide,
  sortOrder = SortOrder.NewestFirst,
  activeFilters,
}: {
  incident: Incident;
  filteredItems: TimelineItem[];
  isEditMode: boolean;
  sortOrder?: SortOrder;
  onHide: (itemIds: string[]) => Promise<TimelineItemsListResponseBody>;
  activeFilters: string[];
}): React.ReactElement => {
  // When a user clicks on an image (e.g. in a pinned slack message), we'll pop it open in a modal.
  const [zoomImageSource, setZoomImageSource] = useState<string | undefined>();

  const shownItems = filteredItems.filter((item) => !item.hidden_at);
  if (_.isEmpty(shownItems)) {
    if (activeFilters.length > 0) {
      return (
        <EmptyState
          icon={IconEnum.Filter}
          content="There are no timeline updates that match your filters."
        />
      );
    } else {
      return (
        <EmptyState
          content="You have removed all of your items from the timeline."
          icon={IconEnum.Activity}
        />
      );
    }
  }

  let items: TimelineElement[] = shownItems.map((item) => ({
    occured_at: item.occured_at,
    item,
  }));

  items = sortTimelineItems(items, sortOrder);

  // Iterate over all items, adding a 'x hours later' element to indicate large
  // time gaps in the timeline
  if (!isEditMode) {
    items = addTimeGapItems(items);
  }

  return (
    <div className="space-y-4">
      <EnrichmentWarnings incident={incident} items={filteredItems} />
      <ContentBox className={tcx(styles.timeline, "intercom-timeline")}>
        <div className={styles.lineHolder}>
          <div className={styles.verticalLine} />
        </div>
        <ul className={styles.timelineItemWrapper}>
          {items.map((element, i) => {
            const shouldShowAddEvent =
              // don't show if curation is enabled
              isEditMode &&
              // don't show before or after an existing note
              !isNoteElementOrBeforeNoteElement(items, i) &&
              // don't show if current item isn't being rendered
              willBeRendered(element) &&
              // don't show if the element is render-only, eg a time gap
              !element.element;

            const isStatusUpdate =
              element.item?.item_type === TimelineItemType.StatusChange ||
              (element.item?.item_type === TimelineItemType.IncidentUpdate &&
                statusHasChanged(element.item?.incident_update));

            return (
              <div
                key={i}
                className={tcx(
                  styles.timelineItemHolder,
                  isEditMode && styles.editEnabled,
                  isStatusUpdate && styles.statusUpdateHolder,
                  isEditMode && "!mb-0 !pb-0", // TODO: when we implement the new UI, let's use tailwind instead of the SCSS
                )}
              >
                <TimelineItemUIWrapper
                  key={i}
                  incident={incident}
                  onHide={onHide}
                  isEditMode={isEditMode}
                  zoomImageSource={zoomImageSource}
                  setZoomImageSource={setZoomImageSource}
                  {...element}
                />
                {shouldShowAddEvent && (
                  <CreateTimelineCustomEventSection
                    key={`custom-event-${i}`}
                    incident={incident}
                    occuredAt={calculateEventOccuredAt(items, i)}
                  />
                )}
              </div>
            );
          })}
        </ul>
      </ContentBox>
    </div>
  );
};

type Sortable = { created_at: Date } | { occured_at: Date };

export const sortTimelineItems = <T extends Sortable>(
  elements: T[],
  sortOrder: SortOrder,
): T[] => {
  if (elements.length === 0) {
    return elements;
  }

  const sortKey = "created_at" in elements[0] ? "created_at" : "occured_at";
  let sortedElements = _.sortBy(elements, sortKey);

  if (sortOrder === SortOrder.NewestFirst) {
    sortedElements = sortedElements.reverse();
  }

  return sortedElements;
};

export type TimelineElement = {
  occured_at: Date;
} & (
  | {
      item: TimelineItem;
      element?: never;
    }
  | {
      item?: never;
      element: React.ReactElement;
    }
);

const willBeRendered = (element: TimelineElement): boolean => {
  // Assume that if it's a "X hours later" element, it can be rendered
  if (!element.item) {
    return true;
  }

  const Component = TimelineItemTypeToComponentMap[element.item?.item_type];

  if (!Component) {
    return false;
  }

  return true;
};

const isNoteElementOrBeforeNoteElement = (
  elements: TimelineElement[],
  index: number,
): boolean => {
  const currentItemIsNote =
    elements[index].item?.item_type === TimelineItemItemTypeEnum.TimelineNote;

  const nextItemIsNote =
    index !== elements.length - 1 &&
    elements[index + 1].item?.item_type ===
      TimelineItemItemTypeEnum.TimelineNote;
  return currentItemIsNote || nextItemIsNote;
};

function addTimeGapItems(items: TimelineElement[]): TimelineElement[] {
  // iterate over all items, adding a 'x hours later' element to indicate large
  // time gaps in the timeline
  const finalItems: TimelineElement[] = [];
  let lastItemTimestamp: Date | null = null;

  items.forEach((element) => {
    // if the element isn't an item, or is a note, we just
    // push it onto the results and no op
    if (!element.item || element.item?.item_type === "timeline_note") {
      finalItems.push(element);
      return;
    }

    const occuredAt = element.item?.occured_at || element.occured_at;

    if (lastItemTimestamp) {
      const timeSinceLastItem =
        occuredAt.getTime() - lastItemTimestamp.getTime();
      if (timeSinceLastItem / 1000 >= 2 * 60 * 60) {
        // more than a 2 hour gap?
        // add a 'x hours later' element
        const inbetweenTime =
          lastItemTimestamp.getTime() +
          (occuredAt.getTime() - lastItemTimestamp.getTime()) / 2;

        // push a new TimeGap item to results, in between the last item and this one
        finalItems.push({
          occured_at: new Date(inbetweenTime),
          element: <TimelineItemTimeGap duration={timeSinceLastItem} />,
        });
      }
    }
    // update the last item timestamp
    lastItemTimestamp = occuredAt;

    // push the current item onto the results
    finalItems.push(element);
  });
  return finalItems;
}

const calculateEventOccuredAt = (
  collectedElements: TimelineElement[],
  index: number,
): Date => {
  const itemOccuredAt = findOccuredAt(collectedElements[index]);

  // if it's not the final item, find the midpoint between the two timeline items we're adding it between
  if (index !== collectedElements.length - 1) {
    const nextItemOccuredAt = findOccuredAt(collectedElements[index + 1]);
    return new Date(
      (itemOccuredAt.getTime() + nextItemOccuredAt.getTime()) / 2,
    );
  }

  // otherwise add one second to the current item's occuredAt
  return new Date(itemOccuredAt.getTime() + 1000);
};

const findOccuredAt = (element: TimelineElement): Date => {
  const occuredAt = element.item?.occured_at || element.occured_at;
  if (!occuredAt) {
    throw new Error(
      "unreachable: expected one of item or group to have occured_at",
    );
  }

  return occuredAt;
};
