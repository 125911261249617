import { Callout, CalloutTheme, ModalFooter, TabModalPane } from "@incident-ui";
import { isEmpty } from "lodash";
import { ReactElement, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { InputV2 } from "src/components/@shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "src/components/@shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "src/components/@shared/forms/v2/inputs/TextareaV2";
import {
  FollowUp,
  FollowUpsConnectExternalIssueRequestBodyProviderEnum,
  IssueTrackersClickUpCreateIssueRequestBody,
  IssueTrackersClickUpTypeaheadOptionsFieldEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation } from "src/utils/swr";

export type ClickUpFormData = IssueTrackersClickUpCreateIssueRequestBody & {
  // These are required to navigate to a list
  team_id: string;
  space_id: string;
  folder_id: string;
  url: string;
};

export const ExportToClickUpModal = ({
  followUp,
  onClose,
  updateCallback,
  isPrivateIncident,
}: {
  followUp: FollowUp;
  isPrivateIncident?: boolean;
  onClose: () => void;
  updateCallback: (followUp: FollowUp) => void;
}): ReactElement | null => {
  const [connectToExistingTicket, setConnectToExistingTicket] =
    useState<boolean>(false);

  const formMethods = useForm<ClickUpFormData>({
    mode: "onSubmit",
    defaultValues: {
      title: followUp.title,
      description: followUp.description,
      url: "",
    },
  });
  const { setError } = formMethods;

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    // The thing we really want updated is the list of follow ups on the incident
    // details page
    "followUpsList",
    { incidentId: followUp.incident_id },
    async (apiClient, data: ClickUpFormData) => {
      const { follow_up: newFollowUp } = connectToExistingTicket
        ? await apiClient.followUpsConnectExternalIssue({
            id: followUp.id,
            connectExternalIssueRequestBody: {
              provider:
                FollowUpsConnectExternalIssueRequestBodyProviderEnum.ClickUp,
              url: data.url,
            },
          })
        : await apiClient.issueTrackersClickUpCreateIssue({
            clickUpCreateIssueRequestBody: {
              follow_up_id: followUp.id,
              title: data.title,
              description: data.description,
              list_id: data.list_id,
              assignee_id: data.assignee_id || undefined,
            },
          });

      updateCallback(newFollowUp);
    },
    { onSuccess: onClose, setError },
  );

  return (
    <Form.TabModal
      genericError={genericError}
      formMethods={formMethods}
      onSubmit={onSubmit}
      title="Export to ClickUp"
      analyticsTrackingId="add-to-clickup"
      onClose={onClose}
      onTabChange={(tab: string) =>
        setConnectToExistingTicket(tab === "connect")
      }
      tabs={[
        {
          id: "add",
          label: "Create new",
        },
        {
          id: "connect",
          label: "Connect existing",
        },
      ]}
      footer={
        <ModalFooter
          confirmButtonText={connectToExistingTicket ? "Connect" : "Create"}
          saving={saving}
          onClose={onClose}
          confirmButtonType="submit"
        />
      }
    >
      <TabModalPane tabId="add">
        <div className="space-y-4">
          {isPrivateIncident && (
            <Callout theme={CalloutTheme.Warning}>
              This is a private incident. This issue will be visible to anyone
              with access to your ClickUp project.
            </Callout>
          )}
          <p className="text-sm">
            We&apos;ll automatically sync any changes in ClickUp.
          </p>
          <InputV2
            formMethods={formMethods}
            label="Title"
            name="title"
            required={
              connectToExistingTicket ? undefined : "Please enter a title"
            }
          />
          <TextareaV2
            formMethods={formMethods}
            label="Description"
            name="description"
            required={
              connectToExistingTicket ? undefined : "Please enter a description"
            }
            placeholder="Any more details you want to add while you're here?"
            rows={4}
          />
          <ClickUpBulkExportableFields formMethods={formMethods} />
        </div>
      </TabModalPane>
      <TabModalPane tabId="connect" className="space-y-4">
        <p className="text-sm">
          Connect this follow-up to an existing ClickUp issue by pasting in the
          issue URL.
        </p>
        <Callout theme={CalloutTheme.Info}>
          If you link this to an issue tracker, we will use that as the source
          of truth, and the follow-up will no longer be editable in incident.io.
        </Callout>
        <InputV2
          formMethods={formMethods}
          name="url"
          label="Existing issue URL"
          required={connectToExistingTicket ? "Please enter a URL" : undefined}
          placeholder="https://app.clickup.com/t/72a371bd"
        />
      </TabModalPane>
    </Form.TabModal>
  );
};

// ClickUpBulkExportableFields holds the fields that are present on both the singular export and the bulk
// export modals. If you're adding a new field, please consider whether it should be added here (ie.
// a user might want to set it for multiple actions at once), or above (ie. a user will only want to
// set it for a particular followUp).
//
// The way ClickUp works is that:
// - Teams in the API are called Workspaces in the product, and are top-level
// - Spaces live within a Team
// - Spaces have both folderless Lists, and Lists that are with a Folder.
//
// A List can be thought of as a project, and Spaces/Folders/Teams are just ways
// of organising that.
//
// For more info, see https://clickup.com/hierarchy-guide
export const ClickUpBulkExportableFields = ({
  formMethods,
}: {
  formMethods: UseFormReturn<ClickUpFormData>;
}): ReactElement => {
  const { watch } = formMethods;
  const [teamId, spaceId, folderId, listId] = watch([
    "team_id",
    "space_id",
    "folder_id",
    "list_id",
  ]);

  return (
    <>
      <SelectTeam formMethods={formMethods} />
      {!isEmpty(teamId) && (
        <SelectSpace formMethods={formMethods} teamId={teamId} />
      )}
      {!isEmpty(spaceId) && (
        <SelectFolder formMethods={formMethods} spaceId={spaceId} />
      )}
      {!isEmpty(spaceId) && (
        <SelectLists
          formMethods={formMethods}
          spaceId={spaceId}
          folderId={folderId}
        />
      )}
      {!isEmpty(listId) && (
        <SelectAssignee formMethods={formMethods} listId={listId} />
      )}
    </>
  );
};

const SelectTeam = ({
  formMethods,
}: {
  formMethods: UseFormReturn<ClickUpFormData>;
}) => {
  const { resetField } = formMethods;
  const { data, isLoading } = useAPI(
    "issueTrackersClickUpTypeaheadOptions",
    {
      field: IssueTrackersClickUpTypeaheadOptionsFieldEnum.Team,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  const teams = data.typeahead_options;

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      label="ClickUp Workspace"
      name="team_id"
      required="Please select a workspace"
      options={teams || []}
      placeholder="Select workspace"
      isLoading={isLoading}
      onValueChange={() => {
        // When the team changes, clear the space, folder, list, and assignee
        resetField("space_id");
        resetField("folder_id");
        resetField("list_id");
        resetField("assignee_id");
      }}
    />
  );
};

const SelectSpace = ({
  formMethods,
  teamId,
}: {
  formMethods: UseFormReturn<ClickUpFormData>;
  teamId: string;
}) => {
  const { data, isLoading } = useAPI(
    "issueTrackersClickUpTypeaheadOptions",
    {
      field: IssueTrackersClickUpTypeaheadOptionsFieldEnum.Space,
      teamId,
    },
    { fallbackData: { typeahead_options: [] } },
  );
  const spaces = data.typeahead_options;
  const { resetField } = formMethods;

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      label="ClickUp Space"
      name={"space_id"}
      required="Please select a space"
      options={spaces || []}
      placeholder="Select space"
      isLoading={isLoading}
      onValueChange={() => {
        // When the space changes, clear the folder, list, and assignee
        resetField("folder_id");
        resetField("list_id");
        resetField("assignee_id");
      }}
    />
  );
};

const SelectFolder = ({
  formMethods,
  spaceId,
}: {
  formMethods: UseFormReturn<ClickUpFormData>;
  spaceId: string;
}) => {
  const { data, isLoading } = useAPI(
    "issueTrackersClickUpTypeaheadOptions",
    {
      field: IssueTrackersClickUpTypeaheadOptionsFieldEnum.Folder,
      spaceId,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  const folders = data.typeahead_options;

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      label="ClickUp Folder"
      name={"folder_id"}
      isClearable
      options={folders || []}
      placeholder="Filter lists by folder"
      isLoading={isLoading}
    />
  );
};

const SelectLists = ({
  formMethods,
  spaceId,
  folderId,
}: {
  formMethods: UseFormReturn<ClickUpFormData>;
  spaceId: string;
  folderId: string;
}) => {
  const { resetField } = formMethods;

  const { data, isLoading } = useAPI(
    "issueTrackersClickUpTypeaheadOptions",
    {
      field: IssueTrackersClickUpTypeaheadOptionsFieldEnum.List,
      // Omit from the request at all things which are empty
      spaceId: spaceId || undefined,
      folderId: folderId || undefined,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  const lists = data.typeahead_options;

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      label="ClickUp List"
      name={"list_id"}
      required="Please select a list"
      options={lists || []}
      placeholder="Select list"
      isLoading={isLoading}
      onValueChange={() => {
        // When the list changes, clear the assignee
        resetField("assignee_id");
      }}
    />
  );
};

const SelectAssignee = ({
  formMethods,
  listId,
}: {
  formMethods: UseFormReturn<ClickUpFormData>;
  listId: string;
}) => {
  const { data, isLoading } = useAPI(
    "issueTrackersClickUpTypeaheadOptions",
    {
      field: IssueTrackersClickUpTypeaheadOptionsFieldEnum.Member,
      listId,
    },
    { fallbackData: { typeahead_options: [] } },
  );

  const members = data.typeahead_options;

  return (
    <StaticSingleSelectV2
      formMethods={formMethods}
      label="ClickUp assignee"
      name={"assignee_id"}
      options={members || []}
      placeholder="Select assignee"
      isLoading={isLoading}
    />
  );
};
