import { format } from "date-fns";
import { tcx } from "src/utils/tailwind-classes";

type TimelineDateGroupProps = {
  date: Date;
  children: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
  childrenClassName?: string;
};

export const TimelineDateGroup = ({
  date,
  children,
  actions,
  className,
  childrenClassName,
}: TimelineDateGroupProps) => {
  return (
    <div className="">
      <div className={tcx("flex justify-between items-center", className)}>
        <div className="text-xs-med text-content-tertiary">
          {format(date, "EEEE do MMMM")}
        </div>
        <div>{actions}</div>
      </div>
      <div className={childrenClassName}>{children}</div>
    </div>
  );
};
