import { IconEnum, Markdown } from "@incident-ui";
import GithubLogo from "src/components/timeline/github_logo.png";
import { TimelineItemRenderProps } from "src/components/timeline/timeline-items/TimelineItem";
import styles from "src/components/timeline/TimelineItems.module.scss";
import { Incident, TimelineItemObject } from "src/contexts/ClientContext";

import { TimelineItemContentBox } from "./TimelineItemContentBox";

export const TimelineItemSlackInferGithub = (
  _: Incident,
  item: TimelineItemObject,
): TimelineItemRenderProps => {
  if (!item.slack_infer_github) {
    throw new Error(
      "malformed timeline item: slack_infer_github was missing slack_infer_github field",
    );
  }

  return {
    avatarUrl: GithubLogo,
    secondaryAvatarUrl: item.slack_infer_github.user.avatar_url,
    icon: IconEnum.Link,
    description: (
      <>
        <strong>{item.slack_infer_github.user.name}</strong> mentioned a{" "}
        <strong>GitHub Link</strong>
      </>
    ),
    children: (
      <TimelineItemContentBox>
        <Markdown className={styles.contentBox}>
          {item.slack_infer_github.slack_message.text}
        </Markdown>
      </TimelineItemContentBox>
    ),
  };
};
