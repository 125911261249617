import { TemplatedTextEditor } from "@incident-shared/forms/v1/TemplatedText/TemplatedTextEditor";
import {
  Button,
  ButtonModal,
  DropdownMenu,
  DropdownMenuItem,
  GenericErrorMessage,
  IconEnum,
  Loader,
  ModalContent,
  Spinner,
} from "@incident-ui";
import { captureMessage } from "@sentry/react";
import _ from "lodash";
import React from "react";
import {
  IncidentsBuildScopeContextEnum,
  PostmortemTemplateBlock,
  PostmortemTemplateBlockBlockTypeEnum,
} from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPI } from "src/utils/swr";
import { v4 as uuid4 } from "uuid";

import { useIncidentScope } from "../../../../hooks/useIncidentScope";
import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSortableList } from "../../SettingsSortableList";
import { PostmortemTemplatePreview } from "./PostmortemTemplatePreview";

export const PostmortemTemplateContentBlocksEditor = ({
  blocks,
  formDisabled,
  setBlocks,
}: {
  formDisabled: boolean;
  blocks: Array<PostmortemTemplateBlock & { id: string }> | null;
  setBlocks: (blocks: Array<PostmortemTemplateBlock & { id: string }>) => void;
}): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(null);

  // There is a bug, whereby if the user types too quickly, the cursor will
  // jump to the end of the text.
  // This is because `setBlocks` is called in the onChange(), but it is async.
  // As `blocks` is quite high-level, there's lots of rerendering to be done.
  // As a hacky fix. we debounce the update of the blocks, to prevent excessive
  // re-renders. A proper fix would be to re-render less on each change.
  const debounceUpdateBlocks = _.debounce((newBlocks) => {
    setBlocks(newBlocks);
  }, 250);

  const {
    data: { blocks: standardContentBlocks },
    isLoading: loadingStandardContentBlocks,
    error,
  } = useAPI("postmortemsListTemplateStandardContentBlocks", undefined, {
    fallbackData: { blocks: [] },
  });

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.FullScope,
  );

  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (!blocks) {
    setBlocks(
      standardContentBlocks.map((blockResponse) => ({
        id: uuid4(),
        block_type:
          blockResponse.block_type as string as PostmortemTemplateBlockBlockTypeEnum,
        Content: blockResponse.Content,
      })),
    );
  }

  if (
    loadingStandardContentBlocks ||
    scopeLoading ||
    resourcesLoading ||
    !blocks ||
    !standardContentBlocks
  ) {
    return <Loader />;
  }
  if (error || scopeError || resourcesError) {
    return (
      <GenericErrorMessage
        hideHeading
        description={`We weren't able to fetch available ${postmortemNameFormatted} standard content blocks`}
      />
    );
  }

  const removeBlock = (index: number) => {
    const newBlocks = [...blocks];
    newBlocks.splice(index, 1);
    setBlocks(newBlocks);
  };
  const appendBlock = (block: PostmortemTemplateBlock) => {
    const newBlocks = [...blocks];
    newBlocks.push({
      ...block,
      id: uuid4(),
    });
    setBlocks(newBlocks);
  };
  return (
    <>
      <SettingsSortableList
        canEdit={!formDisabled}
        items={blocks.map((block, index) => ({
          id: block.id,
          rank: index,
          block,
        }))}
        renderItem={(item, index) => (
          <div className="w-full">
            <SettingsListItem
              title={
                isCustom(item.block)
                  ? undefined
                  : getBlockLabel(item.block, standardContentBlocks)
              }
              buttons={{
                delete: formDisabled
                  ? undefined
                  : {
                      onDelete: () => removeBlock(index),
                      noDeleteConfirmation: true,
                    },
              }}
              description={
                isCustom(item.block) && (
                  <TemplatedTextEditor
                    className="text-content-primary"
                    id={item.block.id}
                    value={item.block.Content}
                    resources={resources}
                    scope={scope}
                    includeVariables={true}
                    includeExpressions={false}
                    format="rich"
                    onChange={(v) => {
                      if (!_.isEqual(v, item.block.Content)) {
                        const newBlocks = [...blocks];
                        newBlocks[index].Content = v;
                        debounceUpdateBlocks(newBlocks);
                      }
                    }}
                  />
                )
              }
            />
          </div>
        )}
        updateItemRanks={(items) => setBlocks(items.map((item) => item.block))}
      />
      <div className="pt-3 flex gap-2 justify-between">
        <div className="flex gap-2">
          <AddStandardContentBlockButton
            loading={loadingStandardContentBlocks}
            standardContentBlocks={standardContentBlocks}
            disabled={formDisabled}
            disabledBlockTypes={
              new Set(blocks.map(({ block_type }) => block_type))
            }
            onAddContentBlock={(addedContentBlockType) => {
              const canAddContentBlock = !blocks.some(
                (block) => block.block_type === addedContentBlockType,
              );
              if (canAddContentBlock) {
                appendBlock({ block_type: addedContentBlockType });
              }
            }}
          />
          <AddCustomContentBlockButton
            disabled={formDisabled}
            onClick={() =>
              appendBlock({
                block_type: PostmortemTemplateBlockBlockTypeEnum.Custom,
                label: "Custom Content Block",
                Content: undefined,
              })
            }
          />
        </div>
        <ButtonModal
          buttonProps={{
            icon: IconEnum.View,
            analyticsTrackingId: "preview-postmortem-doc-in-create-edit",
            children: ["Preview"],
            disabled: !blocks || blocks.length === 0,
          }}
          renderModalContents={() => (
            <ModalContent>
              <PostmortemTemplatePreview blocks={blocks} />
            </ModalContent>
          )}
          modalProps={{
            title: `Preview ${postmortemNameFormatted} document template`,
            analyticsTrackingId: "preview-postmortem-document-template",
            isExtraLarge: true,
          }}
        />
      </div>
    </>
  );
};

interface AddContentBlockButtonProps {
  disabled: boolean | undefined;
  loading: boolean;
  standardContentBlocks: Array<PostmortemTemplateBlock>;
  disabledBlockTypes: Set<PostmortemTemplateBlockBlockTypeEnum>;
  onAddContentBlock: (blockType: PostmortemTemplateBlockBlockTypeEnum) => void;
}

const AddStandardContentBlockButton = ({
  disabled,
  standardContentBlocks,
  disabledBlockTypes,
  loading,
  onAddContentBlock,
}: AddContentBlockButtonProps): React.ReactElement => {
  const dropdownMenuItems = standardContentBlocks
    ?.filter(
      (block) =>
        block.block_type !== PostmortemTemplateBlockBlockTypeEnum.Custom,
    )
    .map((contentBlock) => {
      const blockType =
        contentBlock.block_type as string as PostmortemTemplateBlockBlockTypeEnum;
      if (!contentBlock.label) {
        captureMessage("expected label to be defined for non-custom block", {
          extra: { contentBlock: contentBlock },
        });
      }
      return (
        <DropdownMenuItem
          key={contentBlock.block_type}
          onSelect={() => onAddContentBlock(blockType)}
          label={contentBlock.label || ""}
          analyticsTrackingId={`add-content-block-${contentBlock.block_type}`}
          disabled={disabledBlockTypes.has(blockType)}
          icon={
            disabledBlockTypes.has(blockType) ? IconEnum.Tick : IconEnum.Add
          }
        />
      );
    });
  return (
    <DropdownMenu
      side="bottom"
      triggerButton={
        <Button
          icon={loading ? undefined : IconEnum.Add}
          analyticsTrackingId="post-mortem-add-content-block"
        >
          {loading && <Spinner />}
          Add preset block
        </Button>
      }
      disabled={disabled || loading || standardContentBlocks?.length === 0}
    >
      {dropdownMenuItems}
    </DropdownMenu>
  );
};

const AddCustomContentBlockButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}): React.ReactElement => {
  return (
    <Button
      analyticsTrackingId={"add-content-block-custom"}
      icon={IconEnum.Add}
      onClick={onClick}
      disabled={disabled}
    >
      Add custom block
    </Button>
  );
};

const isCustom = ({
  block_type,
}: {
  block_type: PostmortemTemplateBlockBlockTypeEnum;
}) => block_type === PostmortemTemplateBlockBlockTypeEnum.Custom;

const getBlockLabel = (
  block: PostmortemTemplateBlock,
  standardBlocks: PostmortemTemplateBlock[],
): string | undefined => {
  // Custom blocks do not have labels
  if (block.block_type === "custom") {
    return undefined;
  }
  const matchingBlock = standardBlocks.find(
    (stdBlock) =>
      (stdBlock.block_type as string as PostmortemTemplateBlockBlockTypeEnum) ===
      block.block_type,
  );

  if (!matchingBlock) {
    throw new Error(
      "Unreachable: expected block type to match standard block ",
    );
  }

  return matchingBlock.label;
};
