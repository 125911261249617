import {
  AlertAttribute,
  AlertsMarkSuggestionRequestBodyResponseEnum,
  AlertsMarkSuggestionResponseBody,
  AlertSourceAttributeSuggestion,
  AlertSourceConfig,
  AlertsSuggestAttributesResponseBody,
  EngineParamBindingPayload,
  EngineScope,
} from "@incident-io/api";
import { RainbowContainer } from "@incident-shared/aisuggestions/RainbowContainer";
import { getVariableScope } from "@incident-shared/engine";
import { ReferenceWithExample } from "@incident-shared/engine/expressions/ExpressionsEditor";
import { ViewExpression } from "@incident-shared/engine/expressions/ViewExpression";
import { EngineBinding } from "@incident-shared/engine/labels/EngineBinding";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonSize,
  ButtonTheme,
  Loader,
} from "@incident-ui";
import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "src/components/insights/assistant/AssistantAvatar";
import { useClient } from "src/contexts/ClientContext";
import { useAllResources } from "src/hooks/useResources";
import { EnrichedScope } from "src/utils/scope";
import { useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { AddAcceptedSuggestionToFormState } from "../useAttributeExpressions";
import { useOutstandingSuggestions } from "./useOutstandingSuggestions";

// Show a list of suggestions that can be accepted or declined. This component
// can only be used within the alert source form, as it modifies the alert
// source form data (it probably shouldn't but this is fine for now) to add the
// suggestion should it be accepted.
export const AlertSourceAttributeSuggestions = ({
  alertSourceConfig,
  scopeWithExpressions,
  attributeBindings,
  attributes,
  addAcceptedSuggestionToFormState,
}: {
  alertSourceConfig: AlertSourceConfig;
  scopeWithExpressions: EnrichedScope<ReferenceWithExample>;
  attributeBindings: Record<string, EngineParamBindingPayload>;
  attributes: AlertAttribute[];
  addAcceptedSuggestionToFormState: AddAcceptedSuggestionToFormState;
}) => {
  // Fetch outstanding suggestions for this alert source. The logic around what
  // is outstanding exists as a combination of this hook and the API backend.
  const {
    data: suggestedAttributesData,
    suggestions: outstandingSuggestions,
    error: suggestedAttributesError,
  } = useOutstandingSuggestions({
    alertSourceConfig,
    attributes,
    attributeBindings,
  });

  // Grab a hook that allows us to refresh suggestions.
  const revalidateSuggestions = useRevalidate(["alertsSuggestAttributes"]);

  // Once we've gone through all suggestions we provide the user with a retry
  // button that should forcibly reset them.
  const { trigger: resetAttributes, isMutating: resetLoading } = useAPIMutation(
    "alertsSuggestAttributes",
    {
      id: alertSourceConfig.id,
      suggestAttributesRequestBody: {
        reset: false,
      },
    },
    async (apiClient) =>
      apiClient.alertsSuggestAttributes({
        id: alertSourceConfig.id,
        suggestAttributesRequestBody: {
          reset: true,
        },
      }),
  );

  // Take the first two suggestions only, to avoid dominating the UI with
  // suggestions. This is a bit of a hack and should be done in the backend.
  const suggestions = outstandingSuggestions?.slice(0, 2) || [];

  return !suggestedAttributesError ? (
    <AnimatedCardStack
      suggestions={suggestions}
      onAction={() => {
        revalidateSuggestions();
      }}
      scopeWithExpressions={scopeWithExpressions}
      resetAttributes={resetAttributes}
      alertSourceConfig={alertSourceConfig}
      resetLoading={resetLoading}
      addAcceptedSuggestionToFormState={addAcceptedSuggestionToFormState}
      hasSuggestions={(suggestedAttributesData?.suggestions || []).length > 0}
    />
  ) : null;
};

// We only show the first two suggestions but want to animate them nicely. This
// means we fade them in, then if you accept/reject a suggestion we animate it
// out first, then allow the next card to move up, and when we get to the end of
// the list we're careful not to allow layout shift to animate up further than
// the size of the empty state that we're about to display.
const AnimatedCardStack = ({
  suggestions,
  onAction,
  scopeWithExpressions,
  resetAttributes,
  alertSourceConfig,
  resetLoading,
  hasSuggestions,
  addAcceptedSuggestionToFormState,
}: {
  addAcceptedSuggestionToFormState: AddAcceptedSuggestionToFormState;
  suggestions: AlertSourceAttributeSuggestion[];
  onAction: () => void;
  scopeWithExpressions: EnrichedScope<ReferenceWithExample>;
  resetAttributes: (args: {
    id: string;
    suggestAttributesRequestBody: {
      reset: boolean;
    };
  }) => Promise<AlertsSuggestAttributesResponseBody>;
  alertSourceConfig: AlertSourceConfig;
  resetLoading: boolean;
  hasSuggestions: boolean;
}) => {
  const [removingSuggestion, setRemovingSuggestion] = useState(null);
  const [showEmptyState, setShowEmptyState] = useState(
    suggestions.length === 0,
  );

  useEffect(() => {
    if (suggestions.length === 0 && !removingSuggestion) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
    }
  }, [suggestions, removingSuggestion]);

  const handleAction = async (suggestion) => {
    try {
      // Delay for a bit to allow the animation to play out.
      await new Promise((resolve) => setTimeout(resolve, 300));
      setRemovingSuggestion(suggestion.id);
      onAction();
    } finally {
      setRemovingSuggestion(null);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <ShinyHeader>
        {suggestions.length > 0
          ? "Found suggested attributes"
          : `No${hasSuggestions ? " more " : ""} suggested attributes`}
      </ShinyHeader>
      <div className="flex flex-col gap-4">
        <AnimatePresence mode="popLayout">
          {suggestions.map((suggestion) => (
            <motion.div
              key={suggestion.id}
              layout
              initial={{ opacity: 1, scale: 1 }}
              exit={{
                opacity: 0,
                scale: 0.8,
                transition: { duration: 0.2 },
              }}
              transition={{
                layout: { delay: 0.2, duration: 0.3 },
              }}
            >
              <motion.div
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <AlertSourceSuggestion
                  suggestion={suggestion}
                  alertSourceConfigId={alertSourceConfig.id}
                  scope={scopeWithExpressions}
                  onAccept={async (result) => {
                    if (!result.attribute_id) {
                      throw new Error(
                        "attribute_id not returned from endpoint",
                      );
                    }

                    addAcceptedSuggestionToFormState({
                      suggestion,
                      attributeId: result.attribute_id,
                    });
                    await handleAction(suggestion);
                  }}
                  onDecline={async () => await handleAction(suggestion)}
                />
              </motion.div>
            </motion.div>
          ))}
          {showEmptyState && (
            <EmptyState
              key="empty-state"
              alertSourceConfig={alertSourceConfig}
              resetLoading={resetLoading}
              resetAttributes={resetAttributes}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

// Show a content box with an offer to retry suggestion generation if we have
// already gone through our suggestions.
const EmptyState = ({
  alertSourceConfig,
  resetLoading,
  resetAttributes,
}: {
  alertSourceConfig: AlertSourceConfig;
  resetLoading: boolean;
  resetAttributes: (args: {
    id: string;
    suggestAttributesRequestBody: {
      reset: boolean;
    };
  }) => void;
}) => (
  <RainbowContainer className="border border-purple-100 rounded-lg bg-clip-border overflow-hidden p-4">
    <div className="flex flex-row gap-4">
      <div className="flex flex-row gap-3">
        <Icon id={IconEnum.SparklesColoured} size={IconSize.Medium} />
        <div className="flex text-sm text-content-primary">
          To get more suggestions, update your catalog, send more test alerts,
          or retry the search.
        </div>
      </div>
      <div>
        <Button
          analyticsTrackingId="alert-source-retry-suggestions"
          loading={resetLoading}
          onClick={() => {
            resetAttributes({
              id: alertSourceConfig.id,
              suggestAttributesRequestBody: {
                reset: true,
              },
            });
          }}
        >
          Retry search
        </Button>
      </div>
    </div>
  </RainbowContainer>
);

const ShinyHeader = ({ children }: { children: React.ReactNode }) => (
  <div
    className="text-sm-bold"
    style={{
      width: "fit-content",
      background: "linear-gradient(45deg, #3b82f6, #c084fc, #db2777)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    }}
  >
    {children}
  </div>
);

// Show a suggestion with an accept/decline button.
const AlertSourceSuggestion = ({
  suggestion,
  alertSourceConfigId,
  scope,
  onAccept,
  onDecline,
}: {
  suggestion: AlertSourceAttributeSuggestion;
  alertSourceConfigId: string;
  scope: EngineScope;
  onAccept: (result: AlertsMarkSuggestionResponseBody) => Promise<void>;
  onDecline: () => Promise<void>;
}) => {
  const apiClient = useClient();

  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const { resources, resourcesLoading, resourcesError } = useAllResources();

  if (resourcesLoading) return <Loader />;
  if (resourcesError) return null;
  if (!suggestion?.attribute_payload || !suggestion?.attribute_expression)
    return null;

  const handleAccept = async () => {
    try {
      setIsAccepting(true);

      const result: AlertsMarkSuggestionResponseBody =
        await apiClient.alertsMarkSuggestion({
          id: alertSourceConfigId,
          markSuggestionRequestBody: {
            response: AlertsMarkSuggestionRequestBodyResponseEnum.Accept,
            suggestion_id: suggestion.id,
            attribute_id: suggestion.attribute_payload.id,
          },
        });

      // This should always return an attribute ID.
      if (!result.attribute_id) {
        throw new Error("attribute_id not returned from endpoint");
      }

      await onAccept(result);
    } finally {
      setIsAccepting(false);
    }
  };

  const handleDecline = async () => {
    setIsDeclining(true);
    try {
      await apiClient.alertsMarkSuggestion({
        id: alertSourceConfigId,
        markSuggestionRequestBody: {
          response: AlertsMarkSuggestionRequestBodyResponseEnum.Decline,
          suggestion_id: suggestion.id,
        },
      });

      await onDecline();
    } finally {
      setIsDeclining(false);
    }
  };

  return (
    <RainbowContainer className="border border-purple-100 rounded-lg bg-clip-border overflow-hidden">
      <div className="flex flex-col gap-4 p-4">
        <div className="flex items-center gap-2">
          <AssistantAvatar
            size={IconSize.Small}
            background={AssistantAvatarBackground.Purple}
            rounded={false}
          />
          <div className="flex text-sm text-content-secondary">
            <span>Suggested attribute for&nbsp;</span>
            <span className="text-content-primary font-medium">
              {suggestion.attribute_payload.name}
            </span>
          </div>
        </div>

        <ViewExpression
          expression={suggestion.attribute_expression}
          scope={scope}
          footer={
            (suggestion.attribute_values || []).length === 0 ? (
              <div className="text-sm text-content-secondary">
                No matches found in recent alerts
              </div>
            ) : (
              <div className="flex items-center gap-1">
                {suggestion.attribute_values
                  ?.slice(0, 3)
                  .map((value, idx) => (
                    <EngineBinding
                      key={idx}
                      className="flex flex-row"
                      variableScope={getVariableScope(scope, resources)}
                      binding={{ value }}
                      resourceType={suggestion.attribute_payload.type}
                      displayExpressionAs="pill"
                    />
                  ))}
                <div className="flex flex-row items-center gap-1 text-sm text-content-secondary">
                  {suggestion.attribute_values &&
                    suggestion.attribute_values.length > 3 && (
                      <Badge
                        theme={BadgeTheme.Tertiary}
                        size={BadgeSize.Medium}
                        className={"text-content-primary"}
                      >
                        +{suggestion.attribute_values.length - 3}
                      </Badge>
                    )}
                  <span>found in alerts</span>
                </div>
              </div>
            )
          }
        />

        <div className="flex gap-3">
          <Button
            analyticsTrackingId="accept-suggestion"
            onClick={handleAccept}
            theme={ButtonTheme.Primary}
            size={ButtonSize.Small}
            title="Add: you can edit the attribute before saving"
            icon={IconEnum.Tick}
            loading={isAccepting}
          >
            Accept
          </Button>
          <Button
            analyticsTrackingId="reject-suggestion"
            onClick={handleDecline}
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            title="Skip: we'll remove the suggestion"
            icon={IconEnum.Close}
            loading={isDeclining}
          >
            Skip
          </Button>
        </div>
      </div>
    </RainbowContainer>
  );
};
